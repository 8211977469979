import template from './model.html'
import { booleanToYesNo } from '@isoftdata/utility-string'
import klona from 'klona'
import pProps from 'p-props'
import domValue from 'dom-value'

//Ractive Components
import makeAutocomplete from '@isoftdata/autocomplete'
import makeYearInput from '@isoftdata/year-input'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeTable from '@isoftdata/table'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'

const defaultModel = Object.freeze({
	active: true,
	make: '',
	model: '',
	firstYear: 0,
	lastYear: 9999,
	series: '',
	teardownName: 'DEFAULT',
	vehicleType: 'DEFAULT',
	partListAuthorityId: null,
	interchangeCode: '',
})

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.vehicle.make.model',
		route: 'model',
		querystringParameters: [ 'make', 'vehicleMakeModelId' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itModal: makeModal(),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itInput: makeInput({ twoway: true, lazy: false }),
				itAutocomplete: makeAutocomplete({ twoway: true }),
				itYearInput: makeYearInput({ twoway: true, lazy: false }),
			},
			modelClicked(model) {
				stateRouter.go(null, { vehicleMakeModelId: model.vehicleMakeModelId }, { inherit: true })
			},
			async addModel() {
				await this.set({
					editModalModel: klona({ ...defaultModel, make: this.get('make') }),
					showAddEditModal: true,
					showYearRange: false,
				})

				this.find('#modalModel').focus()
			},
			hasValidYearRange(model) {
				if (!model.firstYear || !model.lastYear) {
					return false
				} else {
					return !(model.firstYear === defaultModel.firstYear && model.lastYear === defaultModel.lastYear)
				}
			},
			async editModel(selectedVehicleMakeModelId) {
				const model = this.get('modelList')
					.find(model => model.vehicleMakeModelId === selectedVehicleMakeModelId)

				await this.set({
					editModalModel: klona(model),
					showAddEditModal: true,
					showYearRange: this.hasValidYearRange(model),
				})

				this.find('#modalMake')?.focus()
			},
			async saveModel(model) {
				try {
					const modelList = this.get('modelList')
					const savedModel = await mediator.call('emitToServer', 'save vehicle make model', { model })

					const modelIndex = modelList.findIndex(model => model.vehicleMakeModelId === savedModel.vehicleMakeModelId)
					if (modelIndex > -1 && modelList[modelIndex].make !== savedModel.make) {
						this.splice('modelList', modelIndex, 1)
					}

					this.upsert('modelList', 'vehicleMakeModelId', savedModel)
					return savedModel
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving vehicle model')
				}
			},
			showYearRangeChanged(event) {
				const shown = !!domValue(event?.srcElement)
				console.log(shown)
				if (shown) {
					this.find('#modalModelFirstYear')?.select()
					this.set({ 'editModalModel.firstYear': null, 'editModalModel.lastYear': null })
				} else {
					this.set({ 'editModalModel.firstYear': 0, 'editModalModel.lastYear': 9999 })
				}
			},
			scrollModelIntoView(vehicleMakeModelId) {
				this.find(`#model-${vehicleMakeModelId}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
			},
			async confirmModel(model, event) {
				event?.preventDefault()

				this.set({ modelSaving: true })
				try {
					console.log(model)
					const savedModel = await this.saveModel(model)
					stateRouter.go(null, { make: savedModel.make, vehicleMakeModelId: savedModel.vehicleMakeModelId })
					this.set({ showAddEditModal: false, selectedVehicleMakeModelId: savedModel.vehicleMakeModelId })
					this.scrollModelIntoView(savedModel.vehicleMakeModelId)
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving vehicle model')
				} finally {
					this.set({ modelSaving: false })
				}
			},
			async updateActiveState(model, element) {
				await this.saveModel({ ...model, active: !!domValue(element) })
			},
			computed: {
				computedTeardownList() {
					const teardownList = this.get('teardownList')
					const teardownName = this.get('editModalModel.teardownName')

					const teardownSet = new Set(teardownList)
					if (teardownName) {
						teardownSet.add(teardownName)
					}
					return Array.from(teardownSet)
				},
				canSaveModel() {
					const model = this.get('editModalModel')
					console.log(model)
					const validYearRange = this.get('showYearRange') ? this.hasValidYearRange(model) : true
					return model.make && model.model && model.series && validYearRange
				},
			},
		},
		async resolve(data, { make, vehicleMakeModelId }) {
			const { teardownList, modelList, vehicleTypeList } = await pProps({
				teardownList: mediator.call('emitToServer', 'load teardown template names', {}),
				modelList: make ? (await mediator.call('emitToServer', 'load vehicle models', { make, options: { allProps: true, activeOnly: false } })) : [],
				vehicleTypeList: mediator.call('emitToServer', 'load vehicle type list', {}),
			})

			return {
				modelList,
				vehicleTypeList: vehicleTypeList.map(type => type.vehicleType).filter(v => v),
				teardownList: teardownList.map(teardown => teardown.teardownName).filter(v => v),
				make,
				sortedModelList: [],
				selectedVehicleMakeModelId: parseInt(vehicleMakeModelId, 10) || null,
				editModalModel: {},
				yesNo: booleanToYesNo,
				showAddEditModal: false,
				modelSaving: false,
				columns: [
					{ property: 'active', name: 'Active' },
					{ property: 'model', name: 'Model', defaultSortColumn: true },
					{ property: 'firstYear', name: 'First Year' },
					{ property: 'lastYear', name: 'Last Year' },
					{ property: 'teardownName', name: 'Teardown' },
					{ property: 'vehicleType', name: 'Type' },
					{ property: 'series', name: 'Series' },
				],
				makeListObject: {},
			}
		},
		activate({ domApi: ractive }) {
			ractive.scrollModelIntoView(ractive.get('selectedVehicleMakeModelId'))

			ractive.observe('makeList', list => {
				ractive.set({
					makeListObject: list.reduce((acc, val) => {
						return { ...acc, [val.make]: val.make }
					}, {}),
				})
			})

			ractive.observe('editModalModel.model', (model, oldModel) => {
				const series = ractive.get('editModalModel.series')
				if (oldModel === series || !series) {
					ractive.set({ 'editModalModel.series': model })
				}
			}, { init: false })
		},
	})
}
