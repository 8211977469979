import template from './info.html'
import pProps from 'p-props'

//Ractive components
import makeTextarea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.vehicle.info',
		route: 'info',
		querystringParameters: [ 'vehicleId' ],
		template: {
			template,
			data: {},
			components: {
				itInput: makeInput({ twoway: true, lazy: true }),
				itSelect: makeSelect({ twoway: true, lazy: true }),
				itTextarea: makeTextarea({ twoway: true, lazy: true }),
			},
		},
		async resolve(data, parameters) {
			const vehicleList = await mediator.call('emitToServer', 'load vehicle', { vehicleId: parameters.vehicleId })
			const { sellerCompanyName, sellerContact } = vehicleList[0]

			return pProps({
				titleStatuses: mediator.call('emitToServer', 'load vehicle title statuses', {}),
				titleTypes: mediator.call('emitToServer', 'load vehicle title types', {}),
				states: mediator.call('emitToServer', 'load states', {}),
				selectedSellerType: (sellerCompanyName || sellerContact) ? 'company' : 'individual',
				previous: {
					sellerCompanyName: '',
					sellerContact: '',
					sellerFirstName: '',
					sellerLastName: '',
				},
			})
		},
		activate({ domApi }) {
			const ractive = domApi

			console.log(ractive.get('vehicle'))

			ractive.observe('vehicle', async vehicle => {
				const savedVehicle = await mediator.call('emitToServer', 'save vehicle', { vehicle })
				console.log(savedVehicle)
			}, { init: false })

			ractive.observe('selectedSellerType', type => {
				const previous = ractive.get('previous')
				const vehicle = ractive.get('vehicle')
				const isCompany = type === 'company'
				const isIndividual = type === 'individual'

				ractive.set({
					'vehicle.sellerCompanyName': isCompany ? previous.sellerCompanyName : '',
					'vehicle.sellerContact': isCompany ? previous.sellerContact : '',
					'vehicle.sellerFirstName': isIndividual ? previous.sellerFirstName : '',
					'vehicle.sellerLastName': isIndividual ? previous.sellerLastName : '',
					'previous': {
						sellerCompanyName: isCompany ? previous.sellerCompanyName : vehicle.sellerCompanyName,
						sellerContact: isCompany ? previous.sellerContact : vehicle.sellerContact,
						sellerFirstName: isIndividual ? previous.sellerFirstName : vehicle.sellerFirstName,
						sellerLastName: isIndividual ? previous.sellerLastName : vehicle.sellerLastName,
					},
				})
			}, { init: false })
		},
	})
}
