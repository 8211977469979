import template from './configuration.html'

//Ractive components
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

export default function({ stateRouter, hasPermission }) {
	stateRouter.addState({
		name: 'app.configuration',
		route: 'configuration',
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		defaultChild: 'user',
		resolve(_data, _parameters) {
			return Promise.resolve({ childStates: [
				{
					icon: 'user',
					title: 'Users',
					stateName: 'app.configuration.user',
					stateParameters: {},
				},
				{
					icon: 'users',
					title: 'Groups',
					stateName: 'app.configuration.group',
					stateParameters: {},
					hidden: !hasPermission('CAN_EDIT_USER_SETTINGS'),
				},
				{
					icon: 'store-alt',
					title: 'Stores',
					stateName: 'app.configuration.store',
					stateParameters: {},
					hidden: !hasPermission('PM_OPTIONS_EDIT'),
				},
				{
					icon: 'badge-percent',
					title: 'Payments & Taxes',
					stateName: 'app.configuration.payment-method-and-tax',
					stateParameters: {},
					hidden: !hasPermission('PM_OPTIONS_EDIT'),
				},
				{
					icon: 'print',
					title: 'Printers',
					stateName: 'app.configuration.printer',
					stateParameters: {},
					hidden: !hasPermission('PM_OPTIONS_EDIT'),
				},
				{
					icon: 'sliders',
					title: 'Settings',
					stateName: 'app.configuration.setting',
					stateParameters: {},
					hidden: !hasPermission('PM_OPTIONS_EDIT'),
				},
				{
					icon: 'engine',
					title: 'Inventory',
					stateName: 'app.configuration.inventory',
					stateParameters: {},
					hidden: !hasPermission('PM_PARTSDATA_VIEW'),
				},
				{
					icon: 'truck',
					title: 'Vehicles',
					stateName: 'app.configuration.vehicle',
					stateParameters: {},
					hidden: !hasPermission('PM_PARTSDATA_VIEW'),
				},
				{
					icon: 'sitemap',
					title: 'Integrations',
					stateName: 'app.configuration.integration',
					stateParameters: {},
					hidden: !hasPermission('PM_QUICKBOOKS_VIEW') || !hasPermission('PM_OPTIONS_EDIT'), // TODO: If we add another integration, this will have to change.
				},
				{
					icon: 'barcode-alt',
					title: 'Scanner',
					stateName: 'app.configuration.scanner',
					stateParameters: {},
				},
				{
					icon: 'chart-line',
					title: 'Dashboard',
					stateName: 'app.configuration.dashboard',
					stateParameters: {},
				},
				{
					icon: 'bug',
					title: 'Error Log',
					stateName: 'app.configuration.error-log',
					stateParameters: {},
				},
			] })
		},
		activate({ domApi: _ractive }) {
			//
		},
	})
}
