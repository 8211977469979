import template from './qanda.html'

//Ractive components
import makeQuestionAndAnswer from 'components/q-and-a'

const makeQuestion = vehicleQuestion => {
	return {
		questionId: vehicleQuestion.vehicleQuestionId,
		question: vehicleQuestion.question,
	}
}

const makeAnswer = vehicleAnswer => {
	return {
		answerId: vehicleAnswer.vehicleAnswerId,
		questionId: vehicleAnswer.vehicleQuestionId,
		answer: vehicleAnswer.answer,
	}
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle.qanda',
		route: 'q-and-a',
		querystringParameters: [ 'vehicleId', 'storeId' ],
		template: {
			template,
			data: {
				newQuestion: '',
			},
			components: {
				questionAndAnswer: makeQuestionAndAnswer({ logAndAlert, mediator }),
			},
		},
		data: {},
		async resolve(data, { vehicleId, storeId }) {
			const { vehicleQuestions, vehicleAnswers } = await mediator.call('emitToServer', 'load vehicle questions and answers', { vehicleId })
			return {
				vehicleQuestions: vehicleQuestions.map(makeQuestion),
				vehicleAnswers: vehicleAnswers.map(makeAnswer),
				saveQuestion: async question => {
					let vehicleQuestion = { question: question.question }

					if (question.questionId) {
						vehicleQuestion.vehicleQuestionId = question.questionId
					}

					//vehicle Id is passed so the backend can find the right Teardown to add the quesiton to
					const savedVehicleQuestion = await mediator.call('emitToServer', 'save vehicle question', {
						question: vehicleQuestion,
						vehicleId: Number(vehicleId),
					})

					return makeQuestion(savedVehicleQuestion)
				},
				saveAnswer: async answer => {
					let vehicleAnswer = {
						vehicleQuestionId: answer.questionId,
						vehicleId: Number(vehicleId),
						answer: answer.answer,
						storeId: Number(storeId) || 1,
					}

					if (answer.answerId) {
						vehicleAnswer.vehicleAnswerId = answer.answerId
					}

					const savedAnswer = await mediator
						.call('emitToServer', 'save vehicle answer', { answer: vehicleAnswer })

					return makeAnswer(savedAnswer)
				},
			}
		},
		activate({ domApi }) {
			/*const ractive = domApi

			ractive.observe('savingAnswerIds', val => console.log(val))
			ractive.observe('vehicleQuestions', val => console.log(val))
			ractive.observe('vehicleAnswers', val => console.log(val))
			ractive.observe('questionsAndAnswers', val => console.log(val))

			ractive.on('saveNewQuestion', context => {
				ractive.set({ savingNewQuestion: true }).then(() => {
					mediator.call('emitToServer', 'save vehicle question', {
						question: context.question,
						vehicleId: ractive.get('vehicleId'),
					}, (err, createdQuestion) => {
						if (err) {
							console.error(err)
							return alert('Error saving vehicle question')
						}

						ractive.set({
							vehicleQuestions: [ ...ractive.get('vehicleQuestions'), createdQuestion ],
							savingNewQuestion: false,
							showQuestionModal: false,
						}).then(() => {
							ractive.find(`#vehicle-question-id-${createdQuestion.vehicleQuestionId}`).focus()
						})
					})
				})
			})

			ractive.observe('vehicleAnswers.*.answer', (val, oldVal, keypath) => {
				const answer = ractive.get(keypath.substring(0, keypath.lastIndexOf('.')))
				console.log('about to save answer', answer)
				ractive.push('savingAnswerIds', answer.vehicleAnswerId)
					.then(() => {
						mediator.call('emitToServer', 'save vehicle answer', { answer }, (err, savedAnswer) => {
							if (err) {
								console.error(err)
								return alert('Error saving vehicle answer')
							}

							const savingAnswerIds = ractive.get('savingAnswerIds')
								.filter(answerId => answerId != savedAnswer.vehicleAnswerId)

							ractive.set({ savingAnswerIds })
						})
					})
			}, { init: false })
			*/
		},
	})
}
