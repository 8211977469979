import template from './lost-sale.html'

//Ractive components
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.lost-sale',
		route: 'lost-sale',
		querystringParameters: [ 'fromDate', 'toDate', 'displayCustomer' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
			},
			computed: {
				displayLostSales() {
					return this.get('lostSales').map(lostSale => {
						return {
							...lostSale,
							total: lostSale.subtotal + lostSale.tax,
							displayCustomerName: (this.get('displayCustomer') === 'Bill') ?
								(lostSale.billingCompany || lostSale.billingContact) :
								(lostSale.shippingCompany || lostSale.shippingContact),
						}
					})
				},
			},
		},
		async resolve(data, parameters) {
			if (parameters.fromDate && parameters.toDate) {
				const lostSales = await mediator.call('emitToServer', 'load lost sales', { fromDate: parameters.fromDate, toDate: parameters.toDate })
				return {
					lostSales,
					displayCustomer: parameters.displayCustomer,
				}
			} else {
				return {}
			}
		},
	})
}
