import template from './manage.html'
import pProps from 'p-props'

//Ractive Components
import makeChangePasswordModal from 'components/change-password'
import makePermission from 'components/permission'
import makeUserAvatar from '@isoftdata/user-avatar-component'
import makeCheckbox from '@isoftdata/checkbox'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'

export default function({ stateRouter, mediator, hasPermission, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.user.manage',
		route: ':userAccountId',
		querystringParameters: [ 'userAccountId' ],
		template: {
			template,
			components: {
				userAvatar: makeUserAvatar(),
				changePasswordModal: makeChangePasswordModal({ mediator, logAndAlert }),
				itTable: makeTable(),
				itCheckbox: makeCheckbox({ twoway: true }),
				itInput: makeInput({ twoway: true, lazy: true }),
				itInputNotLazy: makeInput({ twoway: true, lazy: false }),
				itButton: makeButton(),
				itSelect: makeSelect({ twoway: true, lazy: true }),
				permission: makePermission({ mediator, logAndAlert }),
				itModal: makeModal(),
			},
			deactivateUser() {
				const ractive = this
				ractive.set({
					'user.status': 'Deactivated',
					'user.lockNotes': 'Account deactivated by administator',
				})
			},
			activateUser() {
				const ractive = this
				ractive.set({
					'user.status': 'Active',
					'user.lockNotes': '',
				})
			},
			changePassword() {
				const ractive = this
				const changePasswordModal = ractive.findComponent('changePasswordModal')
				console.log(ractive.get('user'))
				changePasswordModal.show({
					state: 'CHANGE_PASSWORD',
					account: ractive.get('user'),
				})
			},
			hasPermission,
			showSetUserPasswordModal(userName) {
				this.set({
					passwordChangeModal: {
						show: true,
						userName,
						newPassword: '',
						confirmPassword: '',
					},
				}).then(() => {
					this.find('#changeUserPasswordNewPasswordInput').select()
				})
			},
			async confirmSetUserPassword(userName, password) {
				try {
					await mediator.call('emitToServer', 'set user password', { userName, password })
					alert(`Password set successfully for ${userName}`)
					this.set('passwordChangeModal.show', false)
				} catch (err) {
					logAndAlert(err, mediator, 'Error setting user password')
				}
			},
		},
		async resolve(data, { userAccountId }) {
			const loadedData = await pProps({
				groups: mediator.call('emitToServer', 'load groups', {}),
				user: mediator.call('emitToServer', 'load users', { userAccountId }),
				userGroups: mediator.call('emitToServer', 'load user group', { userAccountId }),
				userPermissions: mediator.call('emitToServer', 'load permissions for user', { userAccountId }),
				userGroupMembership: mediator.call('emitToServer', 'load user group membership for user', { userAccountId }),
				stores: mediator.call('emitToServer', 'load stores', {}),
			})

			const groupPermissionsLoads = loadedData.groups.reduce((sum, group) => {
				return { ...sum, [group.groupId]: mediator.call('emitToServer', 'load permissions for group', { groupId: group.groupId }) }
			}, {})

			const groupPermissions = await pProps(groupPermissionsLoads)

			return {
				...loadedData,
				groupPermissions,
				userAccountId,
				setPasswordUser: {},
				passwordChangeModal: {
					show: false,
				},
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('group-membership-clicked', (context, groupId) => {
				const isMember = ractive.get('userGroups').find(userGroup => userGroup.groupId === groupId)

				if (isMember) {
					const userGroups = ractive.get('userGroups')
						.filter(userGroup => userGroup.groupId !== groupId)

					ractive.set({ userGroups })
				} else {
					ractive.push('userGroups', { groupId, userAccountId: ractive.get('userAccountId') })
				}
			})

			ractive.on('group-membership-clicked', async(context, groupId) => {
				if (groupId) {
					const userGroupMembership = ractive.get('userGroupMembership')
					const userGroupIndex = userGroupMembership
						.findIndex(userGroup => userGroup.groupId === groupId)

					const updatedUserGroup = { ...userGroupMembership[userGroupIndex], isMember: !(userGroupMembership[userGroupIndex].isMember) }

					ractive.splice('userGroupMembership', userGroupIndex, 1, updatedUserGroup)

					try {
						const userGroupMembership = await mediator.call('emitToServer', 'update user group membership', {
							groupId,
							userAccountId: ractive.get('userAccountId'),
							isMember: updatedUserGroup.isMember,
							context: 'USER',
						})
						ractive.set({ userGroupMembership })
					} catch (err) {
						logAndAlert(err, mediator, 'Error saving user group membership')
					}
				}
			})

			ractive.observe('user', async user => {
				try {
					await mediator.call('emitToServer', 'save user', user)
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving user account')
				}
			}, { init: false })
		},
	})
}
