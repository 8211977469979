import template from './setting.html'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'

//Ractive components
import makeCheckbox from '@isoftdata/checkbox'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

export default function({ stateRouter, mediator, _hasPermission }) {
	stateRouter.addState({
		name: 'app.configuration.setting',
		route: 'setting',
		querystringParameters: [ 'category' ],
		defaultChild: 'category',
		template: {
			template,
			components: {
				itModal: makeModal(),
				itTable: makeTable(),
				itInputTwo: makeInput({ twoway: true, lazy: false }),
				itCheckbox: makeCheckbox({ twoway: true, lazy: true }),
			},
			computed: {
				displaySettingCategories() {
					const categoryFilter = this.get('categoryFilter').toUpperCase()

					return this.get('setting')
						.filter(setting => setting.category === '' ||
							setting.category.toUpperCase().indexOf(categoryFilter) > -1)
				},
			},
			selectCategory(category) {
				stateRouter.go(null, { category }, { inherit: true })
			},
		},
		async resolve(data, { category }) {
			const setting = await mediator.call('emitToServer', 'load setting categories', {})

			return {
				setting,
				yesNo,
				categoryFilter: '',
				selectedCategory: category || '',
			}
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get(''))
		},
	})
}
