import template from './performance.html'
import pProps from 'p-props'
import financialNumber from 'financial-number'

//Ractive Components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.vehicle.performance',
		route: 'performance',
		querystringParameters: [ 'vehicleId' ],
		data: {
			saving: {},
		},
		template: {
			template,
			twoway: false,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			computed: {
				computedAvailableVehicleParts() {
					const availableVehicleParts = this.get('availableVehicleParts')
					const inventoryTypeList = this.get('inventoryTypeList')

					return availableVehicleParts.map(({ inventoryId, tagNumber, inventoryTypeId, partManufacturer, partModel, category, retailPrice, cost, quantity, location, make, model }) => {
						const inventoryType = inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === inventoryTypeId)

						return {
							inventoryId,
							tagNumber,
							inventoryTypeName: (inventoryType && inventoryType.name) || '',
							partManufacturer:	partManufacturer ? partManufacturer : make,
							partModel: partModel ? partModel : model,
							category,
							retailPrice,
							cost,
							quantity,
							location,
						}
					})
				},
				computedSoldVehicleParts() {
					const soldVehicleParts = this.get('soldVehicleParts')
					const inventoryTypeList = this.get('inventoryTypeList')

					return soldVehicleParts.map(({ inventoryId, tagNumber, inventoryTypeId, partManufacturer, partModel, category, price, cost, inventoryCost, quantity, saleId, vehicleMake, vehicleModel }) => {
						return {
							inventoryId,
							tagNumber: inventoryId ? tagNumber : 'N/A',
							inventoryTypeName: inventoryId ? (inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === inventoryTypeId)?.name || '') : 'Misc. Part',
							partManufacturer: partManufacturer ? partManufacturer : vehicleMake,
							partModel: partModel ? partModel : vehicleModel,
							category,
							price,
							cost,
							inventoryCost,
							quantity,
							saleId,
						}
					})
				},
				vehicleCostsTotal() {
					const total = this.get('vehicleCosts').reduce((acc, { total, isExpense }) => {
						if (!isExpense) {
							return acc.plus((total || 0).toString())
						} else {
							return acc
						}
					}, financialNumber('0'))

					return total.toString(2)
				},
				retailTotal() {
					const total = this.get('availableVehicleParts').reduce((sum, part) => {
						const partTotal = financialNumber((part.retailPrice || 0).toString())
							.times((part.quantity || 0).toString())

						return sum.plus(partTotal)
					}, financialNumber('0'))

					return total.toString(2)
				},
				availablePartsCostTotal() {
					const total = this.get('availableVehicleParts').reduce((sum, part) => {
						const partTotal = financialNumber((part.cost || 0).toString())
							.times((part.quantity || 0).toString())

						return sum.plus(partTotal)
					}, financialNumber('0'))

					return total.toString(2)
				},
				potentialProfit() {
					return financialNumber(this.get('retailTotal'))
						.minus(this.get('vehicleCostsTotal'))
						.minus(this.get('availablePartsCostTotal'))
						.toString(2)
				},
				totalSales() {
					const total = this.get('soldVehicleParts').reduce((sum, part) => {
						const partTotal = financialNumber((part.price || 0).toString())
							.times((part.quantity || 0).toString())

						return sum.plus(partTotal)
					}, financialNumber('0'))

					return total.toString(2)
				},
				totalCOGS() {
					const total = this.get('soldVehicleParts').reduce((sum, item) => {
						const itemTotal = financialNumber((item.inventoryCost || 0).toString())
							.times((item.quantity || 0).toString())

						return sum.plus(itemTotal)
					}, financialNumber('0'))

					return total.toString(2)
				},
				totalProfits() {
					return financialNumber(this.get('totalSales')).minus(this.get('totalCOGS')).toString()
				},
				quantityOfAvailableParts() {
					return this.get('availableVehicleParts').reduce((sum, part) => {
						return sum + (part.quantity || 0)
					}, 0)
				},
				quantityOfSoldParts() {
					return this.get('soldVehicleParts').reduce((sum, part) => {
						return sum + (part.quantity || 0)
					}, 0)
				},
			},
		},
		async resolve(data, parameters) {
			const vehicleId = parameters.vehicleId

			if (vehicleId) {
				return await pProps({
					availableVehicleParts: mediator.call('emitToServer', 'load inventory', { vehicleId, storeId: parameters.storeId, status: [ 'A', 'H' ] }),
					soldVehicleParts: mediator.call('emitToServer', 'load sale lines', {
						vehicleId,
						options: {
							includeTagNumber: true,
							includeInventoryCost: true,
							includeInventoryTypeId: true,
							includeVoid: false,
						},
					}),
					inventoryTypeList: mediator.call('emitToServer', 'load inventory type list', {}),
					vehicleCosts: mediator.call('emitToServer', 'load vehicle costs', { vehicleId }),
				})
			}
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get('availableVehicleParts'), ractive.get('soldVehicleParts'))
			ractive.on('part-clicked', (context, inventoryId) => {
				stateRouter.go('app.part', { inventoryId })
			})

			ractive.observe('computedAvailableVehicleParts', v => console.log(v))
			ractive.observe('computedSoldVehicleParts', v => console.log(v))
		},
	})
}
