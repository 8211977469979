import template from './history-report.html'
import klona from 'klona'
import pProps from 'p-props'

//Ractive Components
import makeResponsiveTable from 'components/responsive-table'
//import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'

//Ractive Transitions
import ractiveTransitionsSlide from 'ractive-transitions-slide'

function hasValidVinAuditAuthInfo(vinAuditAuthInfo) {
	return Object.keys(vinAuditAuthInfo)
		.every(item => vinAuditAuthInfo[item])
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle.history-report',
		route: 'history-report',
		querystringParameters: [ 'reportType', 'vehicleId' ],
		defaultParameters: { reportType: 'standard' },
		template: {
			twoway: false,
			template,
			components: {
				responsiveTable: makeResponsiveTable(),
				itInput: makeInput({ twoway: true, lazy: false }),
				itButton: makeButton(),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			computed: {
				hasValidVinAuditAuthInfo() {
					return hasValidVinAuditAuthInfo(this.get('vinAuditAuthInfo'))
				},
				canSaveVinAuditAuthInfo() {
					return hasValidVinAuditAuthInfo(this.get('editVinAuditAuthInfo'))
				},
				vinAuditAccountLabel() {
					if (this.get('hasValidVinAuditAuthInfo')) {
						return this.get('vinAuditAuthInfo').username
					}
					return 'No VinAudit Account'
				},
			},
			async saveVinAuditAuthInfo(vinAuditAuthInfo) {
				const ractive = this

				try {
					const savedVinAuditAuthInfo = await mediator.call('emitToServer', 'save vin audit auth info', { vinAuditAuthInfo })
					ractive.set({ vinAuditAuthInfo: savedVinAuditAuthInfo })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving vin audit login information')
				} finally {
					ractive.set({ wantsToChangeVinAuditAuthInfo: false })
				}
			},
			getVinAuditReport(vin) {
				this.set({ reportId: true }).then(() => {
					stateRouter.go('app.vehicle.history-report.results', { vin }, { inherit: true })
				})
			},
			showVinAuditAuthInfo() {
				this.toggle('wantsToChangeVinAuditAuthInfo')
					.then(() => {
						if (this.get('wantsToChangeVinAuditAuthInfo')) {
							this.find('#vinAuditUsername').select()
						}
					})
			},
		},
		data: {},
		async resolve(data, { vehicleId, reportType }) {
			const res = await pProps({
				vehicle: vehicleId ? mediator.call('emitToServer', 'load vehicle', { vehicleId }) : {},
				vinAuditAuthInfo: mediator.call('emitToServer', 'load vin audit auth info', {}),
				reportType,
				errorMessage: null,
				wantsToChangeVinAuditAuthInfo: false,
				editVinAuditAuthInfo: {},
			})

			return {
				...res,
				vehicle: res.vehicle[0],
			}
		},
		activate({ domApi }) {
			const ractive = domApi

			ractive.observe('hasValidVinAuditAuthInfo', c => console.log(ractive.get('vinAuditAuthInfo'), c))

			ractive.observe('wantsToChangeVinAuditAuthInfo hasValidVinAuditAuthInfo', () => {
				if (ractive.get('wantsToChangeVinAuditAuthInfo') || !ractive.get('hasValidVinAuditAuthInfo')) {
					ractive.set({ editVinAuditAuthInfo: klona(ractive.get('vinAuditAuthInfo')) })
					const vinAuditUsernameField = ractive.find('#vinAuditUsername')
					if (vinAuditUsernameField) {
						vinAuditUsernameField.select()
					}
				}
			})

			ractive.observe('hasValidVinAuditAuthInfo reportId vehicle.vin', () => {
				if (ractive.get('vehicle.vin') &&
						ractive.get('hasValidVinAuditAuthInfo') &&
						ractive.get('reportId')) {
					stateRouter.go('app.vehicle.history-report.results', {
						reportId: ractive.get('reportId'),
						vin: ractive.get('vehicle.vin'),
					}, { inherit: true, replace: true })
				}
			})
		},
	})
}
