import template from './vehicle.html'
import pProps from 'p-props'

//Ractive components
import makeReportSelectionModal from 'components/report-selection'
import makeLoadItemModal from '@isoftdata/load-item-modal'
import makeSplitButton from '@isoftdata/split-button'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'

let removeSaveVehicleListener = false

export default function({ mediator, stateRouter, isChildState, logAndAlert, hasPermission }) {
	const stateName = 'app.vehicle'

	stateRouter.addState({
		name: stateName,
		route: 'vehicle',
		defaultChild: 'basic',
		querystringParameters: [ 'vehicleId', 'storeId' ],
		defaultParameters: { 'storeId': 1 },
		template: {
			template,
			components: {
				trackingNumberLookupModal: makeModal(),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
				itSplitButton: makeSplitButton(),
				itButton: makeButton(),
				loadVehicleModal: makeLoadItemModal({
					async doSearch(searchString, { lookupEndpoint }) {
						return await mediator.call('emitToServer', lookupEndpoint, { searchString })
					},
					lookupError(err, message) {
						logAndAlert(err, mediator, message)
					},
					chooseItem(item, { destinationStateName, itemIdProp }) {
						stateRouter.go(destinationStateName, { [itemIdProp]: item[itemIdProp] })
					},
				}),
				navTabBar: makeNavTabBar(stateRouter),
			},
			computed: {
				displayChildStates() {
					const vehicle = this.get('vehicle')
					const childStates = this.get('childStates')
					const childStatesNotBVehicleStatus = this.get('childStatesNotBVehicleStatus')
					let childStatesCopy = childStates.slice()

					if (!vehicle || (vehicle && vehicle.status !== 'B')) {
						childStatesCopy.splice(childStatesCopy.length - 1, 0, ...childStatesNotBVehicleStatus)
					}

					if (!vehicle?.vehicleId) {
						childStatesCopy = childStatesCopy.map(state => {
							return { ...state, disabled: true }
						})
					}

					return childStatesCopy
				},
			},
		},
		async resolve(data, parameters) {
			return await pProps({
				vehicle: (async() => parameters.vehicleId ? (await mediator.call('emitToServer', 'load vehicle', { vehicleId: parameters.vehicleId }))?.[0] : [])(),
				bids: parameters.vehicleId ? mediator.call('emitToServer', 'load vehicle bids', { vehicleId: parameters.vehicleId }) : null,
				inventorySearchParameters: mediator.call('emitToServer', 'load inventory search parameters', {}),
				vehicleId: parameters.vehicleId,
				storeId: parameters.storeId,
				trackingNumberModalShown: false,
				trackingNumberLookupModalMode: null,
				trackingNumberLookupResults: null,
				highlightedLookupResultIndex: null,
				trackingNumberLookupString: null,
				childStates: [
					{
						title: 'Basic',
						stateName: 'app.vehicle.basic',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'History Report',
						stateName: 'app.vehicle.history-report',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Attachment',
						stateName: 'app.vehicle.attachment',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Q and A',
						stateName: 'app.vehicle.qanda',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Info',
						stateName: 'app.vehicle.info',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Teardown & Bids',
						stateName: 'app.vehicle.teardown',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
				],
				childStatesNotBVehicleStatus: [
					{
						title: 'Costs',
						stateName: 'app.vehicle.cost',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Performance',
						stateName: 'app.vehicle.performance',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
				],
			})
		},
		activate({ domApi: ractive, parameters }) {
			if (ractive.get('vehicle.vehicleId')) {
				mediator.call('activity', {
					stateName,
					stateParameters: parameters,
					stateCategory: 'VEHICLE',
					action: 'VIEW',
					displayTitle: `Vehicle #${ractive.get('vehicle.trackingNumber') || ractive.get('vehicle.vehicleId')}`,
					stateParameterKey: 'vehicleId',
				})
			}

			ractive.on('showParts', () => {
				const searchParameter = ractive.get('inventorySearchParameters').find(searchParameter => searchParameter.name === 'Stock #')

				if (searchParameter && searchParameter.searchParameterId) {
					stateRouter.go('app.part-search.results', { flexFields: JSON.stringify({ [searchParameter.searchParameterId]: { comparator: '=', value: ractive.get('vehicleId') } }) })
				}
			})

			if (!removeSaveVehicleListener) {
				removeSaveVehicleListener = mediator.provide('vehicleSave', ({ stateThatSaved, data }) => {
					if (isChildState({ childState: stateThatSaved, parentState: stateName })) {
						ractive.set({
							saved: new Date().toString(),
							vehicle: data,
						})
					}
				})
			}

			ractive.on('PRINT_TAG', () => {
				const vehicleId = ractive.get('vehicleId')
				const storeId = ractive.get('storeId')

				if (vehicleId && storeId) {
					ractive.findComponent('reportSelectionModal').printReport({
						type: 'Vehicle Master Tag',
						reportParameters: [
							{ parameterName: 'stocknum', value: parseInt(vehicleId, 10) },
							{ parameterName: 'storenum', value: parseInt(storeId, 10) },
						],
					})
				} else {
					throw 'Error printing tag report'
				}
			})

			ractive.on('PRINT_TEARDOWN', () => {
				const vehicleId = ractive.get('vehicleId')
				const storeId = ractive.get('storeId')

				if (vehicleId && storeId) {
					const bids = ractive.get('bids')
					const isBid = ractive.get('vehicle').status === 'B'
						&& bids.length > 0

					let reportJob = {
						type: '',
						reportParameters: [],
					}

					if (isBid) {
						let bidId = bids[0].vehicleBidId

						try {
							bidId = mediator.call('getSelectedBidId') || bidId
						} catch (err) {
							// swallow
						}

						reportJob.type = 'Bid'
						reportJob.reportParameters = [
							{ parameterName: 'bid_id', value: parseInt(bidId, 10) },
						]
					} else {
						reportJob.type = 'Teardown'
						reportJob.reportParameters = [
							{ parameterName: 'stocknum', value: parseInt(vehicleId, 10) },
							{ parameterName: 'store', value: parseInt(storeId, 10) },
						]
					}

					ractive.findComponent('reportSelectionModal').printReport(reportJob)
				} else {
					throw 'Error printing teardown report'
				}
			})

			ractive.on('NEW_VEHICLE', () => {
				stateRouter.go(null, {})
			})

			ractive.on('PRINT_DETAILS', () => {
				const vehicleId = ractive.get('vehicleId')
				const storeId = ractive.get('storeId')

				if (vehicleId && storeId) {
					const reportParameters = [
						{ parameterName: 'stocknum', value: parseInt(vehicleId, 10) },
						{ parameterName: 'storenum', value: parseInt(storeId, 10) },
					]

					const reportJob = { type: 'Vehicle Printout', reportParameters }

					ractive.findComponent('reportSelectionModal').printReport(reportJob)
				} else {
					throw 'Error printing tag report'
				}
			})

			ractive.on('showLoadVehicleModal', () => {
				ractive.findComponent('loadVehicleModal').fire('show', {}, {
					itemTitle: 'Vehicle',
					lookupEndpoint: 'tracking number lookup',
					itemIdProp: 'vehicleId',
					itemDisplayProp: 'trackingNumber',
				})
			})
		},
	})
}
