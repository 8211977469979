<script lang="ts">
	import type { PartManufacturer } from 'server/db/part-manufacturer-db'
	import { createEventDispatcher, getContext, tick } from 'svelte'
	import type { PartModel } from 'server/db/part-model-db'
	import type { InterchangeWithPricing } from 'server/db/interchange-db'
	import type { Mediator } from 'types/common'
	import type { IconName } from '@fortawesome/fontawesome-common-types'

	import Icon from '@isoftdata/svelte-icon'
	import Modal from '@isoftdata/svelte-modal'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'

	import * as manufacturerModelHelper from 'shared/manufacturer-model-helper'
	import { format as formatCurrency } from '@isoftdata/utility-currency'
	import toTitleCase from 'to-title-case'
	import { reduceToTruthyValues } from '@isoftdata/utility-object'
	import pProps from 'p-props'

	export let manufacturerList: Array<PartManufacturer> = []
	export let modelList: Array<PartModel> = []

	export let manufacturerName = ''
	export let modelName = ''

	export let categoryPricingIsLoading = false
	export let shown = false

	export let context = ''
	export let contextId: number | null = null

	let interchangePricing: Partial<PartPricing>
	let categoryPricing: Partial<PartPricing>
	// let pricing: Array<unknown> = []
	interface PartPricing {
		retailPrice: number
		listPrice: number
		wholesalePrice: number
		core: number
	}

	type CategoryPricing = {
		defaultRetail: number
		defaultWholesale: number
		defaultCore: number
	}

	let partPricing: PartPricing | undefined
	let interchangeNumber: string = ''
	let table: Table<(typeof displayPricing)[number]> | undefined

	$: manufacturerId = manufacturerModelHelper.getPartManufacturerIdFromName(manufacturerList, manufacturerName)
	$: manufacturerModelHelper.getPartModelIdFromModelNameAndManufacturerId(modelList, modelName, manufacturerId)
	$: reducedInterchangePricing = getReducedPricing(interchangePricing)
	$: reducedCategoryPricing = getReducedPricing(categoryPricing)
	$: hasCategoryPricing = getHasCategoryPricing(reducedCategoryPricing)
	$: hasInterchangePricing = getHasInterchangePricing(reducedInterchangePricing)

	$: pricing = partPricing
		? PRICING_KEYS.map(key => {
				return {
					name: toTitleCase(key),
					part: partPricing![key],
					interchange: reducedInterchangePricing[key],
					category: reducedCategoryPricing[key],
				}
			})
		: []
	$: displayPricing = pricing.map(priceType => {
		const categoryIcon = getIcon(priceType.part, priceType.category ?? 0)
		const interchangeIcon = getIcon(priceType.part, priceType.interchange ?? 0)

		return {
			...priceType,
			part: priceType.part ? formatCurrency(priceType.part) : '',
			interchange: priceType.interchange ? formatCurrency(priceType.interchange) : '',
			interchangeIcon: interchangeIcon ? interchangeIcon.icon : null,
			interchangeIconColorClass: interchangeIcon ? interchangeIcon.iconColorClass : '',
			category: priceType.category ? formatCurrency(priceType.category) : '',
			categoryIcon: categoryIcon ? categoryIcon.icon : null,
			categoryIconColorClass: categoryIcon ? categoryIcon.iconColorClass : '',
		}
	})
	$: table?.setColumnVisibility('interchange', hasInterchangePricing)
	$: table?.setColumnVisibility('category', hasCategoryPricing)

	const mediator = getContext<Mediator>('mediator')
	const dispatch = createEventDispatcher<{
		pricingChange: {
			contextId: number | null
		} & Partial<PartPricing>
	}>()

	const PRICING_KEYS = ['retailPrice', 'listPrice', 'wholesalePrice', 'core'] as const
	const columns: Array<Column> = [
		{ property: 'name', name: 'Name' },
		{ property: 'part', name: 'Current', numeric: true },
		{ property: 'interchange', name: 'Interchange', numeric: true },
		{ property: 'category', name: 'Category', numeric: true },
	]

	function applyPricing(context: 'CATEGORY' | 'INTERCHANGE') {
		const pricing = context === 'CATEGORY' ? categoryPricing : interchangePricing
		shown = false
		dispatch('pricingChange', { contextId, ...partPricing, ...pricing })
	}

	export function show(newContext: string) {
		shown = true
		context = newContext
		// TODO: figure out what this is and maybe name it better?
		if (context) {
			document.getElementById(context)?.focus()
		}
	}

	export async function showIfNeeded(
		{
			interchangeRecord,
			context,
			contextId: newContextId,
			interchangeNumber: newInterchangeNumber,
			category,
			inventoryTypeId,
			partPricing: newPartPricing,
			partModelId,
			forceShow,
		}: {
			interchangeRecord?: InterchangeWithPricing
			context: 'CATEGORY' | 'INTERCHANGE'
			contextId?: number
			interchangeNumber?: string
			category?: string | null
			inventoryTypeId?: number | null
			partPricing?: PartPricing
			partModelId?: number | null
			forceShow?: boolean
		} /* = {} */,
	) {
		if ((partModelId && category) || (newInterchangeNumber && inventoryTypeId) || interchangeRecord) {
			categoryPricingIsLoading = true
			let {
				category: [newCategoryPricing],
				interchange: [newInterchangePricing],
			} = (await pProps({
				category:
					partModelId && category
						? mediator.call('emitToServer', 'load category pricing', {
								partModelId,
								categoryName: category,
							})
						: [],
				interchange:
					!interchangeRecord && newInterchangeNumber && inventoryTypeId
						? mediator.call('emitToServer', 'load interchange', {
								inventoryTypeId,
								interchangeNumber: newInterchangeNumber,
								includePricing: true,
							})
						: [interchangeRecord],
			})) as {
				category: Array<CategoryPricing>
				interchange: Array<InterchangeWithPricing>
			}

			contextId = newContextId ?? null
			categoryPricingIsLoading = false
			partPricing = newPartPricing

			console.log({ newCategoryPricing, newInterchangePricing })

			let formattedCategoryPricing: Partial<PartPricing> = {}
			let formattedInterchangePricing: Partial<PartPricing> = {}

			if (newCategoryPricing) {
				formattedCategoryPricing = reduceToTruthyValues({
					object: {
						wholesalePrice: newCategoryPricing.defaultWholesale,
						retailPrice: newCategoryPricing.defaultRetail,
						core: newCategoryPricing.defaultCore,
					},
				})
			}

			if (newInterchangePricing) {
				newInterchangeNumber = newInterchangePricing.interchangeNumber
				formattedInterchangePricing = reduceToTruthyValues({
					object: {
						wholesalePrice: newInterchangePricing.wholesalePrice,
						retailPrice: newInterchangePricing.retailPrice,
						core: newInterchangePricing.core,
					},
				})
			} else {
				newInterchangeNumber = ''
			}

			categoryPricing = formattedCategoryPricing
			interchangePricing = formattedInterchangePricing
			interchangeNumber = newInterchangeNumber
			await tick()

			reducedCategoryPricing = getReducedPricing(formattedCategoryPricing)
			hasCategoryPricing = getHasCategoryPricing(reducedCategoryPricing)
			reducedInterchangePricing = getReducedPricing(formattedInterchangePricing)
			hasInterchangePricing = getHasInterchangePricing(reducedInterchangePricing)

			if ((context === 'CATEGORY' && hasCategoryPricing) || (context === 'INTERCHANGE' && hasInterchangePricing) || (context && forceShow)) {
				show(context)
			}
		}
	}

	function getHasCategoryPricing({ retailPrice, listPrice, wholesalePrice, core }: typeof reducedCategoryPricing) {
		const hasPricing = !!(retailPrice || listPrice || wholesalePrice || core)

		if (hasPricing) {
			return !pricing
				//
				.filter(({ category }) => category)
				.every(({ part, category }) => part === category)
		}
		return hasPricing
	}

	function getHasInterchangePricing({ retailPrice, listPrice, wholesalePrice, core }: typeof reducedInterchangePricing) {
		const hasPricing = !!(retailPrice || listPrice || wholesalePrice || core)

		if (hasPricing) {
			return !pricing
				//
				.filter(({ interchange }) => interchange)
				.every(({ part, interchange }) => part === interchange)
		}
		return hasPricing
	}

	type ReducedPricing = Partial<Record<(typeof PRICING_KEYS)[number], number | null>>

	function getReducedPricing(pricing: ReducedPricing): ReducedPricing {
		return pricing
			? PRICING_KEYS.reduce((acc, key) => {
					if (pricing[key]) {
						return { ...acc, [key]: pricing[key] }
					}
					return { ...acc, [key]: '' }
				}, {})
			: {}
	}

	function getIcon(
		partPrice: number,
		otherPrice: number,
	): {
		icon: IconName
		iconColorClass: string
	} | void {
		if (!otherPrice) {
			return { icon: 'ban', iconColorClass: 'text-dark' }
		} else if (partPrice === otherPrice) {
			return { icon: 'equals', iconColorClass: 'text-dark' }
		} else if (otherPrice > partPrice) {
			return { icon: 'chevron-up', iconColorClass: 'text-success' }
		} else if (otherPrice < partPrice) {
			return { icon: 'chevron-down', iconColorClass: 'text-danger' }
		}
	}
</script>

<Modal
	bind:show={shown}
	title="Pricing Options"
	footerShown={false}
	on:close={() => (shown = false)}
>
	<Table
		columnHidingEnabled
		rows={displayPricing}
		{columns}
		bind:this={table}
	>
		{#snippet body({ rows })}
			{#each rows as row}
				<tr>
					<Td property="name">{row.name}</Td>
					<Td property="part">{row.part}</Td>
					<Td
						property="interchange"
						class="context === 'INTERCHANGE' ? 'table-primary' : ''"
					>
						<div class="d-flex">
							<Icon
								icon={row.interchangeIcon}
								class="{row.interchangeIconColorClass} mr-auto"
							/>
							<span class:text-secondary={row.part === row.interchange}>{row.interchange}</span>
						</div>
					</Td>
					<Td
						property="category"
						class={context === 'CATEGORY' ? 'table-primary' : ''}
					>
						<div class="d-flex">
							<Icon
								icon={row.categoryIcon}
								class="{row.categoryIconColorClass} mr-auto"
							/>
							<span class:text-secondary={row.part === row.category}>{row.category}</span>
						</div>
					</Td>
				</tr>
			{/each}
			<tr>
				<Td property="name"></Td>
				<Td
					property="part"
					class="text-center"
				>
					<Button
						size="xs"
						onclick={() => (shown = false)}>Keep Current Pricing</Button
					>
				</Td>
				<Td
					property="interchange"
					class="text-center"
				>
					<Button
						id="INTERCHANGE"
						size="xs"
						onclick={() => applyPricing('INTERCHANGE')}>Use Interchange</Button
					>
				</Td>
				<Td
					property="category"
					class="text-center"
				>
					<Button
						id="CATEGORY"
						size="xs"
						onclick={() => applyPricing('CATEGORY')}>Use Category</Button
					>
				</Td>
			</tr>
		{/snippet}
	</Table>
</Modal>
