import template from './breakdown.html'
import pProps from 'p-props'
import teardownDataLoader from 'shared/teardown-data-loader'

//Ractive Components
import makeTeardown from 'components/teardown'
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.part.breakdown',
		route: 'breakdown',
		querystringParameters: [ 'inventoryId', 'storeId' ],
		defaultParameters: { 'storeId': 1 },
		template: {
			template,
			twoway: false,
			components: {
				itTable: makeTable(),
				teardown: makeTeardown({ mediator, stateRouter, logAndAlert, hasPermission }),
			},
			data: {},
		},
		async resolve(data, { inventoryId }) {
			let res = await pProps({
				inventoryId: parseInt(inventoryId, 10),
				parentPart: (async() => (await mediator.call('emitToServer', 'load inventory', { inventoryId }))?.[0])(),
				childParts: mediator.call('emitToServer', 'load inventory', { parentInventoryId: inventoryId }),
				inventoryTypes: mediator.call('emitToServer', 'load inventory type list', {}),
				vehicle: (async() => inventoryId ? (await mediator.call('emitToServer', 'load vehicle', { inventoryId }))?.[0] : {})(),
				locations: mediator.call('emitToServer', 'load inventory locations', {}),

			})

			if (res.parentPart) {
				const breakdown = await mediator.call('emitToServer', 'load breakdown', { inventoryTypeId: res.parentPart.inventoryTypeId })
				const teardownData = await teardownDataLoader({
					mediator,
					breakdownTemplate: breakdown,
					parts: res.childParts,
					parent: res.parentPart,
					parentType: 'INVENTORY',
					teardownType: 'INVENTORY',
				})
				console.log(teardownData)
				return { ...res, ...teardownData }
			} else {
				return res
			}
		},
		activate({ domApi: ractive }) {
			//
		},
	})
}
