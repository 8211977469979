import template from './interchange-year-input.html'
import makeYearInput from '@isoftdata/year-input'

export default function createInterchangeYearComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itYearInput: makeYearInput({ twoway: true }),
		},
		data() {
			return {
				year: '',
				yearTo: '',
				showYearTo: false,
				showNaHint: false,
				naHint: '',
				collapsedColClass: 'col-lg-3',
				expandedColClass: 'col-lg-1-5',
				label: 'Year',
			}
		},
		css: `
		@media (min-width: 992px) and (max-width: 1200px) {
			.col-lg-1-5 {
				flex: 0 0 12.5%;
				max-width: 12.5%;
			}
		}
		.col-lg-1-5 {
			position: relative;
			width: 100%;
		}`,
		attributes: {
			required: [ 'year', 'yearTo' ],
			optional: [ 'showYearTo', 'showNaHint', 'naHint', 'collapsedColClass', 'expandedColClass', 'label' ],
		},
		computed: {
		},
		oninit() {

		},
	})
}
