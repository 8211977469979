import type { Status } from 'server/db/status-db'
import type { Mediator } from 'types/common'

export default async function (mediator: Mediator, type: 'Part' | 'Vehicle' = 'Part') {
	const possibleTypes = ['Part', 'Vehicle']

	if (possibleTypes.includes(type)) {
		return (await mediator.call('emitToServer', 'load statuses', { type })) as Array<Status>
	} else {
		console.warn('WARNING: Invalid type passed to status-list-loader')
		return []
	}
}
