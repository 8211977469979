import template from './vehicle.html'

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: 'app.configuration.vehicle',
		route: 'vehicle',
		defaultChild: 'make',
		template: {
			template,
			components: {},
		},
		async resolve(data, parameters) {
			//
		},
		activate(context) {
			const ractive = context.domApi
		},
	})
}
