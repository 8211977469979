import { validate } from 'vinidator'

export default function getBarcodeType(barcode) {
	/*
	If an "I" exists at the start of the scanned VIN barcode,
    it means it's an Import. But that's not part of the
    VIN standard. Thus it needs to be stripped first.
    */
	const vin = barcode.toUpperCase().charAt(0) === 'I' ? barcode.substring(1) : barcode

	if (validate(vin).isValid) {
		return {
			type: 'VIN',
			value: vin,
		}
	} else if (barcode.charAt(0) === '%') {
		return {
			type: 'LOCATION',
			value: barcode.substring(1),
		}
	} else if (barcode.charAt(0) === '$') {
		return {
			type: 'VEHICLE_ID',
			value: barcode.substring(1),
		}
	} else if (barcode.length < 11) {
		return {
			type: 'INVENTORY_ID',
			value: barcode,
		}
	} else {
		return {
			type: 'UPC',
			value: barcode,
		}
	}
}
