import template from './question.html'

//Ractive Components
import makeInventoryQuestion from 'components/inventory-question'
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.inventory.type.question',
		route: 'question',
		querystringParameters: [ 'typeSetId' ],
		template: {
			template,
			components: {
				itTable: makeTable({ stickyHeader: true }),
				inventoryQuestion: makeInventoryQuestion({ mediator, logAndAlert }),
			},
		},
		async resolve(data, { typeSetId }) {
			if (!typeSetId) {
				return new Error('typeSetId not provided!')
			}

			return {
				questions: await mediator.call('emitToServer', 'load inventory questions', {
					inventoryTypeId: typeSetId,
					categoryId: null,
				}),
				typeSetId,
			}
		},
		activate({ domApi: ractive }) {

		},
	})
}
