import template from './entity-selection-modal.html'
import makeModal from '@isoftdata/modal'
import makeList from '@isoftdata/list'
import toTitleCase from 'to-title-case'

function getEntityDisplayName(entity) {
	let billingCompany = (entity?.billingCompany)
		? entity.billingCompany.trim()
		: ''

	let entityCompany = (entity?.billingContact)
		? entity.billingContact.trim()
		: ''

	return billingCompany || entityCompany
}

class EntityOption {
	constructor(entity, entityContext, idKey) {
		this.id = entity[idKey]
		this.label = getEntityDisplayName(entity)
		this.data = entity
		this.rightTextKeyValues = entityContext === 'CUSTOMER' ? [
			{ key: 'Price Level', value: entity.defaultPriceType },
			{ key: 'Percent of Price', value: entity.percentOfPrice ? `${entity.percentOfPrice}%` : '' },
			{ key: 'Tax Rate', value: entity.taxItem },
			{ key: 'Salesperson', value: entity.defaultSalesperson },
		] : []
	}
}

export default function createEntitySelectionModal(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itList: makeList(),
			itModal: makeModal(),
		},
		computed: {
			displayEntityContext() {
				return toTitleCase(this.get('entityContext'))
			},
			idKey() {
				return `${this.get('entityContext').toLowerCase()}Id`
			},
		},
		data() {
			return {
				show: false,
				isSearchLoading: true,
				modalId: 'entitySelectionModal',
				entityContext: 'CUSTOMER',
				searchResults: [],
			}
		},
		isolated: true,
		oninit() {
			const ractive = this

			ractive.on('entityChosen', (_context, _selection) => {
				ractive.set({ show: false, isSearchLoading: true })
			})

			ractive.on('entityLookup', async(_context, searchString) => {
				if (!searchString || !searchString.trim()) {
					return
				}

				const idKey = ractive.get('idKey')
				const entityContext = ractive.get('entityContext')

				await ractive.set({ show: true, isSearchLoading: true })
				let searchResults = await mediator.call('emitToServer', `load ${entityContext.toLowerCase()} search`, { searchQuery: searchString })

				if (searchResults.length === 1) {
					await ractive.set({ show: false })
					ractive.fire('entityChosen', {}, new EntityOption(searchResults[0], entityContext, idKey))
				} else if (searchResults.length > 1) {
					searchResults = searchResults.map(entity => {
						return new EntityOption(entity, entityContext, idKey)
					})

					ractive.set({ searchResults, isSearchLoading: false })
				} else {
					ractive.set({ show: false })
					await mediator.call('showMessage', { message: `Could not find ${entityContext.toLowerCase()}.`, time: 8000, type: 'warning' })
				}
			})
		},
	})
}
