import template from './overview.html'
import pProps from 'p-props'
import makeStorageHelper from 'utility/storage-json-helper.ts'

//Ractive Components
import makeButton from '@isoftdata/button'

const localStorageJSON = makeStorageHelper('localStorage')

const stateName = 'app.quickbooks.overview'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'overview',
		querystringParameters: [ ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				itButton: makeButton(),
			},
			computed: {
				messages() {
					const messages = []

					const lastPushTime = this.get('lastPushTime')

					if (lastPushTime instanceof Date && !isNaN(lastPushTime)) {
						messages.push(`You last pushed to QuickBooks Online on ${lastPushTime.toLocaleDateString()} at ${lastPushTime.toLocaleTimeString()}.`)
					} else {
						messages.push('You have not pushed anything to QuickBooks Online yet.')
					}

					return messages
				},
				warnings() {
					const preferences = this.get('preferences')

					const warnings = []
					if (!preferences.TaxPrefs.UsingSalesTax || !preferences.TaxPrefs.PartnerTaxEnabled) {
						warnings.push('Sales tax is not enabled in QuickBooks Online. This is required for sales tax to get applied to invoices.')
					}
					if (preferences.SalesFormsPrefs.AutoApplyCredit) {
						warnings.push('"Automatically apply credits" is enabled in QuickBooks Online. This could result in customer balances being applied differently than in ITrack. Invoices could also appear out of sync immediately after export due to credits being applied automatically in QuickBooks.')
					}
					if (!preferences.AccountingInfoPrefs.TrackDepartments) {
						warnings.push('Locations are not enabled in QuickBooks Online. This is required for the store to show on invoices.')
					}
					return warnings
				},
			},
		},
		async resolve(data, parameters) {
			const session = localStorageJSON.getItem('session')

			try {
				return pProps({
					syncStatusTotals: await mediator.call('emitToServer', 'load quickbooks sync status totals', {}),
					companyInfo: await mediator.call('emitToServer', 'get quickbooks company info', {}),
					lastPushTime: new Date(await mediator.call('emitToServer', 'check global setting', {
						name: 'QuickBooks Online Last Updated',
						category: 'Options',
						settingType: 'Interface History',
						dataType: 'date',
					})),
				})
			} catch (err) {
				throw {
					redirectTo: {
						name: 'app.configuration.integration.quickbooks',
					},
				}
			}
		},
		activate({ domApi: ractive, parameters }) {
			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `QuickBooks Overview`,
				// stateParameterKey: 'systemSelection',
			})
		},
	})
}
