export default class DefaultPart {
	constructor() {
		this.category = null
		this.location = ''
		this.side = ''
		this.quantity = 1
		this.cost = 0
		this.retailPrice = 0
		this.listPrice = 0
		this.wholesalePrice = 0
		this.description = ''
	}
}
