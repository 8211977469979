import type { AppContext, ResolveParameters } from 'types/common'
import type { PrintJob } from '@isoftdata/svelte-report-viewer'

import component from './Preview.svelte'
import { stringToBoolean } from '@isoftdata/utility-string'

import { getParameterValuesForReport } from 'utility/report-viewer-helper'

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports.preview',
		route: ':selectedReportId',
		querystringParameters: ['selectedReportId'],
		defaultParameters: {
			selectedReportId: null,
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, stateParameters: ResolveParameters<{ selectedReportId: string; preview: boolean }>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null
			const preview = stringToBoolean(stateParameters.preview)

			if (selectedReportId) {
				let storedParameters: Record<string, string> | null = null
				let storedPrintJob: PrintJob | null = null

				try {
					const storedPrintJobString = localStorage.getItem('reportViewerPreviewPrintJob')

					if (storedPrintJobString) {
						storedPrintJob = JSON.parse(storedPrintJobString) as PrintJob
						if (storedPrintJob.reportId === selectedReportId) {
							storedParameters = storedPrintJob.parameters.reduce((acc, parameter) => {
								acc[parameter.key] = parameter.value
								return acc
							}, {} as Record<string, string>)
						}
						localStorage.removeItem('reportViewerPreviewPrintJob')
					}
				} catch (error) {
					console.error('Failed to parse stored parameters', error)
				}

				const parameters = await getParameterValuesForReport(mediator, selectedReportId, storedParameters)

				return {
					parameters,
					selectedReportId,
					preview,
				}
			}

			return {
				selectedReportId: null,
				parameters: [],
				preview: false,
			}
		},
	})
}
