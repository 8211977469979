import template from './interchange.html'
import * as currency from '@isoftdata/utility-currency'
import interchangeLookup from 'utility/interchange-lookup'
import klona from 'klona'

//Ractive components
import makeTable from '@isoftdata/table'
import makeTextarea from '@isoftdata/textarea'
import makeAttachment from '@isoftdata/attachment'
import makeAttachmentFns from 'utility/attachment-helper.ts'
import makeButton from '@isoftdata/button'
import makeReportSelectionModal from 'components/report-selection'
import makeModal from '@isoftdata/modal'

function stripNonNumeric(value) {
	// eslint-disable-next-line no-useless-escape
	value = value.match(/(?:\d*\-)?\d+/g, '')
	return value ? value.join('') : ''
}

const defaultData = Object.freeze({
	inventoryTypeList: [],
	interchangeList: [],
	loading: false,
	sortedInterchange: [],
	selectedInterchange: null,
	stripNonNumeric,
	exactNotes: '',
	inexactNotes: '',
	currencyFormat: currency.format,
	sortedInterchangeSummary: [],
	didSearch: false,
	isPicking: false,
	interchangeModal: {
		shown: false,
		selectedInterchange: {},
	},
	fileList: [],
})

export default function createInterchangeComponent({ mediator, stateRouter, logAndAlert, disableEditingAttachments = false }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		delegate: false,
		data() {
			return {
				...klona(defaultData),
				makePath: stateRouter.makePath,
			}
		},
		components: {
			itTable: makeTable(),
			itTextarea: makeTextarea(),
			attachments: makeAttachment(makeAttachmentFns(mediator)),
			itButton: makeButton(),
			reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
			itModal: makeModal(),
		},
		computed: {
			displayInterchange() {
				return this.get('interchangeList').map(interchange => {
					const { firstYear, lastYear } = interchange
					return {
						...interchange,
						yearRange: firstYear !== lastYear ? `${firstYear} - ${lastYear}` : firstYear,
						editDisable: disableEditingAttachments,
					}
				})
			},
			showSide() {
				return this.get('interchangeList').some(({ side }) => side)
			},
			computedColumns() {
				const showSide = this.get('showSide')
				const sideColumn = { property: 'side', name: 'Side' }

				let columns = [
					{ property: 'interchangeNumber', name: 'Interchange #', sortType: 'alphaNum' },
					{ property: 'make', name: 'Make / Manuf.' },
					{ property: 'model', name: 'Model' },
					{ property: 'yearRange', name: 'Year' },
					{ property: 'option', name: 'Option' },
					{ property: 'listPrice', name: 'List', align: 'right' },
					{ property: 'retailPrice', name: 'Retail', align: 'right' },
					{ property: 'core', name: 'Core', align: 'right' },
					{ property: 'quantityOnHand', name: 'Qty on Hand/Available', align: 'right' },
					{ property: 'salesAmount', name: 'Total Sales', align: 'right' },
					{ property: 'quantitySold', name: 'Qty Sold', align: 'right' },
				]

				if (showSide) {
					columns.splice(columns.findIndex(col => col.property === 'option'), 0, sideColumn)
				}

				return columns
			},
			selectedInterchangeId() {
				const selectedInterchange = this.get('interchangeModal.selectedInterchange')

				if (selectedInterchange) {
					return selectedInterchange.interchangeId
				}
				return null
			},
			inexactInterchangeNumber() {
				const selectedInterchange = this.get('interchangeModal.selectedInterchange')
				if (selectedInterchange) {
					return stripNonNumeric(selectedInterchange.interchangeNumber)
				}
				return ''
			},
			exactInterchangeNumber() {
				const selectedInterchange = this.get('interchangeModal.selectedInterchange')

				if (selectedInterchange &&
					stripNonNumeric(selectedInterchange.interchangeNumber) === selectedInterchange.interchangeNumber) {
					return 'N/A'
				}
				return selectedInterchange.interchangeNumber
			},
			fileAssociation() {
				const selectedInterchange = this.get('interchangeModal.selectedInterchange')

				if (selectedInterchange?.interchangeNumber &&
					selectedInterchange?.inventoryTypeId) {
					return {
						type: 'INTERCHANGE',
						key: {
							interchangeNumber: selectedInterchange.interchangeNumber,
							inventoryTypeId: selectedInterchange.inventoryTypeId,
						},
					}
				}
				return null
			},
		},
		oninit() {
			const ractive = this

			ractive.on('interchangeRowClicked', (context, { inventoryTypeId, interchangeNumber }) => {
				context?.event?.stopPropagation()
				stateRouter.go('app.part-search.results', {
					interchangeNumber,
					inventoryTypeId,
				})
			})

			ractive.observe('fileAssociation', association => {
				ractive.set({ fileList: [] })

				if (association?.key?.interchangeNumber && association?.key?.inventoryTypeId) {
					mediator.call('emitToServer', 'load file info list', { association })
						.then(fileList => ractive.set({ fileList }))
				}
			})

			if (ractive.get('inventoryTypeList').length === 0) {
				mediator.call('emitToServer', 'load inventory type list', {})
					.then(inventoryTypeList => ractive.set({ inventoryTypeList }))
					.catch(err => logAndAlert(err, mediator, 'Error loading inventory type list'))
			}

			ractive.on('printInfo', (_context, { interchangeNumber }) => {
				let interchangeNumberWhere = ''

				if (ractive.get('isExactSearch')) {
					interchangeNumberWhere = `= '${interchangeNumber}'`
				} else {
					const [ interchangeNumberDigits ] = interchangeNumber.match(/\d+/g)
					interchangeNumberWhere = `LIKE '${interchangeNumberDigits}%'`
				}

				const where = `typenum = ${ractive.get('inventoryTypeId')} AND interchangenumber ${interchangeNumberWhere}`

				ractive.findComponent('reportSelectionModal').printReport({
					type: 'Interchange Report',
					reportParameters: [
						{ parameterName: 'make', value: ractive.get('manufacturerOrMake') },
						{ parameterName: 'model', value: ractive.get('model') },
						{ parameterName: 'year', value: ractive.get('year') },
						{ parameterName: 'intlookup', value: where },
					],
				})
			})
		},
		async search(options) {
			const ractive = this

			ractive.set({
				currentSearchType: options.searchType,
				loading: true,
				selectedInterchange: null,
				didSearch: true,
			})

			if (options.interchangeNumber && !options.isExact) {
				options.interchangeNumber = stripNonNumeric(options.interchangeNumber)
			}

			try {
				const interchangeList = await interchangeLookup(mediator, options)
				ractive.set({ interchangeList })

				return interchangeList
			} finally {
				ractive.set({ loading: false })
			}
		},
		show(interchangeList, searchType) {
			this.set({
				interchangeList,
				currentSearchType: searchType,

			})
		},
		async showInterchangeModal(interchange, event) {
			event?.stopPropagation()

			const ractive = this

			const rawEditDisable = interchange?.editDisable
			const editDisable = rawEditDisable === undefined ? false : rawEditDisable

			await ractive.set({
				interchangeModal: {
					selectedInterchange: interchange,
					shown: true,
					exactNotes: '',
					inexactNotes: '',
					uploadDisabled: editDisable,
					modificationDisabled: editDisable,
				},
			})
			const inventoryTypeId = interchange.inventoryTypeId
			const interchangeNumber = interchange.interchangeNumber
			const nonNumbericInterchangeNumber = stripNonNumeric(interchangeNumber)

			if (nonNumbericInterchangeNumber === interchangeNumber) {
				ractive.set({ exactNotes: '' })
			} else {
				ractive.set({ exactNoteLoading: true })
				try {
					const notes = await mediator.call('emitToServer', 'load interchange notes', { inventoryTypeId, interchangeNumber: nonNumbericInterchangeNumber })
					ractive.set({ exactNotes: notes })
				} catch (err) {
					logAndAlert(err, mediator, 'Error loading exact interchange notes')
				} finally {
					ractive.set({ exactNoteLoading: false })
				}
			}

			ractive.set({ inexactNoteLoading: true })
			try {
				const notes = await mediator.call('emitToServer', 'load interchange notes', { inventoryTypeId, interchangeNumber })
				ractive.set({ inexactNotes: notes })
			} catch (err) {
				logAndAlert(err, mediator, 'Error loading inexact interchange notes')
			} finally {
				ractive.set({ inexactNoteLoading: false })
			}
		},
		closeInterchangeModal() {
			this.set({
				interchangeModal: {
					selectedInterchange: {},
					shown: true,
				},
			})
		},
		clear() {
			this.reset(klona(defaultData))
		},
	})
}
