import template from './attachment.html'
import makeAttachmentFns from 'utility/attachment-helper.ts'
import { pluralIfNeeded as plural } from '@isoftdata/utility-string'
import pProps from 'p-props'

//Ractive components
import makeAttachment from '@isoftdata/attachment'
import makeButton from '@isoftdata/button'
import makeYesNo from '@isoftdata/yes-no-modal'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle.attachment',
		route: 'attachment',
		querystringParameters: [ 'vehicleId', 'storeId' ],
		defaultParameters: { 'storeId': 1 },
		template: {
			template,
			components: {
				attachments: makeAttachment(makeAttachmentFns(mediator)),
				itButton: makeButton(),
				itYesNo: makeYesNo(),
			},
		},
		resolve(data, { vehicleId }) {
			const fileAssociation = {
				type: 'VEHICLE',
				key: {
					vehicleId: parseInt(vehicleId, 10),
				},
			}

			return pProps({
				vehicleId,
				inventoryList: mediator.call('emitToServer', 'load inventory', { vehicleId }),
				fileAssociation,
				fileList: mediator.call('emitToServer', 'load file info list', { association: fileAssociation }),
				viewMode: mediator.call('emitToServer', 'check user setting', {
					name: 'Vehicle Attachment Screen View Mode',
					category: 'Options',
					settingType: 'Interface History',
					defaultValue: 'THUMBNAIL-MD',
				}),
			})
		},
		activate({ domApi: ractive }) {
			ractive.observe('viewMode', value => {
				mediator.call('emitToServer', 'save user setting', {
					name: 'Vehicle Attachment Screen View Mode',
					category: 'Options',
					type: 'Interface History',
					value,
				}, { init: false })
			})

			ractive.on('associateWithParts', () => {
				const fileList = ractive.findComponent('attachments')
					.getSelectedFileList()
					.map(file => {
						return {
							fileId: file.fileId,
							public: file.public,
						}
					})

				ractive.findComponent('itYesNo').show({
					title: 'Add vehicle attachments parts?',
					question: `The ${fileList.length} selected vehicle attachment${plural(fileList.length)} will be added to all vehicle parts(${ractive.get('inventoryList').length}).`,
					yesText: 'Add Attachments',
					noText: 'Cancel',
					yesCallback: 'confirmAssociateWithParts',
					yesCallbackContext: fileList,
					bootstrapModalSizeClass: '',
				})
			})

			ractive.on('confirmAssociateWithParts', async(context, fileList) => {
				try {
					await mediator.call('emitToServer', 'copy vehicle file associations to child parts', {
						vehicleId: ractive.get('fileAssociation').key.vehicleId,
						fileList,
					})
				} catch (err) {
					logAndAlert(err, mediator, 'Error adding vehicle images to parts')
				}
			})
		},
	})
}
