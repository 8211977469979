import template from './payment.html'
import pProps from 'p-props'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive components
import makeReportSelectionModal from 'components/report-selection'
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.entity.sale.payment',
		route: 'payment',
		querystringParameters: [ 'saleId', 'paymentId', 'showVoidPayments' ],
		defaultParameters: {
			showVoidPayments: false,
		},
		data: {},
		template: {
			template,
			components: {
				itTable: makeTable(),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
			},
			computed: {
				selectedPayment() {
					const payments = this.get('payments')
					const selectedPaymentId = this.get('selectedPaymentId')

					return payments.find(payment => {
						return selectedPaymentId === payment.paymentId
					})
				},
			},
		},
		async resolve(data, parameters) {
			const showVoidPayments = stringToBoolean(parameters.showVoidPayments)

			if (parameters.saleId) {
				return await pProps({
					saleId: parameters.saleId,
					selectedPaymentId: parameters.paymentId,
					payments: mediator.call('emitToServer', 'load payments', { saleId: parameters.saleId, status: showVoidPayments ? false : 'payment' }),
					showVoidPayments,
				})
			} else {
				return {}
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('PRINT_PAYMENT', (context, paymentId) => {
				const reportParameters = [
					{ parameterName: 'paymentnum', value: parseInt(paymentId, 10) },
				]

				const reportJob = { type: 'Payment Receipt', reportParameters }

				ractive.findComponent('reportSelectionModal').printReport(reportJob)
			})

			ractive.observe('showVoidPayments', showVoidPayments => stateRouter.go(null, { showVoidPayments }, { inherit: true }, { init: false }))
		},
	})
}
