import template from './category.html'
import klona from 'klona'
import domValue from 'dom-value'

//Ractive Components
import makeInventoryQuestion from 'components/inventory-question'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextarea from '@isoftdata/textarea'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import { stringToBoolean } from '@isoftdata/utility-string'

const defaultNewCategory = Object.freeze({
	name: '',
	description: '',
	core: false,
	isoftCategoryId: null,
	partListAuthorityId: null,
	userEditable: true,
})

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.inventory.type.category',
		route: 'category',
		querystringParameters: [ 'typeSetId', 'categoryId' ],
		template: {
			template,
			delegate: false,
			components: {
				itTable: makeTable({ stickyHeader: true }),
				itButton: makeButton(),
				itModal: makeModal(),
				itInput: makeInput({ twoway: true, lazy: false }),
				itCheckbox: makeCheckbox({ twoway: true, lazy: false }),
				itTextarea: makeTextarea({ twoway: true, lazy: false }),
				inventoryQuestion: makeInventoryQuestion({ mediator, logAndAlert }),
			},
			categoryClicked(category) {
				console.log('category clicked!', category)
				stateRouter.go(null, { categoryId: category.categoryId }, { inherit: true })
			},
			async addCategoryClicked() {
				await this.set({
					addCategoryModalShown: true,
					editCategoryMode: false,
					newCategory: klona(defaultNewCategory),
				})
				this.find('#newCategoryName')?.select()
			},
			async editCategoryClicked() {
				const ractive = this

				const selectedCategory = ractive.get('selectedCategory')

				if (!(selectedCategory?.categoryId && selectedCategory?.userEditable)) {
					return
				}

				await ractive.set({
					addCategoryModalShown: true,
					editCategoryMode: true,
					newCategory: klona(selectedCategory),
				})
			},
			async addCategoryConfirm(category, event) {
				const ractive = this
				const editCategoryMode = ractive.get('editCategoryMode')
				event?.preventDefault()
				ractive.set({ categoryIsSaving: true })
				// Checkbox toggle value is stringified boolean
				category.core = stringToBoolean(category.core) ? 1 : 0
				try {
					const savedCategory = await mediator.call('emitToServer', 'save category', { category })
					ractive.upsert('categoryList', 'categoryId', savedCategory)
					ractive.set({
						addCategoryModalShown: false,
						newCategory: klona(defaultNewCategory),
					})
					if (!editCategoryMode) {
						// As of writing, not possible to change category set if editing
						await this.addCategorySet(savedCategory)
					}
					// Update selectedCategory to make sure editing again works as intended
					ractive.set({ selectedCategory: savedCategory })
					if (!editCategoryMode) {
						// Already selected if in edit mode, no need to reload state
						this.categoryClicked(savedCategory)
					}
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving category')
				} finally {
					this.set({ categoryIsSaving: false })
				}
			},
			async addCategorySet(category) {
				try {
					const savedCategorySet = await mediator.call('emitToServer', 'save category set', {
						categorySet: {
							categoryId: category.categoryId,
							typeSetId: this.get('typeSetId'),
						},
					})
					const categorySetsMap = this.get('categorySetsMap')
					categorySetsMap.set(savedCategorySet.categoryId, savedCategorySet)
					this.set({ categorySetsMap })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving category set')
				}
			},
			removeCategorySet(category) {
				console.log('remove!', category)
				//remove it
			},
			async toggleCategoryInclusion(category, event) {
				const include = !!domValue(event.target)
				if (include) {
					await this.addCategorySet(category)
				} else {
					await this.removeCategorySet(category)
				}
			},
			async configureQuestionsClicked(category) {
				const ractive = this

				ractive.set({ isLoadingQuestions: true })
				try {
					console.log(ractive.get('selectedCategory.categoryId'))
					const questionList = await mediator.call('emitToServer', 'load inventory questions', {
						inventoryTypeId: ractive.get('typeSetId'),
						categoryId: ractive.get('selectedCategory.categoryId') || null,
					})
					console.log(questionList)
					ractive.set({
						questionList,
						configureQuestionsModalShown: true,
					})
				} catch (err) {
					logAndAlert(err, mediator, 'Error loading inventory questions')
				} finally {
					ractive.set({ isLoadingQuestions: false })
				}
			},
			computed: {
				displayCategoryList() {
					const categoryList = this.get('categoryList')
					const categorySetsMap = this.get('categorySetsMap')

					return categoryList
						.map(category => {
							return {
								categorySetId: categorySetsMap.get(category.categoryId)?.categorySetId || null,
								...category,
							}
						})
				},
			},
		},
		async resolve(data, { typeSetId, categoryId }) {
			const [ categoryList, categorySets ] = await Promise.all([
				mediator.call('emitToServer', 'load categories', {}),
				mediator.call('emitToServer', 'load category sets', { typeSetId }),
			])

			const selectedCategoryId = parseInt(categoryId, 10)
			const selectedCategory = selectedCategoryId ? categoryList.find(({ categoryId }) => categoryId === selectedCategoryId) : {}

			let categorySetsMap = new Map()
			for (const set of categorySets) {
				categorySetsMap.set(set.categoryId, set)
			}

			return {
				typeSetId: parseInt(typeSetId, 10),
				categoryList,
				categorySets,
				categorySetsMap,
				selectedCategory,
				addCategoryModalShown: false,
				editCategoryMode: false,
				newCategory: klona(defaultNewCategory),
				categoryIsSaving: false,
				isLoadingQuestions: false,
				questionList: [],
			}
		},
		activate({ domApi: ractive }) {
			const selectedCategoryId = ractive.get('selectedCategory.categoryId')
			if (selectedCategoryId) {
				ractive.find(`#category_${selectedCategoryId}`).scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
			}
		},
	})
}
