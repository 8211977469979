import template from './part-history.html'
import pProps from 'p-props'
import { datesFromRange, rangeFromDates } from '@isoftdata/utility-date-time'
import * as currency from '@isoftdata/utility-currency'

//Ractive components
import makeDateRange from '@isoftdata/date-range'
import makeTable from '@isoftdata/table'

const sumProp = (array, prop) => array.reduce((sum, item) => sum + (item[prop] || 0), 0)

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.part.history',
		route: 'history',
		querystringParameters: [ 'inventoryId', 'storeId', 'fromDate', 'toDate' ],
		defaultParameters: { 'storeId': 1 },
		data: {
			formatCurrency: currency.format,
		},
		template: {
			template,
			twoway: false,
			components: {
				itTable: makeTable(),
				itDateRange: makeDateRange(),
			},
			computed: {
				saleRetailTotal() {
					return sumProp(this.get('sortedSaleLines'), 'price')
				},
				saleCoreCharges() {
					return sumProp(this.get('sortedSaleLines'), 'coreAmount')
				},
				saleQuantityInvoiced() {
					return sumProp(this.get('sortedSaleLines'), 'quantity')
				},
				purchaseOrderRetailTotal() {
					return sumProp(this.get('sortedPurchaseOrderLines'), 'price')
				},
				purchaseOrderCoreCharges() {
					return sumProp(this.get('sortedPurchaseOrderLines'), 'coreAmount')
				},
				purchaseOrderQuantityReceived() {
					return sumProp(this.get('sortedPurchaseOrderLines'), 'quantity')
				},
				quoteRetailTotal() {
					return sumProp(this.get('sortedQuoteLines'), 'price')
				},
				quoteCoreCharges() {
					return sumProp(this.get('sortedQuoteLines'), 'coreAmount')
				},
				quoteQuantityInvoiced() {
					return sumProp(this.get('sortedQuoteLines'), 'quantity')
				},
			},
			saleClicked(saleId) {
				stateRouter.go('app.sale', { documentId: saleId, documentType: 'INVOICE' })
			},
			purchaseOrderClicked(purchaseOrderId) {
				stateRouter.go('app.purchase-order', { purchaseOrderId })
			},
			quoteClicked(quoteId) {
				stateRouter.go('app.sale', { documentId: quoteId, documentType: 'QUOTE' })
			},
		},
		async resolve(data, parameters) {
			const { fromDate, toDate, inventoryId } = parameters

			if (fromDate === undefined && toDate === undefined) {
				const defaultDates = datesFromRange('Always')

				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				}
			}

			const lines = await pProps({
				saleLines: mediator.call('emitToServer', 'load sale lines', { inventoryId, options: { includeSale: true }, fromDate, toDate }),
				purchaseOrderLines: mediator.call('emitToServer', 'load purchase order lines', { inventoryId, includePurchaseOrder: true, fromDate, toDate }),
				quoteLines: mediator.call('emitToServer', 'load quote lines', { inventoryId, options: { includeQuote: true }, fromDate, toDate }),
			})

			return {
				...lines,
				range: rangeFromDates(fromDate, toDate),
				dates: { from: fromDate, to: toDate },
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('itDateRange.dateChange', (context, dates) => {
				stateRouter.go(null, { fromDate: dates.from, toDate: dates.to }, { inherit: true })
			})
		},
	})
}
