import template from './status-location-change-modal.html'
import klona from 'klona'
import * as currency from '@isoftdata/utility-currency'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'

const changeTypeMap = new Map()

changeTypeMap.set('STATUS', {
	getTitle: (status, _location) => `Choose which parts to set to status ${status}`,
	getDefaultChangeFlag: (item, vehicleLocation) => item.location === vehicleLocation,
	getNoticeText: (location, _oldLocation) => `Parts whose location matches the location of the vehicle(${location}), are checked by default.`,
	updateKey: 'status',
})

changeTypeMap.set('LOCATION', {
	getTitle: (_status, vehicleLocation) => `Choose which parts to set to location "${vehicleLocation}"`,
	getDefaultChangeFlag: (item, vehicleLocation) => item.location === vehicleLocation,
	getNoticeText: (_location, oldLocation) => `Parts whose location matches the previous location of the vehicle("${oldLocation ?? ''}"), are checked by default.`,
	updateKey: 'location',
})

const defaultState = {
	inventoryItems: [],
	modalShown: false,
	pendingInventoryChange: [],
	noticeText: '',
	formatCurrency: currency.format,
	changeType: '',
}

export default function createStatusLocationChangeModal({ mediator }) {
	return Ractive.extend({
		template,
		attributes: {
			required: [ ],
			optional: [ 'modalShown' ],
		},
		data() {
			return {
				...klona(defaultState),
				inventoryTypeList: [],
				statusList: [],
			}
		},
		components: {
			itModal: makeModal(),
			itTable: makeTable(),
			itButton: makeButton(),
		},
		computed: {
			selectedItemCount() {
				return this.get('pendingInventoryChange')
					.filter(item => item.toChange)?.length
			},
		},
		async showModal(changeType, vehicle, oldLocation) {
			const ractive = this

			ractive.set({ changeType, vehicle })

			if (![ 'STATUS', 'LOCATION' ].includes(changeType)) {
				throw new Error('Invalid change type')
			}

			let doShowModal = true

			if (changeType === 'STATUS') {
				doShowModal = !!ractive.get('statusList').find(status => status.abbreviation === vehicle.status && status.triggerPartChange)
			}

			if (!vehicle?.vehicleId) {
				return
			}

			let vehicleInventory = []

			try {
				vehicleInventory = await mediator.call('emitToServer', 'load inventory', { vehicleId: vehicle?.vehicleId, status: [ 'A', 'H' ] })
			} catch (err) {
				alert('An unknown error occured while loading inventory')
				console.error(err)
			} finally {
				ractive.set({ isLoadingInventory: false })
			}

			if (vehicle?.vehicleId && doShowModal && vehicleInventory.length > 0) {
				ractive.set({
					vehicle,
					modalShown: true,
					isLoadingInventory: true,
					modalTitle: changeTypeMap.get(changeType).getTitle(vehicle.status, vehicle.location),
					noticeText: changeTypeMap.get(changeType).getNoticeText(vehicle.location, oldLocation),
				})

				const inventoryTypeList = ractive.get('inventoryTypeList')

				const pendingInventoryChange = vehicleInventory.map(item => {
					const inventoryType = inventoryTypeList.find(type => type.inventoryTypeId === item.inventoryTypeId)?.name || ''

					return {
						...item,
						inventoryType,
						manufacturerModel: inventoryType.usesManufacturerModel ? `${item.partManufacturer} ${item.partModel}` : `${vehicle.make} ${vehicle.model}`,
						toChange: changeTypeMap.get(changeType).getDefaultChangeFlag(item, oldLocation || vehicle.location),
					}
				})

				console.log(pendingInventoryChange)

				ractive.set({
					isLoadingInventory: false,
					pendingInventoryChange,
				})
			}
		},
		closeModal() {
			this.set({
				...klona(defaultState),
			})
		},
		toggleAllSelectionState() {
			console.log(this.get('selectedItemCount'), this.get('pendingInventoryChange').length)
			const allCurrentlySelected = this.get('selectedItemCount') === this.get('pendingInventoryChange').length

			const pendingInventoryChange = this.get('pendingInventoryChange').map(item => {
				return {
					...item,
					toChange: !allCurrentlySelected,
				}
			})
			console.log(pendingInventoryChange)
			this.set({ pendingInventoryChange })
		},
		async save() {
			const ractive = this
			ractive.set({ isSaving: true })

			try {
				const inventoryIdsToUpdate = ractive.get('pendingInventoryChange')
					.filter(item => item.toChange)
					.map(item => item.inventoryId)

				const updateKey = changeTypeMap.get(ractive.get('changeType')).updateKey

				if (inventoryIdsToUpdate.length > 0 && updateKey && ractive.get('vehicle')[updateKey]) {
					await mediator.call('emitToServer', 'bulk inventory update', { inventoryIds: inventoryIdsToUpdate, update: { [updateKey]: ractive.get('vehicle')[updateKey] } })
				}
				ractive.closeModal()
			} catch (err) {
				console.error(err)
				alert('An unknown error occured while saving')
			} finally {
				ractive.set({ isSaving: false })
			}
		},
		async oninit() {
			const ractive = this

			const [ inventoryTypeList, statusList ] = await Promise.all([
				mediator.call('emitToServer', 'load inventory type list', {}),
				mediator.call('emitToServer', 'load statuses', { type: 'Vehicle' }),
			])

			ractive.set({ inventoryTypeList, statusList })
		},
	})
}
