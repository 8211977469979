import template from './teardown.html'
import pProps from 'p-props'
import domValue from 'dom-value'
import teardownDataLoader from 'shared/teardown-data-loader'
import statusListLoader from 'utility/status-list-loader.ts'
import klona from 'klona'
//import * as arrayHelper from '@isoftdata/utility-array'

//Ractive Components
import makeCurrencyInput from '@isoftdata/currency-input'
import makeTeardown from 'components/teardown'
import makeSelect from '@isoftdata/select'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeStatusSelect from 'components/status-select'
import makeStatusLocationChangeModal from 'components/status-location-change-modal'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	const stateName = 'app.vehicle.teardown'
	stateRouter.addState({
		name: stateName,
		route: 'teardown',
		querystringParameters: [ 'vehicleId', 'storeId', 'vehicleBidId' ],
		defaultParameters: { 'vehicleBidId': '' },
		template: {
			twoway: false,
			template,
			components: {
				teardown: makeTeardown({ mediator, stateRouter, logAndAlert, hasPermission }),
				closeBidModal: makeModal(),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itInput: makeInput({ twoway: true, lazy: true }),
				itStatusSelect: makeStatusSelect({ twoway: true }),
			},
			data: {
				domValue,
			},
			computed: {
				selectedVehicleBidIndex() {
					const vehicleBidId = this.get('vehicleBidId')
					return this.get('bids')?.findIndex(bid => bid.vehicleBidId === vehicleBidId)
				},
				selectedVehicleBid() {
					return this.get('bids')[this.get('selectedVehicleBidIndex')]
				},
			},
			async acceptBid({ vehicleBidId }) {
				try {
					await mediator.call('emitToServer', 'accept vehicle bid', { vehicleBidId })
					const bids = await mediator.call('emitToServer', 'load vehicle bids', { vehicleId: this.get('vehicleId') })

					this.set({ bids })
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured accepting the bid')
				}
			},
			statusChanged() {
				const activeVehicle = this.get('activeVehicle')
				this.findComponent('statusLocationChangeModal')?.showModal?.('STATUS', activeVehicle)
			},
			showCloseBid(bidToClose) {
				const ractive = this

				const vehicle = klona(ractive.get("vehicle"))
				ractive.set({ showCloseBidModal: true, bidToClose, activeVehicle: vehicle })
			},
			async closeBid() {
				const ractive = this

				const bidToClose = ractive.get("bidToClose")

				if (!bidToClose) {
					console.error("bidToClose not set!")
					return
				}

				const activeVehicle = ractive.get("activeVehicle")

				await this.saveVehicle(activeVehicle)
				await this.saveBid({ ...bidToClose, closed: 1 })

				ractive.set({ showCloseBidModal: false, bidToClose: false, activeVehicle: false })
			},
			async saveBid(bidToSave) {
				if (bidToSave) {
					try {
						const savedBid = await mediator.call('emitToServer', 'save vehicle bid', { bid: bidToSave })
						this.upsert('bids', 'vehicleBidId', savedBid)
					} catch (err) {
						logAndAlert(err, mediator, 'An error occured saving the bid')
					}
				} else {
					throw new Error('Bid is required')
				}
			},
			async saveVehicle(vehicle) {
				try {
					this.set({ isSavingVehicle: true })
					const savedVehicle = await mediator.call('emitToServer', 'save vehicle', { vehicle })
					mediator.call('vehicleSave', {
						stateThatSaved: stateName,
						data: savedVehicle,
					})
					return savedVehicle
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured saving the vehicle')
					throw err
				} finally {
					this.set({ isSavingVehicle: false })
				}
			},
		},
		async resolve(data, parameters) {
			const vehicleId = parseInt(parameters.vehicleId, 10)
			const storeId = parseInt(parameters.storeId, 10)
			const vehicleBidId = parameters.vehicleBidId ? parseInt(parameters.vehicleBidId, 10) : null

			if (vehicleId) {
				//STAGE ONE
				const stageOneRes = await pProps({
					vehicle: vehicleId ? mediator.call('emitToServer', 'load vehicle', { vehicleId }) : [],
					bids: vehicleId ? mediator.call('emitToServer', 'load vehicle bids', { vehicleId }) : [],
					vehicleInventory: vehicleId ? mediator.call('emitToServer', 'load inventory', { vehicleId, storeId }) : [],
					defaultTemplateName: mediator.call('emitToServer', 'load default teardown name', { vehicleId }),
					totalVehicleCost: mediator.call('emitToServer', 'load total vehicle cost', { vehicleId }),
					vendors: mediator.call('emitToServer', 'load vendor list', {}),
					locations: mediator.call('emitToServer', 'load inventory locations', {}),
				})

				if (!vehicleBidId && stageOneRes.vehicleInventory.length === 0 && stageOneRes?.bids?.length > 0) {
					throw {
						redirectTo: {
							name: null,
							params: { ...parameters, vehicleBidId: stageOneRes?.bids?.[0].vehicleBidId },
						},
					}
				}

				if (vehicleBidId && !stageOneRes?.bids?.find(bid => vehicleBidId === bid.vehicleBidId)) {
					throw {
						redirectTo: {
							name: null,
							params: { ...parameters, vehicleBidId: '' },
						},
					}
				}

				if (stageOneRes.vehicle.length === 1) {
					stageOneRes.vehicle = stageOneRes.vehicle[0]
				} else {
					throw {
						redirectTo: {
							name: null,
							params: { ...parameters, vehicleId: '' },
						},
					}
				}

				const bidParts = vehicleBidId ? (await mediator.call('emitToServer', 'load vehicle bid parts', { vehicleBidId })) : []
				const teardownData = await teardownDataLoader({
					mediator,
					parts: vehicleBidId ? bidParts : stageOneRes.vehicleInventory,
					parent: stageOneRes.vehicle,
					parentType: 'VEHICLE',
					teardownType: vehicleBidId ? 'BID' : 'INVENTORY',
					vehicleBidId,
				})

				return {
					vehicleId,
					storeId,
					vehicleBidId,
					...stageOneRes,
					...bidParts,
					...teardownData,
					showCloseBidModal: false,
					bidToClose: false,
					activeVehicle: false,
					statusList: await statusListLoader(mediator, 'Vehicle'),
				}
			}
		},
		activate(context) {
			const { domApi: ractive } = context
			//
			const removeSelectedBidIdProvider = mediator.provide('getSelectedBidId', () => ractive.get('selectedVehicleBid.vehicleBidId'))
			context.on('destroy', () => {
				removeSelectedBidIdProvider()
			})
		},
	})
}
