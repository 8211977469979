import template from './results.html'
import pProps from 'p-props'

//Ractive components
import makeImageThumbnailViewer from '@isoftdata/image-thumbnail-viewer'
import makeImageViewerModal from '@isoftdata/image-viewer-modal'
import makeTableCardFooter from '@isoftdata/table-card-footer'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import { stringToBoolean } from '@isoftdata/utility-string'

const storeId = 1 // someday won't be a constant

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle-search.results',
		route: 'results',
		querystringParameters: [ 'selectedVehicleId', 'vin', 'year', 'yearTo', 'make', 'model', 'trackingNum', 'location', 'isDismantled', 'purchasedDate', 'purchasedDateTo', 'teardownDate', 'teardownDateTo' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				imageThumbnailViewer: makeImageThumbnailViewer(),
				imageViewerModal: makeImageViewerModal(),
				itTableCardFooter: makeTableCardFooter(),
				itButton: makeButton(),
			},
		},
		async resolve(data, { selectedVehicleId, searchQuery, status, vehicleType, isDismantled, ...advSearchParams }) {
			const { user } = JSON.parse(localStorage.getItem('session'))

			if (isDismantled !== undefined) {
				isDismantled = stringToBoolean(isDismantled)
			}

			const perPageCount = await mediator.call('emitToServer', 'check user setting', {
				name: 'Chromium Vehicle Search Results Per Page',
				category: 'Options',
				userAccountId: user?.userAccountId,
				settingType: 'Interface History',
				defaultValue: 20,
				dataType: 'integer',
			})

			return pProps({
				selectedVehicleId: parseInt(selectedVehicleId, 10),
				showThumbnails: true,
				imageViewerShown: false,
				imageViewerImages: [],
				perPageCount,
				vehicles: mediator.call('emitToServer', 'vehicle search', {
					searchQuery,
					status,
					vehicleType,
					isDismantled,
					...advSearchParams,
					options: {
						attachmentCount: {
							type: 'Image',
							public: true,
						},
						topRankedImageMetaData: true,
					},
				}),
			})
		},
		activate({ domApi: ractive }) {
			/*
			const vehicles = ractive.get('vehicles')
			const selectedVehicleId = parseInt(ractive.get('selectedVehicleId'), 10)

			if (selectedVehicleId) {
				ractive.find(`#vehicle-${selectedVehicleId}`)?.focus?.()
			}

			if (vehicles.length > 0 && !vehicles.find(vehicle => {
				return vehicle.vehicleId === selectedVehicleId
			})) {
				stateRouter.go('app.vehicle-search.results', { selectedVehicleId: vehicles[0].vehicleId }, { inherit: true })
			}

			ractive.on('itTable.selection-change', (context, id) => {
				stateRouter.go('app.vehicle-search.results', { selectedVehicleId: id }, { inherit: true })
			})

			ractive.on('itTable.enter-pressed', () => {
				ractive.fire('edit-vehicle-clicked', {}, selectedVehicleId)
			}) */

			ractive.on('vehicle-clicked', (context, vehicleId) => {
				stateRouter.go('app.vehicle', { vehicleId, storeId })
			})

			ractive.on('showImages', async(context, vehicleId) => {
				context.event.stopPropagation()
				ractive.set({ imageViewerShown: true, imageViewerImages: [] })

				try {
					const files = await mediator.call('emitToServer', 'load file info list', {
						association: {
							type: 'VEHICLE',
							key: { vehicleId },
						},
						type: 'Image',
					})

					ractive.set({
						imageViewerImages: files
							.filter(({ mimeType }) => mimeType?.startsWith('image/'))
							.map(({ md5sum, fileId, name }) => `attachments/${md5sum}${fileId}/${name}?width=500&height=500&fit=contain`),
						currentImageViewerImageIndex: 0,
					})
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured while loading the list of images')
				}
			})

			ractive.on('perPageCountChanged', async(context, perPageCount) => {
				try {
					await mediator.call('emitToServer', 'save user setting', {
						name: 'Chromium Vehicle Search Results Per Page',
						category: 'Options',
						userAccountId: ractive.get('session.user.userAccountId'),
						settingType: 'Interface History',
						defaultValue: 20,
						dataType: 'integer',
						value: perPageCount,
					}, { doNotAlert: true })
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured while saving the number of seach results per page')
				}
			})
		},
	})
}
