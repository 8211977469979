import component from './Work.svelte'
import homeDataLoader from 'shared/home-data-loader'
import { stringToBoolean } from '@isoftdata/utility-string'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.work',
		route: 'work',
		querystringParameters: [ 'fromDate', 'toDate', 'isActive', 'salesperson' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			// Maintain not existing as undefined, otherwise get as boolean. 3 state logic.
			const isActive = parameters?.isActive !== undefined ? stringToBoolean(parameters.isActive) : undefined
			const isInactive = isActive !== undefined ? !isActive : undefined
			const result = await homeDataLoader({
				fromDate: parameters.fromDate,
				toDate: parameters.toDate,
				documentLoadSocket: 'load work orders',
				documentLoadAdditionalParameters: {
					isClosed: isInactive,
					isExpired: isInactive,
					salesperson: parameters?.salesperson,
				},
				mediator,
			})
			return {
				...result,
				isActive: isActive?.toString() || 'undefined',
				doSalespersonFilter: parameters?.salesperson !== undefined,
			}
		},
	})
}
