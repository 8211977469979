import * as arrayHelper from '@isoftdata/utility-array'
import { getEntityDisplayName } from './entity-helper'

export default async function({
	fromDate,
	toDate,
	documentLoadSocket,
	documentLoadAdditionalParameters = {},
	entityLoadSocket = 'load customer',
	entityKey = 'customerId', //probably customerId for everything except POs would be vendorId
	mediator,
}) {
	if (!(fromDate || toDate)) {
		//the home state should be redirect with dates if no dates are provided, so we can just skip doing anything here
		return { entities: [], displayDocumentList: [] }
	}

	if (fromDate && toDate) {
		const documentSocketOptions = {
			fromDate,
			toDate,
			...documentLoadAdditionalParameters,
		}

		const homeDocumentList = await mediator.call('emitToServer', documentLoadSocket, documentSocketOptions)
		const entityIdsForHomeDocument = arrayHelper.getDistinctArrayValues(homeDocumentList.map(homeDocument => homeDocument[entityKey]))

		let entities = []
		if (entityIdsForHomeDocument && entityIdsForHomeDocument.length > 0) {
			entities = await mediator.call('emitToServer', entityLoadSocket, { [entityKey]: entityIdsForHomeDocument })
		}

		return {
			entities,
			displayDocumentList: getArrayWithEntityDisplayName({
				array: homeDocumentList,
				entities,
				entityKey,
			}),
		}
	} else {
		throw new Error('missing date param')
	}
}

function getArrayWithEntityDisplayName({ array, entities, entityKey }) {
	if (array && Array.isArray(array) && entities && Array.isArray(entities)) {
		return array.map(arrayItem => {
			const entity = entities.find(entity => entity[entityKey] == arrayItem[entityKey])

			return {
				...arrayItem,
				...getEntityDisplayName(entity),
			}
		})
	}
	return array
}
