import template from './quickbooks.html'
import pProps from 'p-props'
import titleCase from 'to-title-case'

//Ractive components
import makeQuickbooksModal from 'components/quickbooks-modal'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeModal from '@isoftdata/modal'

const stateName = 'app.configuration.integration.quickbooks'

export default function({ mediator, stateRouter, hasPermission }) {
	stateRouter.addState({
		name: stateName,
		route: 'quickbooks',
		defaultChild: 'redirect',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				quickbooksModal: makeQuickbooksModal(mediator),
				itModal: makeModal(),
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		async resolve(data, { systemSelection, hideMatches }) {
			if (!hasPermission('PM_QUICKBOOKS_VIEW')) {
				return new Error("You don't have permission to use QuickBooks.")
			}

			return await pProps({
				titleCase,
				isConnectedToQuickbooks: await mediator.call('emitToServer', 'is connected to quickbooks', {}),
			})
		},
		activate(context) {
			const { domApi: ractive } = context

			const removeConnectedToQbProvider = mediator.provide('connectedToQuickbooks', () => {
				//They just connected to quicbooks, show them some getting started thingy.
				mediator.call('show-quickbooks-in-sidebar', true)
				stateRouter.go(null, { justConnected: true, isConnectedToQuickbooks: true })
			})

			context.on('destroy', () => removeConnectedToQbProvider?.())

			ractive.observe('systemSelection', ({ quickbooks, itrack }) => {
				stateRouter.go(null, { systemSelection: (quickbooks && 'quickbooks') || (itrack && 'itrack') }, { inherit: true })
			}, { init: false })

			ractive.observe('hideMatches', hideMatches => {
				stateRouter.go(null, { hideMatches }, { inherit: true })
			}, { init: false })

			ractive.on('qb-auth', (context, event) => {
				event.original.preventDefault()
				const width = Math.floor(window.screen.width / 2)
				const height = Math.floor(window.screen.height / 2)

				const left = Math.floor(width / 2)
				const top = Math.floor(height / 2)
				window.open('connect_to_quickbooks', 'qbAuth', `location=1,width=${width},height=${height},left=${left},top=${top}`)
			})
		},
	})
}
