import template from './error-log.html'
import { getErrorLog } from 'utility/error-handler'
import formatDistance from 'date-fns/formatDistance'
import makeStorageHelper from 'utility/storage-json-helper.ts'

//Ractive components
import makeTableCardFooter from '@isoftdata/table-card-footer'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

const localStorageJSON = makeStorageHelper('localStorage')

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: 'app.configuration.error-log',
		route: 'error-log',
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itTableCardFooter: makeTableCardFooter(),
				itSelect: makeSelect({ twoway: true, lazy: true }),
			},
			dateDistance: date => {
				date = formatDistance(new Date(date), new Date(), {
					includeSeconds: true,
					addSuffix: true,
				})
				return date
			},
			formatDate: date => {
				date = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(date))
				return date
			},
			data: {
				expandedContextIndex: null,
				expandedErrorIndex: null,
			},
			computed: {
				displayUsers() {
					const ractive = this
					const displayUsers = this.get('users')
					const usersWithErrors = Object.keys(ractive.get('errorLog') || {}).map(userId => Number(userId))

					return displayUsers.reduce((selectedUsers, user) => {
						if (user.userAccountId === ractive.get('userAccountId') || usersWithErrors.includes(user.userAccountId)) {
							selectedUsers.push(user)
							return selectedUsers
						}
						return selectedUsers
					}, [])
				},
				displayErrorLog: {
					get() {
						const ractive = this
						const errorLog = ractive.get('errorLog') || {}
						const selectedUserId = ractive.get('selectedUserId')

						if (errorLog[selectedUserId]) {
							return errorLog[selectedUserId].map(error => {
								let isJson = false
								try {
									JSON.parse(error.context)
									error.context.replace(/ {4}/, '\t')
									isJson = true
								} catch (err) {
									// isJson is already false
								}
								return {
									...error,
									isJson,
								}
							})
						}
						return []
					},
					set(value) {
						const ractive = this
						const selectedUserId = ractive.get('selectedUserId')
						ractive.set(`errorLog[${selectedUserId}]`, value)
					},
				},
			},
		},
		async resolve(data, parameter) {
			const session = JSON.parse(localStorage.getItem('session'))

			return {
				userAccountId: session?.userAccountId,
				errorLog: getErrorLog(),
				users: await mediator.call('emitToServer', 'load users', {}),
				selectedUserId: session?.userAccountId,
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.on('removeError', (context, originalIndex) => {
				const userAccountId = ractive.get('userAccountId')

				ractive.splice(`errorLog[${userAccountId}]`, originalIndex, 1)
				localStorageJSON.setItem('errorLog', ractive.get('errorLog'))
			})

			ractive.on('clearAllError', () => {
				const userAccountId = ractive.get('userAccountId')

				const confirmClearAll = confirm("Are you sure you want to remove all the errors?")

				if (confirmClearAll) {
					ractive.set(`errorLog[${userAccountId}]`, [])
					localStorageJSON.setItem('errorLog', ractive.get('errorLog'))
				}
			})

			ractive.observe('displayErrorLog expandedContextIndex', (newVal, oldVal, keypath) => console.log(keypath, newVal))
		},
	})
}
