import template from './teardown.html'

//Ractive Components
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.vehicle.teardown',
		route: 'teardown',
		querystringParameters: [ 'teardown' ],
		defaultChild: 'inventory-type-list',
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: false }),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itModal: makeModal(),
				itButton: makeButton(),
			},
			data: {
				newTemplateName: "",
				isRenaming: false,
				teardownEditSuccessful: false,
				successMessage: "",
			},
			computed: {
				newTeardownInProgress() {
					return this.get('teardownNames').find(teardown => teardown.notSaved)
				},
			},
			addNewTeardown() {
				this.set({
					teardownModalShow: true,
					teardownModalTitle: 'New Teardown',
					templateInputLabel: 'Enter new teardown name',
					isRenaming: false,
				}).then(() => {
					this.addFocusOnModalInput()
				})
			},
			changeTeardownName(teardownSelected) {
				this.set({ teardownModalShow: true,
					teardownModalTitle: 'Editing Teardown Name',
					templateInputLabel: `What would you like to rename ${teardownSelected} to?`,
					isRenaming: true,
				}).then(() => {
					this.addFocusOnModalInput()
				})
			},
			addFocusOnModalInput() {
				this.find('#teardownNameModalInput').focus()
			},
			saveTeardown(event) {
				if (event) {
					event.preventDefault()
				}
				const newName = this.get('newTemplateName').trim()
				let validInput = true

				if (newName === "") {
					validInput = false
					alert("New teardown name is required and cannot be blank.")
				} else if (this.get('teardownNames').find(teardownName => newName === teardownName.teardownName)) {
					validInput = false
					alert("That teardown name exists already, please choose a unique teardown name.")
				}

				if (validInput) {
					if (this.get('isRenaming')) {
						mediator.call('emitToServer', 'rename vehicle teardown name', { oldTeardownName: this.get('currentTeardown') ? this.get('currentTeardown') : '', newTeardownName: newName })
							.then(response => {
								if (response) {
									this.set({ teardownModalShow: false })
									stateRouter.go(null, { teardown: newName })
									mediator.call('showMessage', {
										type: 'success',
										message: 'Teardown renamed successfully!',
										time: 5000,
									})
								}
							}).catch(err => {
								logAndAlert(err, mediator, 'Error renaming teardown')
							})
					} else {
						this.set({ currentTeardown: this.get('newTemplateName'), teardownModalShow: false })
						stateRouter.go(null, { teardown: this.get('newTemplateName') })
						mediator.call('showMessage', {
							type: 'success',
							message: 'New teardown has been created',
							time: 5000,
						})
					}
				}
				this.set("newTemplateName", "")
			},
		},
		async resolve(data, { teardown }) {
			let teardownNames = await mediator.call('emitToServer', 'load teardown template names', {})
			if (teardown) {
				if (!teardownNames.find(name => teardown === name.teardownName)) {
					teardownNames = [ ...teardownNames, { teardownName: teardown, notSaved: true }]
				}
			}

			return {
				currentTeardown: teardown,
				teardownNames,
			}
		},
		activate({ domApi: ractive }) {
			ractive.observe('currentTeardown', currentTeardown => {
				stateRouter.go(null, { teardown: currentTeardown })
			}, { init: false })
		},
	})
}
