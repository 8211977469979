import template from './service.html'
import pProps from 'p-props'
import keyboardJS from 'keyboardjs'
import formatDate from 'date-fns/format'

//Ractive components
import makeTextArea from '@isoftdata/textarea'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

const newUnitService = {
	date: '',
	description: '',
	quoteId: null,
	saleId: null,
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.entity.unit.service',
		route: 'service',
		querystringParameters: [ 'id', 'customerUnitId', 'customerUnitServiceId' ],
		template: {
			template,
			computed: {
				selectedUnitService() {
					const selectedUnitServiceId = this.get('selectedUnitServiceId')

					return this.get('unitServices')
						.find(unitService => unitService.customerUnitServiceId === selectedUnitServiceId)
				},
			},
			components: {
				itTable: makeTable(),
				itModal: makeModal(),
				itButton: makeButton(),
				itTextArea: makeTextArea({ twoway: true, lazy: true }),
				itInput: makeInput({ twoway: true, lazy: true }),
			},
		},
		resolve(data, { id, customerUnitId, customerUnitServiceId }) {
			return pProps({
				unitServices: mediator.call('emitToServer', 'load customer unit services', { customerUnitId }),
				customerId: parseInt(id, 10),
				customerUnitId: parseInt(customerUnitId, 10),
				selectedUnitServiceId: parseInt(customerUnitServiceId, 10),
				shownUnitService: {},
				newUnitService: {
					...newUnitService,
					date: formatDate(new Date(), 'yyyy-MM-dd'),
					customerUnitId,
				},
			})
		},
		activate({ domApi: ractive }) {
			ractive.on('service-clicked', (context, unitServiceId) => {
				if (unitServiceId) {
					stateRouter.go('app.entity.unit.service', { customerUnitServiceId: unitServiceId }, { inherit: true })
				}
			})

			ractive.on('create-service', () => {
				ractive.set({
					showUnitServiceModal: true,
					shownUnitService: { ...ractive.get('newUnitService') },
				})
			})

			ractive.on('edit-service', (context, unitService) => {
				ractive.set({
					showUnitServiceModal: true,
					shownUnitService: unitService,
				})
			})

			ractive.on('delete-service', async(context, customerUnitServiceId) => {
				try {
					await mediator.call('emitToServer', 'delete customer unit service', { customerUnitServiceId })
					await ractive.set({ showUnitServiceDeleteModal: false })

					const deletedIndex = ractive.get('unitServices').findIndex(service => service.customerUnitServiceId === customerUnitServiceId)

					await ractive.splice('unitServices', deletedIndex, 1)
					stateRouter.go('app.entity.unit.service', {
						id: ractive.get('customerId'),
						entityContext: 'customer',
						customerUnitId: ractive.get('selectedUnitServiceId'),
					})
				} catch (err) {
					logAndAlert(err, mediator, 'Error deleting unit service')
				}
			})

			ractive.on('save-service', async(context, unitService) => {
				try {
					const savedUnitService = await mediator.call('emitToServer', 'save customer unit service', { unitService })
					ractive.set({ showUnitServiceModal: false })
					ractive.upsert('unitServices', 'customerUnitServiceId', savedUnitService)
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving unit service')
				}
			})

			stateRouter.on('stateChangeEnd', (state, parameters) => {
				const keyboardController = parameters.keyboardController
				ractive.set('serviceIsKeyboardContext', keyboardController === 'service')

				ractive.observe('showUnitServiceModal', serviceModalShown => {
					if (serviceModalShown) {
						keyboardJS.setContext('serviceModal')
					} else {
						keyboardJS.setContext(keyboardController ? keyboardController : null)
					}
				})

				keyboardJS.bind('s', e => {
					if (parameters.keyboardController === 'service') {
						stateRouter.go(null, { keyboardController: '' }, { inherit: true })
					} else {
						stateRouter.go(null, { keyboardController: 'service' }, { inherit: true })
					}
				})

				keyboardJS.withContext('service', () => {
					keyboardJS.bind('c', e => {
						if (!ractive.get('showUnitServiceModal')) {
							ractive.find('#add-service').click()
						}
					})

					keyboardJS.bind('e', e => {
						if (!ractive.get('showUnitServiceModal')) {
							ractive.find('#edit-service').click()
						}
					})

					keyboardJS.bind('d', e => {
						if (!ractive.get('showUnitServiceModal')) {
							ractive.find('#delete-service').click()
						}
					})
				})
			})

			stateRouter.on('beforeDestroyState', () => {
				keyboardJS.reset()
			})
		},
	})
}
