import type { Mediator } from 'types/common'
import type { InterchangeQueryOptions, InterchangeWithPricing } from 'server/db/interchange-db'

import { hasTruthyValues } from '@isoftdata/utility-object'

interface Options {
	searchType: 'APPLICATION' | 'GROUP'
	inventoryTypeId: number
	interchangeNumber?: string | null
	isExact?: boolean
	partManufacturer?: string | null
	partModel?: string | null
	vehicleYear?: number | null
	vehicleMake?: string | null
	vehicleModel?: string | null
}

interface ApplicationLookupOptions extends InterchangeQueryOptions {
	searchType: 'APPLICATION'
	make: string
	model: string
	vehicleYear: number
	inventoryTypeId: number
}

interface GroupLookupOptions extends InterchangeQueryOptions {
	searchType: 'GROUP'
	interchangeNumber: string
	inventoryTypeId: number
}

const APPLICATION_REQUIRED_KEYS: Array<keyof InterchangeQueryOptions> = ['make', 'model', 'vehicleYear']

const GROUP_REQUIRED_KEYS: Array<keyof InterchangeQueryOptions> = ['interchangeNumber']

function hasRequiredLookupOptions(searchOptions: Partial<InterchangeQueryOptions>, searchTypeRequiredKeys: Array<keyof InterchangeQueryOptions>) {
	searchTypeRequiredKeys.push('inventoryTypeId')
	const reducedObject = searchTypeRequiredKeys.reduce((acc: Partial<InterchangeQueryOptions>, key: keyof InterchangeQueryOptions) => {
		return { ...acc, [key]: searchOptions[key] }
	}, {})

	return hasTruthyValues({ object: reducedObject, all: true }) as boolean
}

export default function (
	mediator: Mediator,
	{
		//linebreak
		searchType = 'APPLICATION',
		inventoryTypeId,
		interchangeNumber,
		isExact = false,
		partManufacturer = '',
		partModel = '',
		vehicleYear,
		vehicleMake = '',
		vehicleModel = '',
	}: Options
): Promise<Array<InterchangeWithPricing>> {
	let options: Partial<ApplicationLookupOptions | GroupLookupOptions> = {
		inventoryTypeId,
		includePricing: true,
		includeInventoryQuantity: true,
		includeSalesAmounts: true,
	}
	let shouldDoLookup = false

	if (searchType === 'APPLICATION') {
		//prefer the more specific part manufacturer/model over vehicle make/model if they provide both
		let make = partManufacturer || vehicleMake
		let model = partModel || vehicleModel

		options = {
			...options,
			vehicleYear: vehicleYear ?? undefined,
			make: make ?? undefined,
			model: model ?? undefined,
		}

		shouldDoLookup = hasRequiredLookupOptions(options, APPLICATION_REQUIRED_KEYS)
	} else if (searchType === 'GROUP') {
		options = { ...options, interchangeNumber: interchangeNumber ?? undefined, isExact }

		shouldDoLookup = hasRequiredLookupOptions(options, GROUP_REQUIRED_KEYS)
	}

	if (shouldDoLookup) {
		return mediator.call('emitToServer', 'load interchange', options) as Promise<Array<InterchangeWithPricing>>
	} else {
		throw new Error('MISSING_REQUIRED_FIELDS')
	}
}
