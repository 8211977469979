import { validate } from 'vinidator'

export default async function(vin, mediator) {
	vin = (vin && typeof vin === 'string') ? vin.trim() : ''

	const validation = validate(vin)

	if (!validation?.isValid) {
		throw validation
	} else {
		const decodedVin = await mediator.call('emitToServer', 'decode vin', { vin })
		if (decodedVin && typeof decodedVin === 'object' && Object.keys(decodedVin).length === 0) {
			throw {
				code: 'NO_MATCH',
				message: 'VIN decoder unable to decode VIN. Perhaps mistyped?',
			}
		}
		return decodedVin
	}
}
