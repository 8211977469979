import template from './store-select.html'
//import domValue from 'dom-value'

//Raclive components
import makeSelect from '@isoftdata/select'

const defaultOptions = {
	twoway: true,
	lazy: true,
	isolated: true,
}

export default function makeStoreSelectComponent({ twoway = true, lazy = true, isolated = true } = defaultOptions) {
	return Ractive.extend({
		template,
		twoway,
		lazy,
		isolated,
		components: {
			itSelect: makeSelect({
				twoway,
				lazy,
				isolated,
			}),
		},
		data() {
			return {
				storeList: [],
				user: {},
				selectedStoreId: '',
				currentStore: JSON.parse(localStorage.getItem('session')).store,
				parentDivClass: '',
				showAllOption: true,
				disabled: false,
			}
		},
		computed: {
			displayStores() {
				const currentStore = this.get('currentStore')
				const storeList = this.get('storeList')

				if (currentStore && currentStore.storeId) {
					return storeList.filter(store => store.storeId !== parseInt(currentStore.storeId, 10))
				}
				return storeList
			},
		},
		oninit() {
			// eslint-disable-next-line no-unused-vars
			const ractive = this

			//
		},
	})
}
