import template from './card-payment.html'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeCurrencyInput from '@isoftdata/currency-input'
//import makeSelect from '@isoftdata/select'
import makeTextarea from '@isoftdata/textarea'
import makeButton from '@isoftdata/button'
import pax from 'utility/pax'
import * as currency from '@isoftdata/utility-currency'
import toTitleCase from 'to-title-case'
import * as dateTimeHelper from '@isoftdata/utility-date-time'
import ractiveTransitionsFade from 'ractive-transitions-fade'

let session = localStorage.getItem('session')
session = session ? JSON.parse(session) : false

const defaultPaymentTerminalTransaction = {
	paymentStatus: 'Initializing',
	salespersonUserId: session ? session.userAccountId : null,
	saleId: null,
	referenceNumber: '',
	amount: '0.00',
	tenderType: 'Credit', //always credit for now
	entryType: 'Unknown',
	verificationStatus: 'None Required',
	resultCode: null,
	resultText: null,
	cardType: null,
	cardNumberLastFour: null,
	cardExpiration: null,
	customerName: null,
	authorizationCode: null,
	transactionId: null,
	transactionDate: null,
	hostTraceNumber: null,
	hostCode: null,
	hostReferenceNumber: null,
	emvCryptogramType: 'Unknown',
	emvCryptogramNumber: null,
	applicationId: null,
}

const defaultTerminalConfiguration = {
	ipAddress: '127.0.0.1',
	port: '10009',
}

function valueOrElse(value, elseValue = null) {
	return value ? value : elseValue
}

export default function createCardPaymentComponent(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
			itInput: makeInput(),
			itInputTwo: makeInput({ twoway: true, lazy: false }),
			itCurrencyInput: makeCurrencyInput({ twoway: true, lazy: true }),
			itTextarea: makeTextarea(),
			itButton: makeButton(),
		},
		transitions: {
			fade: ractiveTransitionsFade,
		},
		computed: {
			displayTerminalStatus() {
				return toTitleCase(this.get('terminalStatus'))
			},
			isPastTransaction() {
				return this.get('paymentTerminalTransaction.paymentTerminalTransactionId')
			},
			cardType() {
				let cardToMatch = this.get('paymentTerminalTransaction.cardType')

				if (cardToMatch && typeof cardToMatch === 'string') {
					cardToMatch = cardToMatch.toUpperCase()

					return pax.cardTypeList.find(cardType => {
						return cardType.code.toUpperCase() === cardToMatch
					})
				}
				return null
			},
			cardTypeDisplayName() {
				const cardTypeMatch = this.get('cardType')

				if (cardTypeMatch && cardTypeMatch.name) {
					return cardTypeMatch.name
				}
				const cardType = this.get('paymentTerminalTransaction.cardType')

				return cardType ? cardType : 'Unknown'
			},
			cardTypeIcon() {
				const cardTypeMatch = this.get('cardType')

				if (cardTypeMatch && cardTypeMatch.icon) {
					return cardTypeMatch.icon
				}
				return false
			},
		},
		data() {
			let cardTerminalConfiguration = localStorage.getItem('cardTerminalConfiguration')
			cardTerminalConfiguration = cardTerminalConfiguration ? JSON.parse(cardTerminalConfiguration) : false

			return {
				show: false,
				errorMessage: false,
				paymentTerminalTransaction: defaultPaymentTerminalTransaction,
				configureMode: !cardTerminalConfiguration,
				currencyFormat: currency.format,
				terminalStatus: 'WAITING',
				deviceConfiguration: cardTerminalConfiguration ? cardTerminalConfiguration : defaultTerminalConfiguration,
				toLocaleDateString: dateTimeHelper.toLocaleDateString,
				signature: false,
				paymentContext: false,
			}
		},
		oninit() {
			const ractive = this

			ractive.on('confirm', () => {
				ractive.set({ show: false })
			})
		},
		show(paymentTerminalTransactionId, documentInfo = { balance: '0.00', id: null }, paymentContext = false) {
			const ractive = this
			/*
				paymentContext is the index of the payment in the payment list,
				so we can set the paymentTerminalTransactionId  to the
				correct item in the list after they process a card payment
			*/
			//Invalidate the signature, since we don't store it and dont' want to show a stale version
			ractive.set({ paymentContext, signature: false })

			if (paymentTerminalTransactionId) {
				mediator.call('emitToServer', 'load payment terminal transactions', { paymentTerminalTransactionId })
					.then(paymentTerminalTransactionList => {
						console.log(paymentTerminalTransactionList[0])
						ractive.setData(paymentTerminalTransactionList[0])
					})
			} else {
				ractive.setData({
					...defaultPaymentTerminalTransaction, amount: parseFloat(documentInfo.balance) < 0 ? '0.00' : documentInfo.balance,
					saleId: documentInfo.id ? documentInfo.id.toString() : null,
				})
			}
		},
		setData(paymentTerminalTransaction) {
			const ractive = this

			ractive.set({
				paymentTerminalTransaction,
				show: true,
			}).then(() => {
				ractive.find('#creditCardPaymentAmount').select()

				ractive.observe('configureMode', mode => {
					if (mode) {
						ractive.find('#configure-ip').select()
					} else {
						ractive.set({
							terminalStatus: 'WAITING',
							errorMessage: false,
						})
						localStorage.setItem('cardTerminalConfiguration', JSON.stringify(ractive.get('deviceConfiguration')))
					}
				}, { defer: true })

				ractive.observe('deviceConfiguration', config => {
					pax.Settings(config.ipAddress, config.port)
				})

				ractive.observe('signature', signature => {
					if (signature) {
						ractive.showSignature(signature, 'signature')
					} else {
						ractive.clearSignature('signature')
					}
				})
			})
		},
		initalizeTerminal(cb) {
			pax.Initialize(undefined, res => {
				cb(null, res && res[5] === 'OK')
			})
		},
		submitToTerminal() {
			const ractive = this

			ractive.savePaymentTerminalTransaction((err, res) => {
				if (err || !res) {
					console.error(err, res)
					return ractive.set({ errorMessage: 'Error saving payment terminal transaction' })
				}

				let paymentTerminalTransaction = res

				if (!paymentTerminalTransaction.referenceNumber) {
					paymentTerminalTransaction.referenceNumber = paymentTerminalTransaction.paymentTerminalTransactionId.toString()
				}

				//We need to make sure that the amount always has
				//2 decimal places of precision at this point.
				//The pax lib will expect it.
				paymentTerminalTransaction.amount = currency.format(paymentTerminalTransaction.amount, {
					includeSymbol: false,
					includeComma: false,
				})

				ractive.set({
					paymentTerminalTransaction,
					terminalStatus: 'PROCESSING_PAYMENT',
				}).then(() => {
					console.log(ractive.get('paymentTerminalTransaction'))
					pax.PerformTransaction({
						requireSignature: true,
						amount: ractive.get('paymentTerminalTransaction.amount'),
						saleId: ractive.get('paymentTerminalTransaction.saleId'),
						referenceNumber: ractive.get('paymentTerminalTransaction.referenceNumber'),
					}, (err, response) => {
						ractive.handleTerminalResponse(err, response)

						if (err && err.message === 'Failed to fetch') {
							ractive.set({
								errorMessage: 'Unable to communicate with terminal. Please check your configuration',
								configureMode: true,
							}).then(() => {
								ractive.find('#configure-ip').select()
							})
						} else if (err) {
							ractive.set({ errorMessage: err.message ? err.message : 'An error occured. Try again later.' })
						}

						if (response && response.signature) {
							ractive.set({ signature: response.signature })
						}
					})
				})
			})
		},
		handleTerminalResponse(err, response) {
			const ractive = this

			let paymentTerminalTransaction = ractive.get('paymentTerminalTransaction')

			paymentTerminalTransaction.paymentStatus = err ? 'Failed' : 'Succeeded'

			if (response && typeof (response) === 'object') {
				paymentTerminalTransaction = {
					...paymentTerminalTransaction,
					referenceNumber: paymentTerminalTransaction.referenceNumber.toString(),
					saleId: valueOrElse(paymentTerminalTransaction.saleId),
					entryType: response.account.entryMode,
					amount: response.amount.approveAmount,
					verificationStatus: response.verificationStatus,
					resultCode: valueOrElse(response.responseCode),
					resultText: valueOrElse(response.responseMessage),
					cardType: valueOrElse(response.account.cardType),
					cardNumberLastFour: valueOrElse(response.account.cardNumberLastFour),
					cardExpiration: valueOrElse(response.account.expireDate),
					customerName: valueOrElse(response.account.cardHolder),
					authorizationCode: valueOrElse(response.host.authCode),
					transactionId: valueOrElse(response.trace.transactionNumber),
					transactionDate: valueOrElse(response.trace.timeStamp),
					hostTraceNumber: valueOrElse(response.host.traceNumber),
					hostCode: valueOrElse(response.host.responseCode),
					hostReferenceNumber: valueOrElse(response.host.referenceNumber),
				}
			}

			ractive.set({
				paymentTerminalTransaction,
				terminalStatus: 'WAITING',
			}).then(() => {
				console.log(ractive.get('paymentTerminalTransaction'))
				ractive.savePaymentTerminalTransaction((err, res) => {
					console.log(err ? err : res)
				})
			})
		},
		async savePaymentTerminalTransaction(cb) {
			try {
				const res = await mediator.call('emitToServer', 'save payment terminal transaction', {
					paymentTerminalTransaction: this.get('paymentTerminalTransaction'),
				})
				cb(null, res)
			} catch (err) {
				cb(err)
			}
		},
		clearSignature(elementId) {
			const canvas = document.getElementById(elementId)

			if (canvas) {
				canvas.getContext('2d')
					.clearRect(0, 0, canvas.width, canvas.height)
			}
		},
		showSignature(signature, elementId) {
			const canvas = document.getElementById(elementId)

			if (canvas) {
				const canvasContext = canvas.getContext('2d')

				signature = signature.split('^')
				signature = signature.slice(0, signature.length - 1)

				signature = signature.reduce((sum, coords) => {
					coords = coords.split(',')

					return sum.concat({ x: coords[0], y: coords[1] })
				}, [])

				this.clearSignature(elementId)

				canvasContext.lineWidth = 2
				canvasContext.lineJoin = 'round'

				let newPath = true

				canvasContext.beginPath()

				signature.forEach(coord => {
					if (coord.x == 0 && coord.y == 65535) {
						newPath = true
					} else if (newPath) {
						canvasContext.moveTo(coord.x, coord.y)
						newPath = false
					} else {
						canvasContext.lineTo(coord.x, coord.y)
					}
				})

				canvasContext.stroke()
			}
		},
	})
}
