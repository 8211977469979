import component from './Internet.svelte'
import homeDataLoader from 'shared/home-data-loader'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.internet',
		route: 'internet',
		querystringParameters: [ 'fromDate', 'toDate', 'status' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			const status = parameters.status ?? await mediator.call('emitToServer', 'check user setting', {
				category: 'Home',
				name: 'Chromium Internet Orders Status Filter',
				settingType: 'Interface History',
				defaultValue: 'Any',
				dataType: 'string',
			})
			const homeData = await homeDataLoader({
				fromDate: parameters.fromDate,
				toDate: parameters.toDate,
				documentLoadSocket: 'load internet orders',
				documentLoadAdditionalParameters: {
					vendorApprovalStatus: status === 'Any' ? undefined : status,
					salesperson: parameters?.salesperson,
				},
				mediator,
			})
			return {
				...homeData,
				status,
			}
		},
	})
}
