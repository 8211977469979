import template from './sale-line.html'
import pProps from 'p-props'
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import * as arrayHelper from '@isoftdata/utility-array'
import financialNumber from 'financial-number'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.sale.line',
		route: 'line',
		querystringParameters: [ 'saleId' ],
		data: {},
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			computed: {
				displaySaleLines() {
					const saleLines = this.get('saleLines')
					const inventory = this.get('inventory')
					const vehicles = this.get('vehicles')

					return saleLines.map(({ inventoryId, vehicleId, partType, description, coreAmount, quantity, price }) => {
						const inventoryItem = inventory.find(inventoryItem => inventoryItem.inventoryId === inventoryId)
						const vehicle = vehicles.find(vehicle => vehicle.vehicleId === vehicleId)

						const pricePlusCore = financialNumber(price?.toString() || '0')
							.plus(coreAmount?.toString() || '0')

						return {
							tagNumber: inventoryItem ? inventoryItem.tagNumber : '',
							inventoryItem,
							trackingNumber: vehicle ? vehicle.trackingNumber : '',
							partType,
							description,
							coreAmount,
							quantity,
							price,
							total: pricePlusCore.times(quantity?.toString() || '0').toString(2),
						}
					})
				},
			},
		},
		async resolve(data, { saleId }) {
			if (saleId) {
				const saleLines = await mediator.call('emitToServer', 'load sale lines', { saleId })
				const inventoryIds = arrayHelper.reduceToTruthyProperties({ array: saleLines, key: 'inventoryId' })
				const vehicleIds = arrayHelper.reduceToTruthyProperties({ array: saleLines, key: 'vehicleId' })

				return await pProps({
					vehicles: vehicleIds ? mediator.call('emitToServer', 'load vehicle', { vehicleId: vehicleIds }) : [],
					inventory: inventoryIds ? mediator.call('emitToServer', 'load inventory', { inventoryId: inventoryIds }) : [],
					saleLines,
					saleId,
				})
			} else {
				return {}
			}
		},
		activate({ domApi: ractive }) {
			ractive.observe('displaySaleLines', v => console.log(v))
		},
	})
}

