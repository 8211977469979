import template from './change-password.html'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'

const defaultData = Object.freeze({
	show: false,
	state: 'CHANGE_PASSWORD',
	resetToken: '',
	currentPassword: '',
	newPassword: '',
	confirmNewPassword: '',
	account: {},
})

export default function createChangePasswordModal({ mediator, logAndAlert }) {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itModal: makeModal(),
			itInput: makeInput({ twoway: true, lazy: false }),
			itButton: makeButton(),
		},
		computed: {
			isValidNewPassword() {
				const newPassword = this.get('newPassword')
				const confirmNewPassword = this.get('confirmNewPassword')

				return (newPassword && newPassword === confirmNewPassword)
			},
		},
		data() {
			return { ...defaultData }
		},
		async resendRecoveryCode() {
			const ractive = this

			ractive.show({ state: 'RECOVERY_EMAIL_SENDING' })

			try {
				const account = await mediator.call('noAuthEmitToServer', 'reset password', {
					userName: ractive.get('account.userName'),
				})

				ractive.show({
					state: 'RECOVERY_EMAIL_SENT',
					account,
				})
			} catch (err) {
				logAndAlert(err, mediator, 'Error resetting password')
			}
		},
		show(data) {
			const ractive = this

			ractive.set({ ...defaultData, ...data, show: true }).then(() => {
				if (data.state === 'RECOVERY_EMAIL_SENT') {
					ractive.find('#recoverycode').select()
				} else if (data.state === 'CHANGE_PASSWORD') {
					ractive.find('#currentpassword').select()
				} else if (data.state === 'FIRST_TIME_PASSWORD_SET') {
					ractive.find('#newpassword').select()
				}
			})
		},
		async confirm() {
			const ractive = this

			try {
				await mediator.call('noAuthEmitToServer', 'change password', {
					resetToken: ractive.get('resetToken'),
					currentPassword: ractive.get('currentPassword'),
					userName: ractive.get('account.userName'),
					newPassword: ractive.get('newPassword'),
				})

				ractive.set({ show: false }).then(() => {
					ractive.fire('passwordChanged', {}, ractive.get('newPassword'))
				})
			} catch (err) {
				console.log('Error changing password', err)
				logAndAlert(err, mediator, 'Error changing password')
			}
		},
	})
}
