import template from './error.html'

export default function({ stateRouter }) {
	stateRouter.addState({
		name: 'error',
		route: 'error',
		querystringParameters: [ 'err' ],
		template: {
			template,
		},
		activate({ domApi: ractive, parameters }) {
			ractive.set('err', parameters.err)
		},
	})
}
