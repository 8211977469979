import template from './cost.html'
import domValue from 'dom-value'
import pProps from 'p-props'
import { stringToBoolean, booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import formatDate from 'date-fns/format'
import klona from 'klona'
import { format as currencyFormat } from '@isoftdata/utility-currency'

//Ractive components
import makeCurrencyInput from '@isoftdata/currency-input'
import makeTextArea from '@isoftdata/textarea'
import makeCheckbox from '@isoftdata/checkbox'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'

//Ractive transitions
import ractiveTransitionsFade from 'ractive-transitions-fade'

const defaultCostModalState = {
	shown: false,
	saving: false,
}
const defaultCostTypeModalState = {
	shown: false,
}
const defaultCostTypeEditorModalState = {
	shown: false,
	saving: false,
}
const defaultCostType = {
	cgsDefault: 0,
	typeName: "",
	useDefault: 1,
	userCreated: 1,
	new: true,
}

const stateName = 'app.vehicle.cost'

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: stateName,
		route: 'cost',
		querystringParameters: [ 'vehicleId' ],
		template: {
			template,
			transitions: {
				fade: ractiveTransitionsFade,
			},
			computed: {
				computedTotals() {
					const vehicleCosts = this.get('vehicleCosts').reduce((totals, {
						paidAmount,
						total,
						isExpense,
					}) => {
						paidAmount = paidAmount ? paidAmount : 0
						total = total ? total : 0

						return {
							totalPaid: totals.totalPaid + paidAmount,
							totalAmount: totals.totalAmount + total,
							totalExpense: isExpense ? totals.totalExpense + total : totals.totalExpense,
						}
					}, { totalPaid: 0, totalAmount: 0, totalExpense: 0 })

					return { ...vehicleCosts, totalOwed: vehicleCosts.totalAmount - vehicleCosts.totalPaid }
				},
				displayVehicleCostList() {
					const costTypes = this.get('vehicleCostTypes')
					const vehicleVendors = this.get('vehicleVendors')
					return this.get('vehicleCosts').map(cost => {
						const typeName = costTypes.find(costType => costType.typeId === cost.typeId)?.typeName
						const vendorName = vehicleVendors.find(vendor => vendor.vendorId === cost.vendorId)?.vendorName

						return {
							...cost,
							costType: typeName || `<Type ID ${cost.typeId} not found!>`,
							vendorName: vendorName || `<Vendor ID ${cost.vendorId} not found!>`,
						}
					})
				},
				canSaveEditCostItem() {
					const currentEditCost = this.get('currentEditCost')

					if (!currentEditCost) {
						return false
					}

					const { vendorId, typeId, total } = currentEditCost

					return vendorId && typeId && total && Number(total) > 0
				},
				canSaveEditCostTypeItem() {
					const currentEditCostType = this.get('currentEditCostType')

					if (!currentEditCostType) {
						return false
					}
					if (!currentEditCostType?.typeName) {
						return false
					}
					// const inventoryId = await this.inventoryIdFromTagNumber(currentEditCostType.tagNumber)
					// if (inventoryId === undefined) {
					// return false
					// }
					if (currentEditCostType.inventoryId === undefined) {
						return false
					}
					return true
				},
			},
			components: {
				itTable: makeTable(),
				itInput: makeInput({ twoway: true, lazy: false }),
				itTextarea: makeTextArea({ twoway: true, lazy: false }),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itCheckbox: makeCheckbox({ twoway: true }),
				itModal: makeModal(),
				itButton: makeButton(),
			},
			data: {
				yesNo,
				domValue,
			},
			totalChanged(total) {
				/*
					If the paid amount is not set, our business logic is to set it to the total amount
					This saves the user from having to enter the same number twice(common case)
				*/
				if (!parseFloat(this.get('currentEditCost.paidAmount'))) {
					this.set('currentEditCost.paidAmount', total)
				}
			},
			async showEditCostModal(vehicleCostId) {
				const ractive = this

				let currentEditCost

				if (vehicleCostId) {
					currentEditCost = klona(this.get('vehicleCosts')
						.find(cost => cost.vehicleCostId === vehicleCostId))
				} else {
					currentEditCost = {
						...klona(this.get('defaultNewCost')),
						documentDate: formatDate(new Date(), 'yyyy-MM-dd'),
					}
				}

				await this.set({ costModal: { saving: false, shown: true }, currentEditCost })
				ractive.find('#editVendorInput')?.focus()
			},
			async closeEditCostModal() {
				await this.set({
					costModal: klona(defaultCostModalState),
					currentEditCost: klona(this.get('defaultNewCost')),
				})
				this.find('#newCostButton')?.focus()
			},
			async costTypeTagInputChange() {
				const ractive = this
				const currentEditCostType = ractive.get('currentEditCostType')

				currentEditCostType.inventoryId = await this.inventoryIdFromTagNumber(currentEditCostType.tagNumber)
				ractive.set({ currentEditCostType })
			},
			async saveCost(vehicleCost) {
				const ractive = this
				if (vehicleCost.typeId) {
					vehicleCost.inventoryId = ractive.get('vehicleCostTypes').find(costType => costType.typeId === vehicleCost.typeId)?.inventoryId ?? 0
				}
				await ractive.set('costModal.saving', true)
				try {
					const savedCost = await mediator.call('emitToServer', 'save vehicle cost', { vehicleCost })

					await ractive.upsert('vehicleCosts', 'vehicleCostId', savedCost?.[0])
					ractive.closeEditCostModal()
				} catch (err) {
					logAndAlert(err, mediator, 'An error occurred saving vehicle cost')
				}
			},
			async deleteCost(vehicleCost) {
				const ractive = this

				if (confirm(`Would you like to remove the ${vehicleCost.costType} cost of ${currencyFormat(vehicleCost.total)}, and the purchase order associated with it?`)) {
					try {
						await mediator.call('emitToServer', 'delete vehicle cost', { vehicleCost })
						const index = ractive.get('vehicleCosts')
							.findIndex(cost => cost.vehicleCostId === vehicleCost.vehicleCostId)

						if (index > -1) {
							ractive.splice('vehicleCosts', index, 1)
						}
					} catch (err) {
						logAndAlert(err, mediator, 'An error occurred removing vehicle cost')
					}
				}
			},
			openNewCostType() {
				const ractive = this

				return ractive.set({ costTypeModal: { saving: false, shown: true } })
			},
			closeEditCostTypeModal() {
				const ractive = this

				return ractive.set({ costTypeModal: { shown: false } })
			},
			closeCostTypeEditorModal() {
				const ractive = this

				return ractive.set({
					costTypeEditorModal: { shown: false },
					currentEditCostType: false,
				})
			},
			async getTagNumberFromInventoryId(inventoryId) {
				if (inventoryId) {
					const results = await mediator.call('emitToServer', 'load inventory', { inventoryId })

					if (results.length) {
						return results[0].tagNumber
					}
				}

				return undefined
			},
			async inventoryIdFromTagNumber(tagNumber) {
				if (tagNumber) {
					const results = await mediator.call('emitToServer', 'load inventory', { tagNumber })

					if (results.length) {
						return results[0].inventoryId
					}
				} else {
					return null
				}

				return undefined
			},
			async costTypeEditClick(costType) {
				const ractive = this

				if (costType?.new) {
					// New cost type clicked
					costType = defaultCostType
				}
				const setCostType = klona(costType)
				setCostType.tagNumber = await this.getTagNumberFromInventoryId(setCostType?.inventoryId)
				setCostType.expenseDefault = !setCostType.cgsDefault

				await ractive.set({
					currentEditCostType: setCostType,
					costTypeEditorModal: { saving: false, shown: true },
				})
				ractive.find("#typeNameInput")?.select()
			},
			async saveCostType() {
				const ractive = this

				const currentEditCostType = ractive.get('currentEditCostType')
				if (!currentEditCostType) {
					console.log("currentEditCostType is not truthy, abort save")
					return
				}
				if (currentEditCostType.inventoryId === undefined) {
					console.log(`inventoryId for cost type is invalid, abort save.`)
					return
				}
				// Needs to be numeric, not boolean
				currentEditCostType.cgsDefault = 0 + !stringToBoolean(currentEditCostType.expenseDefault)

				ractive.set("costTypeEditorModal.saving", true)

				const result = await mediator.call('emitToServer', 'save vehicle cost types', { costType: currentEditCostType })
				ractive.upsert("vehicleCostTypes", "typeId", result)

				// Auto-select new/edited cost type in previous menu
				ractive.set("currentEditCost.typeId", result.typeId)

				ractive.set("costTypeEditorModal.saving", false)
				this.closeCostTypeEditorModal()
			},
		},
		resolve(data, { vehicleId }) {
			return pProps({
				vehicleCosts: mediator.call('emitToServer', 'load vehicle costs', { vehicleId }),
				vehicleCostTypes: mediator.call('emitToServer', 'load vehicle cost types', {}),
				vehicleVendors: mediator.call('emitToServer', 'load vendor list', {}),
				defaultNewCost: Object.freeze({
					vehicleId,
					typeId: '',
					isExpense: false,
					paidAmount: 0.00,
					total: 0.00,
					inventoryId: 0,
					vendorId: '',
					checkNumber: null,
					comments: null,
				}),
				currentEditCost: false,
				costModal: defaultCostModalState,
				costTypeModal: defaultCostTypeModalState,
				currentEditCostType: false,
				costTypeEditorModal: defaultCostTypeEditorModalState,
			})
		},
	})
}
