import * as manModHelper from '../manufacturer-model-helper.mjs'

export default class InventoryPart {
	constructor(TeardownItem, partManufacturerList, partModelList) {
		this.notes = TeardownItem.notes
		this.location = TeardownItem.location
		this.tagNumber = TeardownItem.tagNumber
		this.inventoryId = TeardownItem.id
		this.inventoryTypeId = TeardownItem.inventoryTypeId
		this.description = TeardownItem.description
		this.category = TeardownItem.category
		this.side = TeardownItem.side
		this.quantity = TeardownItem.quantity
		this.retailPrice = TeardownItem.retailPrice
		this.wholesalePrice = TeardownItem.wholesalePrice
		this.listPrice = TeardownItem.listPrice
		this.cost = TeardownItem.cost
		this.interchangeNumber = TeardownItem.interchangeNumber
		this.core = TeardownItem.coreCost
		this.status = TeardownItem.status
		this.partManufacturer = TeardownItem.partManufacturerId ? manModHelper.getPartManufacturerNameFromManufacturerId(partManufacturerList, TeardownItem.partManufacturerId) : ''
		this.partModel = TeardownItem.partModelId ? manModHelper.getPartModelNameFromModelId(partModelList, TeardownItem.partModelId) : ''
		// Vehicle related fields
		this.bodyStyle = TeardownItem.bodyStyle ?? null
		this.make = TeardownItem.make ?? null
		this.model = TeardownItem.model ?? null
		this.vehicleId = TeardownItem.vehicleId ?? null
		this.vin = TeardownItem.vin ?? null
		this.year = TeardownItem.year ?? null
	}
}
