import SalesOrderDocumentLine from './SalesOrderDocumentLine.mjs'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'

export default class SalesOrderDocument {
	constructor({
		saleDocument,
		saleDocumentLines,
		customer,
		payments,
		documentType,
		vehicleList,
		inventoryList,
		saveState,
	}) {
		this.customer = customer ? { ...customer, poRequired: isTrue(customer.poRequired) } : null
		this.saveState = saveState

		if (documentType === 'INVOICE') {
			this.documentId = saleDocument.saleId
			this.quoteId = saleDocument.quoteId
			this.payments = payments
			this.expirationDate = null
			this.status = saleDocument.status
		} else {
			this.documentId = saleDocument.quoteId
			this.quoteId = saleDocument.quoteId
			this.payments = []
			this.expirationDate = saleDocument.expirationDate
			this.isClosed = saleDocument.isClosed
		}

		this.documentType = documentType
		this.storeId = saleDocument.storeId
		this.documentDate = saleDocument.documentDate
		this.creationDate = saleDocument.creationDate
		this.salesperson = saleDocument.salesperson
		this.taxCode = saleDocument.taxCode
		this.tax = saleDocument.tax
		this.isTaxed = saleDocument.isTaxed
		this.subtotal = saleDocument.subtotal
		this.total = saleDocument.total
		this.purchaseOrderId = saleDocument.purchaseOrderId
		this.comments = saleDocument.comments

		this.billingCompany = saleDocument.billingCompany
		this.billingContact = saleDocument.billingContact
		this.billingStreet = saleDocument.billingStreet
		this.billingMailing = saleDocument.billingMailing
		this.billingCity = saleDocument.billingCity
		this.billingState = saleDocument.billingState
		this.billingZip = saleDocument.billingZip
		this.billingCountry = saleDocument.billingCountry
		this.billingPhoneNumber = saleDocument.billingPhoneNumber

		this.shippingCompany = saleDocument.shippingCompany
		this.shippingContact = saleDocument.shippingContact
		this.shippingStreet = saleDocument.shippingStreet
		this.shippingMailing = saleDocument.shippingMailing
		this.shippingCity = saleDocument.shippingCity
		this.shippingState = saleDocument.shippingState
		this.shippingZip = saleDocument.shippingZip
		this.shippingCountry = saleDocument.shippingCountry
		this.shippingPhoneNumber = saleDocument.shippingPhoneNumber

		this.lastModifiedDate = saleDocument.lastModifiedDate
		this.shippingCarrier = saleDocument.shippingCarrier

		this.webSaleId = saleDocument.webSaleId
		this.webSaleType = saleDocument.webSaleType
		this.webSaleOrigin = saleDocument.webSaleOrigin
		this.vendorApprovalStatus = saleDocument.vendorApprovalStatus
		this.customerApprovalStatus = saleDocument.customerApprovalStatus

		this.vehicleList = vehicleList
		this.inventoryList = inventoryList
		this.saleDocumentLines = saleDocumentLines.map(saleDocumentLine => new SalesOrderDocumentLine({
			saleDocument,
			saleDocumentLine,
			documentType,
			saveState,
		}))
	}
}

/*
//Columns not referened

//Invoice
status
paid
taxRate
paymentMethod
appliedCredit
checkAuthorizationNumber
shippingDate
quickbooksEditSequence

*/
