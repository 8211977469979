import makeStorageHelper from './storage-json-helper.ts'
import kebabCase from 'just-kebab-case'
import { logAndAlert } from './error-handler'

const localStorageJSON = makeStorageHelper('localStorage')

const ensureErrorObj = err => {
	let newError = new Error(err?.message ?? 'An unknown error has occurred.')
	if (typeof err === 'object') {
		newError.name = err.name ?? newError.name
		newError.cause = err.cause ?? newError.cause
	} else {
		err === 'string' ? newError.message = err : newError.original = err
	}
	return newError
}

function emit(socket, mediator, ...args) {
	return new Promise((resolve, reject) => {
		// All our endpoints only take 2 args, so if they give a third, that's the options for logAndAlert
		let logOptions = {}
		if (args.length > 2) {
			logOptions = args.pop()
			console.log('logOptions', logOptions)
		}

		socket.emit(...args, (err, res) => {
			if (err) {
				err = logAndAlert(ensureErrorObj(err), mediator, logOptions?.message, logOptions?.context ? logOptions.context : args[0], !logOptions?.doNotAlert)
				reject(err)
			} else {
				try {
					mediator.call('hideMessage')
				} catch (err) {
					//swallow if it fails, it just means that the app state isn't loaded yet
				} finally {
					resolve(res)
				}
			}
		})
	})
}

const provideAuthSocketFn = (mediator, providerName, socket) => {
	let isAuthSocketRegistered = false
	const authSocketManager = new io.Manager(window.location.origin, {
		autoConnect: false,
		reconnection: false,
	})

	const removeProvider = mediator.provide(providerName, (...args) => {
		if (!socket) {
			socket = authSocketManager.socket('/auth', {
				auth: { token: localStorageJSON.getItem('session')?.token },
			})
		}

		socket.on('connected-to-quickbooks', () => {
			try {
				mediator.call('connectedToQuickbooks', true)
			} catch (err) {
				console.error(err)
			}
		})

		return new Promise((resolve, reject) => {
			const onAuthSocketRegistered = () => {
				isAuthSocketRegistered = true
				emit(socket, mediator, ...args).then(resolve).catch(reject)
			}

			/*
				NOTE:
				We can't just wait for the `connect` event to fire here if they're not connected.
				Once the socket connection is made, the server side events might still not be registered.
				The server will emit a `authSocketRegistered` event when it's ready to start receiving
				emit events from the client.
			*/
			if (socket?.disconnected) {
				//we don't do auto reconnect, so we need to manually reconnect
				//if they're disconnected
				socket.once('authSocketRegistered', onAuthSocketRegistered)
				socket.connect()
			} else if (isAuthSocketRegistered) {
				//listeners are registered on the server
				emit(socket, mediator, ...args).then(resolve).catch(reject)
			} else {
				socket.once('authSocketRegistered', onAuthSocketRegistered)
			}
		})
	})

	return { socket, removeProvider }
}

const provideSocketFn = (mediator, providerName, socket) => {
	if (!socket) {
		socket = io(window.location.origin)
	}

	socket.on('version', version => {
		const buildVersion = '__buildVersion__'
		if (version !== buildVersion) {
			try {
				mediator.call('showMessage', {
					message: `An update to Chromium is available. Please refresh the browser window.`,
					type: 'success',
					time: false,
				})
			} catch (err) {
				//swallow this error. It can happen if the socket server emits this message before the
				//client has loaded the app state
			}
		}
	})

	const removeProvider = mediator.provide(providerName, (...args) => {
		return new Promise((resolve, reject) => {
			if (socket?.connected) {
				emit(socket, mediator, ...args).then(resolve).catch(reject)
			} else {
				socket.on('connect', () => {
					emit(socket, mediator, ...args).then(resolve).catch(reject)
				})
			}
		})
	})

	return { socket, removeProvider }
}

/* global io */
export default function registerApiProvider(mediator, providerName, isAuth) {
	let socket, authSocket, removeProvider
	const useHTTP = localStorageJSON.getItem('useHTTP')
	const useSockets = !useHTTP

	return new Promise((apiProviderResolve, apiProviderReject) => {
		if (removeProvider) {
			removeProvider()
		}

		if (useSockets) {
			if (isAuth) {
				({ socket: authSocket, removeProvider } = provideAuthSocketFn(mediator, providerName, authSocket))
			} else {
				({ socket, removeProvider } = provideSocketFn(mediator, providerName, socket))
			}

			apiProviderResolve(removeProvider)
		} else {
			//We're using HTTP requests
			try {
				const removeProvider = mediator.provide(providerName, async(endpoint, endpointArgs, contentType = 'application/json') => {
					const additionalHeaders = isAuth ? { 'token': localStorageJSON.getItem('session')?.token } : {}

					//The fetch API will automatically set the content-type header if we don't set it.
					//We're mostly sending JSON, so we default to application/json but if they caller wants
					//to have fetch figure it out, they can pass a falsy value for contentType
					//For example, FormData https://developer.mozilla.org/en-US/docs/Web/API/FormData/FormData
					let contentTypeHeader = contentType ? { 'content-type': contentType } : {}

					//Support them giving a a conent-type of 'application/x-www-form-urlencoded', for example
					const body = contentType === 'application/json' ? JSON.stringify(endpointArgs) : endpointArgs

					//endpoint names are kebab-cased for HTTP
					const response = await fetch(`${isAuth ? '/auth/' : '/'}${kebabCase(endpoint)}`, {
						method: 'POST',
						headers: {
							...contentTypeHeader,
							...additionalHeaders,
						},
						body,
					})

					if (response.status >= 400) {
						const { error } = (await response.json())
						throw error
					}

					try {
						const parsedResponse = await response?.json()

						return parsedResponse?.data
					} catch (err) {
						logAndAlert(err, mediator, 'Error parsing response from HTTP server')
					}
				})
				apiProviderResolve(removeProvider)
			} catch (err) {
				apiProviderReject()
			}
		}
	})
}

