import template from './shipping.html'
import { stringToBoolean } from '@isoftdata/utility-string'
import pProps from 'p-props'

//Ractive Components
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

const stateName = 'app.configuration.integration.shipping'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'shipping',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true, lazy: true }),
				itCheckbox: makeCheckbox(),
			},
			computed: {
			},
		},
		async resolve() {
			let settings = await pProps({
				shippingURL: mediator.call('emitToServer', `load global setting`, { settingName: 'Integration URL', settingLocation: 'External Shipping Integration' }),
				isTestingMode: mediator.call('emitToServer', `load global setting`, { settingName: 'Testing mode', settingLocation: 'External Shipping Integration' }),
				isShipmentSchedulingDisabled: mediator.call('emitToServer', `load global setting`, { settingName: 'Disable shipment scheduling', settingLocation: 'External Shipping Integration' }),
				markupPercent: mediator.call('emitToServer', `load global setting`, { settingName: 'Local shipping markup', settingLocation: 'External Shipping Integration' }),
				//isFPGShippingEnabled: mediator.call('emitToServer', `load global setting`, { settingName: 'Enable FPG shipping', settingLocation: 'External Shipping Integration' }),
				accountNumber: mediator.call('emitToServer', `load global setting`, { settingName: 'Customer account number', settingLocation: 'External Shipping Integration' }),
				billingLocationCode: mediator.call('emitToServer', `load global setting`, { settingName: 'Billing location code', settingLocation: 'External Shipping Integration' }),
				isTForceShippingEnabled: mediator.call('emitToServer', `load global setting`, { settingName: 'Enable external shipping', settingLocation: 'External Shipping Integration' }),
				useDocumentSalesperson: mediator.call('emitToServer', `load store setting`, { settingName: 'Use salesperson for shipping contact', settingLocation: 'External Shipping Integration' }),
				firstName: mediator.call('emitToServer', `load store setting`, { settingName: 'Shipping contact first name', settingLocation: 'External Shipping Integration' }),
				lastName: mediator.call('emitToServer', `load store setting`, { settingName: 'Shipping contact last name', settingLocation: 'External Shipping Integration' }),
				email: mediator.call('emitToServer', `load store setting`, { settingName: 'Shipping contact email', settingLocation: 'External Shipping Integration' }),
			})
			for (const key in settings) {
				settings[key] = settings[key][0]
				if (settings[key].dataType === 'boolean') {
					settings[key].value = stringToBoolean(settings[key].value)
				}
			}

			return { settings }
		},
		activate({ domApi: ractive }) {
			ractive.observe('settings.*', async changedSetting => {
				try {
					await mediator.call('emitToServer', 'save global setting value', {
						settingValue: changedSetting,
					})
				} catch (err) {
					console.error(err)
					alert('There was an error saving the setting. Please try again or contact ISoft support if the issue continues.')
				}
			}, { init: false })
		},
	})
}
