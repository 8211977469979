import template from './results.html'
import toTitleCase from 'to-title-case'
import * as currency from '@isoftdata/utility-currency'
import * as stringUtil from '@isoftdata/utility-string'
import { pluralIfNeeded as plural } from '@isoftdata/utility-string'
import deepCopy from 'klona'

//Ractive components
import makeTableCardFooter from '@isoftdata/table-card-footer'
import makeCheckboxButton from '@isoftdata/checkbox-button'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeList from '@isoftdata/list'

function getBalanceColor({ balance, accountLimit }) {
	if (balance === 0) {
		return {
			cssColor: 'black',
			info: 'Customer has no balance',
		}
	} else if (balance > 0 && balance >= accountLimit) {
		return {
			cssColor: 'red',
			info: 'The customer owes you money and is over their credit limit',
		}
	} else if (balance > 0 && balance < accountLimit) {
		return {
			cssColor: 'darkgoldenrod',
			info: 'The customer owes you money but is under their credit limit',
		}
	} else if (balance < 0) {
		return {
			cssColor: 'blue',
			info: 'You owe the customer (customer has a credit)',
		}
	}
}

export default function({ stateRouter, mediator, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.entity-search.results',
		route: 'results',
		querystringParameters: [ 'selectedEntityId', 'entityContext', 'showBalances', 'showInactive', 'searchQuery' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itModal: makeModal(),
				itList: makeList(),
				itCheckboxButton: makeCheckboxButton({ twoway: false }),
				itTableCardFooter: makeTableCardFooter(),
			},
			computed: {
				entityDisplayName() {
					return toTitleCase(this.get('entityContext'))
				},
				computedColumns() {
					let columns = [
						{ property: 'view', icon: 'fas fa-check' },
						{ property: 'billingCompany', name: 'Company Name' },
						{ property: 'billingContact', name: 'Contact Name' },
						{ property: 'emailAddress', name: 'Email' },
						{ property: 'billingPhoneNumber', name: 'Phone Number' },
						{ property: 'billingStreetAddress', name: 'Street' },
						{ property: 'billingMailingAddress', name: 'Mailing' },
						{ property: 'billingCity', name: 'City' },
						{ property: 'billingState', name: 'State' },
						{ property: 'billingZip', name: 'Zip Code' },
						{ property: 'billingCountry', name: 'Country' },
						{ property: 'faxNumber', name: 'Fax Number' },
						{ property: 'type', name: 'Type' },
						{ property: 'websiteUrl', name: 'Web Site' },
					]

					if (this.get('showEntityId')) {
						columns.splice(1, 0, { property: 'id', name: '#' })
					}

					if (this.get('entityContext') === 'customer' && this.get('showBalances')) {
						columns = [ ...columns, { property: 'balance', name: 'Balance' }]
					}

					return columns
				},
				displayEntities() {
					const entityContext = this.get('entityContext')
					let entities = this.get('entities')
					const showBalances = this.get('showBalances')

					entities = entities.map(entity => {
						return {
							...entity,
							id: entity[`${entityContext}Id`],
						}
					})

					if (entityContext === 'customer' && showBalances) {
						return entities.map(entity => {
							const { cssColor, info } = getBalanceColor(entity)
							return {
								...entity,
								balanceColor: cssColor,
								balanceTooltip: info,
							}
						})
					} else {
						return entities
					}
				},
			},
			isSelected(entity) {
				return entity && this.get('selectedEntityId').includes(entity[`${this.get('entityContext')}Id`])
			},
			hasPermission,
		},
		async resolve(data, { selectedEntityId, entityContext, showBalances, showInactive, searchQuery }) {
			const { user } = JSON.parse(localStorage.getItem('session'))

			showInactive = stringUtil.stringToBoolean(showInactive)
			showBalances = stringUtil.stringToBoolean(showBalances)

			searchQuery = decodeURIComponent(searchQuery)

			const [ entities, showEntityId ] = await Promise.all([
				mediator.call('emitToServer', `load ${entityContext} search`, {
					searchQuery,
					showInactive,
					showBalances,
					performWildcard: true,
				}),
				mediator.call('emitToServer', 'check user setting', {
					name: 'Show Customer/Vendor ID on Search/Detail Screens',
					category: 'Customer/Vendor',
					userAccountId: user?.userAccountId,
					settingType: 'Interface History',
					defaultValue: false,
					dataType: 'boolean',
				}),
			])

			const updatedEntities = entities.map(entity => {
				return { ...entity, active: stringUtil.stringToBoolean(entity.active) }
			})

			return Promise.resolve({
				searchQuery,
				entities: updatedEntities,
				entityContext,
				selectedEntityId: parseInt(selectedEntityId, 10) ? [ parseInt(selectedEntityId, 10) ] : [],
				plural,
				masterCompanyIndex: 0,
				showBalances,
				showInactive,
				currency,
				showEntityId,
			})
		},
		activate({ domApi: ractive }) {
			ractive.observe('selectedEntityId', console.log)
			if (ractive.get('selectedEntityId') === 0 && ractive.get('entities').length > 0) {
				stateRouter.go('app.entity-search.results', {
					selectedEntityId: [ ractive.get('entities')[0][`${ractive.get('entityContext')}Id`] ],
				}, { inherit: true, replace: true })
			}

			ractive.on('open-entity', (context, entityId) => {
				stateRouter.go('app.entity', {
					id: entityId,
					entityContext: ractive.get('entityContext'),
				})
			})

			ractive.on('itTable.selection-change', (context, id) => {
				stateRouter.go('app.entity-search.results', {
					selectedEntityId: id,
				}, { inherit: true })
			})

			/* ractive.on('itTable.enter-pressed', () => {
				ractive.fire('edit-customer-clicked', {}, ractive.get('selectedEntityId'))
			}) */

			ractive.on('showMergeModal', () => {
				let selectedEntities = ractive.get('selectedEntityId')
				const entityContext = ractive.get('entityContext')

				selectedEntities = ractive.get('entities')
					.filter(entity => selectedEntities.includes(entity[`${entityContext}Id`]))
					.map(entity => {
						return {
							entity,
							label: entity.billingCompany,
							subLabel: entity.billingContact,
							rightTextKeyValues: [
								{ key: 'Phone #', value: entity.billingPhoneNumber },
								{ key: 'Address', value: entity.billingStreetAddress },
							],
						}
					})

				ractive.set({
					showMergeModal: true,
					entitiesToMerge: deepCopy(selectedEntities),
				})
			})

			ractive.on('mergerMasterCompanyClicked', (context, i) => {
				ractive.set({ masterCompanyIndex: i })
			})

			ractive.on('mergeEntities', async() => {
				const entityContext = ractive.get('entityContext')
				const entitiesToMerge = ractive.get('entitiesToMerge')
					.map(entity => entity.entity[`${entityContext}Id`])

				const masterEntityId = entitiesToMerge[ractive.get('masterCompanyIndex')]
				const otherEntityIds = entitiesToMerge.filter(id => id !== masterEntityId)

				try {
					await mediator.call('emitToServer', `merge ${entityContext}s`, {
						[`master${ractive.get('entityDisplayName')}Id`]: masterEntityId,
						[`other${ractive.get('entityDisplayName')}Ids`]: otherEntityIds,
					})
					const entities = ractive.get('entities').filter(entity => {
						return !otherEntityIds.includes(entity[`${entityContext}Id`])
					})

					ractive.set({
						masterCompanyIndex: 0,
						entities,
						showMergeModal: false,
						selectedEntityId: [],
					})
				} catch (err) {
					logAndAlert(err, mediator, `Error merging ${entityContext}s`)
				}
			})
		},
	})
}
