export default (storageContext: 'localStorage' | 'sessionStorage' = 'localStorage') => {
	return {
		getItem<T = any>(key: string) {
			const item = window[storageContext].getItem(key)
			return item ? (JSON.parse(item) as T) : null
		},
		setItem<T = any>(key: string, value: T) {
			return window[storageContext].setItem(key, JSON.stringify(value))
		},
	}
}
