import template from './manage.html'
import makeAttachmentFns from 'utility/attachment-helper.ts'

//Ractive components
import makeAddressForm from 'components/address-form'
import makeTextArea from '@isoftdata/textarea'
import makeCheckbox from '@isoftdata/checkbox'
import makeInput from '@isoftdata/input'

function addressFieldsMatch(store) {
	return store.remittanceAddress === store.address &&
		store.remittanceCity === store.city &&
		store.remittanceState === store.state &&
		store.remittanceZip === store.zip &&
		store.remittanceCountry === store.country &&
		store.remittancePhoneNumber === store.phoneNumber
}

export default function({ stateRouter, mediator, logAndAlert }) {
	const { saveLogoFile } = makeAttachmentFns(mediator)

	stateRouter.addState({
		name: 'app.configuration.store.manage',
		route: ':storeId',
		querystringParameters: [ 'storeId' ],
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true }),
				itTextArea: makeTextArea({ twoway: true }),
				addressForm: makeAddressForm({ mediator, logAndAlert }),
				itCheckbox: makeCheckbox({ twoway: true }),
			},
			async handleFileUpload(fileList) {
				const ractive = this

				const File = fileList[0]

				const storeLogo = await saveLogoFile({ File, name: File.name, mimeType: File.type })
				//await mediator.call('emitToServer', 'save logo file', loadedFile)
				ractive.set({
					'store.logoFileId': storeLogo.fileId,
					storeLogo,
				})
			},
			setRemittanceAddresstoMatch(store) {
				if (this.get('matchRemittanceAddressToMainAddress') && !addressFieldsMatch(store)) {
					this.set('store', {
						...store,
						remittanceAddress: store.address,
						remittanceCity: store.city,
						remittanceState: store.state,
						remittanceZip: store.zip,
						remittanceCountry: store.country,
						remittancePhoneNumber: store.phoneNumber,
					})
				}
			},
		},
		async resolve(data, { storeId }) {
			let store = (await mediator.call('emitToServer', 'load stores', { storeId }))[0] || {}

			if (store.logoFileId) {
				const storeLogo = await mediator.call('emitToServer', 'load file info', { fileId: store.logoFileId })

				return {
					store,
					storeLogo,
					matchRemittanceAddressToMainAddress: addressFieldsMatch(store), //default the checkbox state based on if the 2 addresses loaded match
				}
			}

			return { store }
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.observe('store', async store => {
				try {
					await mediator.call('emitToServer', 'save store', { store })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving store')
				}
			}, { init: false })

			const removeStoreChangedProvider = mediator.provide('store-changed', store => {
				//store name changed from the parent state
				if (store?.storeId === ractive.get('store')?.storeId) {
					ractive.set('store.name', store.name)
				}
			})

			ractive.observe('store', store => {
				if (ractive.get('matchRemittanceAddressToMainAddress')) {
					ractive.setRemittanceAddresstoMatch(store)
				}
			}, { init: false })

			context.on('destroy', () => removeStoreChangedProvider?.())
		},
	})
}
