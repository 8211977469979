import template from './inventory-list.html'
import pProps from 'p-props'
import getManufacturerModelName from 'shared/part-or-vehicle-manufacturer-model'

//Ractive Components
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.scanner.inventory-verification.inventory-list',
		route: 'inventory-list',
		querystringParameters: [ 'location' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			computed: {
				displayInventory() {
					return this.get('inventory').map(part => {
						const { manufacturer, model } = getManufacturerModelName({
							partManufacturer: part.partManufacturer,
							partModel: part.partModel,
							make: part.make,
							model: part.model,
						})

						return {
							...part,
							manufacturerModel: [ manufacturer, model ].join(' '),
						}
					})
				},
			},
			async changeQuantity(inventoryId, change) {
				let inventory = this.get('inventory')
				const itemIndex = inventory.findIndex(inventory => {
					return inventory.inventoryId === inventoryId
				})

				if (itemIndex > -1 && change) {
					const changeAmount = change === '+' ? 1 : -1

					inventory[itemIndex].quantity += changeAmount

					await this.set({
						inventory,
						savingId: inventory[itemIndex].inventoryId,
					})
					await mediator.call('emitToServer', 'save inventory item', { item: inventory[itemIndex] })
					this.set({ savingId: null })
				}
			},
		},
		async resolve(data, { location }) {
			const res = await pProps({
				location,
				inventory: location?.trim() ? mediator.call('emitToServer', 'load inventory', { location, status: '@' }) : [],
				savingId: null,
				sortedInventory: [],
				vehicles: [],
			})

			const vehicleIds = res.inventory.filter(item => item.vehicleId).map(item => item.vehicleId)
			res.vehicles = vehicleIds.length > 0 ? await mediator.call('emitToServer', 'load vehicle', { vehicleId: vehicleIds }) : []

			return res
		},
		activate({ domApi: ractive }) {
			//
		},
	})
}
