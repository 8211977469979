import template from './quote-line.html'
import financialNumber from 'financial-number'

//Ractive components
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.quote.line',
		route: 'line',
		data: {},
		template: {
			template,
			components: {
				itTable: makeTable(),
			},
			computed: {
				displayQuoteLines() {
					const inventory = this.get('inventory')
					const vehicles = this.get('vehicles')

					return this.get('quoteLines').map(({
						inventoryId,
						vehicleId,
						partType,
						description,
						coreAmount,
						quantity,
						price,
					}) => {
						const inventoryItem = inventory.find(part => part.inventoryId === inventoryId)
						const vehicle = vehicles.find(vehicle => vehicle.vehicleId === vehicleId)

						return {
							inventoryId,
							tagNumber: inventoryItem?.tagNumber || '',
							itemStoreId: inventoryItem?.storeId || 1,
							vehicleId,
							trackingNumber: vehicle?.trackingNumber || '',
							vehicleStoreId: vehicle?.storeId || 1,
							partType,
							description,
							coreAmount,
							quantity,
							price,
							total: financialNumber(price.toString())
								.times(quantity.toString())
								.toString(),
						}
					})
				},
			},
		},
		async resolve(data, parameters) {
			const quoteInfo = await mediator.call('emitToServer', 'load quote lines', {
				quoteId: parameters.quoteId,
				options: {
					includeInventory: true,
					includeVehicle: true,
				},
			})

			let quoteLines = []
			let inventory = []
			let vehicles = []

			quoteInfo.forEach(({ waQuoteLine, waInventory, waVehicle }) => {
				quoteLines = [ ...quoteLines, waQuoteLine ]
				inventory = [ ...inventory, waInventory ]
				vehicles = [ ...vehicles, waVehicle ]
			})

			return {
				quoteLines,
				inventory,
				vehicles,
				quoteId: parseInt(parameters.quoteId, 10),
			}
		},
		activate({ domApi: ractive }) {
			//
		},
	})
}

