export function getCustomerAllowedPaymentMethods(customerPaymentMethods, paymentMethods) {
	const customerPaymentMethodById = customerPaymentMethods.reduce((map, customerPaymentMethod) => {
		map[customerPaymentMethod.paymentMethodId] = customerPaymentMethod
		return map
	}, Object.create(null))

	return paymentMethods.map(paymentMethod => {
		const customerPaymentMethod = customerPaymentMethodById[paymentMethod.paymentMethodId]
		return { allowedForCustomer: !!customerPaymentMethod, ...paymentMethod }
	})
}
