import * as dateTimeUtility from '@isoftdata/utility-date-time'

import component from './Home.svelte'

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.home',
		route: 'home',
		defaultChild: 'sale',
		querystringParameters: [ 'fromDate', 'toDate', 'displayCustomer' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			let { fromDate, toDate, displayCustomer } = parameters
			const session = JSON.parse(localStorage.getItem('session'))

			if (!displayCustomer) {
				const localStorageDisplayName = localStorage.getItem('displayCustomer')

				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, displayCustomer: localStorageDisplayName ? localStorageDisplayName : 'Ship' },
					},
				}
			} else if (!fromDate && !toDate) {
				const defaultRange = await mediator.call('emitToServer', 'check user setting', {
					name: 'Chromium Home Screen Date Range',
					category: 'Home',
					defaultValue: 'Last 7 Days',
					settingType: 'Interface History',
					userAccountId: session.userAccountId,
					dataType: 'string',
				}) || 'Last 7 Days'

				let defaultDates
				try {
					defaultDates = dateTimeUtility.datesFromRange(defaultRange)
				} catch (err) {
					logAndAlert(err, mediator, 'An error occurred while trying to load the default date range')
					defaultDates = dateTimeUtility.datesFromRange('Last 7 Days')
				}

				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				}
			}

			return {
				range: dateTimeUtility.rangeFromDates(fromDate, toDate),
				dates: { from: fromDate, to: toDate },
				displayCustomer,
				hasPendingBuyNowOrders: false,
				childStates: [
					{
						title: 'Sales Orders',
						name: 'app.home.sale',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_INVOICE_VIEW',
					},
					{
						title: 'Internet Orders',
						name: 'app.home.internet',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_INTERNET_ORDERS_VIEW',
					},
					{
						title: 'Quotes',
						name: 'app.home.quote',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_QUOTES_VIEW',
					},
					{
						title: 'Build Orders',
						name: 'app.home.build',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_BUILD_ORDERS_VIEW',
					},
					{
						title: 'Work Orders',
						name: 'app.home.work',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_WORK_ORDERS_VIEW',
					},
					{
						title: 'Deliveries',
						name: 'app.home.delivery',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_DELIVERIES_VIEW',
					},
					{
						title: 'Purchase Orders',
						name: 'app.home.purchase',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_PO_VIEW',
					},
					{
						title: 'Vehicle Bids',
						name: 'app.home.vehicle-bid',
						stateParameters: {},
						stateOptions: { inherit: true },
						requiredPermission: 'PM_VEHICLE_VIEW',
					},
				],
			}
		},
	})
}
