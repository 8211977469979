import template from './touchpoint.html'
import pProps from 'p-props'

//Ractive Components
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

const stateName = 'app.entity.contact.correspondence.touchpoint'

export default function({ mediator, stateRouter, isChildState }) {
	stateRouter.addState({
		name: stateName,
		route: 'touchpoint',
		querystringParameters: [ 'id', 'entityContext', 'customerTicketId', 'customerTicketEntryId' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			mediator,
			computed: {
				displayTouchpoints() {
					const users = this.get('users')
					const touchpoints = this.get('touchpoints')

					return touchpoints.map(touchpoint => {
						const createdByUser = users.find(user => user.userAccountId === touchpoint.createdByUserId)

						const entryTypeIcons = {
							'logging': 'fa-clipboard-list',
							'phone': 'fa-phone',
							'email': 'fa-envelope',
							'internal_note': 'fa-sticky-note',
							'text': 'fa-comment-dots',
							'in person': 'fa-handshake',
							'social media': 'fa-hashtag',
						}

						return {
							...touchpoint,
							createdBy: createdByUser ? createdByUser.userName : '',
							entryTypeIcon: entryTypeIcons[touchpoint.entryType],
						}
					})
				},
			},
		},
		resolve(data, { id: customerId, entityContext, customerTicketId, customerTicketEntryId }) {
			return pProps({
				customerTicketId: parseInt(customerTicketId, 10),
				touchpoints: mediator.call('emitToServer', 'load customer ticket entry', { customerTicketId }),
				users: mediator.call('emitToServer', 'load users', { }),
				touchpointColumns: [
					{ property: 'message', name: 'Message' },
					{ property: 'entryType', name: 'Type' },
					{ property: 'createdDate', name: 'Date' },
				],
				selectedCustomerTicketEntryId: customerTicketEntryId ? parseInt(customerTicketEntryId, 10) : null,
			})
		},
		activate(context) {
			const { domApi: ractive } = context
			ractive.on('touchpointClicked', ({ customerTicketEntryId }) => {
				stateRouter.go(null, { customerTicketEntryId }, { inherit: true })
			})

			const removeTouchpointChangedProvider = mediator.provide('touchpointChanged', customerTicketEntry => {
				ractive.upsert('touchpoints', 'customerTicketEntryId', customerTicketEntry, { prepend: true })
			})

			context.on('destroy', () => {
				removeTouchpointChangedProvider()
			})
		},
	})
}
