import template from './app.html'
import makeStateRouter from 'abstract-state-router'
import makeRenderer from '@isoftdata/ractive-svelte-state-renderer-wrapper'
//import socketio from 'socket.io-client'
import mannish from 'mannish'
import * as currency from '@isoftdata/utility-currency'
import { stringToBoolean } from '@isoftdata/utility-string'
import { booleanToString } from '@isoftdata/utility-boolean'
import * as dateTimeHelper from '@isoftdata/utility-date-time'
import makeUserAvatar from '@isoftdata/user-avatar-component'
import makeSidebar from '@isoftdata/sidebar-component'
import makeAlert from '@isoftdata/alert'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeTable from '@isoftdata/table'
import toTitleCase from 'to-title-case'
import makeModal from '@isoftdata/modal'
import getBarcodeType from 'utility/barcode-helper'
import pProps from 'p-props'
import makeOnBarcodeScan from '@isoftdata/scanner-support'
import makeStorageHelper from 'utility/storage-json-helper.ts'
import registerApiProvider from 'utility/api-provider'
import { logAndAlert } from 'utility/error-handler'
import ractiveTransitionsFly from 'ractive-transitions-fly'
import { standardProfile } from '@isoftdata/svelte-scanner-configuration'

const onBarcodeScan = makeOnBarcodeScan()
const localStorageJSON = makeStorageHelper('localStorage')

const mediator = mannish()

function hasPermission(permissionCode) {
	if (!permissionCode || typeof (permissionCode) !== 'string') {
		throw new Error('Invalid permission code provided')
	}

	const session = localStorageJSON.getItem('session')

	if (session?.permissions) {
		return session.permissions.includes(permissionCode.trim().toUpperCase())
	}

	return false
}

function toLocaleDateString(dateString) {
	return dateTimeHelper.toLocaleDateString(dateString)
}

function formatCurrency(decimalString, options) {
	//pass false for includeSymbol option if you're calling this for an input,
	//like the examples shown here: https://getbootstrap.com/docs/4.0/components/forms/#inline-forms
	return currency.format(decimalString, options)
}

function isChildState({ childState, parentState }) {
	return childState.indexOf(parentState) > -1
}

const renderer = makeRenderer(
	{
		data: {
			toLocaleDateString,
			formatCurrency,
			getValidWebsiteURL(url) {
				return url.startsWith('http') ? url : `http://${url}`
			},
			stringToBoolean: str => {
				return stringToBoolean(str)
			},
			booleanToString: bool => {
				return booleanToString(bool)
			},
		},
	},
	{ // passed to svelte-state-renderer
		props: {

		},
		context: new Map([
			[ 'mediator', mediator ],
			[ 'logAndAlert', logAndAlert ],
			[ 'hasPermission', hasPermission ],
			[ 'toLocaleDateString', toLocaleDateString ],
			[ 'formatCurrency', formatCurrency ],
			[ 'stringToBoolean', stringToBoolean ],
			[ 'booleanToString', booleanToString ],
			[ 'isChildState', isChildState ],
		]),
	})

const stateRouter = makeStateRouter(renderer, document.querySelector('#template'), { throwOnError: true })

import ractivePartialNoItemsForSelectedFilter from 'partials/no-items-for-selected-filter.html'
import ractiveDecoratorSelectOnFocus from 'ractive-select-on-focus'
import ractiveMethodUpsert from '@isoftdata/ractive-method-upsert'

Ractive.partials.noItemsForSelectedFilter = ractivePartialNoItemsForSelectedFilter
Ractive.decorators.selectOnFocus = ractiveDecoratorSelectOnFocus
Ractive.defaults.upsert = ractiveMethodUpsert

const context = {
	stateRouter,
	mediator,
	logAndAlert,
	isChildState,
	hasPermission,
}

stateRouter.addState({
	name: 'app',
	defaultChild: 'home',
	template: {
		template,
		data: {
			alertIsShown: false,
			stateIsChanging: false,
		},
		components: {
			userAvatar: makeUserAvatar(),
			sidebar: makeSidebar(stateRouter),
			itModal: makeModal(),
			itAlert: makeAlert(),
			itButton: makeButton(),
			itTable: makeTable(),
			itSelect: makeSelect({ twoway: true }),
		},
		computed: {
			userDisplayName() {
				const { firstName, lastName, userName } = this.get('user')

				return (firstName && lastName) ? `${firstName} ${lastName}` : userName
			},
		},
		transitions: {
			fly: ractiveTransitionsFly,
		},
		hasPermission: context.hasPermission,
		stateRouterGo: stateRouter.go,
		logout() {
			try {
				context.mediator.call('logout')
			} catch (err) {
				//
			} finally {
				stateRouter.go('login')
			}
		},
	},
	async resolve() {
		const session = localStorageJSON.getItem('session')

		if (session?.token) {
			const recentActivity = localStorageJSON.getItem('recentActivity') || {}
			const currentUserRecentActivity = recentActivity[session?.user?.userAccountId] || []
			const isDeveloperMode = localStorageJSON.getItem('isDeveloperMode')

			const isConnectedToQuickBooks = await context.mediator.call('emitToServer', 'is connected to quickbooks', {})

			const res = await pProps({
				session,
				user: session?.user,
				store: session?.store,
				userMenuShown: false,
				showLoadingModal: false,
				socketIsConnected: true,
				isDeveloperMode,
				defaultBarcodeScannerOptions: context.mediator.call('emitToServer', 'check global setting', {
					name: 'Barcode Scanner Options',
					category: 'Options',
					defaultValue: JSON.stringify(standardProfile),
					settingType: 'Important Configuration',
				}),
				advancedPrintManageInstalled: context.mediator.call('emitToServer', 'check global setting', {
					name: 'Advanced print management server installed',
					category: 'Printing',
					defaultValue: false,
					settingType: 'Important Configuration',
					dataType: 'boolean',
				}),
				sidebarCollapsed: context.mediator.call('emitToServer', 'check user setting', {
					name: 'Collapse Chromium Sidebar',
					category: 'Options',
					userAccountId: session?.user?.userAccountId,
					settingType: 'Interface History',
					defaultValue: false,
					dataType: 'boolean',
				}),
				isInApp: !!window.appVersion, //Used by the iOS app
				navExpanded: false,
				states: [
					{
						type: 'HOME',
						name: 'Home',
						route: 'app.home',
						icon: 'fa-home',
					},
					{
						type: 'CUSTOMER',
						name: 'Customer',
						hidden: !context.hasPermission('PM_CUSTOMER_VIEW') || !context.hasPermission('PM_CUSTOMERLIST_VIEW'),
						route: 'app.entity-search',
						routeParameters: { entityContext: 'customer' },
						icon: 'fa-user',
						additionalActiveRoutes: [
							{
								route: 'app.entity',
								routeParameters: { entityContext: 'customer' },
							},
						],
						createRoute: {
							route: 'app.entity',
							routeParameters: { entityContext: 'customer' },
						},
					},
					{
						type: 'Dashboard',
						name: 'Dashboard',
						hidden: !context.hasPermission('PM_DASHBOARD_REPORT_VIEW'),
						route: 'app.dashboard',
						icon: 'fa-chart-line',
					},
					{
						type: 'INTERCHANGE',
						name: 'Interchange',
						route: 'app.interchange',
						icon: 'fa-random',
					},
					{
						type: 'PART',
						name: 'Part',
						route: 'app.part-search',
						icon: 'fa-engine',
						hidden: !context.hasPermission('PM_PART_VIEW'),
						additionalActiveRoutes: [
							{ route: 'app.part' },
						],
						createRoute: context.hasPermission('PM_PART_EDIT') ? { route: 'app.part' } : null,
					},
					{
						type: 'PURCHASE_ORDER',
						name: 'Purchase Order',
						route: 'app.purchase-order',
						icon: 'fa-box-open',
						hidden: !context.hasPermission('PM_PO_VIEW'),
					},
					{
						type: 'QUICKBOOKS',
						name: 'QuickBooks Online',
						route: 'app.quickbooks',
						icon: 'fa-quickbooks',
						iconBaseClass: 'fak',
						hidden: !context.hasPermission('PM_QUICKBOOKS_VIEW') || !isConnectedToQuickBooks,
					},
					{
						type: 'REPORTVIEWER',
						name: 'Report Viewer',
						route: 'app.report-viewer',
						icon: 'fa-file-chart-column',
						hidden: !context.hasPermission('PM_REPORTVIEWER_VIEW'),
					},
					{
						type: 'SALE',
						name: 'Sales Order',
						route: 'app.sale',
						icon: 'fa-dollar-sign',
						hidden: !context.hasPermission('PM_DELIVERIES_VIEW') &&
							!context.hasPermission('PM_INVOICE_VIEW') &&
							!context.hasPermission('PM_QUOTES_VIEW') &&
							!context.hasPermission('PM_WORK_ORDERS_VIEW') &&
							!context.hasPermission('PM_BUILD_ORDERS_VIEW') &&
							!context.hasPermission('PM_INTERNET_ORDERS_VIEW'),
					},
					{
						type: 'VEHICLE',
						name: 'Vehicle',
						route: 'app.vehicle-search',
						icon: 'fa-truck',
						hidden: !context.hasPermission('PM_VEHICLE_VIEW'),
						additionalActiveRoutes: [
							{ route: 'app.vehicle' },
						],
						createRoute: context.hasPermission('PM_VEHICLE_ADD') ? { route: 'app.vehicle' } : null,
					},
					{
						type: 'VENDOR',
						name: 'Vendor',
						route: 'app.entity-search',
						routeParameters: { entityContext: 'vendor' },
						icon: 'fa-user',
						hidden: !context.hasPermission('PM_VENDOR_VIEW'),
						additionalActiveRoutes: [
							{
								route: 'app.entity',
								routeParameters: { entityContext: 'vendor' },
							},
						],
						createRoute: {
							route: 'app.entity',
							routeParameters: { entityContext: 'vendor' },
						},
					},
					{
						type: 'SCANNER',
						name: 'Scanner',
						route: 'app.scanner',
						icon: 'fa-barcode-alt',
					},
					{
						type: 'CONFIGURATION',
						name: 'Configuration',
						route: 'app.configuration',
						icon: 'fa-cog',
						hide: true,
					},
				],
				recentActivity: currentUserRecentActivity,
				hasPermission, //Allow them to call hasPermission in the template
			})

			return {
				...res,
				defaultBarcodeScannerOptions: JSON.parse(res.defaultBarcodeScannerOptions),
			}
		}

		return Promise.reject({
			redirectTo: {
				name: 'login',
			},
		})
	},
	activate(activateContext) {
		const { domApi: ractive } = activateContext

		ractive.observe('sidebarCollapsed', async value => {
			await context.mediator.call('emitToServer', 'save user setting', {
				category: 'Options',
				name: 'Collapse Chromium Sidebar',
				type: 'Interface History',
				value,
			})
		}, { init: false })

		const removeActivityProvider = context.mediator.provide('activity', activity => {
			const currentActivity = ractive.findComponent('sidebar').newActivity(activity)
			const allRecentActivity = localStorageJSON.getItem('recentActivity') || {}

			allRecentActivity[ractive.get('user.userAccountId')] = currentActivity
			localStorageJSON.setItem('recentActivity', allRecentActivity)
		})

		const removeHideMessageProvider = context.mediator.provide('hideMessage', () => {
			ractive.findComponent('itAlert').hide()
		})

		const removeShowMessageProvider = context.mediator.provide('showMessage', (options = {}) => {
			let { type, time, dismissable, ...otherOptions } = options

			const alertClass = `alert-${(type && type.toLowerCase().trim()) || 'secondary'}`

			ractive.findComponent('itAlert').show({
				class: alertClass,
				...otherOptions,
				dismissable: dismissable !== false, //if they don't pass a value for dismissable, make sure we default it to true
			})

			if (time !== false) {
				let timeoutId
				timeoutId = setTimeout(() => {
					ractive.findComponent('itAlert').hide()
				}, time || 3000)

				ractive.findComponent('itAlert')
					.on('alertDismissed', () => {
						if (timeoutId) {
							clearTimeout(timeoutId)
						}
					})
			}
		})

		const removeUserChangedProvider = context.mediator.provide('user-changed', user => {
			ractive.set({ user })
		})

		const removeAddQuickBooksToSidebarProvider = context.mediator.provide('show-quickbooks-in-sidebar', shouldShow => {
			const states = ractive.get('states')
			const qbStateIndex = states.findIndex(state => state.type === 'QUICKBOOKS')
			const qbState = states[qbStateIndex]
			qbState.hidden = !context.hasPermission('PM_QUICKBOOKS_VIEW') || !shouldShow
			ractive.splice('states', qbStateIndex, 1, qbState)
		})

		activateContext.on('destroy', function() {
			removeActivityProvider()
			removeShowMessageProvider()
			removeUserChangedProvider()
			removeHideMessageProvider()
			removeAddQuickBooksToSidebarProvider()
		})

		stateRouter.on('routeNotFound', (route, parameters) => {
			stateRouter.go('not-found', {
				route,
				parameters,
			})
		})

		/*
		stateRouter.on('stateChangeAttempt', (functionThatBeginsTheStateChange) => {
			console.log('stateChangeAttempt', functionThatBeginsTheStateChange)
		})

		stateRouter.on('stateChangeCancelled', (err) => {
			console.log('stateChangeCancelled', err)
		})*/

		stateRouter.on('stateChangeError', err => {
			const stateName = ractive.get('destinationStateName')
			logAndAlert(err, context?.mediator, `Error: State change to ${stateName} failed.`, stateName)
		})

		/*
		stateRouter.on('stateError', (err) => {
			console.log('stateError', err)
		})*/

		stateRouter.on('stateChangeStart', state => {
			ractive.set({
				destinationStateName: state.name,
				navExpanded: false,
				stateIsChanging: true,
			})
		})

		stateRouter.on('stateChangeEnd', (state, stateParameters) => {
			//When the state changes, let the server know, for usage reporting reasons
			const previousStateName = ractive.get('currentActiveState.name')
			ractive.set({ currentActiveState: { name: state.name, parameters: stateParameters }, stateIsChanging: false })
			//ractive.set({ showLoadingModal: false })

			let routeName = state.name.startsWith('app.') ? state.name.substring(4) : state.name
			if (state.name.startsWith('app.entity')) {
				routeName = routeName.replace('entity', stateParameters.entityContext)
			}
			const stateName = routeName.split('.').map(state => toTitleCase(state)).join(' > ')

			if (previousStateName !== state.name) {
				//Don't ever await this, because it's not a critical operation and we don't want the application waiting for it
				context.mediator.call('emitToServer', 'report significant state change', {
					stateTitle: stateName,
					stateName: state.name,
					stateParameters,
				}, { doNotAlert: true }).catch(({ err, companyCode }) => {
					//We don't need to display this to the user, because it's just a reporting thing
					console.error(`Error reporting significant state change.\n\nSupport, this may be because this company does not have a valid company entry.\n\nCompany Code: ${companyCode || 'Unknown'}\n\nhttps://employee.isoftdata.com/company_editor.php\n\n`, err)
				})
			}

			document.title = `${stateName}`

			window.processBarcode = barcode => {
				barcode = getBarcodeType(barcode)

				//This modal won't go away if the user scans a barcode that results in `go`ing to the same state, so I'm doing away with it for now.
				//ractive.set({ showLoadingModal: true })
				if (barcode.type === 'VIN') {
					//alert('VIN', barcode.value)
					context.mediator.call('emitToServer', 'load vehicle', { vin: barcode.value })
						.then(vehicleList => {
							if (vehicleList && vehicleList.length > 0) {
								stateRouter.go('app.vehicle', { vehicleId: vehicleList[0].vehicleId }, { inherit: true })
							} else {
								stateRouter.go('app.vehicle.basic', { vin: barcode.value })
							}
						})
						.catch(err => console.error(err))
					//stateRouter.go('app.scanner.assign', { inventoryId: barcode.value }, { inherit: true })
				} else if (barcode.type === 'INVENTORY_ID') {
					if (state.name.startsWith('app.scanner')) {
						stateRouter.go('app.scanner.assign', { inventoryId: barcode.value, fromScan: true }, { inherit: true })
					} else {
						context.mediator.call('emitToServer', 'load inventory', { inventoryId: barcode.value })
							.then(inventory => {
								if (inventory?.length === 1) {
									stateRouter.go('app.part', { inventoryId: barcode.value }, { inherit: true })
								} else {
									//TODO: kick them over to the part search screen and throw the barcode value in the keyword search box
									alert(`No part was found with SKU # ${barcode.value}. Please try again.`)
								}
							})
					}
				} else if (barcode.type === 'VEHICLE_ID') {
					stateRouter.go('app.vehicle', { vehicleId: barcode.value }, { inherit: true })
				} else if (barcode.type === 'LOCATION' || barcode.type === 'UPC') {
					const inherit = state.name.startsWith('app.scanner')

					if (barcode.type === 'LOCATION') {
						if (state.name.startsWith('app.scanner.inventory-verification')) {
							stateRouter.go(null, {
								location: barcode.value,
							}, { inherit })
						} else {
							stateRouter.go('app.scanner.assign', {
								inventoryFieldValue: barcode.value,
								selectedInventoryFieldKey: 'location',
								fromScan: true,
							}, { inherit })
						}
					} else if (barcode.type === 'UPC') {
						context.mediator.call('emitToServer', 'load inventory', { upc: barcode.value })
							.then(inventory => {
								if (inventory && inventory.length === 1) {
									stateRouter.go('app.part', { inventoryId: inventory[0].inventoryId }, { inherit })
								} else {
									stateRouter.go('app.scanner.assign', {
										inventoryFieldValue: barcode.value,
										selectedInventoryFieldKey: 'upc',
										fromScan: true,
									}, { inherit })
								}
							})
							.catch(err => console.error(err))
					}
				}
			}

			const companyDefaultBarcodeScannerOptions = ractive.get('defaultBarcodeScannerOptions')
			const deviceBarcodeScannerOptions = localStorageJSON.getItem('barcodeScannerOptions')

			onBarcodeScan(window.processBarcode, {
				preamble: deviceBarcodeScannerOptions?.preamble || companyDefaultBarcodeScannerOptions?.preamble,
				postamble: deviceBarcodeScannerOptions?.postamble || companyDefaultBarcodeScannerOptions?.postamble,
				enableHoneywell: deviceBarcodeScannerOptions?.enableHoneywell,
				stop: state.name.startsWith('app.configuration.scanner'),
			})
		})
	},
})

// Sort states by depth so that child states are registered after their parents
Object.entries(import.meta.glob('./states/**/*.[tj]s', { import: 'default', eager: true }))
	.sort((a, b) => {
		const depthA = a[0].split('/').length
		const depthB = b[0].split('/').length
		return depthA - depthB
	})
	.forEach(([ _, createState ]) => {
		if (typeof createState === 'function') {
			createState(context)
		}
	})

Promise.all([
	registerApiProvider(context.mediator, 'emitToServer', true),
	registerApiProvider(context.mediator, 'noAuthEmitToServer'),
])
	.catch(err => {
		console.error(err)
		logAndAlert(err, context.mediator, 'Error registering with API provider')
	})
	.finally(() => {
		stateRouter.evaluateCurrentRoute('login')
	})
