export default ({ make, model, partManufacturer, partModel }) => {
	if (partManufacturer || partModel) {
		return {
			manufacturer: partManufacturer || '',
			model: partModel || '',
		}
	}

	return {
		manufacturer: make || '',
		model: model || '',
	}
}
