<script
	lang="ts"
	context="module"
>
	import type { Sale, DisplaySale, Payment, PaymentMethod, Customer, PaymentTreeNode, EditablePayment } from 'utility/payment-helper'
	import type { Mediator } from 'types/common'
	type PaymentAmountInputValue = Pick<EditablePayment, 'uuid' | 'amount' | 'saleId'>
	interface SalePaymentSum {
		saleId: number
		paymentsSum: string
	}

	import { getDefaultPayment, makeEditablePayment } from 'utility/payment-helper'
	import CurrencyInput from './CurrencyInput.svelte'
	import { tick, getContext, createEventDispatcher } from 'svelte'
	import financialSubsetSum from '@isoftdata/utility-financial-subset-sum'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Modal from '@isoftdata/svelte-modal'
	import Textarea from '@isoftdata/svelte-textarea'
	import { type Column, Table, Td } from '@isoftdata/svelte-table'
	import * as currency from '@isoftdata/utility-currency'
	import Icon from '@isoftdata/svelte-icon'
	//@ts-ignore
	import financialNumber from 'financial-number'
	import klona from 'klona'
	import { toLocaleDateString } from '@isoftdata/utility-date-time'
	//end module section
</script>

<script lang="ts">
	const formatCurrency = currency.format
	const mediator = getContext<Mediator>('mediator')
	const maxNumberOfUnbalancedSalesForAutomaticApplication = 15

	export let show = false
	export let customer: Customer
	export let sales: Sale[] = []
	export let enableFindSalesButton = false
	export let appliedSaleIds: number[] = []
	export let showAppliedSales: boolean = false
	export let paymentMethods: PaymentMethod[] = []
	export let initialCustomerBalance: string = '0'

	let payment: EditablePayment = getDefaultPayment(customer, paymentMethods)
	let paymentLines: EditablePayment[] = []
	let totalInput: HTMLInputElement
	let selectedSales: Sale[] = []
	let sumOfSelectedSales = '0'
	let selectedPaymentMethod: PaymentMethod | undefined = undefined
	let salePaymentSums: SalePaymentSum[] = []
	let addNewAllocationSelection: number | null = null
	let paymentAmountInputValues: PaymentAmountInputValue[] = []
	let allocatableSales: Sale[] = []
	let paymentLinesSum: string = '0'
	let isSaving: boolean = false
	let automaticallyCreateUnappliedPaymentLines: boolean = false
	let initialSumOfPaymentLines: string = '0'
	let unbalancedSales: Sale[] = []
	const salesAndAllocationsColumns: Column[] = [
		{ property: 'saleId', name: 'Sale #', numeric: true },
		{ property: 'documentDate', name: 'Date' },
		{ property: 'total', name: 'Sale Total', numeric: true },
		{ property: 'balance', name: 'Current Balance', numeric: true },
		{ property: 'amount', name: 'Payment Amount', numeric: true, width: '150px' },
		{ property: 'newBalance', name: 'Remaining Balance', numeric: true, sortType: false },
		{ property: 'action', name: 'Action', sortType: false },
	]

	const dispatch = createEventDispatcher<{
		/** Fired when a payment is successfully saved*/
		paymentSaved: Payment[]
	}>()

	async function resetState() {
		show = false
		payment = getDefaultPayment(customer, paymentMethods)
		paymentLines = []
		enableFindSalesButton = false
		showAppliedSales = false
		selectedSales = []
		appliedSaleIds = []
		initialSumOfPaymentLines = '0'
		await tick()
	}

	async function showModal() {
		show = true
		await tick()
		totalInput?.select()
	}

	export async function newPayment() {
		await resetState()

		payment = getDefaultPayment(customer, paymentMethods)
		automaticallyCreateUnappliedPaymentLines = true
		paymentLines = []

		enableFindSalesButton = true
		showAppliedSales = false

		showModal()
	}

	export async function editPayment(givenPayment: PaymentTreeNode) {
		await resetState()

		showAppliedSales = true
		payment = makeEditablePayment(givenPayment)

		const initialPaymentLines = givenPayment.paymentLines.map(paymentLine => makeEditablePayment(paymentLine))
		initialSumOfPaymentLines = getPaymentLinesSum(initialPaymentLines)

		paymentLines = initialPaymentLines
		enableFindSalesButton = true

		showModal()
	}

	export async function applyPayment(givenPayment: PaymentTreeNode, selectedSaleIds: number[]) {
		await resetState()

		if (confirm(`Are you sure you want to apply the following sales to payment #${givenPayment.paymentId}?\n${selectedSaleIds.join('\n')}`)) {
			if (selectedSaleIds.length === 1) {
				payment = makeEditablePayment({ ...givenPayment, saleId: selectedSaleIds[0] })
				paymentLines = [{ ...payment, action: 'update' }]
			} else {
				const saleIds = new Set(selectedSaleIds)
				const salesToHavePayments = sales.filter(sale => saleIds.has(sale.saleId))

				paymentLines = salesToHavePayments.reduce((acc: EditablePayment[], sale, index) => {
					if (index === 0) {
						const payment = makeEditablePayment({ ...givenPayment, saleId: sale.saleId, amount: sale.balance.toString(), parentPaymentId: -1 })
						return [{ ...payment, action: 'update' }]
					} else {
						const parentPayment = acc[0]
						const editablePayment = {
							...getDefaultPayment(customer, paymentMethods, {
								saleId: sale.saleId,
								amount: sale.balance,
								parentPaymentId: parentPayment.paymentId,
								documentDate: new Date(),
								checkAuthorizationNumber: parentPayment.checkAuthorizationNumber ?? '',
								paymentMethodName: parentPayment.paymentMethodName ?? '',
								comments: parentPayment.comments ?? '',
							}),
						}

						return [...acc, { ...editablePayment, action: 'insert' }]
					}
				}, [])
			}
		}

		confirmModal()
	}

	export async function newPaymentFromSales(selectedSaleIds: number[], hasExistingApplicablePayments: boolean) {
		await resetState()

		selectedSales = selectedSaleIds.reduce((acc: Sale[], saleId) => {
			const matchingSale = sales.find(sale => sale.saleId === saleId)
			if (matchingSale) {
				return [...acc, matchingSale]
			}
			return acc
		}, [])
		await tick()

		if (hasExistingApplicablePayments && confirm(`There are existing applicable payments for ${formatCurrency(sumOfSelectedSales)}. Are you sure you want to start a new payment?`) === false) {
			return
		}

		paymentLines = selectedSales.map(sale => ({
			...klona(getDefaultPayment(customer, paymentMethods)),
			amount: sale.balance.toString(),
			saleId: sale.saleId,
			action: 'insert',
		}))
		payment = {
			...getDefaultPayment(customer, paymentMethods, { amount: Number(sumOfSelectedSales) }),
			action: 'insert',
		}
		initialSumOfPaymentLines = '0'

		enableFindSalesButton = false
		showAppliedSales = false

		showModal()
	}

	async function confirmModal() {
		try {
			isSaving = true
			const savedPaymentLines = (await mediator.call('emitToServer', 'save payment group', {
				paymentGroup: paymentLines
					.filter(payment => {
						return !(payment.action === 'insert' && payment.amount === '0') //if they end up somehow trying to save a new payment for $0, just ignore it
					})
					.map(payment => {
						return {
							...payment,
							amount: Number(payment.amount),
							parentPaymentId: payment.parentPaymentId ?? -1,
						}
					}),
			})) as Payment[]
			dispatch('paymentSaved', savedPaymentLines)
			show = false
		} catch (err) {
			console.error(err)
		} finally {
			isSaving = false
		}
	}

	async function findSales(total: string) {
		const totalGreaterThanCustomerBalance = financialNumber(total).gt(initialCustomerBalance)
		const wantsToContinue: boolean = totalGreaterThanCustomerBalance
			? confirm(`The payment total(${formatCurrency(total)}) is greater than the customer\'s balance(${formatCurrency(initialCustomerBalance)}). Are you sure you want to continue?`)
			: true

		if (wantsToContinue) {
			const res = await findSalesForPaymentTotal(total)

			if (!res) {
				return
			}

			const { applications, remainder } = res

			paymentLines = klona(
				applications.map(application => {
					return {
						...klona(getDefaultPayment(customer, paymentMethods)),
						amount: application.paymentLine.amount,
						saleId: application.sale.saleId,
						action: 'insert',
					}
				}),
			)
			selectedSales = applications.map(application => {
				return {
					...application.sale,
					balance: parseFloat(application.sale.balance),
				}
			})
			if (wantsToContinue) {
				paymentLines = [...paymentLines, { ...getDefaultPayment(customer, paymentMethods), amount: remainder, action: 'insert' }]
			}
		}
	}

	type SaleWithStringifiedBalance = Omit<Sale, 'balance'> & { balance: string }
	interface Application {
		sale: SaleWithStringifiedBalance
		paymentLine: EditablePayment
	}

	async function findSalesForPaymentTotal(total: string): Promise<
		| {
				applications: Application[]
				remainder: string
		  }
		| undefined
	> {
		const salesWithStringBalance = sales.reduce((acc: SaleWithStringifiedBalance[], sale) => {
			const balance = sale.balance.toString()
			if (financialNumber(balance).gt('0')) {
				return [
					...acc,
					{
						...sale,
						balance,
					},
				]
			} else return acc
		}, [])

		if (salesWithStringBalance.length > 10 && !confirm(`With ${salesWithStringBalance.length} sales, this may take a while. Are you sure you want to continue?`)) {
			return
		}

		const { items, remainder } = financialSubsetSum(total, salesWithStringBalance, 'balance')

		let applications: Application[] = []
		applications.push(
			...items.map(sale => {
				return {
					sale,
					paymentLine: {
						...klona(getDefaultPayment(customer, paymentMethods)),
						amount: sale.balance.toString(),
						saleId: sale.saleId,
					},
				}
			}),
		)

		return {
			applications,
			remainder,
		}
	}

	function getSalePaymentSums(sales: DisplaySale[], paymentAmountInputValues: PaymentAmountInputValue[]): SalePaymentSum[] {
		return sales.map(sale => {
			const salePayments = paymentAmountInputValues.filter(payment => payment.saleId === sale.saleId)
			return {
				saleId: sale.saleId,
				paymentsSum: salePayments
					.reduce((acc: typeof financialNumber, payment) => {
						return acc.plus(payment.amount || '0')
					}, financialNumber('0'))
					.toString(),
			}
		})
	}

	function getDisplaySales(sales: Sale[]): DisplaySale[] {
		return sales.map(sale => {
			return {
				...sale,
				salesperson: sale.salesperson ?? '',
				total: financialNumber(sale.subtotal?.toString() ?? '0')
					.plus(sale.tax?.toString() ?? '0')
					.toString() as string,
			}
		})
	}

	function addNewSaleAllocation(event: Event) {
		if (!(event.target instanceof HTMLSelectElement)) {
			return
		}
		const saleId = Number(event.target.value)
		const sale = sales.find(sale => sale.saleId === saleId)
		if (sale) {
			paymentLines.push({
				...klona(getDefaultPayment(customer, paymentMethods)),
				saleId: sale.saleId,
				amount: sale.balance.toString(),
				parentPaymentId: payment.paymentId,
				action: 'insert',
			})
			paymentLines = paymentLines
		}
		updateVirtualParentAmount()
		addNewAllocationSelection = null
	}

	function updateVirtualParentAmount() {
		payment.amount = paymentLines
			.reduce((acc, line) => {
				acc = line.action === 'delete' ? acc : acc.plus(line.amount)
				return acc
			}, financialNumber('0'))
			.toString(2)
	}

	function updatePaymentLines(partialPaymentInfo: Partial<EditablePayment>) {
		paymentLines = paymentLines.map(line => {
			return {
				...line,
				...partialPaymentInfo,
			}
		})
	}

	function updatePaymentAllocations(amount: string) {
		payment.amount = financialNumber(amount).toString(2)
		if (automaticallyCreateUnappliedPaymentLines) {
			const unappliedPaymentIndex = paymentLines.findIndex(payment => !payment.saleId)
			if (unappliedPaymentIndex > -1 && paymentLines[unappliedPaymentIndex]?.action !== 'delete') {
				paymentLines[unappliedPaymentIndex] = {
					...paymentLines[unappliedPaymentIndex],
					amount: financialNumber(amount).toString(2),
					action: paymentLines[unappliedPaymentIndex]?.paymentId ? 'update' : 'insert',
				}
			} else {
				const newPaymentLine = getDefaultPayment(customer, paymentMethods, { amount: Number(amount) })
				paymentLines.push({ ...newPaymentLine, amount: financialNumber(newPaymentLine.amount).toString(2), action: 'insert' })
				paymentLines = paymentLines
			}
		}
	}

	$: sumOfSelectedSales = selectedSales.reduce((acc, sale) => acc.plus(sale.balance), financialNumber('0')).toString()
	$: appliedSaleIds = paymentLines.map(line => line.saleId).filter((saleId): saleId is number => !!saleId)
	$: selectedPaymentMethod = paymentMethods.find(method => method.name === payment?.paymentMethodName)
	$: displaySales = getDisplaySales(sales)
	$: paymentAmountInputValues = paymentLines.map(({ uuid, amount, saleId }) => {
		return {
			uuid,
			amount,
			saleId,
		}
	})
	$: salePaymentSums = getSalePaymentSums(displaySales, paymentAmountInputValues)
	$: allocatableSales = sales.filter(sale => {
		return !appliedSaleIds.includes(sale.saleId) && sale.balance > 0
	})

	function updateLiveCustomerBalance(initialCustomerBalance: string, initalSumOfPaymentLines: string, paymentLines: EditablePayment[]) {
		return financialNumber(initialCustomerBalance).plus(initalSumOfPaymentLines).minus(getPaymentLinesSum(paymentLines)).toString(2)
	}

	function getPaymentLinesSum(paymentLines: EditablePayment[]): string {
		return paymentLines
			.reduce((acc, line) => {
				return line.action === 'delete' ? acc : acc.plus(line.amount)
			}, financialNumber('0'))
			.toString(2)
	}

	$: paymentLinesSum = getPaymentLinesSum(paymentLines)
	$: liveCustomerBalance = updateLiveCustomerBalance(initialCustomerBalance, initialSumOfPaymentLines, paymentLines)
	$: unbalancedSales = sales.filter(sale => sale.balance > 0)
</script>

<Modal
	bind:show
	title="{payment.paymentId ? 'Edit' : 'Create'} Payment"
	modalSize="lg"
	cancelButtonText="Cancel"
	confirmButtonText="Save Payment"
	confirmButtonDisabled={!payment.paymentMethodName || !payment.documentDate || financialNumber(payment.amount).equal('0') || !financialNumber(payment.amount).equal(paymentLinesSum)}
	on:close={() => (show = false)}
	on:confirm={() => confirmModal()}
>
	{#if financialNumber(liveCustomerBalance).lt('0')}
		<p
			style="font-size: smaller"
			class="font-weight-bold mb-0 text-danger"
		>
			<Icon icon="triangle-exclamation" /> Are you sure you want the customer to have a credit on their account?
		</p>
	{/if}
	<div class="form-row">
		<div class="col-12 col-lg-4">
			<CurrencyInput
				label="Payment Total"
				placeholder="0.00"
				value={payment.amount}
				bind:input={totalInput}
				on:change={event => {
					payment.amount = event.detail
				}}
				on:change={e => updatePaymentAllocations(e.detail)}
				required
				disabled={isSaving}
			/>
			<div class="border rounded p-2 mt-2">
				<Button
					icon="chart-network"
					outline
					block
					color="primary"
					size="sm"
					disabled={!enableFindSalesButton || unbalancedSales.length === 0 || unbalancedSales.length > maxNumberOfUnbalancedSalesForAutomaticApplication || isSaving}
					onclick={() => findSales(payment.amount)}>Apply Payment to Sales</Button
				>
				<p
					class="mb-0 text-muted mt-1"
					style="font-size: small;"
				>
					Automagically find corresponding sales for this payment
					{#if unbalancedSales.length > maxNumberOfUnbalancedSalesForAutomaticApplication}
						<span class="d-block text-danger">
							<Icon icon="triangle-exclamation" /> Due to the number of unbalanced sales, this feature is unavailable.
						</span>
					{:else if unbalancedSales.length === 0}
						<span class="d-block text-info">No sales to apply</span>
					{/if}
				</p>
			</div>
		</div>
		<div class="col-12 col-lg-4">
			<Select
				label="Method"
				required
				bind:value={payment.paymentMethodName}
				options={paymentMethods}
				emptyValue=""
				onchange={() => updatePaymentLines({ paymentMethodName: payment.paymentMethodName })}
				disabled={isSaving}
				validation={{
					validator: value => !!value,
					value: payment.paymentMethodName,
				}}
			>
				{#snippet option({ option })}
					<option value={option?.name}>{option?.name}</option>
				{/snippet}
			</Select>
			<Input
				label="Date"
				required
				bind:value={payment.documentDate}
				type="date"
				onchange={() => updatePaymentLines({ documentDate: payment.documentDate })}
				disabled={isSaving}
				validation={{
					validator: value => !!value,
					value: payment.documentDate,
				}}
			/>
		</div>
		<div class="col-12 col-lg-4">
			<div>
				<Input
					label="Check/Auth #"
					bind:value={payment.checkAuthorizationNumber}
					onchange={() => updatePaymentLines({ checkAuthorizationNumber: payment.checkAuthorizationNumber })}
					disabled={isSaving}
				/>
				{#if selectedPaymentMethod?.usesCheckAuthorizationNumber && !payment.checkAuthorizationNumber}
					<small class="text-danger">Please include check or auth #</small>
				{/if}
			</div>
			<Textarea
				label="Comments"
				bind:value={payment.comments}
				on:change={() => updatePaymentLines({ comments: payment.comments })}
				disabled={isSaving}
			/>
		</div>
		<div
			class="card mt-2"
			style="width: 100%;"
		>
			<div class="card-header d-flex justify-content-between align-items-end">
				<div class="h4 mb-0">Payment Allocations</div>
				<small
					class:text-danger={!financialNumber(liveCustomerBalance).equal('0')}
					class:text-success={financialNumber(liveCustomerBalance).equal('0')}>Customer Balance: {formatCurrency(liveCustomerBalance)}</small
				>
			</div>
			{#if paymentLines?.length}
				<Table
					rows={paymentLines}
					columns={salesAndAllocationsColumns}
					responsive
				>
					{#snippet body({ rows })}
						{#each rows as paymentLine (paymentLine.uuid)}
							{@const sale = sales.find(sale => sale.saleId === paymentLine.saleId)}
							{@const paymentsSum = sale ? salePaymentSums.find(saleBalance => saleBalance.saleId === paymentLine.saleId)?.paymentsSum || '0' : '0'}
							{@const paymentAmountIndex = paymentAmountInputValues.findIndex(input => input.uuid === paymentLine.uuid)}
							{@const liveBalance = sale
								? financialNumber(sale.total.toString())
										.minus(paymentLine.action === 'delete' ? '0' : paymentsSum || '0')
										.toString(2)
								: null}
							<tr
								class:font-italic={paymentLine.action === 'delete'}
								class:text-muted={paymentLine.action === 'delete'}
							>
								<Td property="saleId">
									{#if paymentLine.saleId}
										{paymentLine.saleId}
									{:else}
										<span class="text-muted font-italic">Unapplied</span>
									{/if}

									{#if paymentLine.action === 'delete'}
										<br />
										<span
											style="font-size: small;"
											class="text-danger font-italic">Deleted</span
										>
									{/if}
								</Td>
								<Td property="documentDate">{sale?.documentDate ? toLocaleDateString(sale.documentDate) : 'N/A'}</Td>
								<Td property="total">{formatCurrency(sale?.total ?? '0')}</Td>
								<Td property="balance">{formatCurrency(sale?.balance ?? '0')}</Td>
								<Td
									property="amount"
									class="font-weight-bold"
								>
									<CurrencyInput
										showLabel={false}
										value={financialNumber(paymentLines?.[paymentLine?.originalIndex]?.amount || '0').toString(2)}
										disabled={paymentLine.action === 'delete' || isSaving}
										on:change={event => {
											const newAmount = event.detail
											paymentLines[paymentLine.originalIndex] = {
												...paymentLines[paymentLine.originalIndex],
												amount: newAmount,
												action: paymentLines[paymentLine.originalIndex].action === 'insert' ? 'insert' : 'update',
											}
											updateVirtualParentAmount()
										}}
										on:input={e => {
											paymentAmountInputValues[paymentAmountIndex] = { ...paymentAmountInputValues[paymentAmountIndex], amount: e.detail }
										}}
									/>
								</Td>
								<Td
									property="newBalance"
									class={liveBalance ? (financialNumber(liveBalance).equal('0') ? 'text-success' : 'text-danger') : ''}
								>
									{liveBalance ? formatCurrency(liveBalance) : 'N/A'}
								</Td>
								<td>
									<Button
										icon={paymentLines[paymentLine.originalIndex].action === 'delete' ? 'trash-undo' : 'trash-alt'}
										outline
										color={paymentLines[paymentLine.originalIndex].action === 'delete' ? 'primary' : 'danger'}
										size="sm"
										title={paymentLines[paymentLine.originalIndex].action === 'delete' ? 'Undo Delete' : 'Delete'}
										onclick={() => {
											paymentLines[paymentLine.originalIndex].action =
												paymentLines[paymentLine.originalIndex].action === 'delete' ? (paymentLines[paymentLine.originalIndex]?.paymentId ? 'update' : 'insert') : 'delete'
											updateVirtualParentAmount()
										}}
									/>
								</td>
							</tr>
						{/each}
					{/snippet}
				</Table>
			{:else}
				<div class="card-body">
					<h5 class="text-muted">No Existing Payment Allocations</h5>
					<p class="text-muted font-italic mb-0">Use the "Add New Sale Allocation" dropdown below</p>
				</div>
			{/if}
		</div>
		<Select
			label="Add New Sale Allocation"
			title="Choose a sale to allocate a portion of this payment to"
			bind:value={addNewAllocationSelection}
			onchange={addNewSaleAllocation}
			disabled={!allocatableSales.length}
			emptyText={allocatableSales.length ? undefined : '🎉 All Sales Are Allocated!'}
		>
			{#each allocatableSales as sale}
				{#if !appliedSaleIds.includes(sale.saleId) && sale.balance > 0}
					<option value={sale.saleId}>Sale #{sale.saleId} - {toLocaleDateString(sale.documentDate || new Date())}{sale.balance ? ` - Balance: ${formatCurrency(sale.balance)}` : ''} </option>
				{/if}
			{/each}
		</Select>
	</div>
	<p
		style="font-size: smaller"
		class="font-weight-bold mb-0 mt-2"
	>
		* Bold fields are required
	</p>
	{#if !financialNumber(payment.amount).equal(paymentLinesSum)}
		<p
			style="font-size: smaller"
			class="font-weight-bold mb-0 text-danger"
		>
			<Icon icon="triangle-exclamation" /> Payment amount does not match the sum of payment allocations
		</p>
	{/if}
</Modal>
