import template from './login.html'
import pProps from 'p-props'
import makeStorageHelper from 'utility/storage-json-helper.ts'

//Ractive components
import makeChangePasswordModal from 'components/change-password'
import makeLogin from '@isoftdata/login-component'

//Ractive transitions
import ractiveTransitionsSlide from 'ractive-transitions-slide'

const localStorageJSON = makeStorageHelper('localStorage')

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		data: {},
		template: {
			template,
			components: {
				login: makeLogin(),
				changePasswordModal: makeChangePasswordModal({ mediator, logAndAlert }),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
		},
		querystringParameters: [ 'message', 'userName', 'passwordResetToken' ],
		resolve(data, { message, userName, passwordResetToken }) {
			localStorage.removeItem('session')

			let session = localStorageJSON.getItem('session')

			if (session?.token) {
				mediator.call('noAuthEmitToServer', 'close session', { token: session.token })
			}

			return pProps({
				stores: mediator.call('noAuthEmitToServer', 'load store key value pairs', {}),
				storeId: 1,
				messageAlertBootstrapClass: 'danger',
				messageFontAwesomeIcon: 'fa-exclamation-circle',
				messageBoxOpacity: 1,
				messageText: message,
				restrictedToStoreId: undefined,
				userName: userName || localStorage.getItem('lastUsername') || '',
				lastStoreId: localStorage.getItem('lastStoreId') || '',
				passwordResetToken: (userName && passwordResetToken) ? passwordResetToken : undefined,
			})
		},
		activate({ domApi: ractive }) {
			const { userName, lastStoreId } = ractive.get('')

			if (!userName) {
				localStorage.removeItem('lastUsername')
			}

			if (lastStoreId) {
				ractive.set({ storeId: lastStoreId })
			}

			const changePasswordModal = ractive.findComponent('changePasswordModal')

			//If we have a password reset token, automatically show the
			//change password modal to start that flow for the user
			const resetToken = ractive.get('passwordResetToken')
			if (resetToken) {
				changePasswordModal.show({
					state: 'FIRST_TIME_PASSWORD_SET',
					resetToken,
					account: {
						userName,
					},
				})
			}

			ractive.on('initiatePasswordRecovery', async({ username: userName }) => {
				changePasswordModal.show({ state: 'RECOVERY_EMAIL_SENDING' })

				try {
					const account = await mediator.call('noAuthEmitToServer', 'initiate password recovery', {
						userName,
					}, { doNotAlert: true })

					changePasswordModal.show({
						state: 'RECOVERY_EMAIL_SENT',
						account,
					})
				} catch (err) {
					console.error(err)
					logAndAlert(err, mediator, 'Error resetting password', 'Error resetting password', false)
					ractive.findComponent('login').setAlert(`Error resetting your password. Contact your ITrack admin.`)

					return changePasswordModal.set({ show: false })
				}
			})

			ractive.on('login', async({ username: userName, password: passphrase, siteId: storeId }, e) => {
				e.preventDefault()

				const restrictedToStoreId = ractive.get('restrictedToStoreId')
				if (restrictedToStoreId) {
					storeId = restrictedToStoreId
				}

				await ractive.set({ isLoading: true })
				try {
					const auth = await mediator.call('noAuthEmitToServer', 'authenticate', {
						userName,
						passphrase,
						storeId,
					}, { doNotAlert: true, context: 'login' })

					if (ractive.get('rememberMe')) {
						localStorage.setItem('lastUsername', userName)
						localStorage.setItem('lastStoreId', storeId)
					} else {
						localStorage.removeItem('lastUsername')
					}

					if (auth.authenticated && auth.session.token) {
						localStorage.setItem('session', JSON.stringify(auth.session))
						stateRouter.go('app')
					} else {
						ractive.findComponent('login').invalidLogin()
					}
				} catch (err) {
					console.error(err)
					ractive.set({ isLoading: false })
					return ractive.findComponent('login').invalidLogin()
				}
			})

			ractive.observe('userName', async username => {
				if (username) {
					try {
						const restrictedToStoreId = await mediator.call('noAuthEmitToServer', 'get store restictions for user', { username })
						ractive.set({ restrictedToStoreId })
					} catch (err) {
						console.error('Error getting store restrictions for user', err)
					}
				}
			})

			ractive.findComponent('changePasswordModal').on('passwordChanged', passphrase => {
				ractive.set({ passphrase }).then(() => ractive.find('#loginButton').click())
			})
		},
	})
}
