import template from './not-found.html'

export default function({ stateRouter }) {
	stateRouter.addState({
		name: 'not-found',
		route: 'not-found',
		querystringParameters: [ 'route', 'parameters' ],
		template: {
			template,
		},
		activate({ domApi: ractive, parameters }) {
			ractive.set('route', parameters.route)
			ractive.set('parameters', parameters.parameters)
		},
	})
}
