<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import DateRange from '@isoftdata/svelte-date-range'
	import { type ComponentProps, type ComponentEvents } from 'svelte'
	import { toLocaleDateString } from '@isoftdata/utility-date-time'
	import { format as formatCurrency } from '@isoftdata/utility-currency'

	export let asr: SvelteAsr
	export let range: ComponentProps<DateRange>['range']
	export let dates: ComponentProps<DateRange>['dates']

	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import { getContext } from 'svelte'
	import NoItemsForSelectedFilter from 'components/NoItemsForSelectedFilter.svelte'
	import { booleanToYesNo } from '@isoftdata/utility-string'

	const mediator = getContext<Mediator>('mediator')

	interface VehicleBid {
		closed: boolean
		bidAccepted: boolean
		vendorCompany: String
		vendorContact: String
		trackingNumber: number
		dateCreated: Date
		amount: number
		username: String
		contactName: String
		contactPhoneNumber: String
	}
	export let vehicleBids: Array<VehicleBid>

	$: displayVehicleBids = vehicleBids.map(vehicleBid => {
		const { closed, bidAccepted, vendorCompany, vendorContact } = vehicleBid

		return {
			...vehicleBid,
			displayVendorName: vendorCompany || vendorContact,
			closed: booleanToYesNo(closed),
			bidAccepted: booleanToYesNo(bidAccepted),
		}
	})

	async function dateChange(event: ComponentEvents<DateRange>['change']) {
		const dates = event.detail.dates
		asr.go(null, { fromDate: dates.from, toDate: dates.to }, { inherit: true })
		if (range !== 'Custom') {
			await mediator.call('emitToServer', 'save user setting', {
				name: 'Chromium Home Screen Date Range',
				category: 'Home',
				defaultValue: 'Last 7 Days',
				settingType: 'Interface History',
				value: range,
			})
		}
	}

	const columns: Array<Column> = [
		{ property: 'trackingNumber', name: 'Tracking #', numeric: true, width: '1rem', sortType: 'ALPHA_NUM' },
		{ property: 'dateCreated', name: 'Date', defaultSortColumn: true, defaultSortDirection: 'DESC' },
		{ property: 'closed', name: 'Closed' },
		{ property: 'bidAccepted', name: 'Accepted' },
		{ property: 'amount', name: 'Amount', numeric: true },
		{ property: 'username', name: 'Created By' },
		{ property: 'displayVendorName', name: 'Vendor' },
		{ property: 'contactName', name: 'Contact' },
		{ property: 'contactPhoneNumber', name: 'Contact Phone #' },
	]
</script>

<div class="card-body p-2 card-body-p2">
	<div class="form-row card-body-p2-form-row">
		<div class="col-md-3 col-lg-2">
			<div
				class="card"
				style="margin-bottom: 20px;"
			>
				<h5 class="card-header">Filters</h5>
				<div class="card-body p-2">
					<DateRange
						bind:range
						bind:dates
						on:change={dateChange}
					></DateRange>
				</div>
			</div>
		</div>
		<div
			class="col-md-9 col-lg-10 tab-pane"
			id="sales"
			role="tab"
		>
			{#if displayVehicleBids.length > 0}
				<Table
					responsive
					{columns}
					perPageCount={15}
					rows={displayVehicleBids}
				>
					{#snippet children({ row })}
						<tr class="cursor-pointer">
							<Td property="trackingNumber">{row.trackingNumber}</Td>
							<Td property="dateCreated">{toLocaleDateString(row.dateCreated)}</Td>
							<Td property="closed">{row.closed}</Td>
							<Td property="bidAccepted">{row.bidAccepted}</Td>
							<Td property="amount">{formatCurrency(row.amount)}</Td>
							<Td property="username">{row.username ?? ''}</Td>
							<Td property="displayVendorName">{row.displayVendorName ?? ''}</Td>
							<Td property="contactName">{row.contactName}</Td>
							<Td property="contactPhoneNumber">{row.contactPhoneNumber ?? ''}</Td>
						</tr>
					{/snippet}
				</Table>
			{:else}
				<div class="well">
					<NoItemsForSelectedFilter>Vehicle Bids</NoItemsForSelectedFilter>
				</div>
			{/if}
		</div>
	</div>
</div>
