import template from './sale.html'
import pProps from 'p-props'
import * as dateTimeUtility from '@isoftdata/utility-date-time'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive components
import makeReportSelectionModal from 'components/report-selection'
import makeDateRange from '@isoftdata/date-range'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeModal from '@isoftdata/modal'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.entity.sale',
		route: 'sale',
		defaultChild: 'line',
		querystringParameters: [ 'customerId', 'fromDate', 'toDate', 'selectedSaleId', 'showVoid' ],
		defaultParameters: {
			showVoid: false,
		},
		template: {
			data: {},
			template,
			components: {
				itDateRange: makeDateRange(mediator),
				itTable: makeTable(),
				voidSaleConfirmationModal: makeModal(),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
				itButton: makeButton(),
			},
			computed: {
				displaySales() {
					const showVoid = this.get('showVoid')

					const sales = this.get('sales').filter(sale => {
						return showVoid ? true : sale.status !== 'Void'
					})

					return sales.map(({ saleId, documentDate, subtotal, tax, paid, paymentMethod, salesperson, status, appliedCredit, quoteId }) => {
						return {
							saleId,
							documentDate,
							subtotal,
							tax,
							total: subtotal + tax,
							paid,
							paymentMethod,
							balance: ((subtotal + tax) - paid - appliedCredit).toFixed(2),
							salesperson,
							status,
							appliedCredit,
							quoteId,
						}
					})
				},
				selectedSale() {
					return this.get('displaySales').find(sale => this.get('selectedSaleId') === sale.saleId)
				},
			},
		},
		async resolve(data, parameters) {
			let { id, fromDate, toDate, selectedSaleId, showVoid } = parameters

			if (!fromDate && !toDate) {
				const defaultDates = dateTimeUtility.datesFromRange('Last 90 Days')

				throw {
					redirectTo: {
						name: null,
						params: {
							...parameters, fromDate: defaultDates.from,
							toDate: defaultDates.to,
						},
					},
				}
			}

			return await pProps({
				sales: mediator.call('emitToServer', 'load sales', { customerId: id, fromDate, toDate }),
				showVoid: stringToBoolean(showVoid),
				selectedSaleId: selectedSaleId ? parseInt(selectedSaleId, 10) : false,
				customerId: parseInt(parameters.customerId, 10),
				range: dateTimeUtility.rangeFromDates(parameters.fromDate, parameters.toDate),
				dates: { from: parameters.fromDate, to: parameters.toDate },
				hasVoidInvoicePermission: hasPermission('PM_INVOICE_VOID'),
			})
		},
		activate({ domApi: ractive, parameters }) {
			ractive.observe('selectedSale', v => console.log(v))

			stateRouter.on('stateChangeEnd', state => {
				if (state.name === 'app.entity.sale.line') {
					ractive.set('childState', 'line')
				} else if (state.name === 'app.entity.sale.payment') {
					ractive.set('childState', 'payment')
				}
			})

			ractive.on('CONFIRM_VOID_SALE', async(context, customerId, saleId) => {
				try {
					await mediator.call('emitToServer', 'void sale', { saleId })
					const sales = await mediator.call('emitToServer', 'load sales', { customerId })
					ractive.set({
						sales,
						showVoidModal: false,
					})
				} catch (err) {
					logAndAlert(err, mediator, 'Error voiding sale')
				}
			})

			ractive.observe('showVoid', showVoid => stateRouter.go(null, { showVoid }, { inherit: true }, { init: false }))
			ractive.observe('selectedSaleId', saleId => stateRouter.go(null, { saleId }, { inherit: true }), { init: false })

			ractive.on('PRINT_SALE', (context, saleId) => {
				const reportParameters = [
					{ parameterName: 'invoicenum', value: parseInt(saleId, 10) },
				]

				const reportJob = { type: 'Invoice', reportParameters }

				const emailAddress = ractive.get('entity')?.emailAddress
				const emailSuggestions = emailAddress ? { [emailAddress]: emailAddress } : undefined

				ractive.findComponent('reportSelectionModal').printReport(reportJob, emailSuggestions)
			})

			ractive.on('itDateRange.dateChange', (context, dates) => {
				stateRouter.go(null, {
					...parameters, fromDate: dates.from,
					toDate: dates.to,
				})
			})
		},
	})
}
