export function getEntityDisplayName(entity) {
	const entityDisplayName = {
		billTo: '',
		shipTo: '',
	}

	const name = (entity, props) => (entity[props[0]] || entity[props[1]])

	if (entity) {
		entityDisplayName.billTo = name(entity, [ 'billingCompany', 'billingContact' ])
		entityDisplayName.shipTo = name(entity, [ 'shippingCompany', 'shippingContact' ])
	}

	return entityDisplayName
}
