import template from './attachment.html'
import makeAttachmentFns from 'utility/attachment-helper.ts'

//Ractive Components
import makeAttachment from '@isoftdata/attachment'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter, hasPermission }) {
	stateRouter.addState({
		name: 'app.part.attachment',
		route: 'attachment',
		querystringParameters: [ 'inventoryId', 'storeId' ],
		defaultParameters: { 'storeId': 1 },
		template: {
			template,
			components: {
				partAttachment: makeAttachment(makeAttachmentFns(mediator)),
				interchangeAttachment: makeAttachment(makeAttachmentFns(mediator)),
				vehicleAttachment: makeAttachment(makeAttachmentFns(mediator)),
				itButton: makeButton(),
			},
		},
		async resolve(data, { inventoryId }) {
			let part = await mediator.call('emitToServer', 'load inventory', { inventoryId })
			part = part?.[0]

			const fileAssociation = {
				type: 'INVENTORY',
				key: {
					inventoryId: Number(inventoryId),
				},
			}

			const interchangeFileAssociation = {
				type: 'INTERCHANGE',
				key: {
					inventoryTypeId: part?.inventoryTypeId,
					interchangeNumber: part?.interchangeNumber,
				},
			}

			const vehicleFileAssociation = {
				type: 'VEHICLE',
				key: {
					vehicleId: part?.vehicleId,
				},
			}

			const [ fileList, vehicleFileList, interchangeFileList, viewMode ] = await Promise.all([
				part ? mediator.call('emitToServer', 'load file info list', { association: fileAssociation }) : [],
				part?.vehicleId ? mediator.call('emitToServer', 'load file info list', { association: vehicleFileAssociation }) : [],
				(part?.inventoryTypeId && part?.interchangeNumber) ? mediator.call('emitToServer', 'load file info list', { association: interchangeFileAssociation }) : [],
				mediator.call('emitToServer', 'check user setting', {
					name: 'Part Attachment Screen View Mode',
					category: 'Options',
					settingType: 'Interface History',
					defaultValue: 'THUMBNAIL-MD',
				}),
			])

			return {
				viewMode,
				part,
				fileList,
				interchangeFileList,
				vehicleFileList,
				fileAssociation,
				interchangeFileAssociation,
				vehicleFileAssociation,
				canEditAttachments: hasPermission('PM_PART_IMAGE_ATTACHMENT_ADD') && hasPermission('PM_PART_IMAGE_ATTACHMENT_DELETE'),
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('associateWithPart', (context, attachmentComponentName) => {
				ractive.findComponent('partAttachment')
					.associateExistingFiles(ractive.findComponent(attachmentComponentName).getSelectedFileList())

				ractive.findComponent(attachmentComponentName)
					.set({ fileSelections: [] })
			})

			ractive.observe('viewMode', value => {
				mediator.call('emitToServer', 'save user setting', {
					name: 'Part Attachment Screen View Mode',
					category: 'Options',
					type: 'Interface History',
					value,
				}, { init: false })
			})
		},
	})
}
