import template from './part-manufacturer-modal.html'
import klona from 'klona'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'

const newManufacturer = {
	name: '',
	partManufacturerId: undefined,
}

export default function createPartManufacturerModalComponent({ mediator, logAndAlert }) {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				manufacturer: {},
				isSaving: false,
				show: false,
			}
		},
		attributes: {
			optional: [
				'manufacturer',
				'show',
			],
		},
		components: {
			itInput: makeInput({ twoway: true, lazy: false }),
			itModal: makeModal(),
		},
		async show(manufacturer) {
			if (!manufacturer) {
				manufacturer = klona(newManufacturer)
			}

			await this.set({ manufacturer, show: true })
			this.find('#partManufacturerModalInput').select()
		},
		async save(manufacturer, event) {
			const ractive = this

			if (event) {
				event.preventDefault()
			}

			ractive.set({ isSaving: true })
			try {
				const savedManufacturer = await mediator.call('emitToServer', 'save part manufacturer', { manufacturer }, { doNotAlert: true })
				ractive.set({ show: false })
				ractive.fire('manufacturerSaved', { manufacturer: savedManufacturer })
			} catch (err) {
				if (err.code === 'ER_DUP_ENTRY') {
					// If they give a duplicate name, add that to the manu list so they can add a model
					const loadedManufacturer = await mediator.call('emitToServer', 'load part manufacturer by name', { name: manufacturer.name })
					ractive.set({ show: false })
					ractive.fire('manufacturerSaved', { manufacturer: loadedManufacturer })
				} else {
					logAndAlert(err, mediator)
				}
			} finally {
				ractive.set({ isSaving: false })
			}
		},
	})
}
