import template from './inventory-question.html'
import klona from 'klona'

//Ractive components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeSelect from '@isoftdata/select'
import makeModal from '@isoftdata/modal'

const defaultQuestion = Object.freeze({
	text: '',
	type: 'Text',
})

export default function createInventoryQuestionComponent({ mediator, logAndAlert } = {}) {
	return Ractive.extend({
		template,
		twoway: false,
		data() {
			return {
				questionList: [],
				includedQuestions: [],
				selectedQuestion: {},
				addEditQuestionModal: {
					shown: false,
					question: {},
					isSaving: false,

				},
				inventoryTypeId: 0,
				categoryId: 0,
				focusAddButtonOnInit: false,
			}
		},
		attributes: {
			required: [
				'questionList',
				'inventoryTypeId',
			],
			optional: [
				'categoryId',
				'focusAddButtonOnInit',
			],
		},
		components: {
			itTable: makeTable(),
			itButton: makeButton(),
			itModal: makeModal(),
			itInput: makeInput({ twoway: true, lazy: false }),
			itSelect: makeSelect({ twoway: true, lazy: false }),
		},
		async showQuestionModal(question) {
			const ractive = this
			await ractive.set({
				addEditQuestionModal: {
					shown: true,
					question: klona(question || {
						...defaultQuestion,
						order: ractive.get('questionList').length + 1,
						inventoryTypeId: parseInt(ractive.get('inventoryTypeId'), 10),
						categoryId: parseInt(ractive.get('categoryId'), 10) || null,
					}),
				},
			})

			ractive.find('#newQuestion').select()
		},
		async addEditQuestionConfirm(question, event) {
			const ractive = this

			event?.preventDefault()
			this.set({ 'addEditQuestionModal.isSaving': true })

			try {
				console.log(question)
				const savedInventoryQuestion = await mediator.call('emitToServer', 'save inventory question', { question })
				ractive.upsert('questionList', 'inventoryQuestionId', savedInventoryQuestion)
				ractive.set({
					'addEditQuestionModal.shown': false,
					'selectedQuestion': savedInventoryQuestion,
				})
				ractive.focusAddButton()
			} catch (err) {
				logAndAlert(err, mediator, 'Error saving inventory question')
			} finally {
				ractive.set({ 'addEditQuestionModal.isSaving': false })
			}
		},
		async deleteQuestion({ inventoryQuestionId, text }) {
			const ractive = this

			if (confirm(`Are you sure you want to delete the "${text}" question?`)) {
				try {
					if (!inventoryQuestionId) {
						throw new Error('No inventoryQuestionId!')
					}
					await mediator.call('emitToServer', 'delete inventory question', { inventoryQuestionId })
					const questionIndex = this.get('questionList').findIndex(question => question.inventoryQuestionId === inventoryQuestionId)

					ractive.splice('questionList', questionIndex, 1)
					ractive.focusAddButton()
				} catch (err) {
					logAndAlert(err, mediator, 'Error deleting question')
				}
			}
		},
		focusAddButton() {
			this.find('#addQuestionButton').focus()
		},
		oninit() {
			const ractive = this

			//if they delete an inventory question or otherwise manage]
			//to make a question go away, ensure that the "selected category"
			// isn't gone from that array
			ractive.observe('questionList selectedQuestion', () => {
				const questionList = ractive.get('questionList')
				const selectedQuestion = ractive.get('selectedQuestion')
				if (selectedQuestion && selectedQuestion.inventoryQuestionId) {
					const match = questionList.find(question => question.inventoryQuestionId === selectedQuestion.inventoryQuestionId)
					if (!match) {
						ractive.set({ selectedQuestion: {} })
					} else {
						ractive.find(`#inventory_question_id_${selectedQuestion.inventoryQuestionId}`)?.scrollIntoView({
							block: 'center',
							inline: 'center',
							behavior: 'smooth',
						})
					}
				}
			})
		},
		oncomplete() {
			//this lifecycle event is fired after everything is rendered to the DOM
			//and all transitions are complete

			const ractive = this

			if (ractive.get('focusAddButtonOnInit')) {
				ractive.focusAddButton()
			}
		},
	})
}
