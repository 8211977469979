<script lang="ts">
	import type { Mediator } from 'types/common'
	import type { Report, Printer, ReportJob } from '@isoftdata/svelte-report-job-modal'

	import ReportJobModal from '@isoftdata/svelte-report-job-modal'
	import { getContext, type ComponentProps } from 'svelte'
	import { logAndAlert } from 'utility/error-handler'
	import makeStorageHelper from 'utility/storage-json-helper'

	export let showPreview = true

	const { getItem } = makeStorageHelper('localStorage')

	const mediator = getContext<Mediator>('mediator')
	const reportJobDestinationTypeMap = new Map([
		['DIRECTORY', 'Directory'],
		['EMAIL', 'Email'],
		['PRINTER', 'Printer'],
	])

	type OpenFn = Exclude<ComponentProps<ReportJobModal>['open'], undefined>
	type Params = Parameters<OpenFn>

	let internalModal: ReportJobModal

	export async function open(...args: Params) {
		const contextEmails = args[1]?.emails?.filter(e => e) ?? []
		args[1] ??= {}
		if (contextEmails.length) {
			args[1].emails = contextEmails
		} else if (!contextEmails.length) {
			const emailRes = (await mediator.call('emitToServer', 'load recent email addresses', {})) as Array<{ emailAddress: string }>
			args[1].emails = emailRes.map(e => e.emailAddress)
		}
		await internalModal.open(...args)
	}

	async function loadReports(reportType: string | null): Promise<Report[]> {
		interface ReportFromApi {
			reportId: number
			name: string
			type: string
		}

		const reports = (await mediator.call('emitToServer', 'load reports', { type: reportType })) as ReportFromApi[]

		return reports.map((report): Report => {
			return {
				id: report.reportId,
				name: report.name,
				type: report.type,
			}
		})
	}

	async function loadPrinters(reportType: string): Promise<Printer[]> {
		return (await mediator.call('emitToServer', 'load printers', { type: reportType, storeId: getItem('session')?.storeId || 1 })) as Printer[]
	}

	async function loadDefaultPrinter(reportType: string): Promise<string> {
		interface ReportPrinter {
			name: string
		}
		const reportPrinter = (await mediator.call('emitToServer', 'load report printer', { reportType })) as ReportPrinter
		return reportPrinter?.name || ''
	}

	async function generatePdfPreview({ name, type, parameters }: Parameters<ComponentProps<ReportJobModal>['generatePdfPreview']>[0]): Promise<{ data: string; mimeType: string }> {
		return (await mediator.call('emitToServer', 'get crystal report pdf', { name, type, parameters })) as { data: string; mimeType: string }
	}

	function changeReportParameterObjectShape(parameters: Record<string, string>) {
		return Object.entries(parameters).map(([key, value]) => {
			return {
				parameterName: key?.toLowerCase(),
				value,
			}
		})
	}

	async function saveReportJobs(reportJobs: ReportJob[]): Promise<void> {
		try {
			await Promise.all(
				reportJobs.map(reportJob => {
					return mediator.call('emitToServer', 'create print queue job', {
						reportQueueJob: {
							type: reportJob.type,
							name: reportJob.name,
							destinationType: reportJobDestinationTypeMap.get(reportJob.destinationType),
							destination: reportJob.destination,
							quantity: reportJob.quantity,
							title: reportJob.title,
							emailBody: reportJob.emailBody,
							emailFrom: reportJob.emailFrom,
						},
						reportQueueJobParameters: changeReportParameterObjectShape(reportJob.parameters),
					})
				}),
			)
		} catch (err) {
			console.error(err)
			logAndAlert(err, mediator, 'Error saving report jobs')
		}
	}

	async function savePrinterPreference(printer: string, reportType: string): Promise<void> {
		try {
			await mediator.call('emitToServer', 'save report printer', { reportType, printer })
		} catch (err) {
			console.error("An error occurred while saving the user's printer preference", err)
		}
	}
</script>

<!-- @component To use this component, you can simply call its `open` function

	```svelte
	<script lang="ts">
		import ReportJobModal from 'components/ReportJobModal.svelte'
		let reportJobModal: ReportJobModal | undefined = undefined
		reportJobModal.open(
			{
				// print job details
				type: 'Payment Receipt',
				parameters: { paymentnum: paymentId.toString() },
			},
			{
				// other options
			},
		)
	</script>
	<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
	```
-->

<ReportJobModal
	{showPreview}
	{loadReports}
	{loadPrinters}
	{loadDefaultPrinter}
	{generatePdfPreview}
	{saveReportJobs}
	{savePrinterPreference}
	showFavoriteButton={false}
	bind:this={internalModal}
></ReportJobModal>
