import template from './interchange.html'
import * as arrayHelper from '@isoftdata/utility-array'
import vinDecode from 'utility/vin-decoder'
import klona from 'klona'
import pProps from 'p-props'

//Ractive components
import makeAutocomplete from '@isoftdata/autocomplete'
import makeInterchange from 'components/interchange'
import makeYearInput from '@isoftdata/year-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeLabel from '@isoftdata/label'
import makeModal from '@isoftdata/modal'

const defaultData = Object.freeze({
	manufacturerList: [],
	manufacturerModelList: [],
	vehicleModelList: [],
	isLoadingManufacturers: false,
	isLoadingModels: false,
	lastSearchType: null,
	isExactSearch: false,
	inventoryTypeId: null,
	year: null,
	manufacturerOrMake: '',
	model: '',
	interchangeNumber: '',
})

const defaultVinDecodeModal = Object.freeze({
	vin: '',
	showModal: false,
	formattedVinInfo: '',
})

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.interchange',
		route: 'interchange',
		//querystringParameters: [ 'state' ],
		template: {
			template,
			data: {},
			components: {
				interchange: makeInterchange({ mediator, stateRouter, logAndAlert }),
				itAutocomplete: makeAutocomplete(),
				itInput: makeInput({ twoway: true, lazy: false }),
				itYearInput: makeYearInput({ twoway: true, lazy: false }),
				itLabel: makeLabel(),
				itButton: makeButton(),
				itCheckbox: makeCheckbox(),
				itModal: makeModal(),
			},
			computed: {
				displayInventoryTypeList() {
					return this.get('inventoryTypeList').reduce((acc, inventoryType) => {
						return {
							...acc,
							[inventoryType.inventoryTypeId]: `${inventoryType.name} - ${inventoryType.inventoryTypeId}`,
						}
					}, {})
				},
				displayManufacturerMakeList() {
					let manufacturerList = this.get('manufacturerList').map(manufacturer => manufacturer.name)
					let makeList = this.get('makeList').map(make => make.make)

					return arrayHelper.getDistinctArrayValues(manufacturerList.concat(makeList))
						.sort()
						.reduce((acc, manufacturerOrMake) => {
							return { ...acc, [manufacturerOrMake]: manufacturerOrMake }
						}, {})
				},
				displayModelList() {
					let manufacturerModelList = this.get('manufacturerModelList').map(manufacturer => manufacturer.name)
					let vehicleModelList = this.get('vehicleModelList').map(make => make.model)

					return arrayHelper.getDistinctArrayValues(manufacturerModelList.concat(vehicleModelList))
						.sort()
						.reduce((acc, model) => {
							return { ...acc, [model]: model }
						}, {})
				},
				hasVehicleInfoSearchFields() {
					return this.get('year') &&
						this.get('manufacturerOrMake') &&
						this.get('model')
				},
				canDoApplicationSearch() {
					return this.get('inventoryTypeId') &&
						this.get('hasVehicleInfoSearchFields')
				},
				canDoInterchangeSearch() {
					return this.get('inventoryTypeId') &&
						this.get('interchangeNumber')
				},
				computedSearchBy() {
					const canDoApplicationSearch = this.get('canDoApplicationSearch')
					const canDoInterchangeSearch = this.get('canDoInterchangeSearch')

					if (canDoInterchangeSearch) {
						return 'Interchange #'
					} else if (canDoApplicationSearch) {
						return 'Vehicle Info'
					} else {
						return false
					}
				},
			},
			async showVinLookupModal() {
				await this.set({ vinModal: { ...klona(defaultVinDecodeModal), showModal: true } })
				this.find('#vinLookup').select()
			},
			async performVinLookup(vin, event) {
				event?.preventDefault()
				try {
					const decodedVinInfo = await vinDecode(vin, mediator)
					const data = Object.entries(decodedVinInfo.rawData)
					const formattedVinInfo = data
						.map(([ key, value ]) => `${key}: ${value}`).join('\n')
					this.set({ 'vinModal.formattedVinInfo': formattedVinInfo })
				} catch (err) {
					logAndAlert(err, mediator, 'Error occured decoding VIN')
				}
			},
		},
		resolve(data, parameters) {
			return pProps({
				...defaultData,
				inventoryTypeList: mediator.call('emitToServer', 'load inventory type list', {}),
				makeList: mediator.call('emitToServer', 'load vehicle makes', {}),
				vinModal: klona(defaultVinDecodeModal),
			})
		},
		activate({ domApi }) {
			const ractive = domApi

			ractive.find('#inventoryType').focus()

			ractive.observe('inventoryTypeId', async inventoryTypeId => {
				if (inventoryTypeId) {
					ractive.set({ isLoadingManufacturers: true })
					const manufacturerList = await mediator.call('emitToServer', 'load part manufacturers using inventory types', { inventoryTypeId })

					ractive.set({
						manufacturerList,
						isLoadingManufacturers: false,
					})
				} else {
					ractive.set({ manufacturerList: [] })
				}
			}, { init: false })

			ractive.observe('manufacturerOrMake', async manufacturerOrMake => {
				if (manufacturerOrMake) {
					ractive.set({ isLoadingModels: true })

					const res = await pProps({
						manufacturerModelList: mediator.call('emitToServer', 'load part models using inventory type', { partManufacturer: manufacturerOrMake }),
						vehicleModelList: mediator.call('emitToServer', 'load vehicle models', { make: manufacturerOrMake }),
					})

					ractive.set({ ...res, isLoadingModels: false })
				}
			})

			ractive.on('interchange.interchangeSelection', (context, interchangeRecord) => {
				ractive.set({
					interchangeNumber: interchangeRecord.interchangeNumber,
					isExactSearch: false,
				})
				ractive.fire('search', context)
			})

			ractive.on('search', ({ event }) => {
				event.preventDefault()

				const interchangeNumber = ractive.get('interchangeNumber')
				const searchType = interchangeNumber ? 'GROUP' : 'APPLICATION'
				const searchOptions = {
					searchType,
					inventoryTypeId: ractive.get('inventoryTypeId'),
					interchangeNumber,
					isExact: ractive.get('isExactSearch'),
					vehicleYear: ractive.get('year'),
					vehicleMake: ractive.get('manufacturerOrMake'),
					vehicleModel: ractive.get('model'),
				}

				ractive.findComponent('interchange').search(searchOptions, (err, interchangeList) => {
					ractive.set({ lastSearchType: searchType })
				})
			})
		},
	})
}
