import template from './financial.html'
import pProps from 'p-props'
import financialNumber from 'financial-number'
import { getCustomerAllowedPaymentMethods } from 'utility/payment'

//Ractive components
import makeCurrencyInput from '@isoftdata/currency-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const stateName = 'app.entity.financial'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: stateName,
		route: 'financial',
		querystringParameters: [ 'id', 'entityContext' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
				itInput: makeInput({ twoway: true, lazy: true }),
				itSelect: makeSelect({ twoway: true, lazy: true }),
				itCurrencyInput: makeCurrencyInput({ twoway: true, lazy: true }),
				itCheckbox: makeCheckbox({ twoway: true, lazy: true }),
			},
			twoway: false,
			data: {},
			computed: {
				displayCustomerPaymentMethods() {
					const customerPaymentMethods = this.get('customerPaymentMethods')
					const paymentMethods = this.get('paymentMethods')

					return getCustomerAllowedPaymentMethods(customerPaymentMethods, paymentMethods)
				},
			},
			saveEntity(entity, cb) {
				const entityContext = this.get('entityContext')
				entity.taxExpirationDate = entity.taxExpirationDate || null

				mediator.call('emitToServer', `save ${entityContext}`, { [entityContext]: entity })
					.then(res => {
						mediator.call('entitySave', { stateThatSaved: stateName, data: res[0] })
						cb(null, res?.[0])
					}).catch(err => cb(err))
			},
		},
		async resolve(data, { id, entityContext }) {
			const customerId = id

			const results = await pProps({
				aging: customerId ? mediator.call('emitToServer', 'load aging', { customerId }) : [],
				taxItems: mediator.call('emitToServer', 'load tax items', {}),
				paymentMethods: mediator.call('emitToServer', 'load payment methods', {}),
				customerPaymentMethods: mediator.call('emitToServer', 'load customer payment methods', { customerId }),
				entityContext,
				customerId,
				saved: [],
				errorFields: [],
				priceLevels: [
					{ name: "Retail" },
					{ name: "Wholesale" },
					{ name: "Cost" },
					{ name: "List" },
				],
			})

			if (results && results.aging.length > 0) {
				results.aging = results.aging[0]
				results.aging.totalDue = financialNumber('0')
					.plus(results.aging.LessThanZero)
					.plus(results.aging.ZeroToThirty)
					.plus(results.aging.ThirtyOneToSixty)
					.plus(results.aging.SixtyOneToNinety)
					.plus(results.aging.NinetyOneToOneTwenty)
					.plus(results.aging.OverOneTwenty)
					.toString()
			}

			return results
		},
		activate({ domApi: ractive }) {
			ractive.observe('entity', entity => {
				ractive.saveEntity(entity, () => { })
			}, { init: false })

			if (!ractive.get('entity.defaultPriceType') || !ractive.get('priceLevels').find(priceLevel => priceLevel.name === ractive.get('entity.defaultPriceType'))) {
				ractive.set('entity.defaultPriceType', 'Retail')
			}

			ractive.observe('customerPaymentMethods', async customerPaymentMethods => {
				try {
					await mediator.call('emitToServer', 'set allowed customer payment methods', {
						paymentMethodsToAllow: customerPaymentMethods,
						customerId: ractive.get('customerId'),
					})
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured setting all customer payment methods to allowed')
				}
			}, { init: false })

			const { entity: customer, taxItems } = ractive.get('')

			if (!taxItems.find(taxItem => taxItem.name === customer.taxItem)) {
				const defaultTaxItem = taxItems.find(taxItem => taxItem.isDefault)
				ractive.set('entity.taxItem', defaultTaxItem.name)
			}

			ractive.on('togglePaymentMethodAllowed', (context, paymentMethodId) => {
				const methodIndex = ractive.get('customerPaymentMethods')
					.findIndex(method => method.paymentMethodId === paymentMethodId)

				if (methodIndex > -1) {
					ractive.splice('customerPaymentMethods', methodIndex, 1)
				} else {
					ractive.push('customerPaymentMethods', {
						customerId: customer.customerId,
						paymentMethodId,
					})
				}
			})

			ractive.on('toggleAllPaymentMethodsAllowed', () => {
				const customerPaymentMethods = ractive.get('customerPaymentMethods')
				const paymentMethods = ractive.get('paymentMethods')

				if (paymentMethods.length === customerPaymentMethods.length) {
					ractive.set({ customerPaymentMethods: [] })
				} else {
					paymentMethods.forEach(({ paymentMethodId }) => {
						if (!customerPaymentMethods.find(customerMethod => customerMethod.paymentMethodId === paymentMethodId)) {
							ractive.push('customerPaymentMethods', { customerId: customer.customerId, paymentMethodId })
						}
					})
				}
			})
		},
	})
}
