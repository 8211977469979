import template from './inventory-type-list.html'
import pProps from 'p-props'

//Ractive Components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.vehicle.teardown.inventory-type-list',
		route: 'inventory-type-list',
		querystringParameters: [ 'teardown' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			computed: {
				allPartsSelected() {
					return this.get('filteredInventoryTypes').every(itemRow => itemRow.isChecked)
				},
				currentTeardownInventoryList() {
					return this.getCurrentInventoryList()
				},
			},
			getCurrentInventoryList() {
				const ractive = this
				const currentTeardown = ractive.get('currentTeardown')

				return ractive.get('partTypes').map(part => {
					return {
						...part,
						isChecked: !!ractive.get('teardownList').find(teardown => {
							return teardown.inventoryTypeId === part.inventoryTypeId && teardown.teardownName === currentTeardown
						}),
					}
				})
			},
			onSelectionHandler(selected) {
				const ractive = this
				let currentTeardownList = ractive.get('teardownList')

				if (selected) {
					ractive.set('teardownList', [
						...currentTeardownList,
						...ractive.get('filteredInventoryTypes').map(part => {
							return { teardownName: ractive.get('currentTeardown'), inventoryTypeId: part.inventoryTypeId }
						}),
					])
					ractive.saveTeardown(ractive.get('currentTeardown'), ractive.get('teardownList').map(item => item.inventoryTypeId), selected, "all")
				} else {
					const filteredInventoryTypeRows = ractive.get('filteredInventoryTypes')
					let confirmDelete = false
					//check if all items are being deselected or if filtered items only are being deselected
					const allItems = filteredInventoryTypeRows.length === currentTeardownList.length //false when filtering, true when showing everything

					if (allItems) {
						confirmDelete = confirm("Deselecting all items from this teardown will delete this teardown. Are you sure you wish to do that?")
						if (confirmDelete) {
							let currentTeardownListWithIds = currentTeardownList.map(item => item.inventoryTypeId)
							ractive.set('teardownList', currentTeardownListWithIds)
							ractive.saveTeardown(ractive.get('currentTeardown'), ractive.get('teardownList'), selected, "all")
							stateRouter.go('app.configuration.vehicle.teardown.inventory-type-list', {})
						}
					} else {
						//get what was deselected
						const deselectedItems = ractive.get('filteredInventoryTypes')

						const restOfTeardownItems = ractive.get('teardownList').filter(teardownItem => {
							return !ractive.get('filteredInventoryTypes').find(partType => partType.inventoryTypeId === teardownItem.inventoryTypeId)
						})

						ractive.set('teardownList', restOfTeardownItems)
						ractive.saveTeardown(ractive.get('currentTeardown'), deselectedItems, selected, "all")
					}
				}
			},
			onPartTypeSelectionHandler(partId, teardown, checked, event) {
				const ractive = this

				if (checked) {
					ractive.push('teardownList', { inventoryTypeId: partId, teardownName: teardown })
				} else {
					let index = ractive.get('teardownList').findIndex(teardownItem => teardownItem.inventoryTypeId === partId && teardownItem.teardownName === teardown)
					ractive.splice('teardownList', index, 1)
				}

				ractive.saveTeardown(teardown, [ partId ], checked, "single")
			},
			checkboxClick(event) {
				event.preventDefault()
				event.stopImmediatePropagation()
			},
			async saveTeardown(name, items, checked, singleOrAll) {
				//check if we're editing existing teardown or saving values for new one
				try {
					let currentTeardownNames = await mediator.call('emitToServer', 'load teardown template names', {})

					if (currentTeardownNames.find(item => name === item.teardownName)) {
						//existing teardown
						await mediator.call('emitToServer', 'edit teardown template inventory', { teardownName: name, teardownItems: items, isChecked: checked, singleOrAll })
					} else {
						//new teardown
						await mediator.call('emitToServer', 'save new teardown template inventory', { teardownName: name, inventoryItems: items })
					}
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured saving teardown template')
				}
			},
		},
		resolve(data, { teardown }) {
			return pProps({
				currentTeardown: teardown,
				teardownList: teardown ? mediator.call('emitToServer', 'load teardown template', { teardownName: teardown }) : [],
				partTypes: mediator.call('emitToServer', 'load inventory type list', {}),
				columns: [
					{ property: 'isChecked', name: '', columnWidth: '5%' },
					{ property: 'inventoryTypeId', name: 'Part Type #', columnWidth: '5%' },
					{ property: 'name', name: 'Part Name', columnWidth: '90%' },
				],
			})
		},
		activate({ domApi: ractive }) {
		},
	})
}
