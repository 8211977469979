import template from './results.html'
import pProps from 'p-props'
import keyboard from 'keyboardjs'
import jsonToCsv from '@isoftdata/json-to-csv'
//import arrayHelper from '@isoftdata/utility-array'
import { stringToBoolean } from '@isoftdata/utility-string'
import focusedElementIsInput from '@isoftdata/focused-element-is-input'

//Ractive components
import makeImageThumbnailViewer from '@isoftdata/image-thumbnail-viewer'
import makeReportSelectionModal from 'components/report-selection'
import makeImageViewerModal from '@isoftdata/image-viewer-modal'
import makeTableCardFooter from '@isoftdata/table-card-footer'
import makeCheckboxButton from '@isoftdata/checkbox-button'
import makeAppraisal from 'components/appraisal'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeTd from '@isoftdata/td'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.part-search.results',
		route: 'results',
		querystringParameters: [
			'inventoryTypeId',
			'partManufacturer',
			'partModel',
			'category',
			'status',
			'side',
			'replenish',
			'needsReordered',
			'needsImages',
			'needsTag',
			'shownOnline',
			'vehicleYear',
			'vehicleMake',
			'vehicleModel',
			'interchangeNumber',
			'flexFields',
			'storeId',
			'more1',
			'more2',
			'more3',
			'more4',
			'keywordSearch',
			'questionValues',
		],
		data() {
			return {
				fileHovered: null,
			}
		},
		template: {
			delegate: false,
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itModal: makeModal(),
				appraisal: makeAppraisal(mediator),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
				itTd: makeTd(),
				itImageViewerModal: makeImageViewerModal(),
				itCheckboxButton: makeCheckboxButton({ twoway: false }),
				itTableCardFooter: makeTableCardFooter(),
				imageThumbnailViewer: makeImageThumbnailViewer(),
			},
			computed: {
				hasStore() {
					return this.get('storeId')
				},
				computedHeader() {
					let headers = [{ property: 'selectPart', icon: 'fas fa-fw fa-list' }]

					if (!this.get('hasStore')) {
						headers = [{ property: 'storeId', name: 'Store' }].concat(headers)
					}

					headers.push(...[
						{ property: 'attachmentCount', icon: 'fas fa-fw fa-photo-video' },
						{ property: 'tagNumber', name: 'Tag #', align: 'right', sortType: 'alphaNum' },
						{ property: 'inventoryType', name: 'Part Type' },
					])

					if (this.get('resultsHaveAnyManufacturerModels')) {
						headers.push(...[
							{ property: 'partManufacturer', name: 'Part Manufacturer' },
							{ property: 'partModel', name: 'Part Model' },
						])
					}

					headers.push(...[
						{ property: 'category', name: 'Category' },
						{ property: 'trackingNumber', name: 'Tracking #', align: 'right', sortType: 'alphaNum' },
						{ property: 'year', name: 'Year', align: 'right' },
						{ property: 'make', name: 'Vehicle Make' },
						{ property: 'model', name: 'Vehicle Model' },
						{ property: 'location', name: 'Location' },
						{ property: 'upc', name: 'UPC' },
						{ property: 'oemNumber', name: 'OEM #' },
						{ property: 'quantityAvailable', name: 'Qty Avail' },
						{ property: 'quantity', name: 'Qty' },
						{ property: 'retailPrice', name: 'Retail' },
					])

					if (this.get('hasAppraisedItems')) {
						headers.push({
							property: 'appraisedValue',
							icon: 'far fa-fw fa-book-open',
							title: `Appraised Value (${this.get('chosenAppraisalOrigin').name})`,
						})
					}

					headers.push(...[
						{ property: 'description', name: 'Description' },
						{ property: 'notes', name: 'Internal Notes' },
						{ property: 'weight', name: 'Weight' },
					])

					return headers
				},
				resultsHaveAnyManufacturerModels() {
					return this.get('searchResults').some(({ partManufacturer, partModel }) => partManufacturer || partModel)
				},
				resultsCSV() {
					const searchResults = this.get('searchResults')

					if (searchResults && searchResults.length > 0) {
						return window.btoa(jsonToCsv(searchResults))
					}
					return false
				},
				selectedResultsCSV() {
					const searchResults = this.get('searchResults')
					const selectedIds = this.get('selectedIds')

					const filteredSearchResults = searchResults
						.filter(result => selectedIds.includes(result.inventoryId))

					if (filteredSearchResults && filteredSearchResults.length > 0) {
						return window.btoa(jsonToCsv(filteredSearchResults))
					}
					return false
				},
				itemsToAppraise() {
					return this.get('searchResults').map(part => {
						return {
							id: part.inventoryId,
							inventoryTypeId: part.inventoryTypeId,
							partManufacturer: part.partManufacturer,
							partModel: part.partModel,
							category: part.category,
							vehicleMake: part.make,
							vehicleModel: part.model,
							year: part.year,
						}
					})
				},
				hasAppraisedItems() {
					const appraisedItems = this.get('appraisedItems')

					return appraisedItems && appraisedItems.length > 0
				},
			},
			/*onteardown() {
				keyboardJS.reset()
			}, */
			sendToDocument(inventoryIds, type) {
				sessionStorage.setItem(`pending-${type}-item-add`, JSON.stringify({ inventoryIds }))
				stateRouter.go(`${type}`)
			},
			selectAllResults() {
				const ractive = this

				const results = ractive.get('searchResults')
				const numSelected = ractive.get('selectedIds')?.length

				if (numSelected !== results.length) {
					const selectedIds = results.map(result => {
						return result.inventoryId
					})
					console.log(selectedIds)
					ractive.set({ selectedIds, selectAllButtonText: 'Deselect All' })
				} else {
					ractive.set({ selectedIds: [], selectAllButtonText: 'Select All' })
				}
			},
		},
		resolve(data, parameters) {
			const { user, store } = JSON.parse(localStorage.getItem('session'))

			let searchParams = {}

			let { needsImages, needsTag, replenish, needsReordered, shownOnline } = parameters

			needsImages = stringToBoolean(needsImages)
			needsTag = stringToBoolean(needsTag)
			replenish = stringToBoolean(replenish)
			needsReordered = stringToBoolean(needsReordered)
			shownOnline = stringToBoolean(shownOnline)

			searchParams = {
				...parameters,
				needsImages,
				needsTag,
				replenish,
				needsReordered,
				shownOnline,
				flexFields: parameters.flexFields ? JSON.parse(parameters.flexFields) : {},
				vehicleYear: parameters.vehicleYear ? JSON.parse(parameters.vehicleYear) : {},
				questionValues: parameters.questionValues ? JSON.parse(parameters.questionValues) : {},
				options: {
					vehicleTrackingNumber: true,
					quantityCommitted: true,
					attachmentCount: {
						type: 'Image',
						public: true,
					},
					topRankedImageMetaData: true,
				},
			}

			const perPageCount = mediator.call('emitToServer', 'check user setting', {
				name: 'Chromium Part Search Results Per Page',
				category: 'Options',
				userAccountId: user?.userAccountId,
				settingType: 'Interface History',
				defaultValue: 20,
				dataType: 'integer',
			})

			return pProps({
				isTruckYard: (async() => {
					return stringToBoolean((await mediator.call('emitToServer', `load global setting`, { settingName: 'Yard Type: Truck' }))?.[0]?.value)
				})(),
				perPageCount,
				poButtonDisabled: false,
				searchResults: searchParams ? mediator.call('emitToServer', 'load inventory', searchParams) : [],
				inventoryTypeList: mediator.call('emitToServer', 'load inventory type list', {}),
				displaySearchResults: [],
				selectedIds: [],
				selectAllButtonText: 'Select All',
				imageViewerShown: false,
				currentImageViewerImageIndex: 0,
				imageViewerImages: [],
				storeId: searchParams.storeId,
				showThumbnails: JSON.parse(localStorage.getItem('showPartSearchImageThumbnails')) ?? true,
				currentStore: store,
			})
		},
		activate({ domApi: ractive }) {
			keyboard.bind('mod + a', event => {
				if (!focusedElementIsInput(document)) {
					event.preventDefault()
					ractive.selectAllResults()
				}
			})

			ractive.on('showImages', async(context, inventoryId) => {
				context.event.stopPropagation()
				ractive.set({ imageViewerShown: true, imageViewerImages: [] })

				try {
					const files = await mediator.call('emitToServer', 'load file info list', {
						association: {
							type: 'INVENTORY',
							key: { inventoryId },
						},
						type: 'Image',
					})

					ractive.set({
						imageViewerImages: files
							.filter(file => file.mimeType.startsWith('image/'))
							.map(({ fileId, md5sum, name }) => `attachments/${md5sum}${fileId}/${name}?width=500&height=500&fit=contain`),
						currentImageViewerImageIndex: 0,
					})
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured while loading the list of images')
				}
			})

			ractive.observe('appraisedItems', appraisedItems => {
				if (appraisedItems && appraisedItems.length > 0) {
					ractive.findComponent('itTable')
						.fire('showColumn', {}, { property: 'appraisedValue' })
				}
			})

			ractive.observe('searchResults inventoryTypeList appraisedItems', () => {
				//idk why but I was running into issues with displaySearchResults as
				//a computed property with ractive where the itTable it was bound to
				//wouldn't recalculate sortedPageRows when displaySearchResults changed
				//seems to work correctly if I just use an observer. TODO: fix this somehow
				const appraisedItems = ractive.get('appraisedItems')
				const inventoryTypeList = ractive.get('inventoryTypeList')
				let displaySearchResults = ractive.get('searchResults')

				displaySearchResults = displaySearchResults.map(item => {
					let appraisedItem = false

					if (appraisedItems && appraisedItems.length > 0) {
						appraisedItem = appraisedItems
							.find(appraiseItem => item.inventoryId == appraiseItem.id)
					}

					const inventoryType = inventoryTypeList
						.find(inventoryType => item.inventoryTypeId == inventoryType.inventoryTypeId)

					let fontColorClass = ''
					let tooltipHint = ''

					const quantity = item.quantity ? item.quantity : 0
					const maximumQuantity = item.maximumQuantity ? item.maximumQuantity : 0
					const minimumQuantity = item.minimumQuantity ? item.minimumQuantity : 0
					const quantityAvailable = quantity - item.quantityCommitted

					if (quantity > maximumQuantity && maximumQuantity > 0) {
						fontColorClass = 'part-search-results-over-max-qty'
						tooltipHint = 'Quantity above maximum quantity'
					}

					if (quantityAvailable < minimumQuantity) {
						fontColorClass = 'part-search-results-below-min-qty'
						tooltipHint = 'Quantity available below minimum quantity'
					}

					if (item.status == 'H' || quantityAvailable == 0) {
						fontColorClass = 'part-search-results-status-H-or-available-qty-zero'

						if (item.status == 'H') {
							tooltipHint = 'Item is on hold'
						} else if (quantityAvailable == 0) {
							tooltipHint = 'No quantity available'
						}
					}

					if (quantity <= 0) {
						fontColorClass = 'part-search-results-qty-zero'
						tooltipHint = 'Quantity is zero or less'
					}

					return {
						...item, appraisedValue: appraisedItem ? appraisedItem.value : 0,
						inventoryType: inventoryType ? inventoryType.name : '',
						quantityAvailable,
						fontColorClass,
						tooltipHint,
					}
				})

				ractive.set({ displaySearchResults })
			})

			ractive.observe('fullSortedSearchResults', results => {
				localStorage.setItem('partSearchResults', JSON.stringify(results.map(item => item.inventoryId)))
			})

			/* window.scrollTo({
				top: ractive.find('#searchResults')
					.getBoundingClientRect().top - document.getElementById('app-nav').offsetHeight - 10,
			}) */

			ractive.on('PRINT_RESULTS', () => {
				const inventoryIds = ractive.get('searchResults')
					.map(inventory => inventory.inventoryId)

				//TODO: change this when we have a version of the report that can accept
				//just a list of inventory ids instead of a full where clause
				const whereClause = `inventory.partnum IN(${inventoryIds.join(', ')})`

				ractive.findComponent('reportSelectionModal').printReport({
					type: 'Part Search',
					reportParameters: [
						{ parameterName: 'param', value: whereClause },
					],
				})
			})

			ractive.on('PRINT_TAGS', () => {
				const inventoryIds = ractive.get('selectedIds') // selectedIds
				const storeId = ractive.get('store.storeId')

				if (inventoryIds.length && storeId) {
					//TODO: this report should support taking a tuple of IDs instead
					//of this screen having to provide an entire WHERE clause
					const reportJobs = inventoryIds.map(inventoryId => {
						const reportParameters = [
							{ parameterName: 'tagnums', value: `inventory.partnum = ${inventoryId}` },
							{ parameterName: 'store', value: parseInt(storeId, 10) },
						]
						return {
							type: 'Tags',
							reportParameters,
						}
					})
					ractive.findComponent('reportSelectionModal').printReport(reportJobs, {}, {}, async(err, res) => {
						if (!err && res.reportQueueJobSave) {
							console.log(res, inventoryIds)
							await mediator.call('emitToServer', 'mark inventory items tag printed', { inventoryIds })
						}
					})
				} else {
					throw 'Error printing tag report'
				}
			})

			ractive.on('result-item-clicked', (context, inventoryId) => {
				if (!ractive.get('isSelectionMode')) {
					const returnState = sessionStorage.getItem('returnState')
					if (returnState) {
						ractive.sendToDocument([ inventoryId ], returnState)
					} else {
						stateRouter.go('app.part', { inventoryId, source: 'PART_SEARCH' })
					}
				} else {
					const selectionMode = ractive.get('selectionMode')
					const array = ractive.get('sortedSearchResults')

					ractive.fire('dispatch', 'SET_RESULT_SELECTION', { payload: { inventoryId, array, selectionMode } })
				}
			})

			ractive.observe('showThumbnails', showThumbnails => localStorage.setItem('showPartSearchImageThumbnails', showThumbnails), { init: false })

			ractive.on('perPageCountChanged', async(context, perPageCount) => {
				try {
					await mediator.call('emitToServer', 'save user setting', {
						name: 'Chromium Part Search Results Per Page',
						category: 'Options',
						userAccountId: ractive.get('session.user.userAccountId'),
						settingType: 'Interface History',
						defaultValue: 20,
						dataType: 'integer',
						value: perPageCount,
					}, { doNotAlert: true })
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured while saving the number of seach results per page')
				}
			})
		},
	})
}
