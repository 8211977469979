import template from './results.html'
import pProps from 'p-props'

//Ractive components
import makeResponsiveTable from 'components/responsive-table'

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle.history-report.results',
		route: 'results',
		querystringParameters: [
			'reportType',
			'reportId',
			'vin',
		],
		defaultParameters: { reportType: 'standard' },
		template: {
			template,
			components: {
				responsiveTable: makeResponsiveTable(),
			},
			computed: {
				displayHistoryReport() {
					const historyReport = this.get('vehicleHistoryReportData')
					const brandCodes = this.get('vinAuditBrandCodes')

					if (this.get('reportType') === 'standard' && historyReport && brandCodes) {
						const checks = historyReport.checks.map(check => {
							const foundCode = brandCodes.find(brandCode => {
								return brandCode.code === check.brandCode
							})

							return {
								...check,
								brandCodeName: foundCode.name,
								brandCodeDescription: foundCode.description,
							}
						})

						return { ...historyReport, checks }
					}
					return null
				},
			},
		},
		data: {},
		resolve(data, { reportType, reportId, vin }) {
			return pProps({
				vinAuditBrandCodes: mediator.call('emitToServer', 'load vin audit brand codes', {}),
				isLoading: true,
				reportType,
				reportId,
				vin,
			})
		},
		activate({ domApi }) {
			const ractive = domApi

			const isPdfReportType = ractive.get('reportType') === 'pdf'

			//defer loading the history report to here, since it can take a while,
			//that way we can show a loading indicator
			mediator.call('emitToServer', 'get vehicle history report', { vin: ractive.get('vin'), pdf: isPdfReportType })
				.then(vehicleHistoryReport => {
					ractive.set({ isLoading: false })
					if (isPdfReportType) {
						ractive.set({
							vehicleHistoryReportPdf: `data:application/pdf;base64,${ vehicleHistoryReport }`,
						})
					} else {
						ractive.set({
							vehicleHistoryReportData: vehicleHistoryReport,
						})
					}
				})
				.catch(err => {
					let errorMessage

					switch (err) {
						case 'invalid_vin':
							errorMessage = 'Not a valid VIN'
							break
						case 'fail_nmvtis':
							errorMessage = 'Failed to reach NMVTIS'
							break
						case 'no_records':
							errorMessage = 'No NMVTIS records exists'
							break
						default:
							if (typeof err === 'string') {
								errorMessage = err
							} else {
								errorMessage = 'Failed to retrieve report. Try again later.'
							}
					}

					logAndAlert(err, mediator, errorMessage)
				})
		},
	})
}
