import template from './customer.html'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'
import makeCheckbox from '@isoftdata/checkbox'

const stateName = 'app.quickbooks.customer'

function makeDisplayRecordFromItrack(itrackCustomer) {
	const { customerId, billingCompany, billingContact } = itrackCustomer

	return {
		id: customerId,
		name: (billingCompany && billingContact) ? `${billingCompany} (${billingContact})` : (billingCompany || billingContact),
		original: itrackCustomer,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksCustomer) {
	return {
		id: Number(quickbooksCustomer.Id) || quickbooksCustomer.Id,
		name: quickbooksCustomer.DisplayName,
		original: quickbooksCustomer,
	}
}

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'customer',
		querystringParameters: [ 'systemSelection', 'hideMatches', 'hideInactive' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
			hideInactive: 'true',
		},
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox(),
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.entity', { entityContext: 'customer', id }),
					makeQuickbooksEntityUrl: id => `__qboBasePath__/customerdetail?nameId=${id}`,
					itrackKey: 'customerId',
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system, hideInactive }) {
			hideInactive = stringToBoolean(hideInactive)
			const { itrackEntityList, quickbooksEntityList } = await mediator.call('emitToServer', 'load customers for quickbooks screen', { hideSynced: stringToBoolean(hideMatches), hideInactive })
			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				hideInactive,
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			ractive.on('hide-inactive-changed', (context, hideInactive) => {
				stateRouter.go(null, { hideInactive }, { inherit: true })
			})

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Customers`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
