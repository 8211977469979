import template from './attachment.html'
import makeAttachmentFns from 'utility/attachment-helper.ts'

//Ractive components
import makeAttachment from '@isoftdata/attachment'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.attachment',
		route: 'attachment',
		querystringParameters: [ 'id', 'entityContext' ],
		template: {
			template,
			components: {
				attachments: makeAttachment(makeAttachmentFns(mediator)),
			},
		},
		async resolve(data, { id, entityContext }) {
			const association = {
				type: entityContext.toUpperCase(),
				key: {
					[`${entityContext}Id`]: Number(id),
				},
			}

			const [ fileList, viewMode ] = await Promise.all([
				mediator.call('emitToServer', 'load file info list', { association }),
				mediator.call('emitToServer', 'check user setting', {
					name: `${entityContext.charAt(0).toUpperCase() + entityContext.slice(1)} Attachment Screen View Mode`,
					category: 'Options',
					settingType: 'Interface History',
					defaultValue: 'THUMBNAIL-MD',
				}),
			])

			return {
				viewMode,
				fileAssociation: association,
				fileList,
			}
		},
		activate({ domApi: ractive }) {
			ractive.observe('viewMode', value => {
				const entityContext = ractive.get('entityContext')
				mediator.call('emitToServer', 'save user setting', {
					name: `${entityContext.charAt(0).toUpperCase() + entityContext.slice(1)} Attachment Screen View Mode`,
					category: 'Options',
					type: 'Interface History',
					value,
				}, { init: false })
			})
		},
	})
}
