import template from './additional-info.html'
import pProps from 'p-props'
import domValue from 'dom-value'
import financialNumber from 'financial-number'
import { firstOr } from '@isoftdata/utility-array'

//Ractive Components
import makeCheckbox from '@isoftdata/checkbox'
import makeAutocomplete from '@isoftdata/autocomplete'
import makeTextarea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const isValidContext = entityContext => [ 'customer', 'vendor' ].includes(entityContext)

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.entity.additional-info',
		route: 'additional-info',
		querystringParameters: [ 'id', 'entityContext' ],
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox(),
				itTextarea: makeTextarea({ twoway: true }),
				itSelect: makeSelect({ twoway: true }),
				itInput: makeInput(),
				itInputTwo: makeInput({ twoway: true }),
				itButton: makeButton(),
				itAutocomplete: makeAutocomplete(),
			},
			computed: {
				displayQuestionAnswer() {
					const questionAnswers = this.get('questionAnswers')
					const entityQuestionIdKey = `${this.get('entityContext')}QuestionId`
					const entityQuestionAnswerIdKey = `${this.get('entityContext')}QuestionAnswerId`

					return this.get('questions').map(question => {
						const matchingAnswer = questionAnswers
							.find(questionAnswer => questionAnswer[entityQuestionIdKey] === question[entityQuestionIdKey])

						return {
							entityQuestionAnswerId: matchingAnswer ? matchingAnswer[entityQuestionAnswerIdKey] : null,
							entityQuestionId: question[entityQuestionIdKey],
							question: question.question,
							answer: matchingAnswer ? matchingAnswer.answer : null,
						}
					}).filter(question => question.entityQuestionId)
				},
				hasZeroBalance() {
					return financialNumber(this.get('customerBalance').balance).equal('0')
				},
				websiteOpenUrl() {
					//we have to have the protocol or it treat it as a relative url
					let url = this.get('entity.websiteUrl')

					if (!url.startsWith('http') && url.trim()) {
						url = `http://${url}`
					}

					return url
				},
			},
			saveAnswer(questionAnswer, cb) {
				mediator.call('emitToServer', `save ${this.get('entityContext')} question answer`, { questionAnswer })
					.then(res => cb(null, res))
					.catch(err => cb(err))
			},
			clearAddQuestion() {
				const ractive = this

				return ractive.set({
					addingQuestion: false,
					newQuestion: '',
				})
			},
			saveEntity(entity) {
				const entityContext = this.get('entityContext')
				return mediator.call('emitToServer', `save ${entityContext}`, { [entityContext]: entity })
			},
			focusAnswerForQuestionId(entityQuestionId) {
				const el = this.find(`#input-question-id-${entityQuestionId}`)
				el?.scrollIntoView()
				el?.select()
			},
		},
		async resolve(data, { id, entityContext }) {
			const res = await pProps({
				entityTypes: isValidContext(entityContext) ? mediator.call('emitToServer', `load ${entityContext} types`, {}) : [],
				users: mediator.call('emitToServer', 'load users', {}),
				questions: isValidContext(entityContext) ? mediator.call('emitToServer', `load ${entityContext} questions`, {}) : [],
				questionAnswers: (id && isValidContext(entityContext)) ? mediator.call('emitToServer', `load ${entityContext} question answers`, { [`${entityContext}Id`]: id }) : [],
				customerBalance: (id && entityContext === 'customer') ? mediator.call('emitToServer', 'load customer balance', { customerId: id }) : { balance: 0 },
				entity: (id && isValidContext(entityContext)) ? mediator.call('emitToServer', `load ${entityContext}`, { [`${entityContext}Id`]: id }) : { balance: 0 },
			})

			return {
				...res,
				customerBalance: firstOr(res.customerBalance, { balance: 0 }),
				entity: firstOr(res.entity, { balance: 0 }),
				addingQuestion: false,
				newQuestion: '',
				displayEntityTypes: res.entityTypes.reduce((acc, entityType) => {
					if (entityType.type) {
						acc[entityType.type] = entityType.type
					}
					return acc
				}, {}),
			}
		},
		activate({ domApi: ractive }) {
			const entityContext = ractive.get('entityContext')

			ractive.observe('entity', async entity => {
				try {
					const savedEntity = await ractive.saveEntity(entity)
					console.log(savedEntity)
				} catch (err) {
					logAndAlert(err, mediator, `An error occured saving ${entityContext}`)
				}
			}, { init: false })

			ractive.on('answer-change', (context, node, questionAnswerRow) => {
				let questionAnswerId = {}

				if (questionAnswerRow.entityQuestionAnswerId) {
					questionAnswerId = {
						[`${entityContext}QuestionAnswerId`]: questionAnswerRow.entityQuestionAnswerId,
					}
				}

				let answerRow = {
					answer: domValue(node),
					[`${entityContext}QuestionId`]: questionAnswerRow.entityQuestionId,
					[`${entityContext}Id`]: ractive.get('id'), ...questionAnswerId,
				}

				console.log(answerRow)

				ractive.saveAnswer(answerRow, (err, res) => {
					if (!err && res[0]) {
						ractive.upsert('questionAnswers', `${entityContext}QuestionAnswerId`, res[0])
					}
				})
			})

			ractive.on('add-question', () => {
				ractive.set({ addingQuestion: true }).then(() => {
					ractive.find('#new-question-input').select()
				})
			})

			ractive.on('confirm-new-question', async({ newQuestion }, event) => {
				event.preventDefault()
				newQuestion = newQuestion.trim()
				const existingQuestion = ractive.get('questions').find(question => question.question === newQuestion)
				if (existingQuestion) {
					ractive.find(`#input-question-id-${existingQuestion[`${entityContext}QuestionId`]}`).select()
					ractive.clearAddQuestion()
				} else {
					try {
						const savedQuestion = await mediator.call('emitToServer', `save ${entityContext} question`, { question: { question: newQuestion } })
						await ractive.upsert('questions', `${entityContext}QuestionId`, savedQuestion)
						ractive.clearAddQuestion()
						const questionMatch = ractive.get('questions').find(question => question.question === newQuestion)
						if (questionMatch) {
							ractive.focusAnswerForQuestionId(questionMatch[`${entityContext}QuestionId`])
						}
					} catch (err) {
						logAndAlert(err, mediator, 'Error saving question')
					}
				}
			})
		},
	})
}
