export default function getEventValue(event: Event) {
    if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        event.target instanceof HTMLSelectElement
    ) {
        return event.target.value
    }
    return ''
}

export function getEventChecked(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target.type === 'checkbox') {
        return event.target.checked
    }
    return false
}