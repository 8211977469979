import template from './purchase-order.html'
import pProps from 'p-props'
import { stringToBoolean } from '@isoftdata/utility-string'
import * as currency from '@isoftdata/utility-currency'
import * as dateTimeHelper from '@isoftdata/utility-date-time'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'
import makeCheckbox from '@isoftdata/checkbox'

function makeDisplayRecordFromItrack(itrackPurchaseOrder) {
	const { purchaseOrderId, vendor, documentDate, total } = itrackPurchaseOrder
	const { billingCompany, billingContact } = vendor

	return {
		id: purchaseOrderId,
		vendorName: (billingCompany && billingContact) ? `${billingCompany} (${billingContact})` : (billingCompany || billingContact),
		documentDate,
		total,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksPurchaseOrder) {
	return {
		id: Number(quickbooksPurchaseOrder.Id) || quickbooksPurchaseOrder.Id,
		vendorName: quickbooksPurchaseOrder?.VendorRef?.name || '',
		documentDate: quickbooksPurchaseOrder?.TxnDate || '',
		total: quickbooksPurchaseOrder?.TotalAmt || 0,
	}
}

const displayItrackColumns = [
	{ property: 'documentDate', formatter: dateTimeHelper.toLocaleDateString, name: 'Date' },
	{ property: 'vendorName', name: 'Vendor' },
	{ property: 'total', formatter: currency.format, name: 'Total', class: 'text-right tabular-nums' },
]

const displayQuickbooksColumns = [
	{ property: 'documentDate', formatter: dateTimeHelper.toLocaleDateString, name: 'Date' },
	{ property: 'vendorName', name: 'Vendor' },
	{ property: 'total', formatter: currency.format, name: 'Total', class: 'text-right tabular-nums' },
]

const stateName = 'app.quickbooks.purchase-order'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'purchase-order',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.purchase-order', { purchaseOrderId: id }),
					makeQuickbooksEntityUrl: id => `__qboBasePath__/purchaseorder?txnId=${id}`,
					itrackKey: 'purchaseOrderId',
					displayItrackColumns,
					displayQuickbooksColumns,
				}),
				itCheckbox: makeCheckbox,
			},
			data: {
				hasVendorOnly: true,
			},
			computed: {
				displayItrackEntityList() {
					const ractive = this
					const hasVendorOnly = ractive.get('hasVendorOnly')
					const itrackEntityList = ractive.get('itrackEntityList')

					return hasVendorOnly ? itrackEntityList.filter(entity => entity.vendorId) : itrackEntityList
				},
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			let { vendorList, purchaseOrderList, quickbooksEntityList } = await pProps({
				quickbooksEntityList: mediator.call('emitToServer', 'load quickbooks purchase orders', {}),
				purchaseOrderList: mediator.call('emitToServer', 'load purchase orders', { include: { quickbooks: true } }),
				vendorList: mediator.call('emitToServer', 'load vendor', {}),

			})

			let vendorMap = new Map()
			for (const vendor of vendorList) {
				vendorMap.set(vendor.vendorId, vendor)
			}

			return pProps({
				quickbooksEntityList,
				itrackEntityList: purchaseOrderList.map(purchaseOrder => {
					return {
						...purchaseOrder,
						vendor: vendorMap.get(purchaseOrder.vendorId) || {},
					}
				}),
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			})
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Purchase Orders`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
