import template from './manage.html'
import pProps from 'p-props'

//Ractive components
import makePermission from 'components/permission'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.group.manage',
		route: ':groupId',
		querystringParameters: [ 'groupId' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itCheckbox: makeCheckbox({ twoway: true }),
				itInput: makeInput({ twoway: true, lazy: false }),
				itButton: makeButton(),
				permission: makePermission({ mediator, logAndAlert }),
			},
			computed: {
				displayGroupUserMembership() {
					return this.get('groupUserMembership').map(user => {
						const { firstName, lastName, username } = user

						return { ...user, displayName: (firstName && lastName) ? `${firstName} ${lastName}` : username }
					})
				},
				allUsersAreSelected() {
					return this.get('displayGroupUserMembership').every(groupUser => groupUser.isMember)
				},
			},
		},
		resolve(data, { groupId }) {
			return pProps({
				groupId: parseInt(groupId, 10),
				groupPermissions: mediator.call('emitToServer', 'load permissions for group', { groupId }),
				groupUserMembership: mediator.call('emitToServer', 'load user group membership for group', { groupId }),
			})
		},
		activate({ domApi: ractive }) {
			ractive.on('select-all-clicked', () => {
				const allSelected = ractive.get('allUsersAreSelected')

				if (allSelected) {
					ractive.get('groupUserMembership')
						.forEach(groupUser => ractive.fire('group-membership-clicked', {}, groupUser.userAccountId))
				} else {
					ractive.get('groupUserMembership').filter(groupUser => !groupUser.isMember)
						.forEach(groupUser => ractive.fire('group-membership-clicked', {}, groupUser.userAccountId))
				}
			})

			ractive.on('group-membership-clicked', async(context, userAccountId) => {
				if (userAccountId) {
					const groupUserMembership = ractive.get('groupUserMembership')
					const groupUserIndex = groupUserMembership
						.findIndex(groupUser => groupUser.userAccountId === userAccountId)

					const updatedGroupUser = { ...groupUserMembership[groupUserIndex], isMember: !(groupUserMembership[groupUserIndex].isMember) }

					ractive.splice('groupUserMembership', groupUserIndex, 1, updatedGroupUser)

					try {
						const groupUserMembership = await mediator.call('emitToServer', 'update user group membership', {
							groupId: ractive.get('groupId'),
							userAccountId,
							isMember: updatedGroupUser.isMember,
							context: 'GROUP',
						})
						ractive.set({ groupUserMembership })
					} catch (err) {
						logAndAlert(err, mediator, 'Error saving user group membership')
					}
				}
			})
		},
	})
}
