import template from './vendor.html'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'
import makeCheckbox from '@isoftdata/checkbox'

function makeDisplayRecordFromItrack(itrackVendor) {
	const { vendorId, billingCompany, billingContact } = itrackVendor

	return {
		id: vendorId,
		name: (billingCompany && billingContact) ? `${billingCompany} (${billingContact})` : (billingCompany || billingContact),
		original: itrackVendor,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksVendor) {
	return {
		id: quickbooksVendor.Id,
		name: quickbooksVendor.DisplayName,
		original: quickbooksVendor,
	}
}

const stateName = 'app.quickbooks.vendor'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'vendor',
		querystringParameters: [ 'systemSelection', 'hideMatches', 'hideInactive' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
			hideInactive: 'true',
		},
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox(),
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.entity', { entityContext: 'vendor', id }),
					makeQuickbooksEntityUrl: id => `__qboBasePath__/vendordetail?nameId=${id}`,
					itrackKey: 'vendorId',
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system, hideInactive }) {
			hideInactive = stringToBoolean(hideInactive)
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load vendors for quickbooks screen', { hideSynced: stringToBoolean(hideMatches), hideInactive })
			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				hideInactive,
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			ractive.on('hide-inactive-changed', (context, hideInactive) => {
				stateRouter.go(null, { hideInactive }, { inherit: true })
			})

			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Vendors`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
