import template from './payment.html'
import { stringToBoolean } from '@isoftdata/utility-string'
import { format as formatCurrency } from '@isoftdata/utility-currency'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'

function makeDisplayRecordFromItrack(itrackPayment) {
	return {
		id: itrackPayment.paymentId,
		customerName: itrackPayment.billingCompany || itrackPayment.billingContact,
		saleId: itrackPayment.saleId || false,
		amount: itrackPayment.amount,
		status: itrackPayment.status,
		date: itrackPayment?.date?.slice?.(0, 10) || itrackPayment.documentDate,
		original: itrackPayment,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksPayment) {
	return {
		id: Number(quickbooksPayment.Id) || quickbooksPayment.Id,
		amount: quickbooksPayment.TotalAmt,
		customerName: quickbooksPayment.CustomerRef.name,
		date: quickbooksPayment.TxnDate,
		original: quickbooksPayment,
	}
}

// Some of the fields will be falsy, so let's make them display 'N/A' instead of a blank space
function falsyToNa(value) {
	return !value ? 'N/A' : value
}

const stateName = 'app.quickbooks.payment'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'payment',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: (paymentId, payment) => stateRouter.makePath('app.entity.payment', { entityContext: 'customer', paymentId, id: payment?.customerId, showAssociated: true, showVoidedPayments: true }), // need customerId too
					makeQuickbooksEntityUrl: id => `__qboBasePath__/recvpayment?txnId=${id}`,
					displayQuickbooksColumns: [
					// Associated Invoices / Credit Memos (can have multiple)
						// Customer
						{ property: 'date', name: 'Date' },
						{ property: 'customerName', name: 'Customer' },
						{ property: 'amount', name: 'Amount', class: 'text-right tabular-nums', formatter: formatCurrency },
					// Void
					],
					displayItrackColumns: [
						{ property: 'date', name: 'Date', formatter: falsyToNa },
						{ property: 'customerName', name: 'Customer' },
						{ property: 'amount', name: 'Amount', class: 'text-right tabular-nums', formatter: formatCurrency },
						{ property: 'saleId', name: 'Sale', class: 'text-right tabular-nums', formatter: falsyToNa },
						{ property: 'status', name: 'Status' },
					],
					itrackKey: 'paymentId',
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load payments for quickbooks screen', { hideSynced: stringToBoolean(hideMatches) })
			return ({
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			})
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Payments`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
