import template from './status-select.html'

//Ractive components
import makeSelect from '@isoftdata/select'

const defaultOptions = {
	twoway: false,
	lazy: true,
	isolated: true,
}

export default function makeStatusSelectComponent({ twoway = false, lazy = true, isolated = true } = defaultOptions) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		twoway,
		lazy,
		isolated,
		components: {
			itSelect: makeSelect({
				twoway,
				lazy,
				isolated,
			}),
		},
		attributes: {
			required: [ ],
			optional: [
				'value',
				'displayFullName',
				'filterStatuses',
				'filterAt',
				'disabled',
				'statuses',
			],
		},
		data() {
			return {
				displayFullName: true,
				filterStatuses: [],
				filterAt: false, //@ === (A or H)
				showLabel: true,
				value: '',
				disabled: false,
				statuses: [],
			}
		},
		computed: {
			displayStatuses() {
				const statuses = this.get('statuses')
				const filterStatuses = this.get('filterStatuses')

				let displayStatuses = statuses

				if (statuses && filterStatuses && Array.isArray(filterStatuses) && filterStatuses.length > 0) {
					displayStatuses = statuses.reduce((acc, status) => {
						if (filterStatuses.find(filterStatus => {
							return filterStatus === status.abbreviation
						})) {
							return acc
						}
						return acc.concat(status)
					}, [])
				}

				function statusFound(statusToMatchOn) {
					if (statusToMatchOn && displayStatuses) {
						return !!displayStatuses.find(status => {
							return status.abbreviation === statusToMatchOn
						})
					}
					return false
				}

				if (statusFound('A') &&
					statusFound('H') &&
					filterStatuses &&
					!filterStatuses.includes('@') &&
					!this.get('filterAt')
				) {
					displayStatuses = [{ abbreviation: '@', name: 'A or H' }].concat(displayStatuses)
				}

				return displayStatuses
			},
		},
	})
}
