export default function getAutomaticDescription(partDescription, inventoryTypeName, otherInfo = {}) {
	partDescription = partDescription ? partDescription.trim() : ''
	inventoryTypeName = inventoryTypeName ? inventoryTypeName.trim() : ''

	otherInfo = Object.values(otherInfo)
		.map(item => item ? item : '')
		.reduce((sum, item) => {
			item = item.toString().trim()
			return item ? sum.concat(item) : sum
		}, [])

	otherInfo = otherInfo.length > 0 ? ` (${otherInfo.join(' ')})` : ``

	return [
		inventoryTypeName,
		otherInfo,
		partDescription,
	]
		.filter(v => v)
		.join(' ')
		.trim()
}
