import template from './search-interface.html'

//Ractive components
import makeButton from '@isoftdata/button'

export default function createSearchInterfaceComponent() {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itButton: makeButton(),
		},
		attributes: {
			required: [],
			optional: [
				'searchQuery',
				'infoAlertText',
				'showKeywordSearchHint',
				'showClearButton',
				'searchButtonText',
				'icon',
				'title',
				'newButton',
				'searchDisabled',
			],
		},
		data() {
			return {
				searchQuery: '',
				infoAlertText: '',
				showKeywordSearchHint: true,
				showClearButton: true,
				searchButtonText: '',
				icon: 'fa-search',
				title: '',
				newButton: null,
				searchDisabled: false,
			}
		},
	})
}
