import template from './q-and-a.html'

//Ractive components
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeTextarea from '@isoftdata/textarea'

export default function createQuestionAndAnswerComponent({ logAndAlert, mediator }) {
	return Ractive.extend({
		template,
		data() {
			return {
				newQuestion: '',
				addingNewQuestion: false,
				savingNewQuestion: false,
				savingAnswers: [],
				questions: [],
				answers: [],
				saveQuestion: () => {},
				saveAnswer: () => {},
			}
		},
		components: {
			itInput: makeInput({ twoway: false }),
			itInputTwo: makeInput({ twoway: true, lazy: false }),
			itTable: makeTable(),
			itButton: makeButton(),
			itTextarea: makeTextarea({ twoway: true }),
		},
		computed: {
			questionsAndAnswers() {
				const questions = this.get('questions')
				const answers = this.get('answers')

				return questions.map(question => {
					const matchingAnswer = answers
						.find(answer => answer.questionId === question.questionId)

					let questionAndAnswer = {
						questionId: question.questionId,
						question: question.question,
						answer: (matchingAnswer && matchingAnswer.answer) || '',
					}

					if (matchingAnswer) {
						questionAndAnswer.answerId = matchingAnswer.answerId
					}

					return questionAndAnswer
				})
			},
		},
		oninit() {
			const ractive = this

			ractive.on('saveQuestion', async({ question }, event) => {
				if (event) {
					event.preventDefault()
				}

				if (!question) {
					return
				}
				ractive.set({ savingNewQuestion: true })

				try {
					const savedQuestion = await ractive.get('saveQuestion')({ question })
					ractive.splice('questions', 0, 0, savedQuestion)
					ractive.set({ savingNewQuestion: false, addingNewQuestion: false, newQuestion: '' })

					const answerInput = ractive.find(`#question-id-${savedQuestion.questionId}`)
					answerInput.scrollIntoView(false)
					answerInput.focus()
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving question')
				}
			})

			ractive.on('saveAnswer', async({ answerId, questionId }, event) => {
				let answer = {
					answer: event.target.value,
					questionId,
				}

				if (answerId) {
					answer.answerId = answerId
				}

				ractive.push('savingAnswers', questionId)

				try {
					const savedAnswer = await this.get('saveAnswer')(answer)

					ractive.upsert('answers', 'answerId', savedAnswer)
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving answer')
				} finally {
					const answerIndex = ractive.get('savingAnswers')
						.findIndex(questionId => questionId === questionId)
					ractive.splice('savingAnswers', answerIndex, 1)
				}
			})
		},
		async addNewQuestion() {
			await this.set({ addingNewQuestion: true, newQuestion: '' })
			this.find('#new-question').focus()
		},
	})
}
