import template from './quickbooks-modal.html'

//Ractive components
import makeModal from '@isoftdata/modal'

export default function quickbooksOnlineModal(mediator) {
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
		},
		data() {
			return {
				modalShown: false,
				context: false,
				loading: true,
				possibleEntities: [],
			}
		},
		computed: {

		},
		oninit() {
			const ractive = this

			ractive.observe('modalShown', shown => {
				const context = this.get('context')
				if (shown && context) {
					const { entity, id } = context

					Promise.all([
						mediator.call('emitToServer', `get quickbooks ${entity}`, { id }),
						mediator.call('emitToServer', 'find quickbooks customers', {}),
					]).then(([ pairedCustomer, possibleCustomers ])=> {
						ractive.set({
							loading: false,
							pairedQbCustomer: pairedCustomer,
							possibleEntities: possibleCustomers,
						})
					})
				}
			})
		},
	})
}
