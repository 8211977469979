import template from './part-pricing-modal.html'
import * as manufacturerModelHelper from 'shared/manufacturer-model-helper'
import * as currency from '@isoftdata/utility-currency'
import toTitleCase from 'to-title-case'
import { reduceToTruthyValues } from '@isoftdata/utility-object'
import pProps from 'p-props'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'

//const priceIsDifferent = (partPrice, newPrice) => !!(newPrice && partPrice !== newPrice)

const PRICING_KEYS = [ 'retailPrice', 'listPrice', 'wholesalePrice', 'core' ]

function getReducedPricing(part, pricing) {
	if (pricing) {
		pricing = PRICING_KEYS.reduce((acc, key) => {
			if (pricing[key]) {
				return { ...acc, [key]: pricing[key] }
			}
			return { ...acc, [key]: '' }
		}, {})
	}

	return pricing ? pricing : {}
}

function getIcon(partPrice, otherPrice) {
	if (!otherPrice) {
		return { icon: 'fa-ban', iconColorClass: 'text-dark' }
	} else if (partPrice === otherPrice) {
		return { icon: 'fa-equals', iconColorClass: 'text-dark' }
	} else if (otherPrice > partPrice) {
		return { icon: 'fa-chevron-up', iconColorClass: 'text-success' }
	} else if (otherPrice < partPrice) {
		return { icon: 'fa-chevron-down', iconColorClass: 'text-danger' }
	}
}

function getInterchangePricing({ _interchangeNumber, wholesalePrice, retailPrice, core }) {
	return reduceToTruthyValues({ object: { wholesalePrice, retailPrice, core } })
}

export default function createPartPricingModalComponent(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
			itTable: makeTable(),
		},
		data() {
			return {
				sortedPricing: [],

				currencyFormat: currency.format,
				manufacturerList: [],
				modelList: [],
				interchangeRecord: {},

				partModelId: null,

				manufacturerName: '',
				modelName: '',
				categoryName: '',

				categoryPricingIsLoading: false,
				show: false,

				context: '',
			}
		},
		computed: {
			manufacturerId() {
				return manufacturerModelHelper.getPartManufacturerIdFromName(this.get('manufacturerList'), this.get('manufacturer'))
			},
			modelId() {
				return manufacturerModelHelper.getPartModelIdFromModelNameAndManufacturerId(this.get('modelList'), this.get('model'), this.get('manufacturerId'))
			},
			reducedInterchangePricing() {
				return getReducedPricing(this.get('partPricing'), this.get('interchangePricing'))
			},
			reducedCategoryPricing() {
				return getReducedPricing(this.get('partPricing'), this.get('categoryPricing'))
			},
			hasCategoryPricing() {
				const { retailPrice, listPrice, wholesalePrice, core } = this.get('reducedCategoryPricing')

				const hasPricing = !!(retailPrice || listPrice || wholesalePrice || core)

				if (hasPricing) {
					return !this.get('pricing')
						.filter(({ category }) => category)
						.every(({ part, category }) => part === category)
				}
				return hasPricing
			},
			hasInterchangePricing() {
				const { retailPrice, listPrice, wholesalePrice, core } = this.get('reducedInterchangePricing')

				const hasPricing = !!(retailPrice || listPrice || wholesalePrice || core)

				if (hasPricing) {
					return !this.get('pricing')
						.filter(({ interchange }) => interchange)
						.every(({ part, interchange }) => part === interchange)
				}
				return hasPricing
			},
			hasAnyPricing() {
				return this.get('hasInterchangePricing') || this.get('hasCategoryPricing')
			},
			pricing() {
				const partPricing = this.get('partPricing')
				const reducedInterchangePricing = this.get('reducedInterchangePricing')
				const reducedCategoryPricing = this.get('reducedCategoryPricing')

				if (partPricing) {
					return PRICING_KEYS.map(key => {
						return { name: toTitleCase(key),
							part: partPricing[key],
							interchange: reducedInterchangePricing[key],
							category: reducedCategoryPricing[key] }
					})
				}
				return []
			},
			displayPricing() {
				return this.get('pricing').map(priceType => {
					const categoryIcon = getIcon(priceType.part, priceType.category)
					const interchangeIcon = getIcon(priceType.part, priceType.interchange)

					return { ...priceType,
						part: priceType.part ? currency.format(priceType.part) : '',
						interchange: priceType.interchange ? currency.format(priceType.interchange) : '',
						interchangeIcon: interchangeIcon ? interchangeIcon.icon : '',
						interchangeIconColorClass: interchangeIcon ? interchangeIcon.iconColorClass : '',
						category: priceType.category ? currency.format(priceType.category) : '',
						categoryIcon: categoryIcon ? categoryIcon.icon : '',
						categoryIconColorClass: categoryIcon ? categoryIcon.iconColorClass : '',
					}
				})
			},
			displayColumns() {
				let columns = [{ property: 'name', name: 'Name' },
					{ property: 'part', name: 'Current', align: 'right' }]

				if (this.get('hasInterchangePricing')) {
					columns.push({ property: 'interchange', name: 'Interchange', align: 'right' })
				}

				if (this.get('hasCategoryPricing')) {
					columns.push({ property: 'category', name: 'Category', align: 'right' })
				}

				return columns
			},
		},
		applyPricing(context) {
			let newPricing = {}

			if (context === 'CATEGORY') {
				newPricing = this.get('reducedCategoryPricing')
			} else if (context === 'INTERCHANGE') {
				newPricing = this.get('reducedInterchangePricing')
			}

			this.set({ show: false })
			this.fire('pricing-change', {}, { id: this.get('contextId'), ...this.get('partPricing'), ...reduceToTruthyValues({ object: newPricing }) })
		},
		show(context) {
			this.set({ show: true, context }).then(() => {
				if (context) {
					this.find(`#${context}`).focus()
				}
			})
		},
		async showIfNeeded({ interchangeRecord, context, contextId, interchangeNumber, category, inventoryTypeId, partPricing, partModelId, forceShow } = {}) {
			const ractive = this

			if ((partModelId && category) || ((interchangeNumber && inventoryTypeId) || interchangeRecord)) {
				ractive.set({ categoryPricingIsLoading: true })
				const pricing = await pProps({
					category: (partModelId && category) ? mediator.call('emitToServer', 'load category pricing', {
						partModelId,
						categoryName: category,
					}) : [],
					interchange: (!interchangeRecord && interchangeNumber && inventoryTypeId) ? mediator.call('emitToServer', 'load interchange', {
						inventoryTypeId,
						interchangeNumber,
						includePricing: true,
					}) : [ interchangeRecord ],
				})

				ractive.set({
					contextId,
					categoryPricingIsLoading: false,
					partPricing,
				})

				if (pricing.category && pricing.category.length > 0) {
					pricing.category = pricing.category[0]

					pricing.category = reduceToTruthyValues({
						object: {
							wholesalePrice: pricing.category.defaultWholesale,
							retailPrice: pricing.category.defaultRetail,
							core: pricing.category.defaultCore,
						},
					})
				}

				if (pricing.interchange && pricing.interchange[0]) {
					interchangeNumber = pricing.interchange[0].interchangeNumber
					pricing.interchange = getInterchangePricing(pricing.interchange[0])
				} else {
					interchangeNumber = ''
				}

				await ractive.set({
					categoryPricing: pricing.category,
					interchangePricing: pricing.interchange,
					interchangeNumber,
				})

				if ((context === 'CATEGORY' && ractive.get('hasCategoryPricing')) ||
						(context === 'INTERCHANGE' && ractive.get('hasInterchangePricing')) || forceShow) {
					ractive.show(context)
				}
			}
		},
		oninit() {
			// eslint-disable-next-line no-unused-vars
			const ractive = this
		},
	})
}
