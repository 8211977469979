import template from './inventory.html'

export default ({ stateRouter, mediator }) => {
	stateRouter.addState({
		name: 'app.configuration.inventory',
		route: 'inventory',
		defaultChild: 'type',
		template: {
			template,
		},
		data: {},
		async resolve(data, parameters) {
			//
		},
		activate: context => {
			const ractive = context.domApi
		},
	})
}
