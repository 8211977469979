import template from './assign.html'
import { stringToBoolean } from '@isoftdata/utility-string'
import { format as formatDate } from 'date-fns'

//Ractive Components
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const inventoryFieldList = [
	{ name: 'Location', key: 'location' },
	{ name: 'UPC', key: 'upc' },
]

const formatForDates = 'iii, MMM do yyyy, h:mm a'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.scanner.assign',
		route: 'assign',
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: false }),
				itButton: makeButton(),
			},
			computed: {
				selectedInventoryField() {
					const selectedKey = this.get('selectedInventoryFieldKey')

					return this.get('inventoryFieldList')
						.find(field => field.key === selectedKey)
				},
			},
			getSelectedInventoryFieldName() {
				const selectedField = this.get('inventoryFieldList')
					.find(listItem => listItem.key === this.get('selectedInventoryFieldKey'))

				if (selectedField) {
					return selectedField.name
				}
				return ''
			},
			changeInventoryFieldKey(key) {
				stateRouter.go('app.scanner.assign', { selectedInventoryFieldKey: key })
			},
		},
		querystringParameters: [
			'selectedInventoryFieldKey',
			'inventoryId',
			'upc',
			'location',
			'inventoryFieldValue',
			'fromScan',
		],
		defaultParameters: {
			selectedInventoryFieldKey: 'location',
		},
		resolve(data, { selectedInventoryFieldKey, inventoryId, inventoryFieldValue, fromScan }) {
			const cachedAssignHistory = JSON.parse(localStorage.getItem('scannerAssignHistory'))

			return Promise.resolve({
				selectedInventoryFieldKey,
				inventoryFieldValue,
				inventoryId,
				history: cachedAssignHistory ? cachedAssignHistory : [],
				inventoryFieldList,
				fromScan: stringToBoolean(fromScan),
			})
		},
		activate({ domApi: ractive }) {
			ractive.observe('history', history => {
				localStorage.setItem('scannerAssignHistory', JSON.stringify(history))
			}, { init: false })

			ractive.on('assign', async() => {
				const { selectedInventoryField, inventoryFieldValue, inventoryId } = ractive.get('')

				if (inventoryId && Number(inventoryId) && inventoryFieldValue && selectedInventoryField) {
					const inventoryFieldObj = ractive.get('selectedInventoryField')

					try {
						const inventory = await mediator.call('emitToServer', 'load inventory', { inventoryId })

						let errorMessage = ''

						if (inventory.length === 0) {
							errorMessage = 'No inventory item with that SKU #'
						} else if (inventory && inventory[0]) {
							const inventoryItem = { ...inventory[0], [selectedInventoryField.key]: inventoryFieldValue }

							if (inventoryItem) {
								await mediator.call('emitToServer', 'save inventory item', { item: inventoryItem })
							}
						}

						await ractive.splice('history', 0, 0, {
							field: inventoryFieldObj,
							value: inventoryFieldValue,
							inventoryId,
							errorMessage,
							dateTime: formatDate(new Date(), formatForDates),
						})
					} catch (err) {
						if (err) {
							ractive.splice('history', 0, 0, {
								field: inventoryFieldObj,
								value: inventoryFieldValue,
								inventoryId,
								errorMessage: err.details[0].message,
								dateTime: formatDate(new Date(), formatForDates),
							})
						}
					} finally {
						const selectedInventoryFieldKey = ractive.get('selectedInventoryFieldKey')
						let routeParams = { selectedInventoryFieldKey }

						if (selectedInventoryFieldKey === 'location') {
							routeParams.inventoryFieldValue = inventoryFieldValue
						}

						stateRouter.go(null, routeParams, { inherit: false })
					}
				}
			})

			if (ractive.get('fromScan')) {
				//asign does checking for if the assign can successfully happen
				ractive.fire('assign')
			}
		},
	})
}
