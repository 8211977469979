import template from './printer.html'
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeCheckbox from '@isoftdata/checkbox'
import makeModal from '@isoftdata/modal'
import { booleanToYesNo } from '@isoftdata/utility-string'
import klona from 'klona'

export default function({ stateRouter, mediator, hasPermission, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.printer',
		route: 'printer',
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true }),
				itCheckbox: makeCheckbox({ twoway: true }),
				itModal: makeModal(),
			},
			async editPrinter(printerId) {
				console.log(printerId)
				const printer = this.get('printers').find(printer => printer.printerId === printerId)

				await this.set({
					editingPrinter: klona({
						printerId: printer.printerId,
						name: printer.name,
						displayName: printer.displayName,
						isVisible: !printer.isHidden,
					}),
					editPrinterModalShown: true,
				})

				this.find('#display-name-input').focus()
			},
			async savePrinterChanges(printerToSave) {
				printerToSave = {
					printerId: printerToSave.printerId,
					displayName: printerToSave.displayName || null,
					isHidden: !printerToSave.isVisible,
				}

				try {
					const savedPrinter = await mediator.call('emitToServer', 'save printer', { printer: printerToSave })
					this.set('editPrinterModalShown', false)
					this.upsert('printers', 'printerId', savedPrinter)
				} catch (err) {
					logAndAlert({ err })
				}
			},
		},
		async resolve() {
			const printers = await mediator.call('emitToServer', 'load printers', { showHidden: true })

			return {
				printers,
				editPrinterModalShown: false,
				yesNo: booleanToYesNo,
				hasPermission,
				editingPrinter: {},
			}
		},
	})
}
