import template from './report-preview.html'
import { getParameterValuesForReport, generatePdfPreview } from 'utility/report-viewer-helper'
import { makeReportViewerPreviewComponent } from '@isoftdata/report-viewer'
/**
 * This component allows you to preview a report given its id and parameters by embedding part of the report viewer component.
 */
export default function createReportPreviewModalComponent({ mediator }) {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			reportViewerPreview: makeReportViewerPreviewComponent({
				generatePdfPreview: ({ name, type, parameters }) => {
					return generatePdfPreview(mediator, { name, type, parameters })
				},
			}),
		},
		attributes: {
			required: [],
			optional: [],
		},
		data() {
			return {
				show: false,
				parameters: [],
				selectedReportId: null,
				selectedCategory: '',
				reportList: [],
			}
		},
		/**
		 *
		 * @param {number} selectedReportId - report id
		 * @param {object} passedParameters - key/value pairs of parameters/values
		 */
		async show(selectedReportId, passedParameters = {}) {
			const ractive = this
			const [ parameters, reportList ] = await Promise.all([
				getParameterValuesForReport(mediator, selectedReportId),
				mediator.call('emitToServer', 'load reports', { reportId: selectedReportId }),
			])

			// Apply parameter values that were passed in
			if (passedParameters && Object.keys(passedParameters).length) {
				for (const key in passedParameters) {
					const parameterIndex = parameters.findIndex(parameter => parameter.name === key)
					if (parameterIndex > -1) {
						parameters[parameterIndex].value = passedParameters[key]
					}
				}
			}

			ractive.set({
				show: true,
				parameters,
				selectedReportId,
				reportList,
				selectedCategory: reportList[0]?.category,
			})

			const rv = ractive.findComponent('reportViewerPreview')
			const selectedReport = rv.get('selectedReport')
			const parametersToSend = rv.get('parametersToSend')
			rv.fire('preview-cancel-report-clicked', {}, selectedReport, parametersToSend)
		},
		hide() {
			const ractive = this
			ractive.set({
				show: false,
			})
		},
	})
}
