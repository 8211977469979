import template from './interchange-selector.html'
import interchangeLookup from 'utility/interchange-lookup'

//Ractive components
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeInterchange from './interchange' // createInterchangeComponent

export default function createInterchangeComponent({ mediator, stateRouter, logAndAlert, disableEditingAttachments = false }, { twoway = true }) {
	return Ractive.extend({
		template,
		twoway,
		data() {
			return {
				inventoryTypeId: null,
				vehicleYear: null,
				partManufacturerName: null,
				partModelName: null,
				vehicleMake: null,
				vehicleModel: null,
				interchangeModalShown: false,
				interchangeLookupLoading: false,
				interchangeNumber: '',
				context: null,
				showLabel: true,
				pickingContext: {},
			}
		},
		components: {
			itInput: makeInput({ twoway }),
			itButton: makeButton(),
			itModal: makeModal(),
			interchange: makeInterchange({ mediator, stateRouter, disableEditingAttachments }),
		},
		computed: {
			canDoInterchangeLookup() {
				return !!(this.get('inventoryTypeId') && this.get('vehicleYear') &&
				(
					(this.get('partManufacturerName') && this.get('partModelName')) ||
					(this.get('vehicleMake') && this.get('vehicleModel'))
				))
			},
			shouldDoInterchangeLookup() {
				return !this.get('interchangeNumber').trim() &&
					this.get('canDoInterchangeLookup')
			},
		},
		async interchangeLookup() {
			const ractive = this

			const options = {
				searchType: 'APPLICATION',
				isExact: true,
				inventoryTypeId: ractive.get('inventoryTypeId'),
				partManufacturer: ractive.get('partManufacturer'),
				partModel: ractive.get('partModel'),
				vehicleYear: ractive.get('vehicleYear'),
				vehicleMake: ractive.get('vehicleMake'),
				vehicleModel: ractive.get('vehicleModel'),
			}

			ractive.set({ interchangeLookupLoading: true })

			try {
				const interchangeList = await interchangeLookup(mediator, options)

				if (interchangeList.length === 1) {
					ractive.set({ interchangeNumber: interchangeList[0].interchangeNumber })
				} else if (interchangeList.length > 1) {
					ractive.set({ interchangeModalShown: true })
						.then(() => {
							ractive.findComponent('interchange')
								.search(options)
						})
				} else if (interchangeList.length === 0) {
					await mediator.call('showMessage', { message: 'No interchange records found!' })
				}
			} catch (err) {
				if (err.message === 'MISSING_REQUIRED_FIELDS') {
					await mediator.call('showMessage', {
						type: 'warning',
						message: 'Part Type, Year, and either Vehicle Make and Model, or Part Manufacturer and Model are required',
						time: 8000,
					})
				}
			} finally {
				ractive.set({ interchangeLookupLoading: false })
			}
		},
		async tryInterchangeLookup() {
			const ractive = this

			if (ractive.get('shouldDoInterchangeLookup')) {
				try {
					return await ractive.interchangeLookup()
				} catch (err) {
					logAndAlert(err, mediator, 'Error performing Interchange lookup')
					return false
				}
			}

			return false
		},
		oninit() {
			const ractive = this

			ractive.on('interchangeSelection', (ractiveContext, interchangeRecord) => {
				ractive.set({ interchangeModalShown: false })
				ractive.fire('interchangeNumberSelected', {}, ractive.get('pickingContext'), interchangeRecord)
			})
		},
		onteardown() {
			//console.log('interchange selector teardown')
		},
	})
}
