import template from './side-select.html'

//Ractive components
import makeSelect from '@isoftdata/select'

const defaultOptions = {
	twoway: false,
	lazy: true,
	isolated: true,
}

export default function({ twoway = false, lazy = true, isolated = true } = defaultOptions) {
	return Ractive.extend({
		template,
		twoway,
		lazy,
		isolated,
		components: {
			itSelect: makeSelect({
				twoway,
				lazy,
				isolated,
			}),
		},
		data() {
			return {
				label: null,
				value: '',
				class: '',
				showLabel: true,
				styleClass: '',
				hasError: false,
				disabled: false,
				isLoading: false,
				wasSaved: false,
				savedInputIdList: [],
				showEmptyOption: true,
				emptyText: '',
				labelId: null,
				sizeClass: 'form-control-sm',
				title: '',
			}
		},
		oninit() {
			// eslint-disable-next-line no-unused-vars
			const ractive = this
		},
	})
}
