import type { Mediator } from 'types/common'
import type { FileAssociation } from 'server/db/file-db'

import makeStorageHelper from './storage-json-helper'
import pFileReader from 'promise-file-reader'
import type { BaseAttachmentFile, UnsavedAttachmentFile } from '@isoftdata/svelte-attachments'
import pMap from 'p-map'

const localStorageJSON = makeStorageHelper('localStorage')

interface FileFromClient {
	File: File
	mimeType: string
	name: string
	data?: string
	fileId?: number | null
	public?: boolean
	rank?: number
	size?: number
}

const saveFile = async (mediator: Mediator, endpoint: string, file: FileFromClient, additionalEndpointProps = {}) => {
	let { File, data, ...fileMetadata } = file

	const useHTTP = localStorageJSON.getItem('useHTTP')
	const useSockets = !useHTTP

	if (useSockets) {
		//if you're passed a data prop, it's a base64 encoded string.
		//if not, we need to read the file ourselves
		if (!data) {
			data = await pFileReader.readAsDataURL(File) //read the file as a base64 string
		}
		return await mediator.call('emitToServer', endpoint, { file: { ...fileMetadata }, data, ...additionalEndpointProps })
	} else {
		//We'll have to POST FormData to the server
		const formData = new FormData()
		formData.append('data', JSON.stringify({ file: fileMetadata, ...additionalEndpointProps }))
		formData.append('file', File) //for	the http request, we'll send fileData as a File object

		//We pass false here for the content-type because that will tell the underlying
		//fetch API to figure out the content-type header for us based on the body we pass
		// @ts-expect-error
		return mediator.call('emitToServer', endpoint, formData, false)
	}
}

export default function makeAttachmentFns(mediator: Mediator) {
	const youtubeUserSetting = {
		name: 'youTubeUser',
		settingType: 'Optional Configuration',
		category: 'Integrations',
	}

	return {
		saveFileAndAssociate: async (file: FileFromClient, association: FileAssociation) => {
			return await saveFile(mediator, 'save file and associate', file, { association })
		},
		saveFile: async (file: FileFromClient) => {
			return await saveFile(mediator, 'save file', file)
		},
		saveLogoFile: async (file: FileFromClient) => {
			return await saveFile(mediator, 'save logo file', file)
		},
		associateFile: async (file: FileFromClient, association: FileAssociation) => {
			return await mediator.call('emitToServer', 'associate file', { ...file, association })
		},
		setTopRankedFile: async (fileId: number, association: FileAssociation) => {
			return await mediator.call('emitToServer', 'set top ranked file', { fileId, association })
		},
		updatePublicFlag: async (fileIds: Array<number>, toPublic: boolean, association: FileAssociation) => {
			return await mediator.call('emitToServer', 'update public flag', { fileIds, toPublic, association })
		},
		deleteFileAssociation: async (fileIds: Array<number>, association: FileAssociation) => {
			return await mediator.call('emitToServer', 'delete file association', { fileId: fileIds, association })
		},
		loadYouTubeUser: async () => {
			const settingValue = (await mediator.call('emitToServer', 'check global setting', {
				...youtubeUserSetting,
				defaultValue: '{}',
			})) as string

			if (settingValue) {
				return JSON.parse(settingValue)
			}
		},
		saveYouTubeUser: async (youTubeUser: string) => {
			const res = await mediator.call('emitToServer', 'save global setting', {
				...youtubeUserSetting,
				value: JSON.stringify(youTubeUser),
			})

			return res
		},
	}
}

export function makeSvelteAttachmentFns(mediator: Mediator) {
	function onFilesAdded(files: Array<UnsavedAttachmentFile>, association: FileAssociation) {
		return pMap(files, async file => saveFile(mediator, 'save file and associate', file, { association }), { concurrency: 2 })
	}
	function onFilesDeleted(files: Array<BaseAttachmentFile>, association: FileAssociation) {
		return mediator.call('emitToServer', 'delete file association', { fileId: files.map(f => f.fileId).filter(Boolean), association })
	}
	function onPublicChange(file: BaseAttachmentFile, association: FileAssociation) {
		return mediator.call('emitToServer', 'update public flag', { fileIds: [file.fileId], toPublic: file.public, association })
	}
	function onRankChange(file: BaseAttachmentFile, association: FileAssociation) {
		return mediator.call('emitToServer', 'set top ranked file', { fileId: file.fileId, association })
	}

	return {
		onFilesAdded,
		onFilesDeleted,
		onPublicChange,
		onRankChange,
	}
}
