import template from './quickbooks-entity-management.html'
import fuzzy from 'fuzzysort'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import { pluralIfNeeded as plural } from '@isoftdata/utility-string'
import klona from 'klona'
import { logAndAlert } from 'utility/error-handler'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

//Ractive components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeCheckboxButton from '@isoftdata/checkbox-button'
import makeTd from '@isoftdata/td'

const noop = () => {}
const defaultSort = (a, b) => {
	return (a?.matchEnum < b?.matchEnum ? 1 : (a?.matchEnum > b?.matchEnum ? -1 : 0))
		|| (a?.syncEnum < b?.syncEnum ? 1 : (a?.syncEnum > b?.syncEnum ? -1 : 0))
		|| (a?.id < b?.id ? 1 : (a?.id > b?.id ? -1 : 0))
}
//const promiseNoop = () => Promise.resolve()

const entityMatchModalDeafults = Object.freeze({
	shown: false,
	entityList: [],
	selectedEntityMatch: {},
	sourceEntity: {},
	isLoading: false,
})

const confirmImportExportModalDefaults = Object.freeze({
	shown: false,
	skipShowing: false,
	context: '',
	entity: {},
	isSaving: false,
	isBulkAction: false,
})

const viewErrorModalDefaults = Object.freeze({
	shown: false,
	error: {
		code: '',
		detail: '',
		message: '',
	},
})

export default function(options = {}) {
	const {
		mediator,
		makeDisplayRecordFromItrack = noop,
		makeDisplayRecordFromQuickbooks = noop,
		makeItrackEntityPath = noop,
		makeQuickbooksEntityUrl = noop,
		sortFunction = defaultSort,
		itrackKey,
		displayItrackColumns = [{ property: 'name', name: 'Name' }],
		displayQuickbooksColumns = [{ property: 'name', name: 'Name' }],
	} = options
	const expectedProps = [
		'mediator',
		'makeDisplayRecordFromItrack',
		'makeDisplayRecordFromQuickbooks',
		'makeItrackEntityPath',
		'makeQuickbooksEntityUrl',
		'itrackKey',
	]
	const missingProps = expectedProps.filter(prop => !options[prop])
	if (missingProps.length > 0) {
		throw new Error(`Missing required initialization properies: ${missingProps.join(', ')}!`)
	}

	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itTable: makeTable(),
			itButton: makeButton(),
			itModal: makeModal(),
			itTd: makeTd(),
			itCheckboxButton: makeCheckboxButton({ twoway: false }),
		},
		attributes: {
			required: [
				'entityType',
			],
			optional: [
				'hideMatches',
				'systemSelection',
				'itrackEntityList',
				'quickbooksEntityList',
				'user',
				'canImport',
				'canExport',
				'canUpdate',
				'canMatch',
				'dependencyNote',
			],
		},
		transitions: {
			slide: ractiveTransitionsSlide,
		},
		data() {
			return {
				entitiesLoading: true,
				makeItrackEntityPath,
				makeQuickbooksEntityUrl,
				entityMatchModal: klona(entityMatchModalDeafults),
				hideMatches: false,
				confirmImportExportModal: klona(confirmImportExportModalDefaults),
				viewErrorModal: klona(viewErrorModalDefaults),
				infoModal: {
					shown: false,
				},
				systemSelection: {
					quickbooks: true,
					itrack: false,
				},
				itrackEntityList: [],
				quickbooksEntityList: [],
				entityType: '',
				plural,
				user: {},
				quickbooksSyncStatusList: [],
				quickbooksEntityMap: new Map(),
				itrackEntityMap: new Map(),
				itrackKey,
				expandedErrorIndex: null,
				canImport: true,
				canExport: true,
				canUpdate: true,
				canMatch: true,
				displayItrackColumns,
				displayQuickbooksColumns,
				selectedIds: [],
				selectAllButtonText: 'Select All',
				sortColumn: '',
				sortDirection: '',
				dependencyNote: '',
			}
		},
		computed: {
			displayItrackEntityList() {
				const itrackEntityList = this.get('itrackEntityList')
				const itrackEntityMap = this.get('itrackEntityMap')
				const itrackKey = this.get('itrackKey')

				return itrackEntityList.filter(entity => itrackEntityMap.has(entity[itrackKey])).map(entity => {
					const records = itrackEntityMap.get(entity[itrackKey])

					return {
						selected: !!entity.selected,
						...makeDisplayRecordFromItrack(entity),
						...records,
						...this.getEntitySyncMatchStatusForDisplay(records, { itrack: true }),
					}
				})
			},
			displayQuickbooksEntityList() {
				const quickbooksEntityList = this.get('quickbooksEntityList')
				const quickbooksEntityMap = this.get('quickbooksEntityMap')

				return quickbooksEntityList.filter(entity => quickbooksEntityMap.has(parseInt(entity.Id, 10))).map(entity => {
					const records = quickbooksEntityMap.get(parseInt(entity.Id, 10)) //we use a number for all map keys

					return {
						selected: !!entity.selected,
						...makeDisplayRecordFromQuickbooks(entity),
						...records,
						...this.getEntitySyncMatchStatusForDisplay(records, { quickbooks: true }),
					}
				})
			},
			displaySourceEntityList() {
				const itrackEntityList = this.get('displayItrackEntityList')
				const quickbooksEntityList = this.get('displayQuickbooksEntityList')
				const systemSelection = this.get('systemSelection')

				let sourceEntityList = []

				if (systemSelection.itrack) {
					sourceEntityList = itrackEntityList
				} else if (systemSelection.quickbooks) {
					sourceEntityList = quickbooksEntityList
				} else {
					throw new Error('Invalid system selection given. Neither itrack nor quickbooks are set.')
				}

				return sourceEntityList
			},
			displayMatchCandidateList() {
				const sourceEntity = this.get('entityMatchModal.sourceEntity')
				const itrackEntityList = this.get('displayItrackEntityList')
				const quickbooksEntityList = this.get('displayQuickbooksEntityList')
				const systemSelection = this.get('systemSelection')

				let matchCandidateList = []

				if (systemSelection.itrack) {
					matchCandidateList = quickbooksEntityList
				} else if (systemSelection.quickbooks) {
					matchCandidateList = itrackEntityList
				} else {
					throw new Error('Invalid system selection given. Neither itrack or quickbooks are set.')
				}

				return this.getFuzzyEntitySuggestions({
					entity: sourceEntity,
					matchCandidateList: matchCandidateList.filter(matchCandidate => !matchCandidate.matched),
					keysToMatch: [ 'name' ],
					sourceKey: 'name',
					matchKey: 'id',
				})
			},
			allUnmatchedSourceEntities() {
				return this.get('displaySourceEntityList')
					.filter(({ matched }) => !matched)
			},
			allDirtyEntityIds() {
				return this.get('displaySourceEntityList')
					.filter(({ syncEnum }) => syncEnum === 2)
					.map(({ id }) => id)
			},
			allUnmatchedEntityIds() {
				return this.get('displaySourceEntityList')
					.filter(({ matched }) => !matched)
					.map(({ id }) => id)
			},
			systemTitle() {
				const systemSelection = this.get('systemSelection')

				if (systemSelection.itrack) {
					return 'ITrack'
				} else if (systemSelection.quickbooks) {
					return 'QuickBooks'
				} else {
					return ''
				}
			},
			matchCandidateSystemTitle() {
				const systemSelection = this.get('systemSelection')

				if (systemSelection.itrack) {
					return 'QuickBooks'
				} else if (systemSelection.quickbooks) {
					return 'ITrack'
				} else {
					return ''
				}
			},
			sourceEntityKey() {
				const systemSelection = this.get('systemSelection')

				return systemSelection === 'itrack' ? 'itrackId' : 'quickbooksOnlineId'
			},
			matchEntityKey() {
				const systemSelection = this.get('systemSelection')

				return systemSelection === 'itrack' ? 'quickbooksOnlineId' : 'itrackId'
			},
			systemSelectionColumns() {
				const systemSelection = this.get('systemSelection')
				const displayItrackColumns = this.get('displayItrackColumns')
				const displayQuickbooksColumns = this.get('displayQuickbooksColumns')
				return systemSelection.itrack ? displayItrackColumns : displayQuickbooksColumns
			},
			computedColumns() {
				const systemSelection = this.get('systemSelection')
				const systemSelectionColumns = this.get('systemSelectionColumns')

				return [
					{ property: 'id', name: 'ID', class: 'tabular-nums, text-right' },
					{ property: 'selected', icon: 'fas fa-list', columnWidth: '1rem' },
					{ property: 'matchEnum', icon: 'fas fa-link', columnWidth: '1rem' },
					{ property: 'syncEnum', icon: 'fas fa-rotate', columnWidth: '1rem' },
					...systemSelectionColumns,
					{ property: systemSelection.itrack ? 'hasError' : 'matched', name: 'ITrack Actions' },
					{ property: systemSelection.quickbooks ? 'hasError' : 'matched', name: 'QuickBooks Actions' },
				]
			},
		},
		makeItrackEntityPath,
		makeQuickbooksEntityUrl,
		clearSelection() {
			const ractive = this
			ractive.set('selectedIds', [])
		},
		getEntitySyncMatchStatusForDisplay(entity, systemSelection) {
			const ractive = this
			const entityType = ractive.get('entityType')
			const itrackKey = this.get('itrackKey')

			const matched = systemSelection.quickbooks ? (entity?.itrack?.[itrackKey] || false) : (entity?.quickbooks?.Id || false)
			const hasError = !!entity?.syncStatus?.errorCode
			/*	0: errored (red)
				1: unmatched / not errored (yellow)
				2: matched / not errored (green) */
			let matchEnum = 0
			/*	0: not matched (gray)
				1: matched, in sync (green)
				2: matched, out of sync (itrack updated) (yellow / blue)
				3: matched, out of sync (qb updated) (red)	*/
			let syncEnum = 0

			// NOT errored (synced or not)
			if (!entity?.syncStatus?.errorCode) {
				matchEnum++
			}
			// matched
			if (systemSelection.itrack ? entity?.quickbooks?.Id : entity?.itrack?.[itrackKey]) {
				matchEnum++
				syncEnum++
			}

			// quickbooks is dirty (itrack updated)
			if (entity?.syncStatus?.quickbooksIsDirty) {
				syncEnum = 2
			}
			// sync token mismatch (qb updated)
			// TaxService objects don't give a sync token in the response, so check if there's a saved sync token first
			if (entity?.syncStatus?.quickbooksSyncToken && entity.quickbooks?.SyncToken && entity.syncStatus.quickbooksSyncToken != entity?.quickbooks?.SyncToken) {
				syncEnum = 3
			}

			const syncTitleMap = [
				`${entityType} is not matched between QuickBooks and ITrack.`,
				`${entityType} is matched, and in sync between QuickBooks and ITrack`,
				`${entityType} is matched, but needs re-exported to QuickBooks from ITrack`,
				`${entityType} is matched, but was edited on QuickBooks, which will be overwritten when re-exporting from ITrack`,
			]
			// 	"This {{entityType}} is{{ !matched ? ' not': ''}} matched with {{systemSelection.quickbooks ? 'an ITrack' : 'a QuickBooks'}} record {{matched ? `(${entityItem.matched})` : ''}}"

			return {
				syncEnum,
				matchEnum,
				syncTitle: syncTitleMap[syncEnum],
				matchTitle: entity.syncStatus?.errorCode ? `Error` : `This ${entityType} is${ !matched ? ' not' : ''} matched with ${systemSelection.quickbooks ? 'an ITrack' : 'a QuickBooks'} record ${matched ? `(${matched})` : ''}`,
				matched,
				hasError,
			}
		},
		async doQuickBooksTransfer(entity, context) {
			const entityType = this.get('entityType')
			const isBatch = Array.isArray(entity)

			if (context === 'Import') {
				const result = await mediator.call('emitToServer', `${isBatch ? 'batch ' : ''}import entity to itrack`, {
					entityType,
					entity,
				},
				{
					message: `Error ${isBatch ? 'batch ' : ''}importing ${entityType} to ITrack`,
				})
				return result // quickbooks, itrack, syncStatus properties
			} else if (context === 'Export') {
				try {
					const result = await mediator.call('emitToServer', `${isBatch ? 'batch ' : ''}export entity to quickbooks`, {
						entityType,
						entity,
					},
					{
						doNotAlert: true,
						context: `${isBatch ? 'Batch e' : 'E'}xporting ${entityType || 'Unknown Entity'} to QuickBooks`,
						message: `Error ${isBatch ? 'batch ' : ''}exporting ${entityType || 'Unknown Entity'} to QuickBooks`,
					})
					return result
				} catch (err) {
					console.error(err)
					logAndAlert(err, null, `Failed to export ${entityType} to QuickBooks`, entity.itrack)
				}
			} else if (context === 'Update') {
				try {
					const result = await mediator.call('emitToServer', `${isBatch ? 'batch ' : ''}export entity to quickbooks`, {
						entityType,
						entity: isBatch ? entity : entity?.id,
						qbId: entity?.syncStatus?.quickbooksOnlineId,
						syncToken: entity?.syncStatus?.quickbooksSyncToken,
					},
					{
						doNotAlert: true,
						context: `${isBatch ? 'Batch e' : 'E'}xporting ${entityType || 'Unknown Entity'} ${entity.toString()} to QuickBooks`,
						message: `Error ${isBatch ? 'batch ' : ''}exporting ${entityType || 'Unknown Entity'} to QuickBooks`,
					})
					return result
				} catch (err) {
					console.error(err)
					logAndAlert(err, null, `Failed to update ${entityType} on QuickBooks`, entity.itrack)
				}
			}
		},
		makeDisplayRecordFromItrack,
		makeDisplayRecordFromQuickbooks,
		showEntityMatchModal(entity) {
			this.set({
				'entityMatchModal.sourceEntity': entity,
				'entityMatchModal.selectedEntityMatch': {},
				'entityMatchModal.shown': true,
				'entityMatchModal.isLoading': true,
			})

			this.set({ 'entityMatchModal.isLoading': false })
		},
		getFuzzyEntitySuggestions({
			entity = {},
			matchCandidateList = [],
			keysToMatch = [],
			sourceKey = '',
			matchKey = '',
		} = {}) {
			//TODO minimum length should probably be an input parameter?
			const stringChunks = entity[sourceKey].split(' ').filter(part => part.length >= 3)

			let suggestionsMap = new Map()

			for (const chunk of stringChunks) {
				const chunkSuggestions = fuzzy.go(chunk, matchCandidateList, {
					keys: keysToMatch,
				}).map(({ obj, score }) => {
					return { original: obj, score }
				})

				if (chunkSuggestions.length > 0) {
					for (const { original, score } of chunkSuggestions) {
						const hasExistingSuggestion = suggestionsMap.has(original[matchKey])
						if (!hasExistingSuggestion || (hasExistingSuggestion && score > suggestionsMap.get(original[matchKey]).score)) {
							suggestionsMap.set(original[matchKey], { ...original, score })
						}
					}
				}
			}

			return sortArrayByObjectKey({
				array: Array.from(suggestionsMap.values()),
				key: 'score',
			}).reverse()
		},
		setEntityMatchSelection(entity) {
			this.set({ 'entityMatchModal.selectedEntityMatch': entity })
		},
		closeEntityModal() {
			this.set({ 'entityMatchModal': klona(entityMatchModalDeafults) })
		},
		showImportExportEntityModal(context = 'Import', entity) {
			const isBulkAction = Array.isArray(entity)
			const skipShowing = this.get('confirmImportExportModal.skipShowing')

			const showModal = isBulkAction || !skipShowing || entity.syncEnum === 3

			this.set({
				'confirmImportExportModal.shown': showModal,
				'confirmImportExportModal.context': context,
				'confirmImportExportModal.entity': entity,
				'confirmImportExportModal.isBulkAction': isBulkAction,
			})

			if (!showModal) {
				this.confirmImportExportConfirmationModal(entity)
			}
		},
		closeImportExportConfirmationModal() {
			this.set({ confirmImportExportModal: klona({
				...confirmImportExportModalDefaults,
				skipShowing: this.getSkipShowingImportExportConfirmModal(),
			}) })
		},
		// Used to update the value of one or more records, ITrack, QuickBooks, and SyncStatus
		updateMaps(records = []) {
			const ractive = this
			if (!Array.isArray(records)) {
				records = [ records ]
			}
			// itrack, quickbooks, syncStatus
			const itrackKey = ractive.get('itrackKey')
			const itrackEntityMap = ractive.get('itrackEntityMap')
			const quickbooksEntityMap = ractive.get('quickbooksEntityMap')
			const hideMatches = ractive.get('hideMatches')

			/* This reducer will update every record in the itrackEntityMap and quickbooksEntityMap
				And then return them on an object that we can ractive.set() once we're done */
			const newMaps = records.reduce(({ itrackEntityMap, quickbooksEntityMap }, { itrack, quickbooks, syncStatus }) => { // return the modified it/qb maps
				// If we just deleted an entity, or we're hiding synced and it was just synced, remove it from the maps and return
				if (quickbooks?.status === 'Deleted' || (hideMatches && !syncStatus.quickbooksIsDirty && syncStatus.quickbooksOnlineId && syncStatus.quickbooksSyncToken == quickbooks.SyncToken)) {
					itrackEntityMap.delete(itrack[itrackKey])
					quickbooksEntityMap.delete(Number(quickbooks.Id))
					return { itrackEntityMap, quickbooksEntityMap }
				}
				const getNewValue = existingValue => {
					return {
						...existingValue,
						itrack,
						syncStatus: syncStatus || false,
						quickbooks,
						selected: false,
					}
				}

				const itrackId = itrack?.[itrackKey] || syncStatus?.itrackId
				if (itrackId) {
					const existingValue = itrackEntityMap.get(itrackId) || {}
					itrackEntityMap.set(itrackId, getNewValue(existingValue))
				}

				const quickbooksOnlineId = Number(quickbooks?.Id || syncStatus?.quickbooksOnlineId) // quickBooksOnlineId may be a string
				if (quickbooksOnlineId) {
					const existingValue = quickbooksEntityMap.get(Number(quickbooksOnlineId)) || {}
					quickbooksEntityMap.set(quickbooksOnlineId, getNewValue(existingValue))
				}

				return { itrackEntityMap, quickbooksEntityMap }
			}, { itrackEntityMap, quickbooksEntityMap })
			ractive.set({ selectedIds: [], ...newMaps })
		},
		async confirmImportExportConfirmationModal(entity, context, skipShowing) {
			try {
				const entityType = this.get('entityType')
				this.set({ 'confirmImportExportModal.isSaving': true })
				const savedRecords = await this.doQuickBooksTransfer(entity, context)

				this.updateMaps(savedRecords)

				// Quickbooks and ITrack errors
				if (Array.isArray(savedRecords)) {
					const numErrored = (savedRecords.filter(({ quickbooks, syncStatus }) => quickbooks?.Fault || syncStatus?.errorCode)).length
					const numSuccess = savedRecords.length - numErrored

					if (numErrored) {
						this.showViewErrorModal({
							name: `${context} ${savedRecords.length} ${this.get('entityType')}${savedRecords.length === 1 ? '' : 's'}`,
							syncStatus: {
								errorMessage: `${numErrored} ${entityType}${numErrored === 1 ? '' : 's'} failed to ${context.toLowerCase()}.`,
								errorDetail: `${numErrored} out of ${savedRecords.length} ${entityType}${numErrored === 1 ? '' : 's'} failed to ${context.toLowerCase()}. ${numSuccess} ${entityType}${numSuccess === 1 ? '' : 's'} ${context.toLowerCase()}ed successfully.`,
							},
							batch: true,
						})
					}
				} else if (savedRecords?.quickbooks?.Fault || savedRecords?.syncStatus?.errorCode) {
					this.showViewErrorModal({
						name: entity.name || `${context} ${entityType}`,
						...entity,
						...savedRecords,
					})
				}

				this.setSkipShowingImportExportConfirmModal(skipShowing)
				this.closeImportExportConfirmationModal()
			} catch (err) {
				console.error(err)
			} finally {
				this.set({ 'confirmImportExportModal.isSaving': false })
			}
		},
		async confirmEntityMatchModal(sourceEntity, matchEntity) {
			try {
				console.log(sourceEntity, matchEntity)
				const systemSelection = this.get('systemSelection')
				const quickbooksOnlineSyncStatus = {
					itrackId: systemSelection.itrack ? sourceEntity.id : matchEntity.id,
					entity: this.get('entityType'),
					operation: 'Export',
					quickbooksOnlineId: systemSelection.quickbooks ? sourceEntity.id : matchEntity.id,
					quickbooksIsDirty: false,
					errorCode: null,
					errorDetail: null,
					errorElement: null,
					errorMessage: null,
					quickbooksSyncToken: matchEntity.quickbooks.SyncToken,
				}

				const savedQuickbooksOnlineSyncStatus = await mediator.call('emitToServer', 'update quickbooks sync status', { quickbooksOnlineSyncStatus }, { doNotAlert: true })
				const itrackEntityMap = this.get('itrackEntityMap')
				const quickbooksEntityMap = this.get('quickbooksEntityMap')

				this.updateMaps({
					itrack: itrackEntityMap.get(savedQuickbooksOnlineSyncStatus.itrackId).itrack,
					syncStatus: savedQuickbooksOnlineSyncStatus,
					quickbooks: quickbooksEntityMap.get(savedQuickbooksOnlineSyncStatus.quickbooksOnlineId).quickbooks,
				})
			} catch (err) {
				console.error(err)
				logAndAlert(err, null, 'An error occured updating quickbooks sync status, please contact ISoft support.')
			} finally {
				this.closeEntityModal()
			}
		},
		setSkipShowingImportExportConfirmModal(skipShowing) {
			const quickbooksConfirmImportExportModal = JSON.parse(localStorage.getItem('quickbooksConfirmImportExportModal')) || {}

			localStorage.setItem('quickbooksConfirmImportExportModal', JSON.stringify({
				...quickbooksConfirmImportExportModal,
				[this.get('user.userAccountId')]: skipShowing,
			}))
		},
		getSkipShowingImportExportConfirmModal() {
			const quickbooksConfirmImportExportModal = JSON.parse(localStorage.getItem('quickbooksConfirmImportExportModal')) || {}
			return !!quickbooksConfirmImportExportModal[this.get('user.userAccountId')]
		},
		showViewErrorModal(entity) {
			this.set({
				'viewErrorModal': {
					shown: true,
					entity,
				},
			})
		},
		closeViewErrorModal() {
			this.set({ viewErrorModal: klona(viewErrorModalDefaults) })
		},
		tableSort(sortProperty, sortDirection = 'ASC') {
			const ractive = this
			const table = ractive.findComponent('itTable')
			const rows = table.get('filteredRows')
			const sortColumn = { property: sortProperty }
			table.doSort({ rows, sortColumn, sortDirection, sameSortOrder: false })
		},
		initializeMaps() {
			const ractive = this

			//set the initial value before you set but the observer
			ractive.set({
				'confirmImportExportModal.skipShowing': ractive.getSkipShowingImportExportConfirmModal(),
				'quickbooksEntityList': ractive.get('quickbooksEntityList').sort(sortFunction),
				'itrackEntityList': ractive.get('itrackEntityList').sort(sortFunction) })

			//load the sync status list and initialize our mappings
			mediator.call('emitToServer', 'load quickbooks sync status list', { entity: ractive.get('entityType') })
				.then(quickbooksSyncStatusList => {
					ractive.set({ quickbooksSyncStatusList })
					const itrackKey = this.get('itrackKey')

					const itrackEntityMap = new Map()
					const quickbooksEntityMap = new Map()

					const itrackEntityList = ractive.get('itrackEntityList')
					const quickbooksEntityList = ractive.get('quickbooksEntityList')

					itrackEntityList.forEach(entity => {
						const syncStatus = quickbooksSyncStatusList.find(syncStatus => syncStatus.itrackId === entity[itrackKey])
						const quickbooks = (syncStatus ? (quickbooksEntityList.find(quickbooks => quickbooks.Id == syncStatus.quickbooksOnlineId) || false) : false)

						itrackEntityMap.set(entity[itrackKey], {
							itrack: entity,
							syncStatus: syncStatus || false,
							quickbooks,
						})
					})

					quickbooksEntityList.forEach(entity => {
						const syncStatus = quickbooksSyncStatusList.find(syncStatus => syncStatus.quickbooksOnlineId == entity.Id)
						const itrack = (syncStatus ? (itrackEntityList.find(itrack => itrack[itrackKey] == syncStatus.itrackId) || false) : false)

						quickbooksEntityMap.set(parseInt(entity.Id, 10), {
							itrack,
							syncStatus: syncStatus || false,
							quickbooks: entity,
						})
					})

					ractive.set({ itrackEntityMap, quickbooksEntityMap, entitiesLoading: false })
				})
			ractive.on('row-select', (context, entityItem) => {
				context.event.stopPropagation()
				const systemSelection = this.get('systemSelection')
				let sourceEntityList = systemSelection.itrack ? 'itrackEntityList' : 'quickbooksEntityList'

				ractive.findComponent('itTable').rowClick(entityItem.id)

				ractive.set(`${sourceEntityList}[${entityItem.originalIndex}].selected`, !entityItem.selected)
			})
			// clear selected rows when the user switches systems
			ractive.observe('systemSelection', () => ractive.clearSelection())
		},
	})
}
