import SalesOrderDocumentLine from './SalesOrderDocumentLine.mjs'

export default class SalesOrderDocumentLineFromVehicle {
	constructor({ saleDocument, vehicle, documentType, saveState }) {
		const description = getAutomaticDescription({
			year: vehicle.year,
			make: vehicle.make,
			model: vehicle.model,
		})

		const saleDocumentLine = {
			partManufacturer: '',
			partModel: '',
			vin: vehicle.vin,
			vehicleMake: vehicle.make,
			vehicleModel: vehicle.model,
			vehicleYear: vehicle.year,
			vehicleBodyStyle: vehicle.bodyStyle,
			description,
			interchangeNumber: '',
			cost: 0,
			price: 0,
			coreAmount: 0,
			quantity: 1,
			inventoryId: null,
			vehicleId: vehicle.vehicleId > 0 ? vehicle.vehicleId : null,
			rank: null,
			isHeld: null,
			isTaxed: true,
		}

		return new SalesOrderDocumentLine({
			saleDocument,
			documentType,
			saleDocumentLine,
			saveState,
		})
	}
}

function getAutomaticDescription(vehicleInfo) {
	vehicleInfo = Object.values(vehicleInfo)
		.map(item => item ? item : '')
		.reduce((sum, item) => {
			item = item.toString().trim()
			return item ? sum.concat(item) : sum
		}, [])

	return vehicleInfo?.length > 0 ? `(${vehicleInfo.join(' ')})` : ``
}
