import template from './quote.html'
import * as dateTimeUtility from '@isoftdata/utility-date-time'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import pProps from 'p-props'

//Ractive components
import makeReportSelectionModal from 'components/report-selection'
import makeTable from '@isoftdata/table'
import makeDateRange from '@isoftdata/date-range'
import makeButton from '@isoftdata/button'

const stateName = 'app.entity.quote'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: stateName,
		route: 'quote',
		querystringParameters: [ 'customerId', 'fromDate', 'toDate', 'quoteId', 'showClosed' ],
		template: {
			template,
			components: {
				itDateRange: makeDateRange(mediator),
				itTable: makeTable(),
				reportSelectionModal: makeReportSelectionModal({ mediator, logAndAlert }),
				itButton: makeButton(),
			},
			computed: {
				displayQuotes() {
					let quotes = this.get('quotes')
					const showClosed = this.get('showClosed')

					if (!showClosed) {
						quotes = quotes.filter(quote => !quote.isClosed)
					}

					const computedQuotes = quotes.map(quote => {
						return {
							...quote,
							total: (quote.subtotal + quote.tax).toFixed(2),
							displayIsClosed: quote.isClosed ? 'Yes' : 'No',
						}
					})

					return computedQuotes
				},
				selectedQuote() {
					const quotes = this.get('displayQuotes')
					const selectedQuoteId = this.get('selectedQuoteId')
					return quotes.find(quote => selectedQuoteId === quote.quoteId)
				},
			},
		},
		async resolve(data, parameters) {
			if (parameters.fromDate === undefined && parameters.toDate === undefined) {
				const defaultDates = dateTimeUtility.datesFromRange('Last 90 Days')

				parameters.fromDate = defaultDates.from
				parameters.toDate = defaultDates.to

				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				}
			} else {
				const res = await pProps({
					quotes: parameters.id ? mediator.call('emitToServer', 'load quotes', { customerId: parameters.id, fromDate: parameters.fromDate, toDate: parameters.toDate }) : [],
					range: dateTimeUtility.rangeFromDates(parameters.fromDate, parameters.toDate),
					dates: { from: parameters.fromDate, to: parameters.toDate },
					showModal: false,
					showClosed: isTrue(parameters.showClosed),
					selectedQuoteId: parseInt(parameters.quoteId, 10),
				})

				const foundQuote = res?.quotes?.find?.(quote => quote.quoteId === res.quoteId)

				if (res?.quotes && res.quoteId && !foundQuote) {
					throw {
						redirectTo: {
							name: null,
							params: { ...parameters, quoteId: null },
						},
					}
				} else {
					return res
				}
			}
		},
		activate({ domApi: ractive, parameters }) {
			stateRouter.on('stateChangeEnd', (context, state, parameters) => {
				if (state.name === 'app.entity.quote' && parameters.quoteId) {
					stateRouter.go('app.entity.quote.line', {}, { inherit: true })
				}
			})

			if (ractive.get('selectedQuoteId')) {
				stateRouter.go('app.entity.quote.line', {}, { inherit: true })
			}

			ractive.on('quote-clicked', (context, quoteId) => {
				console.log(quoteId)
				stateRouter.go('app.entity.quote.line', { ...parameters, quoteId })
			})

			ractive.on('itDateRange.dateChange', (context, dates) => {
				stateRouter.go(null, { ...parameters, fromDate: dates.from, toDate: dates.to })
			})

			ractive.on('showClosed-changed', (context, showClosed) => {
				stateRouter.go(null, { ...parameters, showClosed: !showClosed })
			})

			ractive.on('PRINT_QUOTE', (context, quoteId) => {
				const reportParameters = [
					{ parameterName: 'quotenum', value: parseInt(quoteId, 10) },
				]

				const reportJob = { type: 'Quote', reportParameters }

				const emailAddress = ractive.get('entity')?.emailAddress
				const emailSuggestions = emailAddress ? { [emailAddress]: emailAddress } : undefined

				ractive.findComponent('reportSelectionModal').printReport(reportJob, emailSuggestions)
			})

			ractive.on('toggleCloseQuote', async(context, quoteId, isClosed) => {
				try {
					await mediator.call('emitToServer', 'close quote', { quoteId, close: !isClosed })
					const quotes = await mediator.call('emitToServer', 'load quotes', {
						customerId: parameters.customerId,
						fromDate: parameters.fromDate,
						toDate: parameters.toDate,
					})
					ractive.set('quotes', quotes)
				} catch (err) {
					logAndAlert(err, mediator, 'Error closing quote')
				}
			})
		},
	})
}
