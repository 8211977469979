import type { Spread } from 'type-fest'
import { type TreeNode } from '@isoftdata/svelte-table'
import type { Mediator } from 'types/common'
//@ts-ignore
import financialNumber from 'financial-number'
import { formatISO } from 'date-fns'
import { treeify } from '@isoftdata/svelte-table'
import { v4 as uuid } from '@lukeed/uuid'
//@ts-ignore
import makeStorageHelper from 'utility/storage-json-helper'
const localStorageJSON = makeStorageHelper('localStorage')

export interface Customer {
	customerId: number
	billingCompany: string
	billingContact: string
	billingStreetAddress: string
	billingMailingAddress: string
	billingCity: string
	billingState: string
	billingZip: string
	billingCountry: string
	billingPhoneNumber: string
	shippingCompany: string
	shippingContact: string
	shippingStreetAddress: string
	shippingMailingAddress: string
	shippingCity: string
	shippingState: string
	shippingZip: string
	shippingCountry: string
	shippingPhoneNumber: string
	taxNumber: string
	taxItem: string
	accountLimit: number
	cashOnly: boolean
	notes: string
	type: string
	defaultSalesperson: string
	defaultPriceType: string
	defaultPaymentMethodId: number
	taxExpirationDate: string
	percentOfPrice: number
	faxNumber: string
	driversLicense: string
	emailAddress: string
	websiteUrl: string
	webUserId: number | null
	active: boolean
	poRequired: boolean
	dateEntered: string
	financeRate: number
	netTerms: number | null
}

export interface Payment {
	paymentId: number
	saleId: number | null
	uuid?: string
	parentUuid?: string | null
	customerId: number
	paymentMethodName: string | null
	checkAuthorizationNumber: string | null
	amount: number
	date: Date
	salesperson: string | null
	documentDate: Date | null
	parentPaymentId: number | null
	status: 'Payment' | 'Void' | null
	comments: string | null
	quickbooksId: string
	quickbooksEditSequence: string
	paymentTerminalTransactionId: number | null
}

export type DisplayPayment = Spread<
	Payment,
	{
		amount: string
		paymentMethodName: string
		comments: string
	}
>

export type PaymentTreeNode = TreeNode<
	Spread<
		DisplayPayment,
		{
			uuid: string
			parentUuid: string | null
			parentPaymentId: number | null
			paymentLines: DisplayPayment[]
		}
	>,
	'uuid',
	'parentUuid'
>

export interface PaymentMethod {
	paymentMethodId: number
	name: string | null
	usesCheckAuthorizationNumber: boolean
	addToNewCustomer: boolean
	overrideCashOnly: boolean
	promptForPayment: boolean
	isCard: boolean
}

export interface Sale {
	saleId: number
	storeId: number
	customerId: number | null
	documentDate: Date | null
	creationDate: Date
	salesperson: string | null
	terms: string | null
	taxCode: string | null
	tax: number
	subtotal: number
	total: number
	balance: number
	purchaseOrderId: string
	comments: string | null
	lastModifiedDate: Date
	shippingCarrier: string | null
	billingCompany: string
	billingContact: string
	billingStreet: string
	billingMailing: string | null
	billingCity: string
	billingState: string
	billingZip: string
	billingCountry: string
	billingPhoneNumber: string
	shippingCompany: string
	shippingContact: string
	shippingStreet: string
	shippingMailing: string
	shippingCity: string
	shippingState: string
	shippingZip: string
	shippingCountry: string
	shippingPhoneNumber: string
	status: 'Invoice' | 'Void'
	paid: number
	paymentMethod: string | null
	appliedCredit: number
	checkAuthorizationNumber: string
	quoteId: number | null
	shippingDate: string | null
	quickbooksEditSequence: string
}

export type GeneratePdfPreviewArgs = {
	id: number
	name: string
	type: string
	parameters: Record<string, string>
}

export type DisplaySale = Spread<
	Pick<Sale, 'saleId' | 'documentDate' | 'subtotal' | 'tax' | 'total' | 'paid' | 'balance' | 'salesperson' | 'appliedCredit'>,
	{
		salesperson: string
		total: string
	}
>

export type SalesTableRowWithPayment = Spread<DisplaySale, { paymentAmount: string; newBalance: string; payment: EditablePayment }>

export type EditablePayment = Spread<
	Omit<Payment, 'uuid' | 'paymentId' | 'amount' | 'documentDate' | 'checkAuthorizationNumber' | 'comments' | 'action'>,
	{
		uuid: string
		paymentId: number | null
		amount: string
		documentDate: string
		checkAuthorizationNumber: string
		comments: string
		action: 'insert' | 'update' | 'delete' | null
	}
>

export function getDisplaySales(sales: Sale[], showAppliedSales: boolean = false): DisplaySale[] {
	const computedSales = sales.map(({ saleId, documentDate, subtotal, tax, paid, balance, salesperson, appliedCredit }) => {
		return {
			saleId,
			documentDate,
			subtotal,
			tax,
			total: financialNumber(subtotal).plus(tax).toString() as string, //TODO: remove this "as string" if we upgrade financial number to the version that has types
			paid,
			balance,
			salesperson: salesperson ?? '',
			appliedCredit,
		}
	})

	if (showAppliedSales) {
		return computedSales
	} else {
		return computedSales.filter(sale => financialNumber(sale.balance).gt('0'))
	}
}

export const getDefaultPayment = (customer: Customer, paymentMethods: PaymentMethod[], overrides?: Partial<Payment>): EditablePayment => {
	const now = new Date()
	const session = localStorageJSON.getItem('session')
	const userName: string | undefined = session?.userName

	return makeEditablePayment({
		paymentId: 0,
		status: 'Payment',
		parentPaymentId: null,
		amount: 0,
		customerId: customer.customerId,
		checkAuthorizationNumber: '',
		comments: '',
		salesperson: userName ?? '',
		saleId: 0, //column is not nullable, so 0 is the saleId value for unapplied
		date: now,
		documentDate: now,
		paymentMethodName: paymentMethods.find(method => method.paymentMethodId === customer.defaultPaymentMethodId)?.name ?? '',
		quickbooksId: '',
		quickbooksEditSequence: '',
		paymentTerminalTransactionId: null,
		...overrides,
	})
}

//When we interact with a payment record from the edit modal, we need to make sure some stuff is a string
export function makeEditablePayment(payment: Payment | DisplayPayment | PaymentTreeNode, parentPayment?: EditablePayment): EditablePayment {
	const { amount, documentDate, checkAuthorizationNumber, comments } = payment
	const documentDateString = documentDate ? formatISO(documentDate, { representation: 'date' }) : ''

	return {
		...payment,
		uuid: uuid(),
		paymentId: payment.paymentId || null,
		parentPaymentId: parentPayment?.paymentId ? parentPayment.paymentId : payment.parentPaymentId ?? -1,
		amount: financialNumber(amount.toString()).toString(2),
		documentDate: parentPayment ? parentPayment?.documentDate : documentDateString,
		checkAuthorizationNumber: checkAuthorizationNumber ?? '',
		paymentMethodName: parentPayment ? parentPayment.paymentMethodName : payment.paymentMethodName ?? '',
		comments: parentPayment ? parentPayment.comments : comments ?? '',
		action: null,
	}
}

const makePayment = (payment: Payment) => {
	return {
		...payment,
		amount: payment?.amount?.toString() || '0',
		documentDate: payment.documentDate,
		checkAuthorizationNumber: payment.checkAuthorizationNumber ?? '',
		paymentMethodName: payment.paymentMethodName ?? '',
		comments: payment.comments ?? '',
		paymentLines: [],
	}
}

export function computePaymentsWithVirtualParents(payments: Payment[]): PaymentTreeNode[] {
	const paymentsWithParents = payments.filter(payment => payment.parentPaymentId && payment.parentPaymentId > 0)
	const parentPaymentIds = new Set(paymentsWithParents.map(payment => payment.parentPaymentId))
	let paymentsToTreeify = []

	for (const payment of payments) {
		const parentUuid = uuid()

		if (parentPaymentIds.has(payment.paymentId)) {
			const paymentLines = payments
				.filter(potentialPayment => potentialPayment.parentPaymentId === payment.paymentId || potentialPayment.paymentId === payment.paymentId)
				.map(paymentLine => {
					return {
						...makePayment(paymentLine),
						uuid: uuid(),
						parentUuid,
						parentPaymentId: paymentLine.parentPaymentId,
					}
				})

			paymentsToTreeify.push({
				...makePayment(payment),
				uuid: parentUuid,
				parentUuid: null,
				parentPaymentId: null,
				//For this "virtual" parent payment, the amount will be the sum of all payment lines
				amount: paymentLines.reduce((acc, p) => acc.plus(p.amount), financialNumber('0')).toString(),
				paymentLines,
			})

			//These are the children(essentially payment lines)
			paymentsToTreeify.push(...paymentLines)
		} else if (!payment.parentPaymentId || payment.parentPaymentId <= 0) {
			//This is a "real" payment that is not a child of another payment
			paymentsToTreeify.push({
				...makePayment(payment),
				uuid: parentUuid,
				parentUuid: null,
				parentPaymentId: null,
				paymentLines: [makePayment(payment)],
			})
		}
	}

	return treeify(paymentsToTreeify, 'uuid', 'parentUuid')
}

export async function loadCustomerBalance(entityContext: 'customer' | 'vendor', id: number, mediator: Mediator) {
	let balance = 0
	if (entityContext === 'customer' && id) {
		//@ts-ignore
		balance = (await mediator.call('emitToServer', 'load customer balance', { customerId: id }))?.[0]?.balance
	}

	return balance
}
