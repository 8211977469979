import attachmentHelper from './attachment-helper.ts'

export default function(mediator, files, existingFiles, association) {
	const { saveFile, saveFileAndAssociate } = attachmentHelper(mediator)
	let maxRank = 0

	if (existingFiles.length > 0) {
		const ranks = existingFiles.map(file => file.rank)
		maxRank = Math.max.apply(Math, ranks)
		maxRank = maxRank >= 0 ? maxRank : 0
	}

	return Promise.all(Array.from(files).map(async file => {
		const newFile = {
			name: file.name,
			size: file.size,
			mimeType: file.type,
			rank: maxRank + 1,
			File: file,
		}

		maxRank += 1

		if (association) {
			return await saveFileAndAssociate(newFile, association)
		} else {
			return await saveFile(newFile)
		}
	}))
}
