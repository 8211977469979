import template from './contact.html'
import * as dateTimeUtility from '@isoftdata/utility-date-time'

//Ractive Components
import makeDateRangePicker from '@isoftdata/date-range'
import makeTouchPointModal from 'components/customer-contact-modal'
import makeCheckbox from '@isoftdata/checkbox'

const stateName = 'app.entity.contact'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: stateName,
		route: 'contact',
		querystringParameters: [ 'id', 'entityContext', 'fromDate', 'toDate', 'showClosed', 'customerTicketId' ],
		defaultParameters: {
			showClosed: 0,
		},
		defaultChild: 'correspondence',
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox({ twoway: true }),
				itRangePicker: makeDateRangePicker(),
				touchpointModal: makeTouchPointModal(),
			},
		},
		async resolve(data, parameters) {
			const { id: customerId, fromDate, toDate, showClosed, customerTicketId } = parameters

			if (!fromDate && !toDate) {
				const defaultDates = dateTimeUtility.datesFromRange('Last 365 Days')
				throw {
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				}
			}

			return {
				users: await mediator.call('emitToServer', 'load users', { }),
				range: dateTimeUtility.rangeFromDates(fromDate, toDate),
				dates: { from: fromDate, to: toDate },
				showClosed: !!parseInt(showClosed, 10),
				customerTicketId: customerTicketId ? parseInt(customerTicketId, 10) : null,
				customerId: parseInt(customerId, 10),
			}
		},
		activate(context) {
			const { domApi: ractive } = context
			ractive.on('itRangePicker.dateChange', (context, dates) => {
				stateRouter.go(null, { fromDate: dates.from, toDate: dates.to }, { inherit: true })
			})

			ractive.observe('showClosed', showClosed => {
				stateRouter.go(null, { showClosed: showClosed ? 1 : 0 }, { inherit: true })
			}, { init: false })

			ractive.on('touchpointModal.confirm', async context => {
				try {
					let customerTicketEntry = context.touchpoint
					let customerTicket = { ...context.correspondence, customerId: ractive.get('customerId') }
					let customerTicketId = ractive.get('customerTicketId')

					// New touchpoint on existing correspondence
					if (!context.createCorrespondence && customerTicketId) {
						customerTicket = {
							...customerTicket,
							customerTicketId,
						}

						customerTicketEntry = {
							...context.touchpoint,
							customerTicketId,
						}
					}

					const saved = await mediator.call('emitToServer', 'save customer ticket entry', { customerTicketEntry, customerTicket })

					mediator.call('correspondenceChanged', saved.customerTicket)
					if (context.createCorrespondence) { // Select new correspondence after creating it
						stateRouter.go('app.entity.contact.correspondence.touchpoint', { customerTicketId: saved.customerTicket.customerTicketId }, { inherit: true })
					} else { // Upsert new touchpoint if adding to existing correspondence
						mediator.call('touchpointChanged', saved.customerTicketEntry)
					}
					ractive.findComponent('touchpointModal').set({ modalShown: false })
				} catch (err) {
					logAndAlert(err, mediator, 'Error customer ticket')
				}
			})

			const removeCreateEntryProvider = mediator.provide('createEntry', createCorrespondence => {
				ractive.findComponent('touchpointModal').fire('show', {
					correspondence: { assignedToUserId: ractive.get('session.userAccountId') },
					createCorrespondence: !!createCorrespondence,
				})
			})

			context.on('destroy', () => {
				removeCreateEntryProvider()
			})
		},

	})
}
