import pProps from 'p-props'
import { stringToBoolean } from '@isoftdata/utility-string'
import component from './Payment.svelte'
import { parseISO } from 'date-fns'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.payment',
		route: 'payment',
		querystringParameters: [ 'customerId', 'showApplied', 'lastModified' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, { id, paymentId, saleIds, showApplied }) {
			showApplied = stringToBoolean(showApplied)

			function getSelectedSaleIds(saleIds) {
				let selectedSaleIds = saleIds
				if (!Array.isArray(selectedSaleIds)) {
					selectedSaleIds = [ selectedSaleIds ].filter(v => v)
				}

				return selectedSaleIds.map(saleId => parseInt(saleId, 10))
			}

			let res = await pProps({
				initialCustomerBalance: ((await mediator.call('emitToServer', 'load customer balance', { customerId: id }))?.[0]?.balance ?? 0)?.toString() ?? '0',
				payments: mediator.call('emitToServer', 'load payments', { customerId: id, applied: showApplied ? undefined : false }),
				sales: mediator.call('emitToServer', 'load sales', { customerId: id, status: 'Invoice' }),
				paymentMethods: mediator.call('emitToServer', 'load payment methods', {}),
				selectedPaymentId: paymentId ? parseInt(paymentId, 10) : undefined,
				customerId: parseInt(id, 10),
				showApplied,
				showVoidModal: false,
				selectedSaleIds: getSelectedSaleIds(saleIds),
				showTutorialLink: mediator.call('emitToServer', 'check user setting', {
					category: 'Options',
					name: 'Help -> Customer Payment Tutorial',
					settingType: 'Interface History',
					defaultValue: true,
					dataType: 'boolean',
				}),
			})

			//Turn all dates into native JS Dates
			res.sales = res.sales.map(sale => {
				return {
					...sale,
					documentDate: sale.documentDate ? parseISO(sale.documentDate) : sale.documentDate,
					creationDate: sale.creationDate ? parseISO(sale.creationDate) : sale.creationDate,
					lastModifiedDate: sale.lastModifiedDate ? parseISO(sale.lastModifiedDate) : sale.lastModifiedDate,
				}
			})

			res.payments = res.payments.map(payment => {
				return {
					...payment,
					documentDate: payment.documentDate ? parseISO(payment.documentDate) : payment.documentDate,
					date: payment.date ? parseISO(payment.date) : payment.date,
				}
			})

			return res
		},
	})
}
