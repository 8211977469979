import template from './sales-order.html'
import { stringToBoolean } from '@isoftdata/utility-string'
import * as currency from '@isoftdata/utility-currency'
import * as dateTimeHelper from '@isoftdata/utility-date-time'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'

function makeDisplayRecordFromItrack(itrackSalesOrder) {
	const { saleId, documentDate, total, balance, status, billingCompany, billingContact } = itrackSalesOrder

	return {
		id: saleId,
		customerName: (billingCompany && billingContact) ? `${billingCompany} (${billingContact})` : (billingCompany || billingContact),
		documentDate,
		status,
		balance,
		total,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksInvoice) {
	return {
		id: Number(quickbooksInvoice.Id) || quickbooksInvoice.Id,
		docNumber: Number(quickbooksInvoice.DocNumber) || quickbooksInvoice.DocNumber,
		customerName: quickbooksInvoice?.CustomerRef?.name || '',
		documentDate: quickbooksInvoice?.TxnDate || '',
		total: quickbooksInvoice?.TotalAmt || 0,
		balance: quickbooksInvoice?.Balance || 0,

	}
}

const displayItrackColumns = [
	{ property: 'status', name: 'Status' },
	{ property: 'documentDate', formatter: dateTimeHelper.toLocaleDateString, name: 'Date' },
	{ property: 'customerName', name: 'Customer' },
	{ property: 'balance', formatter: currency.format, name: 'Balance', class: 'text-right tabular-nums' },
	{ property: 'total', formatter: currency.format, name: 'Total', class: 'text-right tabular-nums' },
]

const displayQuickbooksColumns = [
	{ property: 'docNumber', name: '#' },
	{ property: 'documentDate', formatter: dateTimeHelper.toLocaleDateString, name: 'Date' },
	{ property: 'customerName', name: 'Customer' },
	{ property: 'balance', formatter: currency.format, name: 'Balance', class: 'text-right tabular-nums' },
	{ property: 'total', formatter: currency.format, name: 'Total', class: 'text-right tabular-nums' },

]

const stateName = 'app.quickbooks.sales-order'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'sales-order',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.sale', { documentId: id, documentType: 'INVOICE' }),
					makeQuickbooksEntityUrl: (id, entity) => `__qboBasePath__/${entity.RemainingCredit >= 0 ? 'creditmemo' : 'invoice'}?txnId=${id}`,
					itrackKey: 'saleId',
					displayItrackColumns,
					displayQuickbooksColumns,
				}),
			},
			computed: {
				qbSalesTaxEnabled() {
					const ractive = this
					const preferences = ractive.get('preferences')
					return preferences.TaxPrefs?.UsingSalesTax && preferences.TaxPrefs?.PartnerTaxEnabled
				},
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load invoices for quickbooks screen', { hideSynced: stringToBoolean(hideMatches) })

			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Sales Orders`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
