import component from './Delivery.svelte'
import homeDataLoader from 'shared/home-data-loader'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.delivery',
		route: 'delivery',
		querystringParameters: [ 'fromDate', 'toDate' ],
		template: {
			svelte: true,
			component,
		},
		resolve(data, parameters) {
			return homeDataLoader({
				fromDate: parameters.fromDate,
				toDate: parameters.toDate,
				documentLoadSocket: 'load deliveries',
				mediator,
			})
		},

	})
}
