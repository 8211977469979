import template from './category-setting.html'
import pProps from 'p-props'
import { stringToBoolean as toBool, booleanToYesNo as yesNo } from '@isoftdata/utility-string'

//Ractive components
import makeCheckbox from '@isoftdata/checkbox'
import makeSelect from '@isoftdata/select'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

function getObjectForScope({ scope, settingId, value, scopeId }) {
	switch (scope) {
		case 'global':
			return { settingId, value }
		case 'store':
			return { settingId, value, storeId: scopeId }
		case 'user':
			return { settingId, value, userAccountId: scopeId }
	}
}

function getScopeFindFunction(settingId, scope, scopeId) {
	switch (scope) {
		case 'global':
			return settingValue => settingValue.settingId === settingId
		case 'store':
			return settingValue => settingValue.settingId === settingId
				&& settingValue.storeId === scopeId
		case 'user':
			return settingValue => settingValue.settingId === settingId
				&& settingValue.userAccountId === scopeId
	}
}

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.setting.category',
		route: 'category',
		querystringParameters: [ 'category', 'scope' ],
		template: {
			template,
			components: {
				itModal: makeModal(),
				itTable: makeTable(),
				itSelect: makeSelect({ twoway: true }),
				itInput: makeInput({ twoway: false, lazy: true }),
				itCheckbox: makeCheckbox({ twoway: true, lazy: true }),
			},
			computed: {
				displaySettings() {
					const ractive = this
					let settings = this.get('settings')
					const filter = this.get('settingsFilter')
					const selectedScope = this.get('selectedScope')

					if (filter) {
						settings = settings.filter(setting => {
							return setting.name.toUpperCase().indexOf(filter.toUpperCase()) > -1 ||
								setting.settingType.toUpperCase().indexOf(filter.toUpperCase()) > -1
						})
					}

					return settings.map(setting => {
						const scopeContext = selectedScope ? selectedScope : ractive.getDefaultScope(setting.settingId)

						return { ...setting, value: ractive.getValueForScope(scopeContext, setting) }
					})
				},
			},
			getDefaultScope(settingId) {
				const setting = this.get('settings').find(setting => setting.settingId === settingId)

				return setting.scope.toLowerCase()
			},
			getGlobalSettingValue(setting) {
				const match = this.get(`globalSettingValues`).find(value => {
					return value.settingId === setting.settingId
				})

				return match ? match.value : ''
			},
			getStoreSettingValue(setting) {
				const storeId = this.get('store.storeId')

				const match = this.get(`storeSettingValues`).find(value => {
					return value.settingId === setting.settingId &&
						value.storeId === storeId
				})

				return match ? match.value : ''
			},
			getUserSettingValue(setting) {
				const userAccountId = this.get('user.userAccountId')

				const match = this.get(`storeSettingValues`).find(value => {
					return value.settingId === setting.settingId &&
						value.userId === userAccountId
				})

				return match ? match.value : ''
			},
			getValueForScope(scope, setting) {
				const ractive = this
				const storeId = this.get('store.storeId')
				const userAccountId = this.get('user.userAccountId')
				let match

				switch (scope) {
					case 'user':
						match = ractive.get(`${scope}SettingValues`)
							.find(getScopeFindFunction(setting.settingId, scope, userAccountId))

						return match ? match.value : ractive.getValueForScope('store', setting)
					case 'store':
						match = ractive.get(`${scope}SettingValues`)
							.find(getScopeFindFunction(setting.settingId, scope, storeId))

						return match ? match.value : ractive.getValueForScope('global', setting)
					case 'global':
						match = ractive.get(`${scope}SettingValues`)
							.find(getScopeFindFunction(setting.settingId, scope))

						return match ? match.value : setting.defaultValue
					default:
						return setting.defaultValue
				}
			},
			toggleSettingEdit(settingId) {
				const ractive = this

				if (settingId !== ractive.get('currentEditSettingId')) {
					ractive.set({ currentEditSettingId: settingId })
						.then(() => {
							const el = ractive.find(`#settingInput${ settingId}`)
							if (el) {
								el.select()
							}
						})
				}
			},
			async saveSetting(settingId, scope, context) {
				//context could be a ractive context(object) or a raw value(string/number)
				const value = typeof context === 'object' ? context?.event?.srcElement?.value : context
				const ractive = this
				if (!settingId || !scope) {
					return
				}

				scope = scope.toLowerCase()

				let scopeId = ''

				if (settingId && scope) {
					try {
						await ractive.set({ savingSetting: settingId })

						if (scope === 'user') {
							scopeId = ractive.get('user.userAccountId')
						} else if (scope === 'store') {
							scopeId = ractive.get('store.storeId')
						}

						const settingValue = getObjectForScope({ scope, settingId, value, scopeId })

						await mediator.call('emitToServer', `save ${scope} setting value`, { settingValue })

						const scopeFindFn = getScopeFindFunction(settingId, scope, scopeId)

						ractive.upsert(`${scope}SettingValues`, scopeFindFn, settingValue)
						ractive.set({ currentEditSettingId: null })
					} catch (err) {
						logAndAlert(err, mediator, `Error saving setting`)
					} finally {
						ractive.set({ savingSetting: null })
					}
				}
			},
		},
		resolve(data, { scope, category }) {
			const { user, store } = JSON.parse(localStorage.getItem('session'))
			const noScopeOrIs = compScope => !scope || scope === compScope

			return pProps({
				settings: mediator.call('emitToServer', 'load settings', { location: category }),
				globalSettingValues: noScopeOrIs('global') ? mediator.call('emitToServer', `load global setting`, {}) : [],
				storeSettingValues: noScopeOrIs('store') ? mediator.call('emitToServer', `load store setting`, { storeId: store.storeId }) : [],
				userSettingValues: noScopeOrIs('user') ? mediator.call('emitToServer', `load user setting`, { userAccountId: user.userAcocuntId }) : [],
				yesNo,
				toBool,
				selectedScope: scope ? scope : '',
				user,
				store,
			})
		},
		activate({ domApi: ractive }) {
			ractive.update('displaySettings')

			ractive.observe('selectedScope', scope => {
				stateRouter.go(null, { scope }, { inherit: true })
			}, { init: false })
		},
	})
}
