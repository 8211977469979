import template from './entity-search.html'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeCheckbox from '@isoftdata/checkbox'
import makeSearchInterface from 'components/search-interface'
import toTitleCase from 'to-title-case'
import * as stringUtil from '@isoftdata/utility-string'

export default function({ mediator, stateRouter, hasPermission }) {
	stateRouter.addState({
		name: 'app.entity-search',
		route: 'entity-search',
		querystringParameters: [ 'searchQuery', 'entityContext', 'showInactive', 'showBalances' ],
		defaultParameters: { searchQuery: '', showInactive: 'false', showBalances: 'false' },
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: 300 }),
				itButton: makeButton(),
				itCheckbox: makeCheckbox({ twoway: true }),
				searchInterface: makeSearchInterface(),
			},
			computed: {
				entityDisplayName() {
					return toTitleCase(this.get('entityContext') || '')
				},
			},
			clear() {
				stateRouter.go('app.redirect', { state: 'app.entity-search', searchQuery: '', entityContext: this.get('entityContext') })
			},
			hasPermission,
		},
		resolve: (data, { searchQuery, entityContext, showInactive, showBalances }) => {
			return Promise.resolve({
				searchQuery,
				entityContext,
				showInactive: stringUtil.stringToBoolean(showInactive),
				showBalances: stringUtil.stringToBoolean(showBalances),
			})
		},
		activate({ domApi: ractive }) {
			ractive.find('#keyword-search')?.focus()

			ractive.on('searchInterface.search', async(context, event) => {
				event?.preventDefault()
				const entityContext = ractive.get('entityContext')
				const searchQuery = ractive.get('searchQuery')
				const showInactive = ractive.get('showInactive')
				//const showBalances = ractive.get('showBalances')
				//TODO showBalances can be removed at some point. We just always display balances now.

				const searchOptions = { searchQuery, showInactive, showBalances: true }

				const count = await mediator.call('emitToServer', `load ${entityContext} search count`, searchOptions)
				console.log(count)
				if (count <= 500 || confirm(`This search contains ${count} results. This could take a while to load. Load the results anyway?`)) {
					stateRouter.go('app.entity-search.results', searchOptions, { replace: true, inherit: true })
				}
			})

			/* ractive.observe('searchQuery', searchQuery => {
				stateRouter.go('app.entity-search', { searchQuery, showAll: false }, { replace: true, inherit: true })
			}, { init: false })

			ractive.on('create-new-entity-clicked', () => {
				stateRouter.go('app.entity', { entityContext: ractive.get('entityContext') })
			})

			ractive.observe('showInactive', showInactive => {
				stateRouter.go('app.entity-search', { showInactive }, { replace: true, inherit: true })
			}, { init: false })

			ractive.observe('showBalances', showBalances => {
				stateRouter.go('app.entity-search', { showBalances }, { replace: true, inherit: true })
			}, { init: false })*/
		},
	})
}

