import type { AppContext, ResolveParameters } from 'types/common'
import type { Report as ServerReport } from 'server/db/report-db'

import component from './Reports.svelte'
import { serverReportToClientReport } from 'utility/report-viewer-helper'
import makeFavoriteReportsStore from 'stores/favorite-reports-store'

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const { user } = JSON.parse(localStorage.getItem('session') ?? '') as { user: { userAccountId: number } }
			const selectedReportId = (stateParameters.selectedReportId && parseInt(stateParameters.selectedReportId, 10)) || null

			const [settings, reportList] = await Promise.all([
				(await mediator.call('emitToServer', `load user setting`, { settingName: 'Favorite reports', userAccountId: user.userAccountId })) as Array<{ value: string }>,
				((await mediator.call('emitToServer', 'load reports', { userVisible: true, includeAvgTime: true })) as Array<ServerReport>).map(serverReportToClientReport),
			])

			// If they pass a category and report id that's not in the list, load and add it to the list so they can preview it.
			if ((stateParameters.selectedCategory || stateParameters.selectedCategory === '') && selectedReportId) {
				const missingReport = reportList.findIndex(report => report.id === selectedReportId) === -1
				if (missingReport) {
					const fetchedReport = ((await mediator.call('emitToServer', 'load reports', { reportId: stateParameters.selectedReportId })) as Array<ServerReport>)?.[0]
					if (fetchedReport) {
						reportList.push(serverReportToClientReport(fetchedReport))
					}
				}
			}
			return { reportList, selectedReportId, selectedCategory: stateParameters.selectedCategory, favorites: makeFavoriteReportsStore(mediator, settings?.[0]?.value ?? '') }
		},
	})
}
