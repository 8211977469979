import PurchaseOrderDocumentLine from './PurchaseOrderDocumentLine.mjs'
import getAutomaticDescription from '../document-line-description-builder.mjs'

export default class PurchaseOrderDocumentLineFromInventory {
	constructor({ purchaseOrder, inventory, inventoryType, saveState }) {
		const description = getAutomaticDescription(inventory.description, inventoryType?.name, {
			year: inventory.year,
			make: inventory.make,
			model: inventory.model,
			partManufacturer: inventory.partManufacturer,
			partModel: inventory.partModel,
			side: inventory.side,
		})

		const purchaseOrderLine = {
			purchaseOrderId: purchaseOrder.purchaseOrderId,
			inventoryId: inventory.inventoryId,
			description,
			price: inventory.cost,
			coreAmount: inventory.core,
			quantity: 1,
			quantityReceived: 0,
		}

		return new PurchaseOrderDocumentLine({
			purchaseOrderLine,
			saveState,
		})
	}
}
