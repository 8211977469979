import template from './store.html'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'

function makeDisplayRecordFromItrack(itrackStore) {
	return {
		id: itrackStore.storeId,
		name: itrackStore.name,
		original: itrackStore,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksStore) {
	return {
		id: Number(quickbooksStore.Id) || quickbooksStore.Id,
		name: quickbooksStore.Name,
		original: quickbooksStore,
	}
}

const stateName = 'app.quickbooks.store'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'store', // Department
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.configuration.store.manage', { storeId: id }),
					makeQuickbooksEntityUrl: id => `__qboBasePath__/locations`, // Can't link to a specific location
					// Maybe we want to do classes instead of locations, if so: __qboBasePath__/class
					/* displayQuickbooksColumns: [
					], */
					/* displayItrackColumns: [
					], */
					itrackKey: 'storeId',
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load stores for quickbooks screen', { hideSynced: stringToBoolean(hideMatches) })

			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Stores`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
