import template from './dashboard.html'
import { makeDashboardConfigurationComponent } from '@isoftdata/web-dashboard'

export default function({ stateRouter, mediator, hasPermission }) {
	stateRouter.addState({
		name: 'app.configuration.dashboard',
		route: 'dashboard',
		querystringParameters: [ 'dashboardReportId' ],
		template: {
			template,
			hasPermission,
			components: {
				itDashboardConfig: makeDashboardConfigurationComponent({
					loadDashboardReportsForConfiguration: () => {
						return mediator.call('emitToServer', 'load dashboard reports for configuration', {})
					},
					saveDashboardReport: ({ dashboardReport }) => {
						return mediator.call('emitToServer', 'save dashboard report', { dashboardReport })
					},
					deleteDashboardReport: ({ dashboardReportId }) => {
						return mediator.call('emitToServer', 'delete dashboard report', { dashboardReportId })
					},
				}),
			},
		},
		resolve(data, { dashboardReportId }) {
			return Promise.resolve({ dashboardReportId: Number(dashboardReportId) })
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.on('itDashboardConfig.selected-report-changed', (context, dashboardReportId) => {
				if (dashboardReportId) {
					stateRouter.go('app.configuration.dashboard.manage', { dashboardReportId })
				} else {
					// If there's no dashboardReportId, don't go to the manage state
					stateRouter.go('app.configuration.dashboard', { dashboardReportId: null })
				}
			})

			ractive.on('itDashboardConfig.open-report-clicked', (context, dashboardReportId) => {
				stateRouter.go('app.dashboard', { dashboardReportId })
			})
		},

	})
}
