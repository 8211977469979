export default class BidPart {
	constructor(TeardownItem) {
		this.vehicleBidPartId = TeardownItem.id
		this.inventoryTypeId = TeardownItem.inventoryTypeId
		this.description = TeardownItem.description
		this.category = TeardownItem.category || ''
		this.side = TeardownItem.side
		this.quantity = TeardownItem.quantity
		this.retailPrice = TeardownItem.retailPrice
		this.wholesalePrice = TeardownItem.wholesalePrice
		this.listPrice = TeardownItem.listPrice
		this.cost = TeardownItem.cost
		this.interchangeNumber = TeardownItem.interchangeNumber
		this.corePrice = TeardownItem.coreCost || 0
		this.partModelId = TeardownItem.partModelId
	}
}
