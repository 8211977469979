import template from './model.html'

import deepCopy from 'klona'
import * as currency from '@isoftdata/utility-currency'
import pProps from 'p-props'

//Ractive Components
import makePartTypeManModCat from 'components/part-type-man-mod-cat'
import makeManufacturerModal from 'components/part-manufacturer-modal'
import makePartModelModal from 'components/part-model-modal'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'
import makeCheckbox from '@isoftdata/checkbox'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.inventory.type.model',
		route: 'model',
		querystringParameters: [ 'inventoryTypeId', 'typeSetId', 'partManufacturerId', 'partModelId', 'categoryId' ],
		template: {
			twoway: true,
			template,
			data: {
				formatCurrency: currency.format,
			},
			components: {
				itButton: makeButton(),
				partModelModal: makePartModelModal({ mediator, logAndAlert }),
				partManufacturerModal: makeManufacturerModal({ mediator, logAndAlert }),
				itPartTypeManModCat: makePartTypeManModCat({ mediator, logAndAlert }),
				manufacturerTable: makeTable({ stickyHeader: true }),
				modelTable: makeTable({ stickyHeader: true }),
				defaultPricingTable: makeTable({ stickyHeader: true }),
				itSelect: makeSelect({ twoway: true }),
				itInput: makeInput({ twoway: true }),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itModal: makeModal(),
				itCheckbox: makeCheckbox({ twoway: false }),
			},
			computed: {
				hasModelSelected() {
					const model = this.get('selectedPartModel')
					return !!(model && model.partModelId)
				},
				categoryPricingColumns() {
					const columns = [
						{ property: 'name', name: 'Name', defaultSortColumn: true },
						{ property: 'defaultRetail', name: 'Retail', align: 'right' },
						{ property: 'defaultWholesale', name: 'Wholesale', align: 'right' },
						{ property: 'defaultCore', name: 'Core', align: 'right' },
					]

					return this.get('selectedCategoryId') ? [
						...columns,
						{
							property: 'save',
							icon: 'fa fa-check',
						},
					] : columns
				},
				displayManufacturerList() {
					const partModalCounts = this.get('partModalCounts')

					return this.get('manufacturerList').map(man => {
						return {
							...partModalCounts.find(({ partManufacturerId }) => partManufacturerId === man.partManufacturerId),
							...man,
						}
					})
				},
			},
			addPartModel() {
				const partModelModal = this.findComponent('partModelModal')
				partModelModal.show({
					inventoryTypeName: this.get('selectedInventoryType.name') || '',
					typeSetId: this.get('selectedInventoryTypeSet.inventoryTypeId'),
					manufacturer: this.get('selectedPartManufacturer.name'),
				}).then(() => {
					if (!this.get('selectedPartManufacturer.name')) {
						partModelModal.find('#partManufacturer').select()
					} else if (!this.get('selectedPartModel.name')) {
						partModelModal.find('#partModel').select()
					}
				})
			},
			editPartModel() {
				const partModelModal = this.findComponent('partModelModal')

				partModelModal.show({
					inventoryTypeName: this.get('selectedInventoryType.name') || '',
					typeSetId: this.get('selectedInventoryTypeSet.inventoryTypeId'),
					model: this.get('selectedPartModel.name'),
					manufacturer: this.get('selectedPartManufacturer.name'),
				}).then(() => {
					partModelModal.find('#partModel').select()
				})
			},
			editCategoryPricing(category) {
				this.set({
					selectedCategoryId: category.categoryId,
					editCategory: deepCopy(category),
				}).then(() => {
					this.find(`#default_pricing_category_id_${category.categoryId}`)?.scrollIntoView({
						block: 'center',
						inline: 'center',
						behavior: 'smooth',
					})
					this.find('#defaultRetailInput').select()
				})
			},
			async saveCategoryPricing(category) {
				const ractive = this

				ractive.set({ categoryPricingSaving: true })

				let saveCategory = {
					partModelId: category.partModelId,
					categoryName: category.name,
					defaultRetail: category.defaultRetail,
					defaultWholesale: category.defaultWholesale,
					defaultCore: category.defaultCore,
				}

				if (category.categoryPricingId) {
					saveCategory.categoryPricingId = category.categoryPricingId
				}

				try {
					const savedCategoryPricing = await mediator.call('emitToServer', 'save category pricing', { categoryPricing: saveCategory })

					console.log('saved category', savedCategoryPricing)
					console.log('categoryPricing', ractive.get('categoryPricing'))

					ractive.upsert('categoryPricing', 'categoryId', {
						...ractive.get('categoryPricing')
							.find(cat => cat.categoryId === category.categoryId),
						...savedCategoryPricing,
					})
					ractive.set({ selectedCategoryId: null })
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving category pricing')
				} finally {
					ractive.set({ categoryPricingSaving: false })
				}
			},
		},
		async resolve(data, parameters) {
			const { inventoryTypeId, typeSetId, partManufacturerId, partModelId, categoryId } = parameters
			const selectedInventoryType = await mediator.call('emitToServer', 'load inventory type by id', { inventoryTypeId })

			if (selectedInventoryType.usesManufacturerModel) {
				const res = await pProps({
					manufacturerList: typeSetId ? mediator.call('emitToServer', 'load part manufacturer list', { typeSetId }) : [],
					partModalCounts: typeSetId ? mediator.call('emitToServer', 'load part model counts', { typeSetId }) : [],
					modelList: (typeSetId && partManufacturerId) ? mediator.call('emitToServer', 'load part models using inventory type', { typeSetId, partManufacturerId }) : [],
					categoryPricing: partModelId ? mediator.call('emitToServer', 'load category pricing', { partModelId }) : [],
				})

				let selectedInventoryTypeSet = {}
				if (selectedInventoryType && selectedInventoryType.typeSetId) {
					if (selectedInventoryType.typeSetId === selectedInventoryType.inventoryTypeId) {
						selectedInventoryTypeSet = selectedInventoryType
					} else {
						selectedInventoryTypeSet = (await mediator.call('emitToServer', 'load inventory type by id', { inventoryTypeId: selectedInventoryType.typeSetId })) || selectedInventoryType
					}
				}

				return {
					...res,
					selectedInventoryType,
					selectedInventoryTypeSet,
					selectedPartManufacturer: res.manufacturerList.find(man => man.partManufacturerId == partManufacturerId) || {},
					selectedPartModel: res.modelList.find(model => model.partModelId == partModelId) || {},
					selectedCategoryId: categoryId ? parseInt(categoryId, 10) : '',
					savingActiveFlagModelIds: [],
				}
			} else {
				return {
					selectedInventoryType,
					savingActiveFlagModelIds: [],
				}
			}
		},
		activate({ domApi: ractive, parameters }) {
			const selectedPartManufacturer = ractive.get('selectedPartManufacturer')
			const selectedPartModel = ractive.get('selectedPartModel')

			const manufacturerTable = ractive.findComponent('manufacturerTable')
			const modelTable = ractive.findComponent('modelTable')

			if ((selectedPartManufacturer && selectedPartManufacturer.partManufacturerId) && manufacturerTable) {
				ractive.find(`#part_manufacturer_id_${selectedPartManufacturer.partManufacturerId}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
				/* manufacturerTable.setPageVisibleByItemId({
					id: selectedPartManufacturer.partManufacturerId,
					keyName: 'partManufacturerId',
				}) */
			}

			if ((selectedPartModel && selectedPartModel.partModelId) && modelTable) {
				ractive.find(`#part_model_id_${selectedPartModel.partModelId}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
				/* modelTable.setPageVisibleByItemId({
					id: selectedPartModel.partModelId,
					keyName: 'partModelId',
				}) */
			}

			ractive.observe('selectedInventoryType', inventoryType => {
				stateRouter.go(null, { inventoryTypeId: inventoryType.inventoryTypeId }, { inherit: true })
			}, { init: false })

			ractive.observe('selectedPartManufacturer', partManufacturer => {
				stateRouter.go(null, { partManufacturerId: partManufacturer.partManufacturerId }, { inherit: true })
			}, { init: false })

			ractive.observe('selectedPartModel', partModel => {
				stateRouter.go(null, { partModelId: partModel.partModelId }, { inherit: true })
			}, { init: false })

			ractive.on('modelSaved', ({ model }) => {
				ractive.upsert('modelList', 'partModelId', model)
				ractive.set({ selectedPartModel: model })
			})

			ractive.on('manufacturerSaved', ({ manufacturer: savedManufacturer }) => {
				const existing = ractive.get('manufacturerList')
					.find(man => man.partManufacturerId === savedManufacturer.partManufacturerId)
				const manufacturer = { ...existing, ...savedManufacturer }

				ractive.upsert('manufacturerList', 'partManufacturerId', manufacturer)
				ractive.set({ selectedPartManufacturer: manufacturer })
			})

			ractive.on('toggleModelActive', async(_context, partModel) => {
				//Keep track of which models are being saved so we can disable the checkbox
				await ractive.push('savingActiveFlagModelIds', partModel.partModelId)

				try {
					const savedModel = await mediator.call('emitToServer', 'save part model', { partModel: { ...partModel, active: partModel.active ? 1 : 0 } }, { doNotAlert: true })
					ractive.upsert('modelList', 'partModelId', savedModel)
				} finally {
					const savingPartModelIndex = ractive.get('savingActiveFlagModelIds').indexOf(partModel.partModelId)
					if (savingPartModelIndex > -1) {
						//Mark the part model as no longer being saved, so we can enable the checkbox
						ractive.splice('savingActiveFlagModelIds', savingPartModelIndex, 1)
					}
				}
			})
		},
	})
}
