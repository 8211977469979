export default function({ stateRouter }) {
	stateRouter.addState({
		name: 'app.redirect',
		route: 'redirect',
		querystringParameters: [ 'state' ],
		template: {
			template: '',
			data: {},
		},
		resolve(data, parameters) {
			return Promise.resolve({ parameters })
		},
		activate({ parameters }) {
			let state
			({ state, ...parameters } = parameters)

			if (state) {
				stateRouter.go(state, parameters, { replace: true })
			}
		},
	})
}

