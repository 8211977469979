import template from './responsive-table.html'

export default function createResponsiveTableComponent() {
	return Ractive.extend({
		template,
		twoway: false,
		data() {
			return {}
		},
	})
}
