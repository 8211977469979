export default [
	'application/pdf',
	'audio/flac',
	'audio/x-m4a',
	'audio/mpeg',
	'audio/mp3',
	'audio/ogg',
	'audio/ogg',
	'audio/ogg',
	'audio/wav',
	'audio/webm',
	'image/avif',
	'image/bmp',
	'image/gif',
	'image/x-icon',
	'image/jpeg',
	'image/jpeg',
	'image/jpeg',
	'image/jpeg',
	'image/jpeg',
	'image/png',
	'image/svg+xml',
	'image/svg+xml',
	'image/tiff',
	'image/tiff',
	'image/webp',
	'image/x-xbitmap',
	'text/css',
	'text/csv',
	'text/html',
	'text/html',
	'text/html',
	'text/html',
	'text/html',
	'text/plain',
	'text/plain',
	'video/mp4',
	'video/mp4',
	'video/mpeg',
	'video/mpeg',
	'video/ogg',
	'video/ogg',
	'video/webm',
]
