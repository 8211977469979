import template from './payment.html'
import domValue from 'dom-value'
import financialNumber from 'financial-number'
import * as currency from '@isoftdata/utility-currency'
import pProps from 'p-props'
import klona from 'klona'

//Ractive components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeCardPaymentModal from './card-payment'
import makePaymentLineRow from './payment-line-row'
import makeYesNoModal from '@isoftdata/yes-no-modal'

const newPayment = {
	saleId: null,
	paymentMethodName: '',
	amount: '0',
	checkAuthorizationNumber: '',
}

export default function createPaymentComponent({ mediator, logAndAlert }, { twoway = false, isolated = true }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated,
		twoway,
		data() {
			return {
				//formatCurrency: currency.format,
				paymentList: [],
				documentId: null,
				sortedPaymentList: [],
				saleList: [],
				paymentMethodsList: [],
				customerPaymentMethodsList: [],
				customerId: null,
				showCardPaymentModal: false,
				documentBalance: 0,
				showSaleIdSelect: false,
				loading: true,
				disableModification: false,
			}
		},
		components: {
			itButton: makeButton(),
			itTable: makeTable(),
			paymentLineRow: makePaymentLineRow(),
			cardPaymentModal: makeCardPaymentModal(mediator),
			itYesNoModal: makeYesNoModal(),
		},
		computed: {
			allowedPaymentMethods() {
				const paymentMethodsList = this.get('paymentMethodsList')
				const customerPaymentMethodsList = this.get('customerPaymentMethodsList')

				return customerPaymentMethodsList.map(allowedMethod => {
					const method = paymentMethodsList
						.find(method => method.paymentMethodId === allowedMethod.paymentMethodId)

					return { ...allowedMethod, ...method }
				})
			},
			customerHasAllowedPaymentMethods() {
				return !!this.get('allowedPaymentMethods')?.length
			},
			defaultPaymentMethod() {
				const defaultPaymentMethodId = this.get('defaultPaymentMethodId')
				const paymentMethodsList = this.get('paymentMethodsList')

				return paymentMethodsList.find(method => method.paymentMethodId === defaultPaymentMethodId)
			},
			hasAnyCardTransactions() {
				return this.get('paymentList')
					.some(payment => payment.paymentTerminalTransactionId)
			},
			computedColumns() {
				let columns = [
					{ property: 'paymentMethodName', name: 'Method' },
					{ property: 'amount', name: 'Amount', align: 'right' },
					{ property: 'checkAuthorizationNumber', name: 'Check #' },
				]

				if (this.get('hasAnyCardTransactions')) {
					columns = columns.concat({ property: 'paymentTerminalTransactionId' })
				}

				if (this.get('showSaleIdSelect')) {
					columns = [{ property: 'saleId', name: 'Invoice #' }].concat(columns)
				}

				return columns.concat([{ property: 'removePayment', icon: 'fas fa-trash-alt' }])
			},
			hasZeroDocumentBalance() {
				return financialNumber(this.get('documentBalance').toString())
					.equal('0')
			},
			displayDocumentBalance() {
				return currency.format(this.get('documentBalance'))
			},
			hasPaymentsToVoid() {
				return this.get('paymentList').some(payment => payment.saveState === 'TO_VOID')
			},
		},
		addPayment(payment = {}) {
			const ractive = this

			payment = { ...klona(newPayment), ...payment, amount: ractive.get('documentBalance') }
			const saleId = ractive.get('documentId')

			if (saleId) {
				payment.saleId = saleId
			}

			let paymentMethod = null

			if (payment.paymentMethodName) {
				paymentMethod = ractive.get('paymentMethodsList')
					.find(method => method.name === payment.paymentMethodName)
			}

			if (paymentMethod && paymentMethod.isCard) {
				//set the payment to 0 if it's a card.
				//The card payment dialog will take care of getting the amount
				payment.amount = 0
			}

			ractive.push('paymentList', payment).then(() => {
				const list = ractive.findAll('.paymentListMethodSelect')

				const index = list.length - 1

				list[index].focus()

				ractive.showCardPaymentModalIfNeeded(paymentMethod, index)
			})
		},
		addQuickFullPayment() {
			const ractive = this

			const defaultPaymentMethod = ractive.get('defaultPaymentMethod')

			ractive.addPayment({ paymentMethodName: defaultPaymentMethod ? defaultPaymentMethod.name : '' })
		},
		showCardPaymentModalIfNeeded(paymentMethod, index) {
			const ractive = this

			if (paymentMethod && paymentMethod.isCard) {
				ractive.findComponent('cardPaymentModal')
					.show(null, {
						id: ractive.get('documentId'),
						balance: ractive.get('documentBalance'),
					}, index)
			}
		},
		async oninit() {
			const ractive = this

			ractive.on('cardPaymentModal.confirm', (context, paymentTerminalTransaction, paymentContext) => {
				//If we did a terminal transaction, set the paymentTerminalTransactionId on the payment row
				//so they're associated
				if (paymentTerminalTransaction && paymentTerminalTransaction.paymentTerminalTransactionId) {
					let payment = {
						...ractive.get('paymentList')[paymentContext], paymentTerminalTransactionId: paymentTerminalTransaction.paymentTerminalTransactionId,
						amount: paymentTerminalTransaction.amount,
					}

					ractive.splice('paymentList', paymentContext, 1, payment).then(() => {
						console.log(ractive.get('paymentList'))
					})
				}
			})

			const paymentMethodsList = await mediator.call('emitToServer', 'load payment methods', {})
			ractive.set({ paymentMethodsList })

			ractive.observe('customerId', async customerId => {
				if (customerId) {
					ractive.set({ loading: true })

					try {
						const { customers, customerPaymentMethodsList, unbalancedSales } = await pProps({
							customers: mediator.call('emitToServer', 'load customer', { customerId }),
							customerPaymentMethodsList: mediator.call('emitToServer', 'load customer payment methods', { customerId }),
							unbalancedSales: mediator.call('emitToServer', 'load unbalanced sales', { customerId }),
						})

						await ractive.set({
							loading: false,
							customerPaymentMethodsList,
						})

						ractive.find('#addPaymentButton').focus()

						if (customers.length > 0) {
							ractive.set({ defaultPaymentMethodId: customers[0].defaultPaymentMethodId })
						}

						const thisSaleId = ractive.get('documentId')

						if (thisSaleId && !unbalancedSales.includes(thisSaleId)) {
							unbalancedSales.splice(0, 0, thisSaleId)
						}

						ractive.set({ saleList: unbalancedSales })
					} catch (err) {
						logAndAlert(err, mediator, 'Error loading payment info')
					}
				}
			})

			ractive.on('paymentMethodChange', (context, event, index) => {
				const selectedPaymentMethod = domValue(event.target)

				if (selectedPaymentMethod) {
					const paymentMethod = ractive.get('paymentMethodsList')
						.find(method => method.name === selectedPaymentMethod)

					ractive.showCardPaymentModalIfNeeded(paymentMethod, index)
				}
			})

			ractive.on('cardButtonClicked', (context, paymentTerminalTransactionId, index) => {
				ractive.findComponent('cardPaymentModal')
					.show(paymentTerminalTransactionId, {
						id: ractive.get('documentId'),
						balance: ractive.get('documentBalance'),
					}, index)
			})

			//ractive.on('cancelPaymentVoid', () => )

			ractive.on('removePayment', (context, index) => {
				const paymentToRemove = ractive.get('paymentList')[index]
				if (paymentToRemove.paymentId) {
					//If the payment has a paymentId, it's an existing payment, so we need to void it.
					//If it's already marked to void, allow them to undo it with an additional click
					ractive.splice('paymentList', index, 1, { ...klona(paymentToRemove), saveState: paymentToRemove.saveState === 'TO_VOID' ? undefined : 'TO_VOID' })
				} else {
					ractive.splice('paymentList', index, 1)
				}
			})

			ractive.on('removePaymentClicked', (context, index) => {
				const paymentList = ractive.get('paymentList')
				const paymentToRemove = paymentList[index]
				if (paymentToRemove && paymentToRemove?.paymentId && paymentToRemove?.saveState !== 'TO_VOID') {
					ractive.findComponent('itYesNoModal')
						.show({
							title: 'Void Payment?',
							question: 'Are you sure you want to void this payment?',
							yesText: 'Void Payment',
							noText: 'Cancel',
							yesCallback: 'removePayment',
							yesCallbackContext: index,
							yesButtonBootstrapClass: 'danger',
						})
				} else {
					ractive.fire('removePayment', {}, index)
				}
			})
		},
	})
}
