import template from './basic.html'
import pProps from 'p-props'
import domValue from 'dom-value'
import vinDecode from 'utility/vin-decoder'
import { getDistinctArrayValues } from '@isoftdata/utility-array'
import formatDate from 'date-fns/format'
import klona from 'klona'
import statusListLoader from 'utility/status-list-loader.ts'

//Ractive components
import makeStatusLocationChangeModal from 'components/status-location-change-modal'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeStatusSelect from 'components/status-select'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextArea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeList from '@isoftdata/list'

const defaultVehicle = {
	asIsPrice: 0,
	purchasedDate: formatDate(new Date(), 'yyyy-MM-dd'),
	status: 'A',
	isDismantled: false,
}
Object.freeze(defaultVehicle)

async function loadModelsForMake(make, mediator) {
	const models = await mediator.call('emitToServer', 'load vehicle models', { make })
	return (models && Array.isArray(models)) ? models.map(model => model.model) : []
}

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	const controlOptions = { twoway: true }

	const stateName = 'app.vehicle.basic'

	stateRouter.addState({
		name: stateName,
		route: 'basic',
		querystringParameters: [ 'vehicleId', 'storeId', 'vin' ],
		defaultParameters: { storeId: 1 },
		template: {
			template,
			data: {
				domValue,
			},
			components: {
				vinModal: makeModal(),
				vinNotesModal: makeModal(),
				statusChangeModal: makeModal(),
				itInput: makeInput(controlOptions),
				itButton: makeButton(),
				itCheckbox: makeCheckbox(controlOptions),
				itCurrencyInput: makeCurrencyInput(controlOptions),
				itSelect: makeSelect(controlOptions),
				itStatusSelect: makeStatusSelect(controlOptions),
				itTextArea: makeTextArea(controlOptions),
				itList: makeList(),
				itTable: makeTable(),
				statusLocationChangeModal: makeStatusLocationChangeModal({ mediator }),
				vinDuplicateModal: makeModal(),
			},
			computed: {
				displayVinNotes() {
					return this.get('vehicle.vinNotes')
						.split('\n')
						.map(label => ({ label }))
				},
			},
			statusChanged() {
				const vehicle = this.get('vehicle')
				this.findComponent('statusLocationChangeModal')?.showModal?.('STATUS', vehicle)
			},
			locationChanged(newLocation, oldLocation) {
				const vehicle = this.get('vehicle')
				this.findComponent('statusLocationChangeModal')?.showModal?.('LOCATION', vehicle, oldLocation)
			},
			selectLocalMakeModelMatch({ make, model, year, rawData }) {
				const ractive = this
				console.log(make, model, year, rawData)

				return new Promise(resolve => {
					const vinNotes = Object.keys(rawData)
						.map(key => `${key}: ${rawData[key]}`)
						.join('\n')

					console.log(vinNotes)

					const internalNotes = ractive.get('vehicle.internalNotes') || ''

					ractive.set({
						'vehicle.make': make,
						'vehicle.model': model,
						'vehicle.year': year,
						'vehicle.vinNotes': vinNotes,
						'vehicle.internalNotes': internalNotes ? internalNotes.concat(`\n\n${vinNotes}`) : vinNotes,
					}).then(() => resolve())
				})
			},
			async createVehicle(vehicleType) {
				const vehicle = this.get('vehicle')
				vehicle.status = vehicleType === 'BID' ? 'B' : 'A'

				try {
					const savedVehicle = await this.saveVehicle(vehicle)
					if (savedVehicle?.vehicleId) {
						if (vehicleType === 'BID') {
							await mediator.call('emitToServer', 'save vehicle bid', {
								bid: {
									vehicleId: savedVehicle.vehicleId,
									closed: 0,
									amount: 0,
									bidAccepted: 0,
									vendorId: null,
									contactName: '',
									contactPhoneNumber: null,
								},
							})
						}

						this.openVehicle(savedVehicle.vehicleId)
					}
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured creating the vehicle')
				}
			},
			openVehicle(vehicleId) {
				console.log(vehicleId)
				stateRouter.go(null, { vehicleId }, { replace: true })
			},
			async saveVehicle(vehicle) {
				try {
					this.set({ isSavingVehicle: true })
					const savedVehicle = await mediator.call('emitToServer', 'save vehicle', { vehicle })
					mediator.call('vehicleSave', {
						stateThatSaved: stateName,
						data: savedVehicle,
					})
					return savedVehicle
				} catch (err) {
					logAndAlert(err, mediator, 'An error occured saving the vehicle')
					throw err
				} finally {
					this.set({ isSavingVehicle: false })
				}
			},
			async decodeVin(vin) {
				const ractive = this
				vin = vin.trim()

				if (!vin) {
					return
				}

				console.log('decode vin', vin)
				ractive.set({ vinLoading: true })
				try {
					const decodedVinObject = await vinDecode(vin, mediator)
					await ractive.set({ decodedVinObject })
					await ractive.matchVinDecodeToMakeModel(decodedVinObject)
					const duplicateVinVehicles = await mediator.call('emitToServer', 'load vehicle', { vin })
					if (duplicateVinVehicles.length > 0) {
						ractive.set({ vinDuplicateModal: {
							show: true,
							vehicle: duplicateVinVehicles[0],
						} })
					}
				} catch (err) {
					logAndAlert(err, mediator, 'Error decoding VIN. Try again later or check your internet connection.')
				} finally {
					ractive.set({ vinLoading: false })
				}
			},
			async matchVinDecodeToMakeModel({ make, model, year, rawData }) {
				const ractive = this

				if (make && model) {
					const possibleMakeModels = await mediator.call('emitToServer', 'find fuzzy make model match', { make, model })

					const exactMakeMatch = possibleMakeModels.find(possible => {
						return possible.make.toUpperCase() === make.toUpperCase()
					})

					const exactModelMatch = possibleMakeModels.find(possible => {
						return possible.model.toUpperCase() === model.toUpperCase()
					})

					const hasExactMatch = exactMakeMatch && exactModelMatch

					if (hasExactMatch) {
						await ractive.selectLocalMakeModelMatch({
							make: exactMakeMatch.make,
							model: exactModelMatch.model,
							year,
							rawData,
						})
					} else if (possibleMakeModels.length > 0 || !hasExactMatch) {
						await ractive.set({
							showVinModal: true,
							vinMakeModelMatchSession: {
								addNew: {
									make: exactMakeMatch ? exactMakeMatch.make : make,
									model: exactModelMatch ? exactModelMatch.model : model,
								},
								exactMakeMatch: !!exactMakeMatch,
								exactModelMatch: !!exactModelMatch,
								vinDecodeMake: make,
								vinDecodeModel: model,
								vinDecodeYear: year,
								rawData,
								possibleMakeModels,
							},
						})
					}
				}
			},
		},
		async resolve(data, { vehicleId, storeId, vin }) {
			//in the case where they scan a vin to create a vehicle.
			let newVehicle = klona(defaultVehicle)

			if (vin) {
				newVehicle.vin = vin
			}

			const res = await pProps({
				vehicle: (async() => vehicleId ? (await mediator.call('emitToServer', 'load vehicle', { vehicleId }))?.[0] : newVehicle)(),
				statusList: statusListLoader(mediator, 'Vehicle'),
				makes: mediator.call('emitToServer', 'load vehicle makes', {}),
				users: mediator.call('emitToServer', 'load users', {}),
				topRankedImage: (async() => {
					if (vehicleId) {
						const vehicleFileList = await mediator.call('emitToServer', 'load file info list', {
							association: {
								type: 'VEHICLE',
								key: {
									vehicleId: Number(vehicleId),
								},
							},
						})

						const imagesList = vehicleFileList.filter(file => file.mimeType.startsWith('image'))

						return imagesList.find(file => {
							return file.rank === Math.min.apply(Math, imagesList.map(file => file.rank))
						})
					}
				})(),
				vehicleId: vehicleId ? parseInt(vehicleId, 10) : undefined,
				storeId: storeId ? parseInt(storeId, 10) : 1,
				validVIN: true,
				showNotesModal: false,
				showVinModal: false,
				decodedVinObject: {},
				decodedVinNotes: null,
				vinLoading: false,
				modelsLoading: false,
				showVinNotesModal: false,
				possibleMakeModelMatches: [],
				vinMakeModelMatchSession: false,
				models: [],
				isSavingVehicle: false,
				isSavingBid: false,
				canAddVehicle: hasPermission('PM_VEHICLE_ADD'),
				vinDuplicateModal: {
					show: false,
					vehicle: null,
				},
			})

			if (res && res.vehicle && res.vehicle.make) {
				res.models = await loadModelsForMake(res.vehicle.make, mediator)
			}

			return res
		},
		activate(context) {
			const { domApi: ractive, parameters } = context

			if (!parameters.vehicleId) {
				ractive.find('#vin').focus()

				if (parameters.vin) {
					//If we got here via a VIN scan,
					//just go ahead and decode the VIN they scanned
					ractive.decodeVin(parameters.vin)
				}
			}
			//ractive.observe('decodedVinObject', (val) => console.log(val))
			//ractive.observe('vehicle', v => console.log(v))

			ractive.observe('showVinModal', shown => {
				if (!shown) {
					//reset our vin make model matching session
					ractive.set({ vinMakeModelMatchSession: false })
				}
			})

			ractive.observe('vehicle', vehicle => {
				//We only want  to save in real time if it's an existing vehicle. New vehicles require a save button click
				if (vehicle?.vehicleId) {
					ractive.saveVehicle(vehicle)
				}
			}, { init: false })

			ractive.observe('vehicle.make', async make => {
				await ractive.set({ modelsLoading: true })
				const models = await loadModelsForMake(make, mediator)
				ractive.set({ modelsLoading: false, models })
			}, { init: false })

			ractive.on('addModel', async(context, { make, model }) => {
				await mediator.call('emitToServer', 'add make model', { make, model })

				const makeList = getDistinctArrayValues(ractive.get('makes').concat(make)).sort()
				const modelList = getDistinctArrayValues(ractive.get('models').concat(model)).sort()

				const vinMakeModelMatchSession = ractive.get('vinMakeModelMatchSession')

				await ractive.selectLocalMakeModelMatch({
					make,
					model,
					year: vinMakeModelMatchSession.vinDecodeYear,
					rawData: vinMakeModelMatchSession.rawData,
				})

				ractive.set({
					makes: makeList,
					models: modelList,
					vinMakeModelMatchSession: false,
					showVinModal: false,
				})
			})

			ractive.on('useExistingMakeModel', async(context, { make, model }) => {
				const vinMakeModelMatchSession = ractive.get('vinMakeModelMatchSession')

				await ractive.selectLocalMakeModelMatch({
					make,
					model,
					year: vinMakeModelMatchSession.vinDecodeYear,
					rawData: vinMakeModelMatchSession.rawData,
				})

				await ractive.set({
					showVinModal: false,
					vinMakeModelMatchSession: false,
				})
			})

			ractive.on('itList.click', (context, index) => {
				const notes = ractive.get('displayVinNotes')
				navigator?.clipboard?.writeText?.(notes[index].label)
			})

			const { cancel: cancelVehicleLocationObserver } = ractive.observe('vehicle.location', (newLocation, oldLocation) => {
				ractive.locationChanged(newLocation, oldLocation)
			}, { init: false })

			//clean up the vehicle location observer when the state changes
			context.on('destroy', () => cancelVehicleLocationObserver())
		},
	})
}
