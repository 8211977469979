import component from './Quote.svelte'
import homeDataLoader from 'shared/home-data-loader'
import { stringToBoolean } from '@isoftdata/utility-string'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.quote',
		route: 'quote',
		querystringParameters: [ 'fromDate', 'toDate', 'isClosed', 'salesperson' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			// Maintain not existing as undefined, otherwise get as boolean. 3 state logic.
			const isClosed = parameters?.isClosed !== undefined ? stringToBoolean(parameters.isClosed) : undefined
			const result = await homeDataLoader({
				fromDate: parameters.fromDate,
				toDate: parameters.toDate,
				documentLoadSocket: 'load quotes',
				documentLoadAdditionalParameters: {
					isClosed,
					salesperson: parameters?.salesperson,
				},
				mediator,
			})
			return {
				...result,
				isClosed: isClosed?.toString() || 'undefined',
				doSalespersonFilter: parameters?.salesperson !== undefined,
			}
		},

	})
}
