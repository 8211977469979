import template from './salespeople.html'
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'
import { stringToBoolean } from '@isoftdata/utility-string'

function makeDisplayRecordFromItrack(itrackSalesPerson) {
	const { firstName, lastName } = itrackSalesPerson
	const displayName = (firstName && lastName) ? ` (${firstName} ${lastName})` : (firstName || lastName) ? ` (${firstName || lastName})` : ''
	return {
		id: itrackSalesPerson.userAccountId,
		name: `${itrackSalesPerson.userName}${displayName}`,
		original: itrackSalesPerson,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksSalesperson) {
	return {
		id: Number(quickbooksSalesperson.Id) || quickbooksSalesperson.Id,
		name: quickbooksSalesperson.DisplayName,
		original: quickbooksSalesperson,
	}
}

const stateName = 'app.quickbooks.salespeople'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'salespeople',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.configuration.user.manage', { userAccountId: id }),
					makeQuickbooksEntityUrl: id => `__qboBasePath__/employees?Id`, // can't link directly to an employee
					itrackKey: 'userAccountId',
					// displayItrackColumns,
					// displayQuickbooksColumns,
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load salespeople for quickbooks screen', { hideSynced: stringToBoolean(hideMatches) })

			return {
				itrackEntityList,
				quickbooksEntityList,
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Salespeople`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
