type SettingType = 'Accessibility' | 'Hidden' | 'Important Configuration' | 'Interface History' | 'Interface Preference' | 'Optional Configuration' | 'Preference'
type Scope = 'USER' | 'STORE' | 'GLOBAL'
import type { Mediator, EndpointNames } from 'types/common'
import { type Writable, writable } from 'svelte/store'

interface InitArgs<T extends string | boolean | number = string> {
	category: string
	name: string
	settingType: SettingType
	scope: Scope
	/** The initial value of the store - the current value of the setting */
	initialValue: T
}

export type SettingValueStore<T extends string | number | boolean> = Omit<Writable<T>, 'update'>
/**
 * This function creates a store that will automatically update the setting value when the store's value is updated.
 * @param setting An object representing the setting
 * @returns A writable store that contains the setting value. It also has a teardown function that should be called when the store is no longer needed.
 */
export default function makeSettingValueStore<T extends string | boolean | number = string>(mediator: Mediator, setting: InitArgs<T>): SettingValueStore<T> {
	const { subscribe, set: defaultSet } = writable<T>(setting.initialValue)

	function set(newValue: T) {
		defaultSet(newValue)
		saveSettingValue(mediator, setting, newValue).catch(err => console.error('Error setting setting value', err))
	}

	return {
		subscribe,
		set,
	}
}

async function saveSettingValue<T extends string | number | boolean>(mediator: Mediator, setting: InitArgs<T>, newValue: string | boolean | number) {
	return await mediator.call('emitToServer', mutations[setting.scope], {
		category: setting.category,
		name: setting.name,
		settingType: setting.settingType,
		value: newValue,
	})
}

const mutations: Record<Scope, EndpointNames> = {
	USER: 'save user setting',
	GLOBAL: 'save global setting',
	STORE: 'save store setting',
}
