<script lang="ts">
	import type { Mediator, HasPermission, SvelteAsr } from 'types/common'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import DateRange from '@isoftdata/svelte-date-range'
	import { type ComponentProps, getContext } from 'svelte'

	export let asr: SvelteAsr
	export let dates: ComponentProps<DateRange>['dates']
	export let hasPendingBuyNowOrders: boolean
	export let childStates: Array<ComponentProps<AsrNavTabBar>['tabs'][number] & { requiredPermission: string }>

	const mediator = getContext<Mediator>('mediator')
	const hasPermission = getContext<HasPermission>('hasPermission')

	$: displayChildStates = childStates
		.filter(state => hasPermission(state.requiredPermission))
		.map(state => {
			if (state.name === 'app.home.internet' && hasPendingBuyNowOrders) {
				return {
					...state,
					icon: {
						icon: 'bell-on' as const,
						class: 'text-danger',
					},
				}
			} else {
				return state
			}
		})

	mediator
		.call('emitToServer', 'load internet orders', {
			fromDate: dates.from,
			to: dates.to,
		})
		.then(internetOrders => {
			console.log('internetOrders =', internetOrders)
			if (!Array.isArray(internetOrders)) {
				hasPendingBuyNowOrders = false
			} else {
				hasPendingBuyNowOrders = internetOrders.some(internetOrder => {
					return internetOrder.vendorApprovalStatus === 'Pending' || (internetOrder.vendorApprovalStatus === 'Approved' && internetOrder.customerApprovalStatus !== 'Pending')
				})
			}
		})
</script>

<div class="card">
	<div class="card-header">
		<div>
			<p class="h4"><i class="fas fa-home fa-fw mr-1"></i>Home</p>
			<iframe
				class="homeiframe"
				src="https://getitrack.com/itrack-chromium-home-screen/"
				title="ITrack Chromium Home Screen"
			></iframe>
			<AsrNavTabBar
				{asr}
				tabs={displayChildStates}
				breakpoint="md"
			/>
		</div>
	</div>
	<uiView></uiView>
</div>
