import template from './unit.html'
import pProps from 'p-props'
import keyboardJS from 'keyboardjs'

//Ractive components
import makeTextArea from '@isoftdata/textarea'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeModal from '@isoftdata/modal'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'

const newCustomerUnit = {
	customerId: null,
	make: '',
	model: '',
	year: 0,
	color: '',
	description: '',
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.entity.unit',
		route: 'unit',
		querystringParameters: [ 'id', 'customerUnitId' ],
		template: {
			template,
			components: {
				itModal: makeModal(),
				itTable: makeTable(),
				itButton: makeButton(),
				itTextArea: makeTextArea({ twoway: true, lazy: true }),
				itInput: makeInput({ twoway: true, lazy: true }),
				itSelect: makeSelect({ twoway: true, lazy: true }),
			},
			computed: {
				selectedCustomerUnit() {
					const selectedCustomerUnitId = this.get('selectedCustomerUnitId')

					return this.get('customerUnits')
						.find(customerUnit => selectedCustomerUnitId === customerUnit.customerUnitId)
				},
			},
		},
		resolve(data, { id, customerUnitId }) {
			return pProps({
				customerUnits: mediator.call('emitToServer', 'load customer units', { customerId: id }),
				vehicleMakes: mediator.call('emitToServer', 'load vehicle makes', {}),
				vehicleModels: [],
				selectedCustomerUnitId: parseInt(customerUnitId, 10),
				customerId: parseInt(id, 10),
				newCustomerUnit: { ...newCustomerUnit, customerId: id },
				showUnitDeleteModal: false,
				shownCustomerUnit: {},
			})
		},
		activate({ domApi: ractive }) {
			ractive.on('create-unit', () => {
				ractive.set({
					showCustomerUnitModal: true,
					shownCustomerUnit: { ...ractive.get('newCustomerUnit') },
				}).then(() => {
					ractive.find('#make-input').focus()
				})
			})

			ractive.on('edit-unit', (context, customerUnit) => {
				ractive.set({
					shownCustomerUnit: customerUnit,
					showCustomerUnitModal: true,
				})
			})

			ractive.on('delete-unit', async(context, customerUnitId) => {
				try {
					await mediator.call('emitToServer', 'delete customer unit', { customerUnitId })
					ractive.set({ showUnitDeleteModal: false })

					const deletedIndex = ractive.get('customerUnits')
						.findIndex(unit => unit.customerUnitId === customerUnitId)

					await ractive.splice('customerUnits', deletedIndex, 1)
					stateRouter.go('app.entity.unit', {
						id: ractive.get('customerId'),
						entityContext: 'customer',
					})
				} catch (err) {
					logAndAlert(err, mediator, 'Error deleting customer unit')
				}
			})

			ractive.on('save-unit', async(context, shownCustomerUnit) => {
				try {
					const savedUnit = await mediator.call('emitToServer', 'save customer unit', { customerUnit: shownCustomerUnit })
					ractive.set({ showCustomerUnitModal: false })
					ractive.upsert('customerUnits', 'customerUnitId', savedUnit)
				} catch (err) {
					logAndAlert(err, mediator, 'Error saving customer unit')
				}
			})

			ractive.on('unit-clicked', (context, customerUnitId) => {
				if (customerUnitId) {
					stateRouter.go('app.entity.unit.service', { customerUnitId }, { inherit: true })
				}
			})

			ractive.observe('shownCustomerUnit.make', async make => {
				console.log(`load models for ${make}`)
				if (make) {
					try {
						const vehicleModels = await loadModelsForMake(make)
						ractive.set({ vehicleModels })
					} catch (err) {
						logAndAlert(err, mediator, `An error occured loading models for ${make}`)
					}
				} else {
					ractive.set({ vehicleModels: [] })
				}
			})

			stateRouter.on('stateChangeEnd', (context, state, parameters) => {
				const keyboardController = parameters.keyboardController
				ractive.set('unitIsKeyboardContext', keyboardController === 'unit')

				ractive.observe('showCustomerUnitModal', unitModalShown => {
					if (unitModalShown) {
						keyboardJS.setContext('unitModal')
					} else {
						keyboardJS.setContext(keyboardController ? keyboardController : null)
					}
				})

				keyboardJS.bind('u', e => {
					if (parameters.keyboardController === 'unit') {
						stateRouter.go(null, { keyboardController: '' }, { inherit: true })
					} else {
						stateRouter.go(null, { keyboardController: 'unit' }, { inherit: true })
					}
				})

				keyboardJS.withContext('unit', () => {
					keyboardJS.bind('c', e => {
						if (!ractive.get('showCustomerUnitModal')) {
							ractive.find('#add-unit').click()
						}
					})

					keyboardJS.bind('e', e => {
						if (!ractive.get('showCustomerUnitModal')) {
							ractive.find('#edit-unit').click()
						}
					})

					keyboardJS.bind('d', e => {
						if (!ractive.get('showCustomerUnitModal')) {
							ractive.find('#delete-unit').click()
						}
					})
				})
			})

			stateRouter.on('beforeDestroyState', () => {
				keyboardJS.reset()
			})
		},
	})

	async function loadModelsForMake(make) {
		const models = await mediator.call('emitToServer', 'load vehicle models', { make })
		return models.map(model => {
			return { model: model.model }
		})
	}
}
