import PurchaseOrderDocumentLine from './PurchaseOrderDocumentLine.mjs'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'

const valueOrElse = (val, defaultVal = '') => val ? val : defaultVal

export default class PurchaseOrderDocument {
	constructor({
		purchaseOrder,
		purchaseOrderLines,
		purchaseOrderHistory,
		purchaseOrderLineHistory,
		customer,
		vendor,
		inventoryList,
		saveState,
	}) {
		this.purchaseOrderId = purchaseOrder ? valueOrElse(purchaseOrder.purchaseOrderId, null) : null
		this.storeId = valueOrElse(purchaseOrder.storeId, null)
		this.customer = customer ? { ...customer, poRequired: isTrue(customer.poRequired) } : null
		this.vendor = valueOrElse(vendor, null)
		this.status = valueOrElse(purchaseOrder.status, 'NEW')
		this.documentType = valueOrElse(purchaseOrder.documentType, 'Purchase Order')
		this.documentDate = purchaseOrder.documentDate
		this.creationDate = purchaseOrder.creationDate
		this.salesperson = purchaseOrder.salesperson
		this.tax = valueOrElse(purchaseOrder.tax, 0)
		this.subtotal = valueOrElse(purchaseOrder.subtotal, 0)
		this.total = valueOrElse(purchaseOrder.total, 0)
		this.totalReceived = valueOrElse(purchaseOrder.totalReceived, 0)
		this.isPaid = isTrue(purchaseOrder.isPaid)
		this.checkNumber = purchaseOrder.checkNumber
		this.isClosed = isTrue(purchaseOrder.isClosed)
		this.didNotify = isTrue(purchaseOrder.didNotify)
		this.comments = valueOrElse(purchaseOrder.comments, '')
		this.lastModifiedDate = purchaseOrder.lastModifiedDate
		this.shippingCompany = valueOrElse(purchaseOrder.shippingCompany, '')
		this.shippingContact = valueOrElse(purchaseOrder.shippingContact, '')
		this.shippingStreetAddress = valueOrElse(purchaseOrder.shippingStreetAddress, '')
		this.shippingMailingAddress = valueOrElse(purchaseOrder.shippingMailingAddress, '')
		this.shippingCity = valueOrElse(purchaseOrder.shippingCity, '')
		this.shippingState = valueOrElse(purchaseOrder.shippingState, '')
		this.shippingZip = valueOrElse(purchaseOrder.shippingZip, '')
		this.shippingCountry = valueOrElse(purchaseOrder.shippingCountry, '')
		this.shippingPhoneNumber = valueOrElse(purchaseOrder.shippingPhoneNumber, '')
		this.inventoryList = inventoryList
		this.purchaseOrderLines = purchaseOrderLines.map(purchaseOrderLine => new PurchaseOrderDocumentLine({
			purchaseOrderLine,
			saveState,
		}))
		this.purchaseOrderHistory = purchaseOrderHistory
		this.purchaseOrderLineHistory = purchaseOrderLineHistory
		this.saveState = saveState
	}
}
