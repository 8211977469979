import template from './user.html'
import { sortArrayByObjectKey } from '@isoftdata/utility-array'
import klona from 'klona'

//Ractive components
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const newUser = {
	userName: '',
	email: '',
	recoveryEmail: '',
	firstName: '',
	lastName: '',
	restrictedToStoreId: null,
}

export default function({ stateRouter, mediator, hasPermission, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',
		querystringParameters: [ 'userAccountId' ],
		template: {
			template,
			components: {
				itSelect: makeSelect({ twoway: true, lazy: true }),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true, lazy: false }),
			},
			computed: {
				displayActiveUsers() {
					const activeUsers = this.get('users')
						.filter(user => user.status === 'Active')

					return sortArrayByObjectKey({
						array: activeUsers,
						key: 'userName',
					})
				},
				displayInactiveUsers() {
					const inactiveUsers = this.get('users')
						.filter(user => user.status !== 'Active')

					return sortArrayByObjectKey({
						array: inactiveUsers,
						key: 'userName',
					})
				},
			},
			hasPermission,
		},
		async resolve(data, { userAccountId }) {
			const session = JSON.parse(localStorage.getItem('session'))

			if ((!hasPermission('CAN_EDIT_USER_SETTINGS') && session.userAccountId != userAccountId) || !userAccountId) {
				throw {
					redirectTo: {
						name: 'app.configuration.user.manage',
						params: { userAccountId: session.userAccountId },
					},
				}
			}

			return {
				users: await mediator.call('emitToServer', 'load users', {}),
				selectedUserId: userAccountId,
				newUser,
				currentAddEditUser: {},
				addEditUser: false,
			}
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get('users'))
			ractive.on('add-user', () => {
				ractive.set({
					addEditUser: true,
					currentAddEditUser: klona(ractive.get('newUser')),
				}).then(() => {
					ractive.find('#addEditUser').select()
				})
			})

			ractive.on('edit-user', (context, userAccountId) => {
				const currentUserIndex = ractive.get('users')
					.findIndex(user => user.userAccountId == userAccountId)

				if (currentUserIndex > -1) {
					ractive.link(`users[${currentUserIndex}]`, 'currentAddEditUser')
					ractive.set({ addEditUser: true })
						.then(() => ractive.find('#addEditUser').select())
				}
			})

			ractive.on('save-user', async(context, event) => {
				event.preventDefault()

				const saveUser = ractive.get('currentAddEditUser')
				const users = ractive.get('users')
				const existingUserName = users
					.find(user => user.userName.trim().toUpperCase() === saveUser.userName.trim().toUpperCase())

				if (existingUserName && !saveUser.userAccountId) {
					return ractive.set({
						addEditUser: false,
						selectedUserId: existingUserName.userAccountId,
					})
				}

				if (saveUser && saveUser.userName.trim()) {
					try {
						const savedUser = await mediator.call('emitToServer', 'save user', saveUser)
						ractive.upsert('users', 'userAccountId', savedUser)

						await ractive.set({
							addEditUser: false,
							selectedUserId: savedUser.userAccountId,
						})
						// Only update the current user if that is the user that was just saved
						const currentUserAccountId = ractive.get('user.userAccountId')
						if (currentUserAccountId === savedUser.userAccountId) {
							mediator.call('user-changed', savedUser)
						}
					} catch (err) {
						logAndAlert(err, mediator, 'Error saving user')
					}
				}

				ractive.set({ addEditUser: false })
			})

			ractive.observe('selectedUserId', userAccountId => {
				stateRouter.go('app.configuration.user.manage', { userAccountId })
			}, { init: false })
		},
	})
}
