<script lang="ts">
	import type { PartModel } from '../../server/db/part-model-db'
	import type { InventoryType } from '../../server/db/inventory-type-db'
	import type { PartManufacturer } from '../../server/db/part-manufacturer-db'

	import type { Mediator } from 'types/common'

	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'

	import { logAndAlert } from 'utility/error-handler'
	import { createEventDispatcher, getContext, tick } from 'svelte'

	export let shown = false

	const mediator = getContext<Mediator>('mediator')
	const dispatch = createEventDispatcher<{
		modelSaved: { model: PartModel }
	}>()

	let isSaving = false
	let inventoryType = ''
	let manufacterer = ''
	let model: Partial<PartModel> = {}

	// todo double check whether this function needs exported or how they are used
	export async function show(newModel: Partial<PartModel> = {}) {
		if (!newModel.typeSetId || !newModel.partManufacturerId) {
			throw logAndAlert(new Error('Missing type set id or part manufacturer id'), mediator, 'Missing type set id or part manufacterer id')
		}

		const [inventoryTypeObj, manufacturerObj] = (await Promise.all([
			mediator.call('emitToServer', 'load inventory type by id', { inventoryTypeId: newModel.typeSetId }),
			mediator.call('emitToServer', 'load part manufacturer by id', { partManufacturerId: newModel.partManufacturerId }),
		])) as unknown as [InventoryType, PartManufacturer]

		model = newModel
		shown = true
		inventoryType = inventoryTypeObj.name ?? ''
		manufacterer = manufacturerObj.name
		await tick()
		document.getElementById('partModelModalInput')?.focus()
	}

	async function save() {
		isSaving = true
		try {
			// TODO figure out how to handle the secret fourth argument
			const savedModel = (await mediator.call('emitToServer', 'save part model', { partModel: model }, { doNotAlert: true })) as PartModel
			shown = false
			dispatch('modelSaved', { model: savedModel })
		} catch (err) {
			if (err instanceof Object && 'code' in err && err.code === 'ER_DUP_ENTRY') {
				logAndAlert(err, mediator, `Another model "${model.name}" already exists. Pick a different name.`)
			} else {
				logAndAlert(err, mediator)
			}
		} finally {
			isSaving = false
		}
	}
</script>

<Modal
	bind:show={shown}
	title="{model.partModelId ? 'Edit' : 'Add'} Part Model"
	modalSize="sm"
	confirmButtonText="Save"
	savingInProgress={isSaving}
	confirmButtonDisabled={!model.name}
	confirmButtonType="submit"
	confirmButtonFormId="partModelModalForm"
	on:confirm={() => save()}
	on:close={() => (shown = false)}
>
	<Input
		label="Part Type"
		readonly
		value={inventoryType}
	/>
	<Input
		label="Manufacturer"
		readonly
		value={manufacterer}
	/>
	<form
		id="partModelModalForm"
		on:submit|preventDefault={() => save()}
	>
		<Input
			id="partModelModalInput"
			label="Model"
			value={model.name ?? ''}
			autocomplete="off"
			maxlength={50}
			oninput={event => event.target instanceof HTMLInputElement && (model.name = event.target.value)}
		></Input>
	</form>
</Modal>
