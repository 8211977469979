import template from './inventory-verification.html'
import makeAutocomplete from '@isoftdata/autocomplete'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.scanner.inventory-verification',
		route: 'inventory-verification',
		querystringParameters: [ 'location' ],
		template: {
			template,
			components: {
				itAutocomplete: makeAutocomplete(),
			},
			computed: {
				displayLocationList() {
					return this.get('locationList').reduce((acc, location) => {
						return {
							...acc,
							[location]: location,
						}
					}, {})
				},
			},
		},
		async resolve(data, { location }) {
			return {
				location: location ? location : '',
				locationList: await mediator.call('emitToServer', 'load inventory locations', {}),
			}
		},
		activate({ domApi: ractive }) {
			ractive.find('#location').focus()

			ractive.observeOnce('location', location => {
				stateRouter.go('app.scanner.inventory-verification.inventory-list', { location }, { inherit: true })
			})
		},
	})
}
