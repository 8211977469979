import template from './part-search.html'
import fuzzy from 'fuzzysort'
import { getYearArray } from '@isoftdata/utility-date-time'
import klona from 'klona'
import statusListLoader from 'utility/status-list-loader.ts'
import pProps from 'p-props'
import domValue from 'dom-value'
//import * as arrayHelper from '@isoftdata/utility-array'
import * as objectHelper from '@isoftdata/utility-object'
import * as urlHelper from '@isoftdata/utility-url'
import { stringToBoolean } from '@isoftdata/utility-string'
import keyboard from 'keyboardjs'

//Ractive Components
import makeInterchangeSelector from 'components/interchange-selector'
import makePartTypeManModCat from 'components/part-type-man-mod-cat'
import makeInterchangeYear from 'components/interchange-year-input'
import makeSearchInterface from 'components/search-interface'
import makeAutocomplete from '@isoftdata/autocomplete'
import makeStatusSelect from 'components/status-select'
import makeStoreSelect from 'components/store-select'
import makeYesNoModal from '@isoftdata/yes-no-modal'
import makeYearInput from '@isoftdata/year-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

import ractiveTransitionsSlide from 'ractive-transitions-slide'

const tagSearchTypeMapping = [
	{ type: 'INVENTORY_TYPE', searchKey: 'inventoryTypeId', useIdForSourceKey: true },
	{ type: 'PART_MANUFACTURER', searchKey: 'partManufacturer' },
	{ type: 'PART_MODEL', searchKey: 'partModel' },
	{ type: 'YEAR', searchKey: 'vehicleYear' },
	{ type: 'VEHICLE_MAKE', searchKey: 'vehicleMake' },
	{ type: 'VEHICLE_MODEL', searchKey: 'vehicleModel' },
	{ type: 'CATEGORY', searchKey: 'category' },
]

const onlineSearchKeyMapping = [
	{ onlineKey: 'PartID', sourceKey: 'inventoryTypeId' },
	{ onlineKey: 'year_start', sourceKey: 'vehicleYear' },
	{ onlineKey: 'category', sourceKey: 'category' },
	{ onlineKey: 'ManufMake', sourceKey: 'partManufacturer' },
	{ onlineKey: 'Model', sourceKey: 'partModel' },
	{ onlineKey: 'keywords', sourceKey: 'keywordSearch' },
]

function makeFlexFieldSelection(flexFieldOptions, val, matchOnProperty = 'searchParameterId') {
	const flexField = flexFieldOptions.find(param => param[matchOnProperty] == val) || {}
	return { ...flexField, field: flexField.searchParameterId, comparator: '=', value: '' }
}

const defaultMoreFieldValues = Object.freeze({
	more1: '',
	more2: '',
	more3: '',
	more4: '',
})

export default function({ mediator, stateRouter, hasPermission, logAndAlert }) {
	stateRouter.addState({
		name: 'app.part-search',
		route: 'part-search',
		template: {
			template,
			components: {
				itButton: makeButton(),
				itCheckbox: makeCheckbox(),
				itPartTypeManModCat: makePartTypeManModCat({ mediator, logAndAlert }),
				itStatusSelect: makeStatusSelect({ twoway: true }),
				itSelectTwo: makeSelect({ twoway: true }),
				itSelect: makeSelect({ twoway: false }),
				itAutocomplete: makeAutocomplete(),
				itYearInput: makeYearInput({ twoway: true }),
				itYesNo: makeYesNoModal(),
				searchInterface: makeSearchInterface(),
				interchangeInput: makeInterchangeSelector({ mediator, stateRouter, logAndAlert, disableEditingAttachments: true }, { twoway: true }),
				itInput: makeInput({ twoway: true, lazy: 200 }),
				searchField: makeInput({ twoway: true, lazy: 400 }),
				storeSelect: makeStoreSelect(),
				interchangeYearInput: makeInterchangeYear(),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			data: {
				domValue,
			},
			hasPermission,
			clear() {
				stateRouter.go('app.redirect', { state: 'app.part-search' })
			},
			computed: {
				searchParams() {
					const searchType = this.get('searchType')
					const searchTags = this.get('searchTags')
					let searchParams = { status: this.get('status') }

					if (searchType === 'TAG') {
						const taggedParams = searchTags.reduce((acc, searchTag) => {
							const match = tagSearchTypeMapping.find(item => item.type == searchTag.type)

							return { ...acc, [match.searchKey]: match.useIdForSourceKey ? searchTag.id : searchTag.value }
						}, {})

						searchParams = { ...searchParams, ...taggedParams }

						return objectHelper.reduceToTruthyValues({ object: searchParams })
					} else if (searchType === 'FIELD') {
						searchParams = { ...searchParams, keywordSearch: this.get('keywordSearch') }

						if (this.get('showAdvancedSearchFields')) {
							let flexFields = this.get('flexFieldSelections')
								.filter(item => item.field && item.value)
								.map(item => {
									console.log(item)
									return {
										[item.field]: { comparator: item.comparator || '=', value: item.value },
									}
								}).reduce((acc, val) => {
									return { ...acc, ...val }
								}, {})

							flexFields = Object.keys(flexFields).length > 0 ? flexFields : false

							const moreFieldValues = this.get('moreFieldValues')

							for (const key in moreFieldValues) {
								if (moreFieldValues[key]) {
									searchParams[key] = moreFieldValues[key]
								}
							}

							const reducedQuestionValuesList = Object.entries(this.get('questionValues')).filter(([ _, value ]) => value)

							searchParams = {
								...searchParams,
								inventoryTypeId: this.get('inventoryTypeId'),
								category: this.get('category'),
								side: this.get('side'),
								replenish: this.get('replenish'),
								needsReordered: this.get('needsReordered'),
								needsImages: this.get('needsImages'),
								needsTag: this.get('needsTag'),
								shownOnline: this.get('shownOnline'),
								flexFields,
								storeId: this.get('storeId'),
								questionValues: reducedQuestionValuesList.length ? Object.fromEntries(reducedQuestionValuesList) : null,
							}

							const interchangeNumber = this.get('interchangeNumber')

							if (interchangeNumber) {
								searchParams.interchangeNumber = interchangeNumber
							} else {
								const yearSearch = this.get('yearSearch')
								const vehicleFromYear = this.get('vehicleYear')
								const vehicleToYear = this.get('vehicleToYear')

								if (yearSearch === 'SINGLE' && vehicleFromYear) {
									searchParams.vehicleYear = {
										from: vehicleFromYear,
										to: vehicleFromYear,
									}
								} else if (yearSearch === 'RANGE' && vehicleFromYear && vehicleToYear) {
									searchParams.vehicleYear = {
										from: vehicleFromYear,
										to: vehicleToYear,
									}
								}

								searchParams.vehicleMake = this.get('vehicleMake')
								searchParams.vehicleModel = this.get('vehicleModel')
								searchParams.partManufacturer = this.get('partManufacturer')
								searchParams.partModel = this.get('partModel')
							}
						}
					}

					return objectHelper.reduceToTruthyValues({ object: searchParams })
				},
				hasSearchParams() {
					return objectHelper.hasTruthyValues({ object: this.get('searchParams') })
				},
				onlineSearchParams() {
					const searchParams = this.get('searchParams')

					let onlineSearchParams = Object.keys(searchParams).reduce((acc, key) => {
						const match = onlineSearchKeyMapping.find(item => item.sourceKey === key)

						if (match) {
							return { ...acc, [match.onlineKey]: searchParams[match.sourceKey] }
						}
						return acc
					}, {
						distance_zip: this.get('store.zip'),
						PartID: 'any', //HTP won't show results without this being set to "any"
					})

					if (!searchParams.partManufacturer) {
						onlineSearchParams.ManufMake = this.get('vehicleMake')
						onlineSearchParams.Model = this.get('vehicleModel')
					}

					return urlHelper.buildUrlParams(objectHelper.reduceToTruthyValues({ object: onlineSearchParams }))
				},
				availableFlexFieldOptions() {
					const flexFieldSelections = this.get('flexFieldSelections')

					return this.get('flexFieldOptions')
						.filter(option => {
							return !flexFieldSelections.find(selection => {
								return selection.field == option.searchParameterId
							})
						})
				},
				flexFieldSelectionIds() {
					return this.get('flexFieldSelections')
						.map(selection => parseInt(selection.field, 10))
				},
				displayVehicleMakes() {
					return this.get('vehicleMakes').reduce((acc, make) => {
						return {
							...acc,
							[make.make]: make.make,
						}
					}, {})
				},
				displayVehicleModels() {
					return this.get('vehicleModels').reduce((acc, model) => {
						return {
							...acc,
							[model.model]: model.model,
						}
					}, {})
				},
			},
		},
		async resolve(data, parameters) {
			const { user } = JSON.parse(localStorage.getItem('session')) || {}

			const { flex1, flex2, flex3, flex4, ...res } = await pProps({
				stores: mediator.call('emitToServer', 'load stores', {}),
				flexFieldOptions: mediator.call('emitToServer', 'load inventory search parameters', {}),
				inventoryTypes: mediator.call('emitToServer', 'load inventory type list', {}),
				vehicleMakes: mediator.call('emitToServer', 'load vehicle makes', {}),
				yearList: getYearArray().map(year => {
					return { value: year.toString() }
				}),
				partManufacturers: mediator.call('emitToServer', 'load part manufacturer list', {}),
				partModels: [],
				categories: mediator.call('emitToServer', 'load categories', {}),
				showAdvancedSearchFields: mediator.call('emitToServer', 'check user setting', {
					name: 'Show Advanced Search Fields',
					category: 'Search (Parts)',
					userAccountId: user?.userAccountId,
					settingType: 'Interface History',
					defaultValue: false,
					dataType: 'boolean',
				}),
				partManufacturer: '',
				partModel: '',
				category: '',
				partManufacturersLoading: false,
				partModelsLoading: false,
				categoriesLoading: false,
				flexFieldSelections: [],
				side: '',
				vehicleModels: [],
				status: '@', //@ means Available and On Hold inventory, which is out default
				storeId: user?.restrictedToStoreId || '',
				replenish: parameters.replenish ? stringToBoolean(parameters.replenish) : false,
				needsReordered: parameters.needsReordered ? stringToBoolean(parameters.needsReordered) : false,
				needsImages: parameters.needsImages ? stringToBoolean(parameters.needsImages) : false,
				needsTag: parameters.needsTag ? stringToBoolean(parameters.needsTag) : false,
				shownOnline: parameters.shownOnline ? stringToBoolean(parameters.shownOnline) : false,
				usingInterchangeNote: '(N/A. Using Interchange #)',
				interchangeNumber: '',
				yearSearch: 'SINGLE',
				inventoryAutocomplete: '',
				searchType: 'FIELD',
				searchTags: [],
				autocompleteVehicleMakes: [],
				autocompletePartModels: [],
				autocompleteVehicleModels: [],
				autocompleteCategories: [],
				autocompleteCache: [],
				interchangeModalShown: false,
				moreFieldValues: klona(defaultMoreFieldValues),
				keywordSearch: '',
				flex1: user ? mediator.call('emitToServer', 'check user setting', {
					name: 'PartSearchFlex1',
					category: 'Search (Parts)',
					userAccountId: user.userAccountId,
					settingType: 'Interface History',
					defaultValue: 'Tag #' }) : 'Tag #',
				flex2: user ? mediator.call('emitToServer', 'check user setting', {
					name: 'PartSearchFlex2',
					category: 'Search (Parts)',
					userAccountId: user.userAccountId,
					settingType: 'Interface History',
					defaultValue: 'Tracking #' }) : 'Tracking #',
				flex3:	user ? mediator.call('emitToServer', 'check user setting', {
					name: 'PartSearchFlex3',
					category: 'Search (Parts)',
					userAccountId: user.userAccountId,
					settingType: 'Interface History',
					defaultValue: 'Location' }) : 'Location',
				flex4: user ? mediator.call('emitToServer', 'check user setting', {
					name: 'PartSearchFlex4',
					category: 'Search (Parts)',
					userAccountId: user.userAccountId,
					settingType: 'Interface History',
					defaultValue: 'OEM #' }) : 'OEM #',
				statusList: statusListLoader(mediator, 'Part'),
				questions: [],
				// question name -> answer
				questionValues: {},
			})

			res.userDefaultFlexFieldList = [ flex1, flex2, flex3, flex4 ]
			res.userDefaultFlexFieldList.filter(v => v).map(userDefaultFlex => {
				return makeFlexFieldSelection(res.flexFieldOptions, userDefaultFlex, 'name')
			})

			res.store = res?.stores?.[0]

			return { ...parameters, ...res }
		},
		activate({ domApi: ractive }) {
			ractive.observe('searchParams', v => console.log(v))

			ractive.observe('showAdvancedSearchFields', async value => {
				await mediator.call('emitToServer', 'save user setting', {
					category: 'Search (Parts)',
					value,
					name: 'Show Advanced Search Fields',
					type: 'Interface History',
				})
			}, { init: false })

			ractive.on('inventoryTypeChange', () => {
				ractive.set({ moreFieldValues: klona(defaultMoreFieldValues) })
			})

			ractive.on('flexFieldSelectionChange', (context, searchParameterId, index) => {
				ractive.splice('flexFieldSelections', index, 1, makeFlexFieldSelection(ractive.get('flexFieldOptions'), searchParameterId))
			})

			ractive.observe('searchType', searchType => {
				if (searchType === 'TAG') {
					document.querySelector('.tagify').click()
				}
			}, { defer: true })

			rebuildAutocompleteCache()

			function getTagSearchObject(array, idKey, valueKey, type) {
				return array.map(item => {
					return { value: item[valueKey], id: item[idKey], type }
				})
			}

			function rebuildAutocompleteCache() {
				let autocompleteCache = [
					getTagSearchObject(ractive.get('inventoryTypes'), 'inventoryTypeId', 'name', 'INVENTORY_TYPE'),
					getTagSearchObject(ractive.get('autocompleteVehicleMakes').length > 0 ? ractive.get('autocompleteVehicleMakes') : ractive.get('vehicleMakes'), 'make', 'make', 'VEHICLE_MAKE'),
					getTagSearchObject(ractive.get('partManufacturers'), 'partManufacturerId', 'name', 'PART_MANUFACTURER'),
					getTagSearchObject(ractive.get('autocompleteCategories').length > 0 ? ractive.get('autocompleteCategories') : ractive.get('categories'), 'categoryId', 'name', 'CATEGORY'),
					getTagSearchObject(ractive.get('yearList'), 'value', 'value', 'YEAR'),
					getTagSearchObject(ractive.get('autocompletePartModels'), 'partModelId', 'name', 'PART_MODEL'),
					getTagSearchObject(ractive.get('autocompleteVehicleModels'), 'model', 'model', 'VEHICLE_MODEL'),
				].flat()

				let existingTagTypes = ractive.get('searchTags')

				if (existingTagTypes.length > 0) {
					existingTagTypes = existingTagTypes.map(tag => tag.type)

					autocompleteCache = autocompleteCache.filter(item => !existingTagTypes.includes(item.type))
				}

				return ractive.set({ autocompleteCache })
			}

			async function loadAndRebuildCache({ ractiveProp, loadEndpoint, loadOptions }) {
				if (ractiveProp && loadEndpoint && typeof (loadOptions) === 'object' && Object.keys(loadOptions.length > 0)) {
					try {
						const res = await mediator.call('emitToServer', loadEndpoint, loadOptions)
						await ractive.set({ [ractiveProp]: res })
						await rebuildAutocompleteCache()
					} catch (err) {
						logAndAlert(err, mediator, `Failed to ${loadEndpoint}`)
					}
				}
			}

			ractive.on('tagSearchInput', (context, event) => {
				let searchString = ''

				if (typeof (event.detail) === 'object') {
					searchString = event.detail.value
				} else if (typeof (event.detail) === 'string') {
					searchString = event.detail
				}

				searchString = searchString.trim()
				ractive.set({ tagSearchAutocomplete: [] })

				if (searchString.length <= 1) {
					return
				}

				ractive.set({
					tagSearchAutocomplete: fuzzy.go(searchString, ractive.get('autocompleteCache'), {
						key: 'value',
					}).map(res => res.obj),
				})
			})

			ractive.on('addSearchTag', async(context, event) => {
				const addedTag = event.detail.data
				let loadEndpoint, loadOptions = {}
				const tags = ractive.get('searchTags')

				switch (addedTag.type) {
					case 'YEAR': {
						loadEndpoint = 'load vehicle makes'
						loadOptions = { ...loadOptions, firstYear: addedTag.value, lastYear: addedTag.value }

						await loadAndRebuildCache({ ractiveProp: 'autocompleteVehicleMakes', loadEndpoint, loadOptions })
						break
					}
					case 'PART_MANUFACTURER': {
						loadEndpoint = 'load part models using inventory type'

						const inventoryTypeTag = tags.find(tag => tag.type === 'INVENTORY_TYPE')

						if (inventoryTypeTag) {
							loadOptions.inventoryTypeId = inventoryTypeTag.id
						}

						loadOptions.partManufacturerId = addedTag.id

						await loadAndRebuildCache({ ractiveProp: 'autocompletePartModels', loadEndpoint, loadOptions })
						break
					}
					case 'VEHICLE_MAKE': {
						loadEndpoint = 'load vehicle models'
						loadOptions.make = addedTag.id

						await loadAndRebuildCache({ ractiveProp: 'autocompleteVehicleModels', loadEndpoint, loadOptions })
						break
					}
					case 'INVENTORY_TYPE':
						loadEndpoint = 'load categories'
						loadOptions.inventoryTypeId = addedTag.id

						await loadAndRebuildCache({ ractiveProp: 'autocompleteCategories', loadEndpoint, loadOptions })
						break
					default:
						rebuildAutocompleteCache()
				}
			})

			ractive.on('removeSearchTag', (context, event) => {
				if (event.detail.data) {
					rebuildAutocompleteCache()
				}
			})

			keyboard.bind('alt + n', e => {
				e.preventDefault()
				ractive.find('#newPart').click()
			})

			keyboard.bind('alt + s', e => {
				e.preventDefault()
				ractive.fire('search')
			})

			keyboard.bind('alt + shift + s', e => {
				e.preventDefault()
				ractive.find('#searchOnline').click()
			})

			ractive.on('toggleYearSearchType', () => {
				ractive.set({ yearSearch: ractive.get('yearSearch') == 'SINGLE' ? 'RANGE' : 'SINGLE' })
					.then(() => {
						const inputId = ractive.get('yearSearch') == 'SINGLE' ? 'singleYear' : 'rangeYearTo'
						ractive.find(`#${inputId}`).select()
					})
			})

			ractive.find('#keyword-search')?.focus()

			ractive.observe('vehicleMake', async make => {
				await ractive.set({ vehicleModelsLoading: true })
				try {
					const vehicleModels = await mediator.call('emitToServer', 'load vehicle models', { make })
					ractive.set({ vehicleModels })
				} catch (err) {
					logAndAlert(err, mediator, 'Failed to load vehicle models')
				} finally {
					ractive.set({ vehicleModelsLoading: false })
				}
			})

			ractive.on('addFlexField', (context, field) => {
				console.log(field)
				if (field) {
					ractive.push('flexFieldSelections', makeFlexFieldSelection(ractive.get('flexFieldOptions'), field)).then(() => {
						const flexFieldValueInputs = ractive.findAll('.flex-field-values')

						flexFieldValueInputs[flexFieldValueInputs.length - 1].focus()
					})
				}
			}, { init: false })

			ractive.observe('inventoryTypeId partManufacturer partModel vehicleYear vehicleMake vehicleModel', () => {
				ractive.findComponent('interchangeInput').tryInterchangeLookup()
			}, { init: false })

			// fetch Q&A
			ractive.observe('inventoryTypeId category', async() => {
				const inventoryTypeId = ractive.get('inventoryTypeId')
				if (!inventoryTypeId) {
					ractive.set('questions', [])
				} else {
					const questionNames = new Set()
					const newQa = await mediator.call('emitToServer', 'load inventory questions', {
						// If we don't set category, show for all categories
						categoryName: ractive.get('category') || undefined,
						inventoryTypeId: ractive.get('inventoryTypeId'),
					})
					ractive.set('questions', newQa.reduce((acc, qa) => {
						if (!questionNames.has(qa.text)) {
							questionNames.add(qa.text)
							acc.push(qa)
						}
						return acc
					}, []))
				}
			})

			ractive.on('interchangeInput.interchangeNumberSelected', (context, item, interchangeRecord) => {
				ractive.set({ interchangeNumber: interchangeRecord.interchangeNumber })
			})

			ractive.on('forceSearch', (_, searchParams) => {
				// Klona the object so we don't overwrite the objects with the JSON stringified versions
				searchParams = klona(searchParams)
				//If we're sending an object for any params, it needs to be serialized
				//some other way since it gets sent as a GET-style param.
				if (searchParams.flexFields) {
					console.log(searchParams.flexFields)
					try {
						searchParams.flexFields = JSON.stringify(searchParams.flexFields)
					} catch (err) {
						logAndAlert(err, mediator, 'Failed to read to flex field choices')
					}
				}

				if (searchParams.questionValues) {
					try {
						searchParams.questionValues = JSON.stringify(searchParams.questionValues)
					} catch (err) {
						logAndAlert(err, mediator, 'Failed to read question values')
					}
				}

				if (searchParams.vehicleYear) {
					searchParams.vehicleYear = JSON.stringify(searchParams.vehicleYear)
				}

				stateRouter.go('app.part-search.results', searchParams)
			})

			ractive.on('searchInterface.search', async(context, event) => {
				console.log('search')
				event?.preventDefault()
				const searchParams = ractive.get('searchParams')

				const count = await mediator.call('emitToServer', 'load inventory count', { searchParams })

				if (count <= 1000 || confirm(`This search contains ${count} results. This could take a while to load. Load the results anyway?`)) {
					ractive.fire('forceSearch', {}, searchParams)
				}
			})
		},
	})
}
