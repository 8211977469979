<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import DateRange from '@isoftdata/svelte-date-range'
	import { type ComponentProps, type ComponentEvents } from 'svelte'
	import { toLocaleDateString } from '@isoftdata/utility-date-time'
	import { format as formatCurrency } from '@isoftdata/utility-currency'

	export let asr: SvelteAsr
	export let range: ComponentProps<DateRange>['range']
	export let dates: ComponentProps<DateRange>['dates']

	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import { getContext } from 'svelte'
	import NoItemsForSelectedFilter from 'components/NoItemsForSelectedFilter.svelte'
	import type { Document } from 'types/common'

	export let displayDocumentList: Array<Document>

	const mediator = getContext<Mediator>('mediator')

	async function dateChange(event: ComponentEvents<DateRange>['change']) {
		const dates = event.detail.dates
		asr.go(null, { fromDate: dates.from, toDate: dates.to }, { inherit: true })
		if (range !== 'Custom') {
			await mediator.call('emitToServer', 'save user setting', {
				name: 'Chromium Home Screen Date Range',
				category: 'Home',
				defaultValue: 'Last 7 Days',
				settingType: 'Interface History',
				value: range,
			})
		}
	}

	const columns: Array<Column> = [
		{ property: 'quoteId', name: 'Delivery #', numeric: true, width: '1rem' },
		{ property: 'documentDate', name: 'Date', defaultSortColumn: true, defaultSortDirection: 'DESC' },
		{ property: 'billTo', name: 'Bill To' },
		{ property: 'shipTo', name: 'Ship To' },
		{ property: 'subtotal', name: 'Subtotal', numeric: true },
		{ property: 'tax', name: 'Tax', numeric: true },
		{ property: 'total', name: 'Total', numeric: true },
		{ property: 'salesperson', name: 'Salesperson' },
		{ property: 'comments', name: 'Comments' },
	]
</script>

<div class="card-body p-2 card-body-p2">
	<div class="form-row card-body-p2-form-row">
		<div class="col-md-3 col-lg-2">
			<div
				class="card"
				style="margin-bottom: 20px;"
			>
				<h5 class="card-header">Filters</h5>
				<div class="card-body p-2">
					<DateRange
						bind:range
						bind:dates
						on:change={dateChange}
					></DateRange>
				</div>
			</div>
		</div>

		<div
			class="col-md-9 col-lg-10 tab-pane"
			id="sales"
			role="tab"
		>
			{#if displayDocumentList.length > 0}
				<Table
					responsive
					{columns}
					perPageCount={15}
					rows={displayDocumentList}
				>
					{#snippet children({ row })}
						<tr
							on:click={() => asr.go('app.sale', { documentId: row.quoteId, documentType: 'DELIVERY' })}
							class="cursor-pointer"
						>
							<Td property="quoteId">{row.quoteId}</Td>
							<Td property="documentDate">{toLocaleDateString(row.documentDate)}</Td>
							<Td property="billTo">{row.billTo}</Td>
							<Td property="shipTo">{row.shipTo}</Td>
							<Td property="subtotal">{formatCurrency(row.subtotal)}</Td>
							<Td property="tax">{formatCurrency(row.tax)}</Td>
							<Td property="total">{formatCurrency(row.total)}</Td>
							<Td property="salesperson">{row.salesperson}</Td>
							<Td property="comments">{row.comments ?? ''}</Td>
						</tr>
					{/snippet}
				</Table>
			{:else}
				<div class="well">
					<NoItemsForSelectedFilter>Deliveries</NoItemsForSelectedFilter>
				</div>
			{/if}
		</div>
	</div>
</div>
