import pProps from 'p-props'
import TeardownItem from './classes/TeardownItem.mjs'
import DefaultPart from './classes/DefaultPart.mjs'

class DefaultInventoryPart extends DefaultPart {
	constructor(parent, parentType) {
		super()

		if (parentType === 'INVENTORY') {
			this.parentInventoryId = parent.inventoryId
		} else if (parentType === 'VEHICLE') {
			this.vehicleId = parent.vehicleId
		}

		this.partManufacturer = ''
		this.partModel = ''
		this.notes = null
		this.status = ''
		this.location = parent.location || '' //default the location to the parent's location
	}
}

class DefaultBidPart extends DefaultPart {
	constructor(vehicleBidId) {
		super()

		//Currently bids can only be a child of a vehicle, so no special handling here.

		this.vehicleBidId = vehicleBidId
		this.partManufacturerId = null
		this.partModelId = null
	}
}

export default async function({ mediator, parts, parent, parentType, teardownType, vehicleBidId, breakdownTemplate }) {
	function loadModels(inventoryTypeId, partModelId, partModel) {
		return mediator.call('emitToServer', 'load part models using inventory type', {
			inventoryTypeId,
			partModelId,
			partModel,
		})
	}

	const inventoryTypeList = await mediator.call('emitToServer', 'load inventory type list', {})
	let defaultPart
	let doBreakdownTemplate = false

	if (teardownType === 'BID') {
		defaultPart = new DefaultBidPart(vehicleBidId)
	} else if (teardownType === 'INVENTORY') {
		defaultPart = new DefaultInventoryPart(parent, parentType)
		doBreakdownTemplate = parentType === 'INVENTORY'
	}

	const partsInventoryIds = parts
		.filter(part => part.inventoryId)
		.map(part => part.inventoryId)

	const partsThatNeedModelsLoaded = parts.filter(part => {
		return part.inventoryTypeId && (part.partManufacturer || part.partModelId)
	})

	let res = await pProps({
		partManufacturerList: mediator.call('emitToServer', 'load part manufacturers using inventory types', {}),
		partModelList: (async() => (await Promise.all(partsThatNeedModelsLoaded.map(part => loadModels(part.inventoryTypeId, part.partModelId, part.partModel))))?.flat())(),
		partCategoryList: mediator.call('emitToServer', 'load inventory type categories', {}), // need typeSetId for grouping categories per part
		fileCounts: partsInventoryIds.length > 0 ? mediator.call('emitToServer', 'load file count', {
			association: {
				type: 'INVENTORY',
				key: {
					inventoryId: partsInventoryIds,
				},
			},
			options: {
				fileType: 'Image',
			},
		}) : [],
		templates: mediator.call('emitToServer', 'load teardown template', {}),
		inventoryTypeList,
		parent,
		parentType,
		teardownType,
		parts,
		defaultPart,
		selectedTemplate: '',
	})

	if (doBreakdownTemplate && breakdownTemplate && Array.isArray(breakdownTemplate)) {
		let templateName = 'Part Breakdown'

		if (res.parent && res.parent.inventoryTypeId) {
			const parentPartInventoryType = res.inventoryTypeList
				.find(type => type.inventoryTypeId === res.parent.inventoryTypeId)

			if (parentPartInventoryType) {
				templateName = `${ parentPartInventoryType.name } Breakdown`
			}
		}

		//make a breakdown template look like a teardown template
		breakdownTemplate = breakdownTemplate.map(item => {
			return {
				teardownName: templateName,
				inventoryTypeId: item.inventoryTypeId,
			}
		})

		res.templates = [ ...res.templates, ...breakdownTemplate ]
		res.selectedTemplate = templateName
	}

	res.parts = res.parts.map(part => {
		return new TeardownItem(part,
			teardownType,
			res.inventoryTypeList,
			res.partManufacturerList,
			res.partModelList,
			res.partCategoryList,
		)
	})

	return res
}
