import template from './manage.html'
import { makeDashboardManageComponent } from '@isoftdata/web-dashboard'

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: 'app.configuration.dashboard.manage',
		route: ':dashboardReportId',
		querystringParameters: [ 'dashboardReportId' ],
		template: {
			template,
			components: {
				itDashboardManage: makeDashboardManageComponent({
					loadDashboardChartMembershipForReport: ({ dashboardReportId }) => {
						return mediator.call('emitToServer', 'load dashboard chart membership for report', { dashboardReportId })
					},
					loadDashboardReportAuthor: ({ dashboardReportId }) => {
						return mediator.call('emitToServer', 'load dashboard report author', { dashboardReportId })
					},
					loadDashboardReportMetadata: ({ dashboardReportId, chartId }) => {
						return mediator.call('emitToServer', 'load dashboard report metadata', { dashboardReportId, chartId })
					},
					loadChartDataForReport: ({ chartId, dashboardReportId, parameterSelectionList }) => {
						return mediator.call('emitToServer', 'load chart data for report', { chartId, dashboardReportId, parameterSelectionList })
					},
					loadDashboardReportsForConfiguration: () => {
						return mediator.call('emitToServer', 'load dashboard reports for configuration', {})
					},
					updateDashboardChartMembershipForReport: row => {
						return mediator.call('emitToServer', 'update dashboard chart membership for report', row)
					},
					saveDashboardReport: ({ dashboardReport }) => {
						return mediator.call('emitToServer', 'save dashboard report', { dashboardReport })
					},
					saveDashboardReportChartOptions: ({ dashboardReportChart }) => {
						return mediator.call('emitToServer', 'save dashboard report chart options', { dashboardReportChart })
					},
					loadUsers: () => {
						return mediator.call('emitToServer', 'load users', { status: 'Active' })
					},
					loadStores: async() => {
						let storeList = await mediator.call('emitToServer', 'load stores', {})
						storeList = storeList.map(store => {
							store.name = `${store.storeId} - ${store.name}`
							return store
						})
						return storeList
					},
					loadGroups: () => {
						return mediator.call('emitToServer', 'load groups', {})
					},
				}),
			},
		},
		resolve(data, { dashboardReportId }) {
			return Promise.resolve({ dashboardReportId: Number(dashboardReportId) })
		},
	})
}
