export type FavoriteReportsStore = Omit<Writable<string[]>, 'update'>
import type { Mediator } from 'types/common'

import makeSettingValueStore from './setting-value-store'
import { derived, type Writable } from 'svelte/store'

export default function makeFavoriteReportsStore(mediator: Mediator, initialValue: string): FavoriteReportsStore {
	const settingStore = makeSettingValueStore<string>(mediator, {
		category: 'Report Viewer',
		settingType: 'Interface History',
		name: 'Favorite reports',
		scope: 'USER',
		initialValue,
	})

	const setStore = derived(
		settingStore,
		(newString, set) => {
			set(dedupeArray(newString.split(';')))
		},
		initialValue.split(';').filter(Boolean)
	)

	return {
		subscribe: setStore.subscribe,
		set: (value: string[]) => settingStore.set(dedupeArray(value).join(';')),
	}
}

function dedupeArray<T>(array: T[]): T[] {
	return Array.from(new Set(array))
}
