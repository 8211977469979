import template from './address.html'
import domValue from 'dom-value'
import toTitleCase from 'to-title-case'

//Ractive Components
import makeAddressForm from 'components/address-form'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'
import makeTableComponent from '@isoftdata/table'

const stateName = 'app.entity.address'

const syncingKeys = [
	'Company',
	'Contact',
	'StreetAddress',
	'MailingAddress',
	'City',
	'State',
	'Zip',
	'Country',
	'PhoneNumber',
]

const defaultCustomerEntity = Object.freeze({
	billingCompany: '',
	billingContact: '',
	billingStreetAddress: '',
	billingMailingAddress: '',
	billingCity: '',
	billingState: '',
	billingZip: '',
	billingCountry: '',
	billingPhoneNumber: '',
	shippingCompany: '',
	shippingContact: '',
	shippingStreetAddress: '',
	shippingMailingAddress: '',
	shippingCity: '',
	shippingState: '',
	shippingZip: '',
	shippingCountry: '',
	shippingPhoneNumber: '',
	percentOfPrice: 100,
	defaultPriceType: 'Retail',
})

function sendResult(success, value) {
	return { success, value }
}
function didSucceed(result) {
	return result.success
}

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: stateName,
		route: 'address',
		querystringParameters: [ 'id', 'entityContext' ],
		template: {
			data: {
				domValue,
			},
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: true }),
				billingAddressForm: makeAddressForm({ mediator, logAndAlert }),
				shippingAddressForm: makeAddressForm({ mediator, logAndAlert }),
				itCheckbox: makeCheckbox(),
				itButton: makeButton(),
				duplicateCheckModal: makeModal(),
				duplicateSelectTable: makeTableComponent(),
			},
			computed: {
				entityDisplayName() {
					return toTitleCase(this.get('entityContext'))
				},
			},
			saveEntity(entity, cb) {
				const entityContext = this.get('entityContext')
				mediator.call('emitToServer', `save ${entityContext}`, { [entityContext]: entity })
					.then(savedCustomer => {
						mediator.call('entitySave', { stateThatSaved: stateName, data: savedCustomer })
						cb(null, savedCustomer)
					})
			},
			async findDuplicate(entity) {
				const entityContext = this.get('entityContext')
				if (entityContext !== 'customer') {
					console.log(`findDuplicate encountered unhandled type '${entityContext}'`)
					return sendResult(false)
				}
				const response = await mediator.call('emitToServer', `create ${entityContext} duplicate search`, { [entityContext]: entity })
				if (!response.length) {
					return sendResult(false)
				}
				return sendResult(true, response)
			},
			async createEntity(entity, forceAccept = false) {
				// forceAccept is force continue even though dupicates might exist
				if (!forceAccept) {
					const result = await this.findDuplicate(entity)
					if (didSucceed(result)) {
						this.set({ duplicateRows: result.value, showDuplicates: true })
						return
					}
				}
				// TODO: create a default entity for vendors
				const entityToSave = this.get('entityContext') === 'customer' ? { ...defaultCustomerEntity, ...entity } : entity
				this.saveEntity(entityToSave, (err, savedEntity) => {
					stateRouter.go(null, { id: savedEntity[`${this.get('entityContext')}Id`] }, { inherit: true })
				})
			},
			syncBillingToShipping() {
				const entity = this.get('entity')

				this.set({
					['entity.shippingCompany']: entity.billingCompany,
					['entity.shippingContact']: entity.billingContact,
					['entity.shippingStreetAddress']: entity.billingStreetAddress,
					['entity.shippingMailingAddress']: entity.billingMailingAddress,
					['entity.shippingCity']: entity.billingCity,
					['entity.shippingState']: entity.billingState,
					['entity.shippingZip']: entity.billingZip,
					['entity.shippingCountry']: entity.billingCountry,
					['entity.shippingPhoneNumber']: entity.billingPhoneNumber,
				})
			},
		},
		resolve(data, { entityContext, id }) {
			return Promise.resolve({
				keepShippingInSyncWithBilling: false,
				entityContext,
				id,
				duplicateRows: [],
				duplicateColumns: [
					{ property: 'customerId', name: 'Customer #' },
					{ property: 'billingCompany', name: 'Billing Company' },
					{ property: 'billingContact', name: 'Billing Contact' },
					{ property: 'billingZip', name: 'Billing ZIP Code' },
				],
			})
		},
		activate({ domApi: ractive }) {
			const id = ractive.get('id')

			if (!id && ractive.find('#billingCompany')) {
				ractive.find('#billingCompany').focus()
			}

			const billingKeys = syncingKeys.map(key => `entity.billing${key}`).join(' ')

			ractive.observe('entity', entity => {
				if (id) {
					ractive.saveEntity(entity, () => {})
				}
			}, { init: false })

			ractive.observe(billingKeys, (val, oldVal, keypath) => {
				if (ractive.get('keepShippingInSyncWithBilling')) {
					ractive.set(keypath.replace('billing', 'shipping'), val)
				}
			}, { init: false })

			ractive.observe('keepShippingInSyncWithBilling', matchBilling => {
				if (matchBilling) {
					ractive.syncBillingToShipping()
				}
			}, { init: false })

			ractive.on('DUPLICATE_ACCEPT', (context, customerId) => {
				stateRouter.go(null, { id: customerId }, { inherit: true })
			})
		},
	})
}
