import type { AppContext } from 'client/types/common'
import component from './ReportViewer.svelte'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer',
		route: 'report-viewer',
		defaultChild: 'reports',
		template: {
			svelte: true,
			component,
		},
		resolve() {
			return Promise.resolve()
		},
	})
}
