import template from './entity.html'
import pProps from 'p-props'
import toTitleCase from 'to-title-case'
import klona from 'klona'

//Ractive Components
import makeQuickbooksModal from 'components/quickbooks-modal'
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeButton from '@isoftdata/button'

const stateName = 'app.entity'

const newEntity = Object.freeze({
	billingCompany: '',
	billingContact: '',
	billingStreetAddress: '',
	billingMailingAddress: '',
	billingCity: '',
	billingState: '',
	billingZip: '',
	billingCountry: '',
	billingPhoneNumber: '',
	faxNumber: '',
	emailAddress: '',
	shippingCompany: '',
	shippingContact: '',
	shippingStreetAddress: '',
	shippingMailingAddress: '',
	shippingCity: '',
	shippingState: '',
	shippingZip: '',
	shippingCountry: '',
	shippingPhoneNumber: '',
})

export default function({ mediator, stateRouter, isChildState, hasPermission }) {
	async function loadCustomerBalance(entityContext, id) {
		let balance = 0
		if (entityContext === 'customer' && id) {
			balance = (await mediator.call('emitToServer', 'load customer balance', { customerId: id }))?.[0]?.balance
		}

		return balance
	}

	stateRouter.addState({
		name: stateName,
		route: 'entity',
		defaultChild: 'address',
		querystringParameters: [ 'id', 'entityContext' ],
		template: {
			template,
			twoway: false,
			components: {
				itButton: makeButton(),
				quickbooksModal: makeQuickbooksModal(mediator),
				navTabBar: makeNavTabBar(stateRouter),
			},
			computed: {
				displayChildStates() {
					const childStates = this.get('childStates')

					switch (this.get('entityContext')) {
						case 'customer':
							return [ ...childStates, ...this.get('childStatesCustomer') ]
						case 'vendor':
							return [ ...childStates, ...this.get('childStatesVendor') ]
						default:
							return childStates
					}
				},
				entityDisplayName() {
					return toTitleCase(this.get('entityContext'))
				},
				entityDisplayHeader() {
					const entity = this.get('entity')
					const entityDisplayName = this.get('entityDisplayName')

					if (!this.get('isNewEntity') && entity) {
						const { billingCompany, billingContact } = entity

						return { title: billingCompany, subtitle: billingContact }
					}

					return { title: `New ${entityDisplayName}` }
				},
				isNewEntity() {
					return !this.get('id')
				},
			},
			showQBModal(context, id) {
				console.log(context, id)
				this.set({
					qbContext: {
						entity: context,
						id,
					},
					qbModalShown: true,
				})
			},
		},
		resolve(data, { id, entityContext }) {
			let hasAddEditPermission = false

			if (entityContext === 'customer') {
				hasAddEditPermission = hasPermission('PM_CUSTOMER_ADD')
			} else if (entityContext === 'vendor') {
				hasAddEditPermission = hasPermission('PM_VENDOR_ADD_EDIT')
			}
			const { user } = JSON.parse(localStorage.getItem('session'))

			return pProps({
				entity: (async() => (id && entityContext) ? (await mediator.call('emitToServer', `load ${entityContext}`, { [`${entityContext}Id`]: id }))?.[0] : klona(newEntity))(),
				customerBalance: loadCustomerBalance(entityContext, id),
				showEntityId: mediator.call('emitToServer', 'check user setting', {
					name: 'Show Customer/Vendor ID on Search/Detail Screens',
					category: 'Customer/Vendor',
					userAccountId: user?.userAccountId,
					settingType: 'Interface History',
					defaultValue: false,
					dataType: 'boolean',
				}),
				entityContext,
				hasAddEditPermission,
				id: id ? parseInt(id, 10) : id,
				customer: {},
				qbContext: {},
				childStates: [
					{
						title: 'Addresses',
						stateName: 'app.entity.address',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Additional Info',
						stateName: 'app.entity.additional-info',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Attachments',
						stateName: 'app.entity.attachment',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
				],
				childStatesCustomer: [
					{
						title: 'Financials',
						stateName: 'app.entity.financial',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Sales',
						stateName: 'app.entity.sale',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Payments',
						stateName: 'app.entity.payment',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Quotes',
						stateName: 'app.entity.quote',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Units & Service',
						stateName: 'app.entity.unit',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
					{
						title: 'Contact',
						stateName: 'app.entity.contact',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},

				],
				childStatesVendor: [
					{
						title: 'Purchase Orders',
						stateName: 'app.entity.purchase-order',
						stateParameters: {},
						stateOptions: { inherit: true },
						disabled: !id,
					},
				],
			})
		},
		activate(activateContext) {
			const { domApi: ractive, parameters } = activateContext
			if (parameters.id) {
				mediator.call('activity', {
					stateName,
					stateParameters: parameters,
					stateCategory: ractive.get('entityContext').toUpperCase(),
					action: 'VIEW',
					displayTitle: `${ractive.get('entityDisplayName')} ${ractive.get('entityDisplayHeader').title}`,
					stateParameterKey: 'id',
				})
			}

			const removeEntitySaveProvider = mediator.provide('entitySave', ({ stateThatSaved, data, entityContext }) => {
				if (isChildState({ childState: stateThatSaved, parentState: stateName })) {
					ractive.set({
						saved: new Date().toString(),
						[entityContext]: data,
					})
				}
			})

			const removePaymentChangedProvider = mediator.provide('paymentsChanged', async() => {
				const balance = await loadCustomerBalance(ractive.get('entityContext'), ractive.get('id'))
				ractive.set({ customerBalance: balance })
			})

			activateContext.on('destroy', () => {
				removeEntitySaveProvider?.()
				removePaymentChangedProvider?.()
			})
		},
	})
}
