import type { AppContext, MediatorProviders } from 'types/common'
import type { Inventory } from 'server/db/inventory-db'

import pProps from 'p-props'

import component from './Part.svelte'

export default function ({ mediator, stateRouter }: AppContext) {
	const stateName = 'app.part'

	stateRouter.addState({
		name: stateName,
		route: 'part',
		defaultChild: 'basic',
		querystringParameters: ['inventoryId', 'storeId', 'vehicleId', 'source'],
		defaultParameters: {
			storeId: '1',
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_, { inventoryId, source }) {
			const parsedInventoryId = parseInt(inventoryId, 10)

			const partSearchResults = JSON.parse(localStorage.getItem('partSearchResults') ?? '[]')

			return await pProps({
				inventoryTypes: inventoryId ? mediator.call('emitToServer', 'load inventory type list', {}) : [],
				part: (async () => (inventoryId ? ((await mediator.call('emitToServer', 'load inventory', { inventoryId: parsedInventoryId })) as Array<Inventory>)?.[0] : undefined))(),
				showPartSearchResultsControls: source === 'PART_SEARCH' && partSearchResults?.length > 1,
				stateName,
				inventoryId: parsedInventoryId,
				partSearchResults,
			})
		},
	})
}

export interface PartProviders extends MediatorProviders {
	partSave: (obj: { stateThatSaved: string; data: Inventory }) => void
}
