import template from './group.html'

//Ractive components
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const newGroup = {
	groupId: undefined,
	name: '',
}

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.group',
		route: 'group',
		querystringParameters: [ 'groupId' ],
		template: {
			template,
			components: {
				itSelect: makeSelect({ twoway: true, lazy: true }),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true, lazy: false }),
			},
		},
		async resolve(data, { groupId }) {
			const groups = await mediator.call('emitToServer', 'load groups', {})
			if (!groupId && groups.length > 0) {
				throw {
					redirectTo: {
						name: 'app.configuration.group.manage',
						params: { groupId: groups[0].groupId },
					},
				}
			}

			return {
				groups,
				selectedGroupId: groupId ? parseInt(groupId, 10) : null,
				addEditGroup: false,
				currentAddEditGroup: {},
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('add-group', () => {
				ractive.set({ addEditGroup: true, currentAddEditGroup: { ...newGroup } }).then(() => {
					ractive.find('#addEditGroup').select()
				})
			})

			ractive.on('edit-group', (context, groupId) => {
				const currentAddEditGroup = ractive.get('groups').find(group => group.groupId === groupId)

				ractive.set({ addEditGroup: true, currentAddEditGroup: { ...currentAddEditGroup } }).then(() => {
					ractive.find('#addEditGroup').select()
				})
			})

			ractive.on('save-group', (context, saveGroup) => {
				const groups = ractive.get('groups')
				const existingGroupName = groups
					.find(group => group.name.trim().toUpperCase() === saveGroup.name.trim().toUpperCase())

				if (existingGroupName) {
					return ractive.set({
						addEditGroup: false,
						selectedGroupId: existingGroupName.groupId,
					})
				}

				if (saveGroup && saveGroup.name.trim()) {
					mediator.call('emitToServer', 'save group', { group: saveGroup })
						.then(savedGroup => {
							ractive.upsert('groups', 'groupId', savedGroup)

							ractive.set({
								addEditGroup: false,
								selectedGroupId: savedGroup.groupId,
							})
						})
						.catch(err => {
							logAndAlert(err, mediator, 'Error saving group')
						})
				}

				ractive.set({ addEditGroup: false })
			})

			ractive.observe('selectedGroupId', groupId => stateRouter.go(null, { groupId }), { init: false })
		},
	})
}
