import template from './tax-item.html'
import { stringToBoolean } from '@isoftdata/utility-string'
import financialNumber from 'financial-number'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'
import makeCheckbox from '@isoftdata/checkbox'

function makeDisplayRecordFromItrack(itrackTaxItem) {
	return {
		id: itrackTaxItem.taxItemId,
		name: itrackTaxItem.name,
		rate: Number(financialNumber(itrackTaxItem.rate).times('100')),
		active: itrackTaxItem.active ? 'Yes' : 'No',
		original: itrackTaxItem,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksTaxItem) {
	return {
		...quickbooksTaxItem,
		id: Number(quickbooksTaxItem.Id) || quickbooksTaxItem.Id,
		name: quickbooksTaxItem.Name,
		rate: quickbooksTaxItem.rate,
		original: quickbooksTaxItem,
	}
}

function percentString(value) {
	if (!value && value !== 0) {
		return 'N/A'
	}
	return `${Number(value).toFixed(4)}%`
}

const stateName = 'app.quickbooks.tax-item'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'tax-item',
		querystringParameters: [ 'systemSelection', 'hideMatches', 'hideInactive' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
			hideInactive: 'true',
		},
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox(),
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.configuration.payment-method-and-tax', { selectedTaxItemId: id }), // state doesn't actually accept this parameter, maybe later
					makeQuickbooksEntityUrl: id => `__qboBasePath__/salestax?showExistingUI=true`, // doesn't accept an id, also this interface sucks
					displayQuickbooksColumns: [
						{ property: 'name', name: 'Tax Code' },
						{ property: 'rate', name: 'Tax Rate', class: 'text-right tabular-nums', formatter: percentString },
					],
					displayItrackColumns: [
						{ property: 'name', name: 'Tax Item Name' },
						{ property: 'rate', name: 'Tax Rate', class: 'text-right tabular-nums', formatter: percentString },
						{ property: 'active', name: 'Active' },
					],
					itrackKey: 'taxItemId',
				}),
			},
			computed: {
				displayQuickbooksEntityList: {
					get() {
						const itrackOnly = stringToBoolean(this.get('itrackOnly'))
						const quickbooksEntityList = this.get('quickbooksEntityList')
						if (itrackOnly) {
							return quickbooksEntityList.filter(entity => entity.Name.toLowerCase().includes('itrack')) // could match the literal string 'Unknown (From ITrack)' but it could change and break this
						}
						return quickbooksEntityList
					}, set(val) {
						this.set('quickbooksEntityList', val)
					},
				},
			},
		},
		async resolve(data, { hideMatches, systemSelection: system, hideInactive }) {
			hideInactive = stringToBoolean(hideInactive)
			hideMatches = stringToBoolean(hideMatches)
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load tax items for quickbooks screen', { hideSynced: hideMatches, hideInactive })

			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches,
				hideInactive,
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			ractive.on('hide-inactive-changed', (context, hideInactive) => {
				stateRouter.go(null, { hideInactive }, { inherit: true })
			})

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Tax Items`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
