<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { InterchangeWithPricing } from 'server/db/interchange-db'

	import Interchange from './Interchange.svelte'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'

	import { createEventDispatcher, getContext, tick } from 'svelte'
	import interchangeLookup from 'utility/interchange-lookup'
	import { logAndAlert } from 'utility/error-handler'

	export let asr: SvelteAsr
	export let inventoryTypeId: number | null
	export let vehicleYear: number | null = null
	export let partManufacturerName: string | null = null
	export let partModelName: string | null = null
	export let vehicleMake: string | null = null
	export let vehicleModel: string | null = null
	export let interchangeModalShown = false
	export let interchangeLookupLoading = false
	export let interchangeNumber = ''
	export let showLabel = true
	export let disabled = false

	let interchange: Interchange

	const mediator = getContext<Mediator>('mediator')
	const dispatch = createEventDispatcher<{
		interchangeNumberSelected: {
			interchange: InterchangeWithPricing
		}
	}>()

	$: canDoInterchangeLookup = !!(inventoryTypeId && vehicleYear && ((partManufacturerName && partModelName) || (vehicleMake && vehicleModel)))
	$: shouldDoInterchangeLookup = !interchangeNumber.trim() && canDoInterchangeLookup

	export async function doInterchangeLookup() {
		if (!inventoryTypeId) {
			return
		}

		const options: Parameters<typeof interchangeLookup>[1] = {
			searchType: 'APPLICATION',
			isExact: true,
			inventoryTypeId,
			partManufacturer: partManufacturerName,
			partModel: partModelName,
			vehicleYear,
			vehicleMake,
			vehicleModel,
		}

		interchangeLookupLoading = true

		try {
			const interchangeList = await interchangeLookup(mediator, options)

			if (interchangeList.length === 1) {
				interchangeNumber = interchangeList[0].interchangeNumber
			} else if (interchangeList.length > 1) {
				interchangeModalShown = true
				await tick()
				interchange.search(options)
			} else if (interchangeList.length === 0) {
				await mediator.call('showMessage', { heading: 'No interchange records found!' })
			}
		} catch (err) {
			if ((err as Error).message === 'MISSING_REQUIRED_FIELDS') {
				mediator.call('showMessage', {
					heading: 'Missing Required Fields',
					message: 'Part Type, Year, and either Vehicle Make and Model, or Part Manufacturer and Model are required',
					type: 'warning',
					time: 10000,
				})
			}
		} finally {
			interchangeLookupLoading = false
		}
	}

	export async function tryInterchangeLookup() {
		if (shouldDoInterchangeLookup) {
			try {
				return await doInterchangeLookup()
			} catch (err) {
				logAndAlert(err, mediator, 'Error performing Interchange lookup')
				return false
			}
		}

		return false
	}

	function onInterchangeSelection(interchange: InterchangeWithPricing) {
		interchangeModalShown = false
		dispatch('interchangeNumberSelected', { interchange })
	}
</script>

<!-- Ractive version used a separate label, allegedly bc of a bug that made the label show in the table view, so double check that later -->
<Input
	label="Interchange #"
	{showLabel}
	{disabled}
	bind:value={interchangeNumber}
>
	{#snippet append()}
		<Button
			outline
			{disabled}
			icon="books"
			isLoading={interchangeLookupLoading}
			onclick={doInterchangeLookup}
		></Button>
	{/snippet}
</Input>

<Modal
	bind:show={interchangeModalShown}
	title="Interchange"
	footerShown={false}
	modalSize="xl"
	on:close={() => (interchangeModalShown = false)}
>
	<Interchange
		{asr}
		isPicking
		on:interchangeSelection={event => onInterchangeSelection(event.detail.interchange)}
		bind:this={interchange}
	></Interchange>
</Modal>
