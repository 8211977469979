import template from './store.html'
import klona from 'klona'

//Ractive Components
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'

const newStoreTemplate = Object.freeze({
	storeId: undefined,
	name: '',
	address: null,
	city: null,
	state: null,
	zip: null,
	country: null,
	phoneNumber: null,
	faxNumber: null,
	website: null,
	companyCode: undefined,
	slogan: null,
	description: null,
	invoiceMemo: null,
	quoteMemo: null,
	lowRetailMarkup: 0,
	highRetailMarkup: 0,
	lowWholesaleMarkup: 0,
	highWholesaleMarkup: 0,
	markupShift: 0,
	autoApplyStandard: false,
	autoApplyMisc: false,
	autoApplyUsed: false,
	deliveryExpire: 14,
	quoteExpire: 14,
	workorderExpire: 14,
	nmvtisId: '',
	nmvtisPin: '',
	nmvtisDismantlerNumber: '',
	emailContact: '',
	emailHtpPartsRequests: '',
	logoFileId: null,
})

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.store',
		route: 'store',
		querystringParameters: [ 'storeId' ],
		template: {
			template,
			components: {
				itSelect: makeSelect({ twoway: true, lazy: true }),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true, lazy: false }),
			},
		},
		async resolve(data, { storeId }) {
			const stores = await mediator.call('emitToServer', 'load stores', {})
			if (!storeId && stores.length > 0) {
				throw {
					redirectTo: {
						name: 'app.configuration.store.manage',
						params: { storeId: stores[0].storeId },
					},
				}
			}

			const newStore = stores.length > 0 ?
				({ ...klona(newStoreTemplate), companyCode: stores[0].companyCode }) : klona(newStoreTemplate)

			return {
				stores,
				selectedStoreId: parseInt(storeId, 10),
				addEditStore: false,
				currentAddEditStore: {},
				newStore,
			}
		},
		activate({ domApi: ractive }) {
			ractive.on('add-store', () => {
				ractive.set({
					addEditStore: true,
					currentAddEditStore: klona(ractive.get('newStore')),
				}).then(() => {
					ractive.find('#addEditStore').select()
				})
			})

			ractive.on('edit-store', (context, storeId) => {
				const currentStoreIndex = ractive.get('stores')
					.findIndex(store => store.storeId === storeId)

				ractive.link(`stores[${currentStoreIndex}]`, 'currentAddEditStore')
				ractive.set({ addEditStore: true })
					.then(() => ractive.find('#addEditStore').select())
			})

			ractive.on('save-store', async(context, event) => {
				event.preventDefault()

				const saveStore = ractive.get('currentAddEditStore')
				const stores = ractive.get('stores')
				const existingStoreName = stores
					.find(store => store.name.trim().toUpperCase() === saveStore.name.trim().toUpperCase())

				if (existingStoreName && !saveStore.storeId) {
					return ractive.set({
						addEditStore: false,
						selectedStoreId: existingStoreName.storeId,
					})
				}

				if (saveStore && saveStore.name.trim()) {
					try {
						const savedStore = await mediator.call('emitToServer', 'save store', { store: saveStore })
						ractive.upsert('stores', 'storeId', savedStore)

						await ractive.set({
							addEditStore: false,
							selectedStoreId: savedStore.storeId,
						})
						mediator.call('store-changed', savedStore)
					} catch (err) {
						logAndAlert(err, mediator, 'Error saving store')
					}
				}

				ractive.set({ addEditStore: false })
			})

			ractive.observe('selectedStoreId', storeId => {
				stateRouter.go(null, { storeId })
			}, { init: false })
		},
	})
}
