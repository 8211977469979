const baseState = 'app.configuration.integration.quickbooks'
const route = 'redirect'

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: `${baseState}.${route}`,
		route,
		template: { template: `` },
		async resolve() {
			const isConnectedToQuickbooks = await mediator.call('emitToServer', 'is connected to quickbooks', {})

			return Promise.reject({
				redirectTo: { name: isConnectedToQuickbooks ? `${baseState}.connected` : `${baseState}.auth` },
			})
		},
	})
}
