import template from './payment-method-and-tax.html'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import klona from 'klona'
import pProps from 'p-props'

//Ractive Components
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'
import makeInput from '@isoftdata/input'
import makeModal from '@isoftdata/modal'

const newTaxItem = Object.freeze({
	active: false,
	breakpoint: null,
	breakrate: null,
	isDefault: false,
	name: '',
	rate: 0,
})

const newPaymentMethod = Object.freeze({
	addToNewCustomer: false,
	isCard: false,
	name: '',
	overrideCashOnly: false,
	promptForPayment: false,
	usesCheckAuthorizationNumber: false,
	allowPaymentMethodForAllExistingCustomers: false,
})

export default function({ stateRouter, mediator, logAndAlert }) {
	stateRouter.addState({
		name: 'app.configuration.payment-method-and-tax',
		route: 'payment-method-and-tax',
		querystringParameters: [],
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox({ twoway: true }),
				itTable: makeTable(),
				itInput: makeInput({ twoway: true, lazy: false }),
				itButton: makeButton(),
				itModal: makeModal(),
			},
			computed: {
				displayTaxes() {
					let taxes = this.get('taxes')
					const invoiceUsage = this.get('invoiceUsage')
					const customerDefaultUsage = this.get('customerDefaultUsage')

					if (!this.get('showInactive')) {
						taxes = taxes.filter(tax => tax.active)
					}

					return taxes.map(tax => {
						const matchingInvoice = invoiceUsage
							.find(invoiceTax => invoiceTax.name === tax.name)
						const matchingCustomerCount = customerDefaultUsage
							.find(customerTax => customerTax.name === tax.name)

						return {
							...tax,
							customerUsageCount: matchingCustomerCount ? matchingCustomerCount.count : 0,
							invoiceUsageCount: matchingInvoice ? matchingInvoice.count : 0,
						}
					})
				},
				defaultTaxItem() {
					return this.get('taxes').find(tax => tax.isDefault)
				},
			},
			yesNo,
			paymentMethodExists(name, id) {
				name = name.toUpperCase()
				return this.get('paymentMethods')
					.find(paymentMethod => paymentMethod.name.toUpperCase() === name && paymentMethod.paymentMethodId !== id)
			},
			taxNameExists(name, id) {
				name = name.toUpperCase()
				return this.get('taxes')
					.find(tax => tax.name.toUpperCase() === name && tax.taxItemId !== id)
			},
			showTaxItemModal(taxItemId) {
				const ractive = this

				let currentTaxItem

				if (taxItemId) {
					currentTaxItem = klona(ractive.get('taxes').find(tax => tax.taxItemId === taxItemId))
				} else {
					currentTaxItem = klona(newTaxItem)
				}

				this.set({ taxItemModalShown: true, currentTaxItem }).then(() => {
					this.find('#taxname').select()
				})
			},
			saveTaxItem(taxItem) {
				const ractive = this

				mediator.call('emitToServer', 'save tax item', { taxItem })
					.then(taxList => {
						ractive.set({ taxes: taxList })
						ractive.closeTaxItemModal()
					}).catch(err => {
						logAndAlert(err, mediator, 'Error saving tax item')
					})
			},
			closeTaxItemModal() {
				this.set({ taxItemModalShown: false, currentTaxItem: {} })
			},
			showPaymentMethodModal(paymentMethodId) {
				const ractive = this

				let currentPaymentMethod

				if (paymentMethodId) {
					currentPaymentMethod = klona(ractive.get('paymentMethods').find(paymentMethod => paymentMethod.paymentMethodId === paymentMethodId))
				} else {
					currentPaymentMethod = klona(newPaymentMethod)
				}

				this.set({
					paymentMethodModalShown: true,
					currentPaymentMethod,
					allowPaymentMethodForAllExistingCustomers: false,
				}).then(() => {
					this.find('#paymentmethodname').select()
				})
			},
			savePaymentMethod(paymentMethod, allowPaymentMethodForAllExistingCustomers) {
				const ractive = this

				mediator.call('emitToServer', 'save payment method', { paymentMethod, allowPaymentMethodForAllExistingCustomers })
					.then(savedPaymentMethod => {
						ractive.upsert('paymentMethods', 'paymentMethodId', savedPaymentMethod)
						ractive.closePaymentMethodModal()
					})
					.catch(err => {
						logAndAlert(err, mediator, 'Error saving payment method')
					})
			},
			closePaymentMethodModal() {
				this.set({
					paymentMethodModalShown: false,
					currentPaymentMethod: {},
					allowPaymentMethodForAllExistingCustomers: false,
				})
			},
		},
		resolve(data, parameters) {
			return pProps({
				taxes: mediator.call('emitToServer', 'load tax items', { activeOnly: false, percentage: true }),
				invoiceUsage: mediator.call('emitToServer', 'load invoice tax item usage count', {}),
				customerDefaultUsage: mediator.call('emitToServer', 'load customers default tax item count', {}),
				paymentMethods: mediator.call('emitToServer', 'load payment methods', {}),
				showInactive: false,
				taxItemModalShown: false,
				currentTaxItem: {},
				selectedTaxItemId: Number(parameters.selectedTaxItemId) || null,
				paymentMethodModalShown: false,
				currentPaymentMethod: {},
				selectedPaymentMethodId: null,
			})
		},
		activate({ domApi: ractive }) {
			ractive.on('createNewTax', context => {
				context.event.preventDefault()
				return ractive.showTaxItemModal()
			})

			if (ractive.get('selectedTaxItemId')) {
				ractive.find(`#tax-${ractive.get('selectedTaxItemId')}`)?.scrollIntoView({
					block: 'center',
					inline: 'center',
					behavior: 'smooth',
				})
			}
		},
	})
}
