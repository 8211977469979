import template from './teardown.html'

//Helper utilities
import { getBootstrapBreakpoint } from '@isoftdata/utility-bootstrap'
import * as manModHelper from 'shared/manufacturer-model-helper'
import * as arrayHelper from '@isoftdata/utility-array'
import fileUpload from 'utility/file-upload'
import * as currency from '@isoftdata/utility-currency'
import domValue from 'dom-value'

//Ractive Components
import makeAppraisalOriginPicker from 'components/appraisal'
import makeInterchangeSelector from 'components/interchange-selector'
import makePartPricingModal from 'components/part-pricing-modal'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeImageViewer from '@isoftdata/image-viewer-modal'
import makeSideSelect from 'components/side-select'
import makeTextArea from '@isoftdata/textarea'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeInput from '@isoftdata/input'
import makeTable from '@isoftdata/table'
import makeModal from '@isoftdata/modal'

//Ractive Events
import dragAndDropFiles from 'ractive-drag-and-drop-files'

//Ractive Transitions
import ractiveTransitionsFade from 'ractive-transitions-fade'
import ractiveTransitionsFly from 'ractive-transitions-fly'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

//Classes
import InventoryPart from 'shared/classes/InventoryPart'
import TeardownItem from 'shared/classes/TeardownItem'
import BidPart from 'shared/classes/BidPart'

export default function createTeardownComponent({ mediator, stateRouter, logAndAlert, hasPermission }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		twoway: false,
		data() {
			return {
				makePath: stateRouter?.makePath,
				defaultPart: {},
				parentType: '', // VEHICLE or INVENTORY
				teardownType: '', // INVENTORY or BID(BID is only valid if parent type is VEHICLE)
				showTeardownItemModal: false,
				showAppraisalModal: false,
				currentPageTeardownRows: [],
				sortedAllTeardownItems: [],
				domValue,
				fileDraggedOverItemId: null,
				savingFiles: [],
				displayFiles: [],
				imageViewerShown: false,
				currentPhotoIndex: 0,
				userViewMode: 'TABLE', //TABLE or LIST
				listViewSelection: 0,
				currency,
				selectedTemplate: '',
				addingPart: false,
				vehicle: {}, //Used for interchange
				currentTeardownPageNumber: 1,
				canEditPart: hasPermission('PM_PART_EDIT'),
				canEditFinancial: hasPermission('PM_PARTFINANCIAL_EDIT'),
				canEditCost: hasPermission('PM_PARTSCOST_EDIT'),
				canViewCost: hasPermission('PM_PARTSCOST_VIEW'),
				locations: [],
			}
		},
		components: {
			itInput: makeInput({ twoway: false }),
			itSelect: makeSelect(),
			itSelectTwo: makeSelect({ twoway: true, lazy: false }),
			itTable: makeTable({ stickyHeader: true }),
			itSideSelect: makeSideSelect(),
			itTextArea: makeTextArea({ twoway: true }),
			itCurrencyInput: makeCurrencyInput(),
			itImageViewer: makeImageViewer(),
			appraisalModal: makeModal(),
			teardownItemModal: makeModal(),
			appraisalOriginPicker: makeAppraisalOriginPicker(mediator),
			partPricingModal: makePartPricingModal(mediator),
			interchangeSelector: makeInterchangeSelector({ mediator, stateRouter, logAndAlert }, { twoway: false }),
			itButton: makeButton({ twoway: true, lazy: true }),
		},
		transitions: {
			fade: ractiveTransitionsFade,
			fly: ractiveTransitionsFly,
			slide: ractiveTransitionsSlide,
		},
		events: {
			dragdropfiles: dragAndDropFiles,
		},
		computed: {
			templateList() {
				const templates = this.get('templates')
					.map(template => template.teardownName)

				return arrayHelper.getDistinctArrayValues(templates)
			},
			selectedTemplateList() {
				const selectedTemplate = this.get('selectedTemplate')
				const teardownType = this.get('teardownType')
				const inventoryTypeList = this.get('inventoryTypeList')
				const partManufacturerList = this.get('partManufacturerList')
				const partModelList = this.get('partModelList')
				const partCategoryList = this.get('partCategoryList')

				if (selectedTemplate) {
					const parent = this.get('parent')
					const parentType = this.get('parentType')
					return this.get('templates')
						.filter(templateItem => templateItem.teardownName === selectedTemplate)
						.map(templateItem => {
							return new TeardownItem(
								this.addVehicleInfo(templateItem, parent, parentType),
								teardownType,
								inventoryTypeList,
								partManufacturerList,
								partModelList,
								partCategoryList)
						})
				}
				return []
			},
			displayTeardown() {
				const existingParts = this.get('parts')
				const template = this.get('selectedTemplateList')
				const defaultPart = this.get('defaultPart')

				const partModelList = this.get('partModelList')

				//Const appraisalItems = this.get('appraisalItems')
				const fileCounts = this.get('fileCounts')

				const appraisedItems = this.get('appraisedItems')

				/* Build an array of items that match the template
				   as well as items remaining in the template */
				const templateWithMatchingParts = template.map(templateItem => {
					const matchingPartIndex = existingParts.findIndex(part => part.inventoryTypeId === templateItem.inventoryTypeId)

					return { ...templateItem, ...(matchingPartIndex > -1 ? existingParts[matchingPartIndex] : defaultPart) }
				})

				/* Build an array of items that exist,
				   but are not in the teardown template,
				   because we still want to display those */
				const existingPartsWithNoTemplateMatch = existingParts.reduce((sum, part) => {
					return templateWithMatchingParts.findIndex(item => item.inventoryTypeId === part.inventoryTypeId) > -1
						? sum : sum.concat(part)
				}, [])

				let computedTeardownItems = [ ...templateWithMatchingParts, ...existingPartsWithNoTemplateMatch ]

				computedTeardownItems = computedTeardownItems.map(teardownItem => {
					let fileCount = 0

					if (fileCounts) {
						const foundCount = fileCounts.find(part => part.inventoryId === teardownItem.id)

						if (foundCount) {
							fileCount = foundCount.count
						}
					}

					return {
						...teardownItem,
						itemCreationButtonClasses: getClassFromInventoryStatus(teardownItem.status),
						partStatusIsToggleable: partStatusIsToggleable(teardownItem.exists, teardownItem.status),
						tooltipTextForStatus: getTooltipTextFromInventoryStatus(teardownItem.status),
						status: teardownItem.exists ? teardownItem.status : '', //Only used for sorting
						fileCount,
					}
				})

				computedTeardownItems = computedTeardownItems.map(item => {
					if (item.partManufacturerId && partModelList && partModelList.length > 0) {
						const currentPartModelList = partModelList.filter(partModel => {
							return partModel.partManufacturerId === item.partManufacturerId
								&& partModel.typeSetId === item.inventoryTypeId
						})
						return { ...item, partModelList: currentPartModelList }
					}
					return { ...item, partModelList: [] }
				})

				if (appraisedItems && appraisedItems.length > 0) {
					computedTeardownItems = computedTeardownItems.map(item => {
						const match = appraisedItems.find(appraisedItem => appraisedItem.id === item.id)
						return { ...item, appraisalValue: match ? match.value : '' }
					})
				}

				return arrayHelper.setUuidFromIndex(computedTeardownItems)
			},
			itemsToAppraise() {
				const parts = this.get('parts')
				const partManufacturerList = this.get('partManufacturerList')
				const partModelList = this.get('partModelList')

				const parentIsVehicle = this.get('parentType') === 'VEHICLE'
				const parent = this.get('parent')

				return parts.map(part => {
					return {
						id: part.id,
						inventoryTypeId: part.inventoryTypeId,
						partManufacturer: part.partManufacturerId ? manModHelper.getPartManufacturerNameFromManufacturerId(partManufacturerList, part.partManufacturerId) : '',
						partModel: part.partModelId ? manModHelper.getPartModelNameFromModelId(partModelList, part.partModelId) : '',
						category: part.category,
						vehicleMake: parentIsVehicle ? parent.make : '',
						vehicleModel: parentIsVehicle ? parent.model : '',
						year: parentIsVehicle ? parent.year : '',
					}
				})
			},
			isBid() {
				return this.get('teardownType') === 'BID'
			},
			tableColumns() {
				const chosenAppraisalOrigin = this.get('chosenAppraisalOrigin')
				const appraisedItems = this.get('appraisedItems')
				const viewSize = this.get('viewSize')
				const viewMode = this.get('viewMode')
				const isBid = this.get('isBid')

				const smallViewHeaders = [
					{
						property: 'tagNumber',
						name: 'Tag #',
						title: 'Tag #',
						columnMinWidth: '150px',
					},
					{
						property: 'status',
						name: 'Status',
						sortType: 'alphaNum',
						footer: {
							altProperty: 'exists',
							fn: 'COUNT',
							requiredValue: true,
						},
					},
					{ property: 'inventoryTypeName', name: 'Part Type' },
					{ property: 'inventoryTypeId', name: 'Type #', defaultSortColumn: true },
				]

				const mediumViewHeaders = smallViewHeaders.concat([
					{
						property: 'fileCount',
						name: 'Attachments',
						title: 'Attachment Count',
						footer: {
							fn: 'SUM',
						},
					},
					{
						property: 'partManufacturer',
						name: 'Manufacturer',
					},
					{
						property: 'partModel',
						name: 'Model',
					},
					{
						property: 'partCategory',
						name: 'Category',
						columnMinWidth: '185px',
					},
					{
						property: 'location',
						name: 'Location',
						columnMinWidth: '120px',
					},
					{
						property: 'interchange',
						name: 'Interchange #',
					},
					{
						property: 'side',
						name: 'Side',
						columnMinWidth: '80px',
					},
					{
						property: 'quantity',
						name: 'Qty',
						columnMinWidth: '80px',
					},
					{
						property: 'retailPrice',
						name: 'Retail',
						columnMinWidth: '90px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
					{
						property: 'wholesalePrice',
						name: 'Wholesale',
						columnMinWidth: '90px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
				])

				const largeViewHeaders = mediumViewHeaders.concat([
					{
						property: 'listPrice',
						name: 'List',
						columnMinWidth: '90px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
					},
					{
						property: 'cost',
						name: 'Cost',
						columnMinWidth: '90px',
						footer: {
							fn: 'SUM',
							formatCurrency: true,
						},
						requiredViewPermission: 'PM_PARTSCOST_VIEW',
					},
					{
						property: 'description',
						name: 'Description',
						columnMinWidth: '120px',
					},
				])

				const inventoryHeader = { property: 'notes', name: 'Notes', columnMinWidth: '120px' }

				const appraisalHeader = {
					property: 'appraisalValue',
					name: 'Appraised',
					title: chosenAppraisalOrigin ? chosenAppraisalOrigin.name : '',
					footer: {
						fn: 'SUM',
						formatCurrency: true,
					},
				}

				let headers

				switch (viewSize) {
					case 'SMALL':
						headers = smallViewHeaders
						break
					case 'MEDIUM':
						if (viewMode === 'LIST') {
							headers = smallViewHeaders
						} else if (viewMode === 'TABLE') {
							headers = mediumViewHeaders
							headers.push({
								property: 'uploadImage',
								name: '',
								title: 'Browse files to upload an image',
							})
							headers.splice(1, 0, {
								property: 'creation',
								sortType: false,
								icon: 'fas fa-plus-minus',
								columnMaxWidth: '150px',
							})
						}
						break
					case 'LARGE':
						if (viewMode === 'LIST') {
							headers = smallViewHeaders
						} else if (viewMode === 'TABLE') {
							headers = largeViewHeaders
							if (!isBid) {
								headers = headers.concat(inventoryHeader)
							}

							if (appraisedItems && appraisedItems.length > 0) {
								headers = headers.concat(appraisalHeader)
							}
							headers.splice(1, 0, {
								property: 'creation',
								sortType: false,
								icon: 'fas fa-plus-minus',
								columnMaxWidth: '150px',
							})
						}
						break
					default:
						headers = smallViewHeaders
				}

				return headers.filter(header => {
					if (header.requiredViewPermission) {
						return hasPermission(header.requiredViewPermission)
					} else {
						return true
					}
				})
			},
			tableHeaderProperties() {
				return this.get('tableColumns').map(header => header.property)
			},
			viewSize() {
				switch (this.get('bsBreakpoint')) {
					case 'xs':
					case 'sm':
						return 'SMALL'
					case 'md':
					case 'lg':
						return 'MEDIUM'
					case 'xl':
						return 'LARGE'
				}
			},
			partManufacturerListByTypeId() {
				const partManufacturerList = this.get('partManufacturerList')

				if (partManufacturerList) {
					return manModHelper.partManufacturerListByTypeId(partManufacturerList)
				}
				return null
			},
			partModelListByPartManufacturerId() {
				const partModelList = this.get('partModelList')

				if (partModelList) {
					return manModHelper.partModelListByPartManufacturerId(partModelList)
				}
				return null
			},
			partCategoryListByTypeId() {
				const partCategoryList = this.get('partCategoryList')

				if (partCategoryList) {
					return manModHelper.partManufacturerListByTypeId(partCategoryList)
				}
				return null
			},
			fileSupport() {
				return this.get('teardownType') !== 'BID'
			},
			tableInline() {
				return this.get('viewMode') === 'TABLE' && !this.get('showTeardownItemModal')
			},
			viewMode() {
				const breakpoint = this.get('bsBreakpoint')
				const userViewMode = this.get('userViewMode')

				return [ 'xl', 'lg' ].includes(breakpoint) ? userViewMode : 'LIST'
			},
		},
		addVehicleInfo(templateItem, parent, parentType) {
			if (parentType === 'VEHICLE') {
				return {
					...templateItem,
					bodyStyle: parent.bodyStyle,
					make: parent.make,
					model: parent.model,
					vehicleId: parent.vehicleId,
					vin: parent.vin,
					year: parent.year,
				}
			} else if (parentType === 'INVENTORY') {
				return {
					...templateItem,
					bodyStyle: parent.bodyStyle,
					make: parent.vehicleMake,
					model: parent.vehicleModel,
					vehicleId: parent.vehicleId,
					vin: parent.vin,
					year: parent.year,
				}
			}
			return templateItem
		},
		addPart(inventoryTypeId, defaults = {}) {
			const ractive = this
			if (inventoryTypeId) {
				ractive.set({ addingPart: true }).then(() => {
					inventoryTypeId = Number(inventoryTypeId)

					const teardownType = this.get('teardownType')
					const inventoryTypeList = this.get('inventoryTypeList')
					const partManufacturerList = this.get('partManufacturerList')
					const partModelList = this.get('partModelList')
					const partCategoryList = this.get('partCategoryList')

					const item = { ...this.get('defaultPart'), ...defaults, inventoryTypeId, status: toggleInventoryStatus('') }

					const saveItem = new TeardownItem(ractive.addVehicleInfo(item, this.get('parent'), this.get('parentType')),
						teardownType,
						inventoryTypeList,
						partManufacturerList,
						partModelList,
						partCategoryList)

					ractive.saveItem(saveItem, (err, _res) => {
						if (!err) {
							ractive.set({ addingPart: false })
						}
					})
				})
			}
		},
		updateFileCount({ id, count }) {
			const ractive = this

			let arrayFileCounts = ractive.get('fileCounts')

			const foundIndex = arrayFileCounts.findIndex(part => part.inventoryId === id)

			if (foundIndex > -1) {
				arrayFileCounts[foundIndex].count = count
			} else {
				arrayFileCounts = arrayFileCounts.concat({ inventoryId: id, count })
			}

			ractive.set({ fileCounts: arrayFileCounts })
		},
		async fileUpload(id, files) {
			const ractive = this

			await fileUpload(mediator, files, [], { type: 'INVENTORY', key: { inventoryId: id } })
			const fileCounts = await mediator.call('emitToServer', 'load file count', {
				association: {
					type: 'INVENTORY',
					key: {
						inventoryId: [ id ],
					},
				},
				options: {
					fileType: 'Image',
				},
			})
			const match = fileCounts.find(item => item.inventoryId === id)
			ractive.updateFileCount({ id, count: (match && match.count) || 0 })

			ractive.set({
				savingFiles: ractive.get('savingFiles')
					.filter(idSaving => idSaving !== id),
			})
		},
		saveItem(teardownItem, cb) {
			const ractive = this

			const parentType = ractive.get('parentType')
			const teardownType = ractive.get('teardownType')
			const parent = ractive.get('parent')

			let saveItem

			if (parentType === 'VEHICLE') {
				if (teardownType === 'INVENTORY') {
					const inventoryPart = new InventoryPart(teardownItem, ractive.get('partManufacturerList'), ractive.get('partModelList'))
					saveItem = {
						...inventoryPart,
						vehicleId: parent.vehicleId,
					}
				} else if (teardownType === 'BID') {
					const bidPart = new BidPart(teardownItem)
					//TODO handle these category and corePrice fallbacks somewhere else
					saveItem = {
						...bidPart,
						vehicleBidId: ractive.get('vehicleBidId'),
					}
				}
			} else if (parentType === 'INVENTORY') {
				const inventoryPart = new InventoryPart(teardownItem, ractive.get('partManufacturerList'), ractive.get('partModelList'))
				saveItem = { ...inventoryPart, parentInventoryId: parent.inventoryId }
			}

			let socket
			if (teardownType === 'INVENTORY') {
				socket = 'save inventory item'
			} else if (teardownType === 'BID') {
				socket = 'save vehicle bid part'
			}

			mediator.call('emitToServer', socket, { item: saveItem })
				.then(savedItem => {
					savedItem = new TeardownItem(savedItem,
						ractive.get('teardownType'),
						ractive.get('inventoryTypeList'),
						ractive.get('partManufacturerList'),
						ractive.get('partModelList'),
						ractive.get('partCategoryList'))

					ractive.upsert('parts', 'id', savedItem).then(() => cb(null, savedItem))
				})
				.catch(err => cb(err))
		},
		loadModels(item) {
			const ractive = this

			mediator.call('emitToServer', 'load part models using inventory type', {
				inventoryTypeId: item.inventoryTypeId,
				partManufacturerId: item.partManufacturerId,
			})
				.then(loadedModels => {
					let partModelList = ractive.get('partModelList')

					partModelList = partModelList.filter(partModel => {
						return loadedModels.find(loadedModel => partModel.partModelId === loadedModel.partModelId)
					})

					ractive.set({ partModelList: partModelList.concat(loadedModels) })
				})
		},
		setCostUsingVcr() {
			const ractive = this

			const parts = ractive.get('parts')
			const totalVehicleCost = ractive.get('totalVehicleCost')

			const inventoryTypesWithVCRValue = ractive.get('inventoryTypeList')
				.reduce((sum, { ratio, inventoryTypeId }) => {
					if (ratio) {
						const foundPart = parts.find(part => part.inventoryTypeId === inventoryTypeId)

						return foundPart ? sum.concat({ inventoryTypeId, vcr: ratio }) : sum
					}
					return sum
				}, [])

			const totalVCR = inventoryTypesWithVCRValue
				.reduce((sum, inventoryType) => sum + inventoryType.vcr, 0)

			const costifiedParts = parts.map(part => {
				const matchingInventoryType = inventoryTypesWithVCRValue
					.find(({ inventoryTypeId }) => inventoryTypeId === part.inventoryTypeId)

				if (matchingInventoryType) {
					const cost = totalVehicleCost / (totalVCR / matchingInventoryType.vcr)
					return { ...part, cost }
				}

				return part
			})

			ractive.set({ parts: costifiedParts })
		},
		oninit() {
			const ractive = this

			const handleResize = () => ractive.set({ bsBreakpoint: getBootstrapBreakpoint() })

			handleResize()

			window.addEventListener('resize', handleResize)

			ractive.on('list-item-clicked', (_context, index) => {
				ractive.set({ listViewSelection: index }).then(() => {
					if (ractive.get('viewSize') === 'SMALL') {
						ractive.set({
							showTeardownItemModal: true,
							listViewSelection: index,
						})
					}
				})
			})

			ractive.on('file-count-clicked', (_context, inventoryId) => {
				ractive.set({
					imageViewerShown: true,
					showTeardownItemModal: false,
				}).then(() => {
					mediator.call('emitToServer', 'load file info list', {
						association: {
							type: 'INVENTORY',
							key: { inventoryId },
						},
						type: 'Image',
					})
						.then(files => {
							ractive.set({
								imageViewerImages: files
									.filter(file => file.type === 'Image')
									.map(({ fileId, md5sum, name }) => `attachments/${md5sum}${fileId}/${name}?width=500&height=500&fit=contain`),
								currentPhotoIndex: 0,
							})
						})
				})
			})

			ractive.on('itImageViewer.imageViewerClosed', () => ractive.set({ displayFiles: [] }))

			ractive.on('drag-file', (_context, id, e) => {
				if (ractive.get('fileSupport')) {
					if (id) {
						e.original.dataTransfer.setData('image', e.original.target.id)
						e.original.dataTransfer.effectAllowed = 'copy'

						ractive.set({ fileDraggedOverItemId: id })
					}
				}
			})

			ractive.on('drag-leave', () => {
				if (ractive.get('fileSupport')) {
					ractive.set({ fileDraggedOverItemId: null })
				}
			})

			ractive.on('drop-file', context => {
				const id = ractive.get('fileDraggedOverItemId')
				if (ractive.get('fileSupport') && id) {
					ractive.set({
						fileDraggedOverItemId: null,
						savingFiles: ractive.get('savingFiles').concat(id),
					}).then(() => {
						ractive.fileUpload(id, context.files)
					})
				}
			})

			ractive.on('add-attachments-single-row', (_context, id) => {
				ractive.find(`#attachment-upload-${id}`).click()
			})

			ractive.on('add-attachments', () => {
				//We have to use a hidden input since we
				//Want to style our input differently
				ractive.find('#attachment-upload').click()
			})

			ractive.on('toggleInventoryStatusClicked', (_context, item) => {
				item.status = toggleInventoryStatus(item.status)

				ractive.saveItem(item, (_err, _savedItem) => { })
			})

			ractive.on('partManufacturerSelection', (_context, item, partManufacturerId) => {
				item.partManufacturerId = partManufacturerId ? partManufacturerId : null
				item.partModelId = null

				ractive.saveItem(item, (_err, _savedItem) => { })

				if (item.inventoryTypeId && item.partManufacturerId) {
					ractive.loadModels(item)
				}
			})

			ractive.on('partModelSelection', (_context, item, partModelId) => {
				item.partModelId = partModelId ? partModelId : null

				ractive.saveItem(item, (_err, _savedItem) => { })
			})

			function showPricingModal({ context, contextId, item, interchangeRecord }) {
				ractive.findComponent('partPricingModal')
					.showIfNeeded({
						context,
						contextId,
						inventoryTypeId: item.inventoryTypeId,
						category: item.category,
						interchangeRecord,
						interchangeNumber: item.interchangeNumber,
						partModelId: item.partModelId,
						partPricing: {
							retailPrice: item.retailPrice,
							wholesalePrice: item.wholesalePrice,
							listPrice: item.listPrice,
							core: item.core,
						},
					})
			}

			ractive.on('partCategorySelection', (_context, item, partCategoryName) => {
				item.category = partCategoryName ? partCategoryName : null

				ractive.saveItem(item, (_err, savedItem) => {
					const { partModelId, inventoryTypeId } = item

					if (partModelId && inventoryTypeId) {
						showPricingModal({
							context: 'CATEGORY',
							contextId: item.id,
							item: savedItem,
						})
					}
				})
			})

			ractive.on('interchangeSelector.interchangeNumberSelected', (_context, item, interchangeRecord) => {
				if (interchangeRecord && interchangeRecord.interchangeNumber) {
					item.interchangeNumber = interchangeRecord.interchangeNumber || ''

					ractive.saveItem(item, (_err, savedItem) => {
						showPricingModal({
							context: 'INTERCHANGE',
							contextId: item.id,
							item: savedItem,
							interchangeRecord,
						})
					})
				}
			})

			ractive.on('partPricingModal.pricing-change', (_context, newPricing) => {
				let currentPartIndex = ractive.get('parts')
					.findIndex(part => part.id === newPricing.id)

				if (currentPartIndex > -1) {
					const currentPart = { ...ractive.get('parts')[currentPartIndex], ...newPricing }

					ractive.splice('parts', currentPartIndex, 1, currentPart).then(() => {
						ractive.saveItem(currentPart, () => { })
					})
				}
			})

			ractive.on('costChange', (context, item) => {
				item.cost = context.value || null

				ractive.saveItem(item, _err => { })
			})

			ractive.on('retailPriceChange', (context, item) => {
				item.retailPrice = context.value || null

				ractive.saveItem(item, _err => { })
			})

			ractive.on('listPriceChange', (context, item) => {
				item.listPrice = context.value || null

				ractive.saveItem(item, _err => { })
			})

			ractive.on('wholesalePriceChange', (context, item) => {
				item.wholesalePrice = context.value || null

				ractive.saveItem(item, _err => { })
			})
			ractive.on('locationChange', (context, item, value) => {
				item.location = value
				ractive.saveItem(item, _err => { })
			})
			ractive.on('quantityChange', (context, item, value) => {
				item.quantity = parseInt(value, 10)
				ractive.saveItem(item, _err => { })
			})

			ractive.on('descriptionChange', (context, item) => {
				ractive.saveItem(item, _err => { })
			})

			ractive.on('notesChange', (context, item) => {
				ractive.saveItem(item, _err => { })
			})

			ractive.on('interchangeNumberChange', (context, item) => {
				const interchangeNumber = context.node.value
				item.interchangeNumber = interchangeNumber ? interchangeNumber : null

				ractive.saveItem(item, _err => { })
			})

			ractive.on('sideChange', (context, item) => {
				let side = domValue(context.event.target) || ''

				if (side === 'Both') {
					//Add a new item for the "Right" side. addPart function handles the saving
					ractive.addPart(item.inventoryTypeId, { side: 'Right' })
					//Make the existing item be the "Left" side
					item.side = 'Left'
				} else {
					item.side = side
				}

				ractive.saveItem(item, _err => { })
			})

			ractive.on('tagNumberChange', (_context, item, event) => {
				item.tagNumber = event.target.value ? event.target.value : null
				ractive.saveItem(item, _err => { })
			})

			ractive.on('goToPart', (_context, partID) => {
				stateRouter.go('app.part', { inventoryId: partID })
			})

			ractive.on('partPagination', (_context, direction) => {
				const currentIndex = ractive.get('listViewSelection') || 0
				const newIndex = currentIndex + (direction === 'NEXT' ? 1 : -1)
				ractive.set('listViewSelection', newIndex)
				ractive.find(`#teardown-item-${newIndex}`)?.scrollIntoView?.({ block: 'center' })
			})
		},
	})
}

function getClassFromInventoryStatus(status = false) {
	const classObj = {
		button: 'btn-outline-secondary',
		icon: 'fas fa-minus',
	}
	if ([ 'D', '', false ].includes(status)) {
		classObj.button = 'btn-outline-success'
		classObj.icon = 'fas fa-plus'
	} else if (status === 'A') {
		classObj.button = 'btn-outline-danger'
	}
	return classObj
}

function getTooltipTextFromInventoryStatus(status) {
	switch (status) {
		case 'A': return 'Available part. Click to set status to \'Deleted\'.'
		case 'H': return 'On Hold part. The status of this part can\'t be changed.'
		case 'D': return 'Deleted part. Click to set status to \'Available\'.'
		case 'S': return 'Sold part. The status of this part can\'t be changed.'
		default: return 'This part doesn\'t exist, click to create it.'
	}
}

function toggleInventoryStatus(status) {
	switch (status) {
		case '':
		case null:
			return 'A'
		case 'A': return 'D'
		case 'D': return 'A'
		default: return status
	}
}

function partStatusIsToggleable(exists, status) {
	return !exists || status === 'A' || status === 'D'
}
