<script lang="ts">
	import type { SvelteAsr } from 'client/types/common'
	import type { Mediator } from 'types/common'
	import { History, type PrintJob } from '@isoftdata/svelte-report-viewer'
	import { getContext } from 'svelte'

	export let asr: SvelteAsr

	export let history: Array<PrintJob>
	export let showCancelledJobs: boolean
	export let showSentJobs: boolean
	export let showReadyJobs: boolean
	export let showFailedJobs: boolean
	export let showDelayedJobsOnly: boolean
	const mediator = getContext<Mediator>('mediator')

	function onPreviewClick(event: CustomEvent<PrintJob>) {
		localStorage.setItem('reportViewerPreviewPrintJob', JSON.stringify(event.detail))
		asr.go('app.report-viewer.reports.preview', { selectedReportId: event.detail.reportId, preview: true })
	}

	function setStatus(reportQueueId: number | Array<number>, status: 'READY' | 'CANCELLED') {
		mediator.call('emitToServer', 'set report queue job status', {
			reportQueueId,
			status,
		})
	}

	function filterChange(event: CustomEvent<boolean>, parameter: 'showCancelledJobs' | 'showSentJobs' | 'showReadyJobs' | 'showFailedJobs' | 'showDelayedJobsOnly') {
		asr.go(null, { [parameter]: event.detail }, { inherit: true })
	}
</script>

<History
	showDelayUntil
	showAdditionalStatusFilters
	{history}
	{showSentJobs}
	{showCancelledJobs}
	{showReadyJobs}
	{showFailedJobs}
	{showDelayedJobsOnly}
	refreshHref={asr.makePath(null, { lastRefreshTime: Date.now(), pageNumber: 1 }, { inherit: true })}
	on:previewClick={onPreviewClick}
	on:setReadyClick={event => setStatus(event.detail.id, 'READY')}
	on:cancelClick={event => setStatus(event.detail, 'CANCELLED')}
	on:showCancelledChange={e => filterChange(e, 'showCancelledJobs')}
	on:showSentChange={e => filterChange(e, 'showSentJobs')}
	on:showReadyChange={e => filterChange(e, 'showReadyJobs')}
	on:showFailedChange={e => filterChange(e, 'showFailedJobs')}
	on:showDelayedOnlyChange={e => filterChange(e, 'showDelayedJobsOnly')}
></History>
