import template from './scanner.html'

//Ractive components
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

export default function({ stateRouter }) {
	stateRouter.addState({
		name: 'app.scanner',
		route: 'scanner',
		defaultChild: 'assign',
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		querystringParameters: [],
		resolve() {
			return Promise.resolve({
				childStates: [
					{
						title: 'Assign Location / UPC',
						stateName: 'app.scanner.assign',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
					{
						title: 'Inventory Verification',
						stateName: 'app.scanner.inventory-verification',
						stateParameters: {},
						stateOptions: { inherit: true },
					},
				],
			})
		},
	})
}
