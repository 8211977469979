import template from './integration.html'

//Ractive components
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'

const stateName = 'app.configuration.integration'

export default function({ stateRouter, hasPermission }) {
	stateRouter.addState({
		name: stateName,
		route: 'integration',
		template: {
			template,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
			},
		},
		defaultChild: 'quickbooks',
		resolve(data, parameters) {
			return Promise.resolve({
				childStates: [
					{
						title: 'QuickBooks',
						stateName: `${stateName}.quickbooks`,
						stateParameters: {},
						hidden: !hasPermission('PM_QUICKBOOKS_VIEW'),
					},
					{
						title: 'Shipping',
						stateName: `${stateName}.shipping`,
						stateParameters: {},
					},
				],
			})
		},
		activate({ domApi: ractive }) {
			//
		},
	})
}
