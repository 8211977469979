import template from './permission.html'
import { booleanToYesNo as yesNo } from '@isoftdata/utility-string'
import toTitleCase from 'to-title-case'

//Ractive components
import makeTable from '@isoftdata/table'

function buildPermissionName(permission, permissionList) {
	let hasParentPermission = !!permission.parentPermissionId
	let namedParts = hasParentPermission ? [] : [ permission.name ]

	let foundPermission = permission

	//Walk the tree of permissions to build an array of permission names
	//ordered highest to lowest
	while (hasParentPermission) {
		namedParts = [ foundPermission.name ].concat(namedParts)

		foundPermission = permissionList
			.find(listItem => listItem.permissionId === foundPermission.parentPermissionId)

		hasParentPermission = foundPermission && foundPermission.parentPermissionId
	}

	return [ permission.category ].concat(namedParts).join(' > ')
}

export default function createPermissionComponent({ mediator, logAndAlert }) {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itTable: makeTable(),
		},
		data() {
			return {
				permissionList: [],
				sortedPermissions: [],
				toTitleCase,
				yesNo,
				listEnabled: true,
				userGroups: [],
				groupPermissions: [],
				context: '',
				perPageCount: 10,
				contextId: null,
			}
		},
		computed: {
			permissionsFromAnyGroup() {
				const userGroups = this.get('userGroups')

				if (userGroups.length > 0) {
					const groupPermissions = this.get('groupPermissions')
					const groupIds = userGroups.map(userGroup => userGroup.groupId)
					console.log(userGroups)
					let permissionsFromAnyGroup = groupPermissions[groupIds[0]].reduce((sum, permission) => {
						return { ...sum, [permission.permissionId]: false }
					}, {})

					groupIds.forEach(groupId => {
						groupPermissions[groupId].forEach(permission => {
							if (permission.hasPermission) {
								permissionsFromAnyGroup[permission.permissionId] = true
							}
						})
					})

					return permissionsFromAnyGroup
				}
				return {}
			},
			displayPermissions() {
				const permissionList = this.get('permissionList')
				const permissionsFromAnyGroup = this.get('permissionsFromAnyGroup')
				const context = this.get('context')

				return permissionList.map(permission => {
					let permissionSources = []

					if (permissionsFromAnyGroup[permission.permissionId]) {
						permissionSources.push('GROUP')
					}

					if (permission.hasPermission) {
						permissionSources.push(context)
					}

					return {
						permissionId: permission.permissionId,
						name: buildPermissionName(permission, permissionList),
						hasPermission: permission.hasPermission,
						permissionSources,
					}
				})
			},
			computedColumns() {
				const context = this.get('context')

				const columns = [
					{
						property: 'hasPermission',
						name: `${ toTitleCase(context) } Permission`,
						icon: context === 'USER' ? 'fas fa-user' : false,
						iconLeft: true,
					},
					{
						property: 'name',
						name: 'Name',
					},
				]

				return context === 'USER' ? columns.concat([{ property: 'permissionSources', name: 'Permission Sources' }]) : columns
			},
		},
		oninit() {
			const ractive = this

			ractive.on('permission-click', async(ractiveContext, clickedPermission) => {
				const context = ractive.get('context').toLowerCase()

				ractive.set({ listEnabled: false })
				let permission = ractive.get('permissionList')
					.find(permission => permission.permissionId === clickedPermission.permissionId)

				if (permission) {
					permission.hasPermission = !permission.hasPermission

					const contextKeyName = `${context === 'user' ? 'userAccount' : context}Id`

					try {
						const permissionList = await mediator.call('emitToServer', `update ${context} permissions`, {
							[`${context}Permission`]: permission,
							[contextKeyName]: ractive.get('contextId'),
						})

						ractive.set({ permissionList })
					} catch (err) {
						if (err) {
							logAndAlert(err, mediator, 'Error saving permission change')
						}
					} finally {
						ractive.set({ listEnabled: true })
					}
				}
			})
		},
	})
}
