import template from './purchase-order-line-row.html'
import * as currency from '@isoftdata/utility-currency'
import financialNumber from 'financial-number'

//Ractive components
import makeTextArea from '@isoftdata/textarea'
import makeInput from '@isoftdata/input'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'

export default function() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				PurchaseOrderDocumentLine: null,
				tableColumns: [],
				purchaseOrderLineHistory: [],
				documentClosed: false,
			}
		},
		components: {
			itInput: makeInput({}),
			itInputTwo: makeInput({ twoway: true }),
			itTextArea: makeTextArea({ twoway: true, lazy: false }),
			itCurrencyInput: makeCurrencyInput({ twoway: true }),
			itCheckbox: makeCheckbox({ twoway: true }),
			itButton: makeButton(),
		},
		computed: {
			displayReceived() {
				const totalQuantityReceived = this.get('totalQuantityReceived')
				const { quantity } = this.get('PurchaseOrderDocumentLine')

				return `${totalQuantityReceived}/${quantity}`
			},
			totalQuantityReceived() {
				const { newReceived, quantityReceived } = this.get('PurchaseOrderDocumentLine')

				return financialNumber(newReceived.toString())
					.plus(quantityReceived.toString())
					.toString()
			},
			totalOrdered() {
				const { price, coreAmount, quantity } = this.get('PurchaseOrderDocumentLine')

				const total = financialNumber(price.toString())
					.plus(coreAmount.toString())
					.times(quantity.toString())
					.toString()

				return currency.format(total)
			},
			totalReceived() {
				const { price } = this.get('PurchaseOrderDocumentLine')

				const total = financialNumber(price.toString())
					//.plus(coreAmount.toString()) //apparently core amount is not included in total received
					.times(this.get('totalQuantityReceived'))
					.toString()

				return currency.format(total)
			},
			retailPrice() {
				const inventoryItem = this.get('inventoryItem')

				let price = inventoryItem ? inventoryItem.retailPrice : 0

				return currency.format(price)
			},
			listPrice() {
				const inventoryItem = this.get('inventoryItem')

				let price = inventoryItem ? inventoryItem.listPrice : 0

				return currency.format(price)
			},
			canBeDeleted() {
				const purchaseOrderLineHistory = this.get('purchaseOrderLineHistory')
				const purchaseOrderLineId = this.get('PurchaseOrderDocumentLine.purchaseOrderLineId')

				const lineHistory = purchaseOrderLineHistory
					.filter(lineHistory => lineHistory.purchaseOrderLineId === purchaseOrderLineId)

				return lineHistory.length == 0
			},
		},
		oninit() {
			const ractive = this

			ractive.observe('inventoryItem', inventoryItem => {
				if (inventoryItem && inventoryItem.inventoryId) {
					ractive.set('PurchaseOrderDocumentLine', { ...ractive.get('PurchaseOrderDocumentLine'), tagNumber: inventoryItem.tagNumber })
				}
			})
		},
		clear() {
			/*
			this.findComponent('tagNumberInput').clear()
			this.findComponent('trackingNumberInput').clear()
			this.findComponent('descriptionTextArea').clear()
			this.findComponent('quantityInput').clear(1)
			this.findComponent('priceInput').clear('0.00')
			this.findComponent('isHeldCheckbox').clear(true)
			this.findComponent('isTaxedCheckbox').clear(true)
			*/
		},
	})
}
