import template from './sale-document-line-row.html'
import * as currency from '@isoftdata/utility-currency'
import financialNumber from 'financial-number'

//Ractive components
import makeTextArea from '@isoftdata/textarea'
import makeInput from '@isoftdata/input'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeCheckbox from '@isoftdata/checkbox'
import makeButton from '@isoftdata/button'

export default function() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				row: {},
				tableColumns: [],
				inventoryList: [],
				vehicleList: [],
				canEditPrice: false,
				canEditLineItemTaxability: false,
				disabled: false,
				deleteDisabled: false,
				documentType: '',
				documentSaveState: '',
			}
		},
		components: {
			itInput: makeInput(),
			itInputTwo: makeInput({ twoway: true }),
			itCurrencyInput: makeCurrencyInput({ twoway: true }),
			itButton: makeButton(),
			itTextArea: makeTextArea({ twoway: true }),
			itCheckbox: makeCheckbox({ twoway: true }),
		},
		computed: {
			columnProperties() {
				return this.get('tableColumns').map(column => column.property)
			},
			total() {
				const row = this.get('row')

				const price = row.price || 0
				const core = row.coreAmount || 0
				const quantity = row.quantity || 0

				const total = financialNumber(price.toString())
					.plus(core.toString())
					.times(quantity.toString())
					.toString()

				return currency.format(total)
			},
		},
		shouldShowForPrint(key) {
			const matchingColumn = this.get('tableColumns').find(col => col.property === key)
			return (matchingColumn && matchingColumn.hideForPrint) || false
		},
		oninit() {
			const ractive = this

			ractive.observe('row.*', (thing, oldThing, keypath) => {
				if (keypath !== 'row.saveState' && ractive.get('row.saveState') !== 'NEW') {
					ractive.set('row.saveState', 'MODIFIED')
				}
			}, { init: false })
		},
		getTagNumberFromInventoryId(inventoryId) {
			let tagNumber = ''

			if (inventoryId) {
				const item = this.get('inventoryList').find(inventory => inventory.inventoryId === inventoryId)

				if (item) {
					tagNumber = item.tagNumber
				}
			}

			return tagNumber
		},
		getTrackingNumberFromVehicleId(vehicleId) {
			let trackingNumber = ''

			if (vehicleId) {
				const item = this.get('vehicleList').find(vehicle => vehicle.vehicleId === vehicleId)

				if (item) {
					trackingNumber = item.trackingNumber
				}
			}

			return trackingNumber
		},
		clear() {
			//this.findComponent('tagNumberInput').clear()
			//this.findComponent('trackingNumberInput').clear()
			//this.findComponent('descriptionTextArea').clear()
			//this.findComponent('quantityInput').clear(1)
			//this.findComponent('priceInput').clear('0.00')
			//this.findComponent('isHeldCheckbox').clear(true)
			//this.findComponent('isTaxedCheckbox').clear(true)
		},
	})
}
