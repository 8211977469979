import * as arrayHelper from '@isoftdata/utility-array'

export function getPartManufacturerNameFromManufacturerId(partManufacturerList, partManufacturerId) {
	if (!partManufacturerId || !partManufacturerList) {
		throw 'error getting part manufacturer name from manufacturer id'
	}

	return partManufacturerList.find(partManufacturer => {
		return partManufacturer.partManufacturerId === partManufacturerId
	}).name
}

export function getPartModelNameFromModelId(partModelList, partModelId) {
	if (!partModelId || !partModelList) {
		throw 'error getting part model name using model id'
	}

	return partModelList.find(partModel => {
		return partModel.partModelId === partModelId
	}).name
}

export function getCategoryNameFromCategoryId(categoryList, categoryId) {
	if (!categoryId || !categoryList) {
		throw 'error getting category name from category id'
	}

	return categoryList.find(category => {
		return category.categoryId === categoryId
	}).name
}

export function getPartManufacturerIdFromName(partManufacturerList, partManufacturerName) {
	if (!partManufacturerName || !partManufacturerList) {
		return null
	}

	const foundPartManufacturer = partManufacturerList.find(partManufacturer => {
		return partManufacturer.name.toUpperCase() === partManufacturerName.toUpperCase()
	})

	if (foundPartManufacturer && foundPartManufacturer.partManufacturerId) {
		return foundPartManufacturer.partManufacturerId
	} else {
		return null
	}
}

export function getPartManufacturerIdFromModelId(partModelList, partModelId) {
	if (!partModelId || !partModelList) {
		throw 'error getting part manufacturer id using model id'
	}

	return partModelList.find(partModel => {
		return partModel.partModelId === partModelId
	}).partManufacturerId
}

export function getPartModelIdFromModelNameAndManufacturerId(partModelList, partModelName, partManufacturerId) {
	if (!partModelList || !partModelName || !partManufacturerId) {
		return null
	}

	const matchingPartModel = partModelList.find(partModel => {
		return partModel.partManufacturerId === partManufacturerId &&
			partModel.name.toUpperCase() === partModelName.toUpperCase()
	})

	if (matchingPartModel) {
		return matchingPartModel.partModelId
	} else {
		return null
	}
}

export function partManufacturerListByTypeId(partManufacturerList) {
	return arrayHelper.groupItemsByObjectProperty(partManufacturerList, 'inventoryTypeId')
}

export function partModelListByPartManufacturerId(partModelList) {
	return arrayHelper.groupItemsByObjectProperty(partModelList, 'partManufacturerId')
}

export function partCategoryListByTypeId(partCategoryList) {
	return arrayHelper.groupItemsByObjectProperty(partCategoryList, 'typeSetId')
}
