import template from './customer-contact-modal.html'
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeSelect from '@isoftdata/select'
import makeTextarea from '@isoftdata/textarea'
import klona from 'klona'
import formatDate from 'date-fns/format'

const newTouchpoint = {
	entryType: 'phone',
	message: '',
}

const correspondence = {
	assignedToUserId: null,
	dueDate: formatDate(new Date(), 'yyyy-MM-dd'),
}

export default function createLoadItemModal() {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itModal: makeModal(),
			itSelect: makeSelect({ twoway: true }),
			itInput: makeInput({ twoway: true }),
			itTextarea: makeTextarea({ twoway: true }),
		},
		data() {
			return {
				users: [],
				modalShown: false,
				touchpoint: klona(newTouchpoint),
				correspondence: klona(correspondence),
				createCorrespondence: false,
			}
		},
		oninit() {
			const ractive = this

			ractive.on('save', () => {
				ractive.fire('confirm', {
					touchpoint: ractive.get('touchpoint'),
					correspondence: ractive.get('correspondence'),
					createCorrespondence: ractive.get('createCorrespondence'),
				})
			})

			ractive.on('show', context => {
				console.log(context)
				ractive.set({
					touchpoint: klona({
						...newTouchpoint,
						...context.touchpoint,
					}),
					correspondence: {
						...correspondence,
						...context.correspondence,
						dueDate: formatDate(new Date(), 'yyyy-MM-dd'),
						priority: '4',
					},
					createCorrespondence: context.createCorrespondence || false,
					modalShown: true,
				}).then(() => {
					ractive.find('#message').focus()
				})
			})
		},
	})
}
