<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { FavoriteReportsStore } from 'stores/favorite-reports-store'

	import { ReportList, type Report } from '@isoftdata/svelte-report-viewer'
	import { onMount } from 'svelte'

	export let asr: SvelteAsr

	export let selectedReportId: number | null
	export let selectedCategory: string | null
	export let reportList: Array<Report>
	export let favorites: FavoriteReportsStore

	function getFirstReportIdForCategory(category: string | null) {
		if (!category) {
			return null
		}
		const report = reportList.find(report => report.category === category)
		return report?.id ?? null
	}

	onMount(() => {
		if (selectedReportId && !selectedCategory) {
			const report = reportList.find(report => report.id === selectedReportId)
			if (report) {
				selectedCategory = report.category
				asr.go(null, { selectedReportId, selectedCategory })
			}
		}
	})
</script>

<ReportList
	{reportList}
	bind:selectedCategory
	bind:selectedReportId
	enableFavorites
	bind:favorites={$favorites}
	on:reportClick={e => {
		selectedCategory = e.detail.category
		selectedReportId = e.detail.reportId ?? getFirstReportIdForCategory(e.detail.category)
		asr.go('app.report-viewer.reports.preview', { selectedReportId, selectedCategory })
	}}
>
	<uiView></uiView>
</ReportList>
