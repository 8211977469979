import template from './correspondence.html'
import pProps from 'p-props'

//Ractive components
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

const stateName = 'app.entity.contact.correspondence'

export default function({ mediator, stateRouter, isChildState }) {
	stateRouter.addState({
		name: stateName,
		route: 'correspondence',
		querystringParameters: [ 'id', 'entityContext', 'customerTicketId', 'fromDate', 'toDate', 'showClosed' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			mediator,
			computed: {
				displayCorrespondence() {
					let correspondenceList = this.get('correspondence')
					const users = this.get('users')

					correspondenceList = correspondenceList.map(correspondence => {
						const createdByUser = users.find(user => user.userAccountId === correspondence.createdByUserId)
						const assignedToUser = users.find(user => user.userAccountId === correspondence.assignedToUserId)

						return {
							...correspondence,
							createdBy: createdByUser ? createdByUser.userName : '',
							assignedTo: assignedToUser ? assignedToUser.userName : '',
						}
					})

					if (!this.get('showClosed')) {
						correspondenceList = correspondenceList.filter(correspondence => !correspondence.closedDate)
					}

					return correspondenceList
				},
			},
		},
		resolve(data, parameters) {
			const { id: customerId, entityContext, fromDate, toDate, customerTicketId, showClosed } = parameters

			return pProps({
				correspondence: mediator.call('emitToServer', 'load customer ticket', {
					customerId,
					createdFrom: fromDate,
					createdTo: toDate,
				}),
				users: mediator.call('emitToServer', 'load users', { }),
				correspondenceColumns: [
					{ property: 'customerTicketId', name: 'Ticket #' },
					{ property: 'priority', name: 'Priority' },
					{ property: 'createdDate', name: 'Created' },
					{ property: 'dueDate', name: 'Due' },
					{ property: 'closedDate', name: 'Closed' },
					{ property: 'createdBy', name: 'Created By' },
					{ property: 'assignedTo', name: 'Assigned To' },
				],
				showClosed: !!parseInt(showClosed, 10),
				selectedCustomerTicketId: customerTicketId ? parseInt(customerTicketId, 10) : null,
			})
		},
		activate(context) {
			const { domApi: ractive } = context
			ractive.on('correspondenceClicked', ({ customerTicketId }) => {
				stateRouter.go('app.entity.contact.correspondence.touchpoint', { customerTicketId }, { inherit: true })
			})

			const removeChangeProvider = mediator.provide('correspondenceChanged', customerTicket => {
				ractive.upsert('correspondence', 'customerTicketId', customerTicket, { prepend: true })
			})

			context.on('destroy', () => {
				removeChangeProvider()
			})
		},
	})
}
