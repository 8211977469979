const configBaseState = 'app.configuration.integration.quickbooks'
const qbBaseState = 'app.quickbooks'
const route = 'redirect'

export default function({ stateRouter, mediator }) {
	stateRouter.addState({
		name: `${qbBaseState}.${route}`,
		route,
		template: { template: `` },
		async resolve() {
			const isConnectedToQuickbooks = await mediator.call('emitToServer', 'is connected to quickbooks', {})

			return Promise.reject({
				redirectTo: { name: isConnectedToQuickbooks ? `${qbBaseState}.overview` : `${configBaseState}.auth` },
			})
		},
	})
}
