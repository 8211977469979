<script lang="ts">
	import { v4 as uuid } from '@lukeed/uuid'

	export let id = uuid()
	export let options: Array<{
		value: string
		label: string
	}>
	export let value: string
</script>

{#each options as option}
	<div class="form-check">
		<input
			class="form-check-input"
			type="radio"
			id="{id}-{option.value}"
			value={option.value}
			bind:group={value}
			on:change
		/>
		<label
			class="form-check-label"
			for="{id}-{option.value}">{option.label}</label
		>
	</div>
{/each}
