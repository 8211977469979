import template from './appraisal.html'
import ractiveTransitionsFade from 'ractive-transitions-fade'
import ractiveTransitionsFly from 'ractive-transitions-fly'
import ractiveTransitionsSlide from 'ractive-transitions-slide'
//import domValue from 'dom-value'

export default function createAppraisalOriginPicker(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		twoway: false,
		data() {
			return {
				loadingOriginList: true,
				shown: false,
				showAllAuctionOrigins: false,
				loadingOriginCode: false,
				chosenAppraisalOrigin: '',
				error: false,
				errorDetails: false,
			}
		},
		transitions: {
			fade: ractiveTransitionsFade,
			fly: ractiveTransitionsFly,
			slide: ractiveTransitionsSlide,
		},
		computed: {
			displayAppraisalOrigins() {
				let origins = this.get('origins')

				if (origins) {
					// For now, "all" will not mean the "CUSTOM".
					// TODO: To support custom we'll need another ui view where they pick specific auction(s)
					let originsCodesToFilter = [ 'ORIGIN_CUSTOM' ]

					if (!this.get('showAllAuctionOrigins')) {
						originsCodesToFilter = [
							...originsCodesToFilter,
							'ORIGIN_LAST_AUCTION',
							'ORIGIN_LAST_QUARTER',
							'ORIGIN_ALL_AUCTIONS',
						]
					}

					origins = origins.map(apprisalOrigin => {
						if (apprisalOrigin.code === 'ORIGIN_LAST_YEAR' || apprisalOrigin.code === 'ORIGIN_LAST_QUARTER') {
							return { ...apprisalOrigin, name: `${apprisalOrigin.name } of Auctions` }
						} else if (apprisalOrigin.code === 'ORIGIN_HTP_LIVE') {
							return { ...apprisalOrigin, name: 'HeavyTruckParts.net' }
						}
						return apprisalOrigin
					})

					return origins
						.filter(origin => !originsCodesToFilter.includes(origin.code) && origin.allowed)
				}
				return []
			},
		},
		async appraisalOriginChosen(origin) {
			const ractive = this
			const itemsToAppraise = ractive.get('itemsToAppraise')

			if (itemsToAppraise && itemsToAppraise.length > 0) {
				await ractive.set({ loadingOriginCode: origin.code })
				try {
					const appraisedItems = await mediator.call('emitToServer', 'appraise items', { itemsToAppraise, originCode: origin.code })
					ractive.set({
						appraisedItems,
						loadingOriginCode: false,
						shown: false,
						chosenAppraisalOrigin: origin,
					})
				} catch (err) {
					ractive.set({
						error: 'Error appraising items. Try again later.',
						errorDetails: JSON.stringify(err),
					})
					console.error(err)
				}
			}
		},
		oninit() {
			const ractive = this

			//ractive.observe('displayAppraisalOrigins', v => console.log(v))

			//Delay the loading of appraisal origins until they hit the button,
			//since it has to hit the interwebz.
			ractive.observe('shown', async shown => {
				if (shown) {
					try {
						const loadedOrigins = await mediator.call('emitToServer', 'load origins', {})
						const allOrigins = [
							...loadedOrigins,
							{ name: 'Local Sales', code: 'LOCAL_SALES', allowed: true },
						]

						ractive.set({
							origins: allOrigins,
							loadingOriginList: false,
						})
					} catch (err) {
						ractive.set({
							error: 'Error loading apprasal origins',
							errorDetails: JSON.stringify(err),
						})
						console.error('Error loading apprasal origins')
					}
				} else {
					ractive.set({ loadingOriginList: true })
				}
			})
		},
	})
}
