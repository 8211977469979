import template from './make.html'
import snake from 'to-snake-case'

//Ractive Components
import makeInput from '@isoftdata/input'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.configuration.vehicle.make',
		route: 'make',
		defaultChild: 'model',
		querystringParameters: [ 'make' ],
		template: {
			template,
			components: {
				itInput: makeInput({ twoway: true, lazy: false }),
			},
			selectMake(make) {
				this.set({ selectedMake: make })
				stateRouter.go(null, { make }, { inherit: false })
			},
			computed: {
				displayMakes() {
					const makeList = this.get('makeList')
					const makeFilter = this.get('makeFilter').toLowerCase()

					if (makeFilter) {
						return makeList.filter(({ make }) => {
							return make.toLowerCase().indexOf(makeFilter) > -1
						})
					} else {
						return makeList
					}
				},
			},
		},
		async resolve(data, { make }) {
			const makeList = await mediator.call('emitToServer', 'load vehicle makes', {})
			return {
				makeList,
				makeFilter: '',
				selectedMake: make || '',
				snake,
			}
		},
		activate({ domApi: ractive }) {
			ractive.find(`#make-${snake(ractive.get('selectedMake'))}`)?.scrollIntoView({
				block: 'center',
				inline: 'center',
				behavior: 'smooth',
			})
		},
	})
}
