import template from './document-load-modal.html'
import makeModal from '@isoftdata/modal'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'

export default function createDocumentLoadModal() {
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
			itSelectTwo: makeSelect({ twoway: true }),
			itInputTwo: makeInput({ twoway: true }),
		},
		data() {
			return {
				documentTypeList: [],
				documentTypeName: '',
				documentNotFound: false,
				loadDocumentDocumentNumber: '',
				loadDocumentModalShown: false,
				loadButtonText: '',
				selectedLoadDocumentTypeCode: '',
			}
		},
		computed: {
			selectedDocumentLoadTypeName() {
				const documentTypeList = this.get('documentTypeList')
				const documentTypeName = this.get('documentTypeName')
				const selectedLoadDocumentTypeCode = this.get('selectedLoadDocumentTypeCode')

				console.log(documentTypeList, selectedLoadDocumentTypeCode)

				if (documentTypeList && documentTypeList.length > 0) {
					const match = documentTypeList
						.find(documentType => selectedLoadDocumentTypeCode === documentType.code)

					if (match) {
						return match.name
					}
					return documentTypeName
				}

				return documentTypeName
			},
		},
		oninit() {
			const ractive = this

			ractive.on('show', ({ documentType }) => {
				ractive.set({ loadDocumentModalShown: true })
				if (documentType) {
					ractive.set({ selectedLoadDocumentTypeCode: documentType })
				}
			})

			ractive.observe('loadDocumentModalShown', ractive.focusFirstControl, {
				init: false,
				defer: true,
			})
		},
		focusFirstControl() {
			const elementList = this.findAll('input,select')

			if (elementList && elementList.length > 0) {
				if (elementList[0].select) {
					elementList[0].select()
				} else {
					elementList[0].focus()
				}
			}
		},
	})
}
