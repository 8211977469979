import template from './part-type-man-mod-cat.html'
import * as manModHelper from 'shared/manufacturer-model-helper'
import { v4 as uuid } from '@lukeed/uuid'

//Ractive components
import makeAutocomplete from '@isoftdata/autocomplete'
import makePartModelModal from 'components/part-model-modal'
import makeManufacturerModal from 'components/part-manufacturer-modal'

export default function createItPartTypeManModCatComponent({ mediator, logAndAlert }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: false,
		twoway: true,
		components: {
			itAutocomplete: makeAutocomplete(),
			partModelModal: makePartModelModal({ mediator, logAndAlert }),
			partManufacturerModal: makeManufacturerModal({ mediator, logAndAlert }),
		},
		data() {
			return {
				inventoryTypeLabel: 'Part Type',
				partManufacturerLabel: 'Manufacturer',
				partModelLabel: 'Model',
				categoryLabel: 'Category',

				inventoryTypeId: null,
				partManufacturer: '',
				partModel: '',
				category: '',

				isInventoryTypeListLoading: false,
				isManufacturerListLoading: false,
				isModelListLoading: false,
				isCategoryListLoading: false,

				sizeClass: '',
				savedInputIdList: [],
				colSizeClass: 'col-sm',

				inventoryTypeList: [],
				partManufacturerList: [],
				partModelList: [],
				categoryList: [],

				inventoryTypeMoreFields: [],
				inventoryTypeQuestions: [],

				inventoryTypeClass: '',
				partManufacturerClass: '',
				partModelClass: '',
				categoryClass: '',

				inventoryTypeShown: true,
				manufacturerShown: true,
				modelShown: true,
				categoryShown: true,

				canAddType: false,
				canAddManufacturer: false,
				canAddModel: false,
				canAddCategory: true,

				inventoryTypeDisabled: false,
				partManufacturerDisabled: false,
				partModelDisabled: false,
				categoryDisabled: false,

				inventoryTypeHint: '',
				partManufacturerHint: '',
				partModelHint: '',
				categoryHint: '',

				inventoryTypeHintClass: '',
				partManufacturerHintClass: '',
				partModelHintClass: '',
				categoryHintClass: '',

				inventoryTypeInputId: uuid(),
				partManufacturerInputId: uuid(),
				partModelInputId: uuid(),
				categoryInputId: uuid(),

				loadAllManufacturers: false,
				categoryListObject: {},
			}
		},
		computed: {
			inventoryTypeListObject() {
				return this.get('inventoryTypeList').reduce((acc, inventoryType) => {
					return {
						...acc,
						[inventoryType.inventoryTypeId]: `${inventoryType.name} - ${inventoryType.inventoryTypeId}`,
					}
				}, {})
			},
			partManufacturerListObject() {
				return this.get('partManufacturerList').reduce((acc, manufacturer) => {
					return { ...acc, [manufacturer.name]: manufacturer.name }
				}, {})
			},
			partModelListObject() {
				return this.get('partModelList').reduce((acc, model) => {
					return { ...acc, [model.name]: model.name }
				}, {})
			},
			inventoryType() {
				const inventoryTypeId = this.get('inventoryTypeId')

				return this.get('inventoryTypeList')
					.find(inventoryType => inventoryType.inventoryTypeId == inventoryTypeId)
			},
		},
		getInventoryTypeName() {
			return this.get('inventoryType')?.name
		},
		async oninit() {
			const ractive = this

			ractive.set({ isInventoryTypeListLoading: true })
			const inventoryTypeList = await mediator.call('emitToServer', 'load inventory type list', {})
			await ractive.set({
				inventoryTypeList,
				isInventoryTypeListLoading: false,
			})

			ractive.observe('inventoryType', async inventoryType => {
				if (inventoryType) {
					const typeSetId = (inventoryType && inventoryType.typeSetId) || inventoryType.inventoryTypeId

					await Promise.all([
						loadManufacturers(typeSetId),
						loadCategories(typeSetId),
						loadQuestions(typeSetId),
					])

					let inventoryTypeMoreFields = []

					inventoryTypeMoreFields = [ 'more1', 'more2', 'more3', 'more4' ]
						.reduce((sum, more) => {
							if (inventoryType[more]) {
								return { ...sum, [more]: inventoryType[more] }
							}

							return sum
						}, {})

					ractive.set({ inventoryTypeMoreFields })
				} else {
					ractive.set({
						partManufacturerList: [],
						partModelList: [],
						categoryList: [],
						inventoryTypeMoreFields: [],
					})
				}
			})

			ractive.observe('partManufacturer', partManufacturer => {
				if (partManufacturer) {
					loadModels(ractive.get('inventoryTypeId'), partManufacturer)
				}
			})

			ractive.observe('partManufacturer partManufacturerList partModelList partModel', () => {
				const manufacturerId = manModHelper.getPartManufacturerIdFromName(ractive.get('partManufacturerList'), ractive.get('partManufacturer'))

				ractive.set({ partModelId: manModHelper.getPartModelIdFromModelNameAndManufacturerId(ractive.get('partModelList'), ractive.get('partModel'), manufacturerId) })
			})

			ractive.on('addModel', () => {
				const selectedPartManufacturer = ractive.get('partManufacturer')
				const inventoryType = ractive.get('inventoryType')

				if (inventoryType && selectedPartManufacturer) {
					const partManufacturerId = ractive.get('partManufacturerList')
						.find(manu => manu.name === selectedPartManufacturer)?.partManufacturerId

					ractive.findComponent('partModelModal').show({
						typeSetId: inventoryType.typeSetId,
						partManufacturerId,
					})
				} else {
					alert('You must select an inventory type & manufacturer before adding a model')
				}
			})

			ractive.on('modelSaved', ({ model }) => {
				ractive.upsert('partModelList', ractive.get('partModelId'), model)
				ractive.set('partModel', model.name)
			})

			ractive.on('manufacturerSaved', ({ manufacturer }) => {
				ractive.push('partManufacturerList', manufacturer)
				ractive.set('partManufacturer', manufacturer.name)
			})

			async function loadManufacturers(inventoryTypeId) {
				ractive.set({ isManufacturerListLoading: true })
				let socketOptions = { inventoryTypeId }

				if (ractive.get('loadAllManufacturers')) {
					socketOptions = {}
				}

				const partManufacturerList = await mediator.call('emitToServer', 'load part manufacturers using inventory types', socketOptions)
				ractive.set({
					partManufacturerList,
					isManufacturerListLoading: false,
				})

				const currentManufacturer = ractive.get('partManufacturer')

				if (currentManufacturer && !partManufacturerList.find(manufacturer => manufacturer.name === currentManufacturer)) {
					ractive.set({
						partManufacturer: '',
						partModel: '',
					})
				}
			}

			async function loadModels(inventoryTypeId, partManufacturer) {
				if (partManufacturer && inventoryTypeId) {
					ractive.set({ isModelListLoading: true })
					const inventoryType = ractive.get('inventoryTypeList')
						.find(inventoryType => inventoryType.inventoryTypeId === inventoryTypeId)

					const typeSetId = inventoryType?.typeSetId || inventoryTypeId

					const partModelList = await mediator.call('emitToServer', 'load part models using inventory type', { inventoryTypeId: typeSetId, partManufacturer })
					ractive.set({
						partModelList,
						isModelListLoading: false,
					})

					const currentModel = ractive.get('partModel')

					if (currentModel && !partModelList.find(model => model.name === currentModel)) {
						ractive.set({ partModel: '' })
					}
				} else {
					ractive.set({
						partModelList: [],
						isModelListLoading: false,
					})
				}
			}

			async function loadCategories(inventoryTypeId) {
				ractive.set({ isCategoryListLoading: true })
				const categoryList = await mediator.call('emitToServer', 'load categories', { inventoryTypeId })
				ractive.set({
					categoryList,
					isCategoryListLoading: false,
				})

				const currentCategory = ractive.get('category')

				if (currentCategory && !categoryList.find(category => category.name === currentCategory)) {
					ractive.set({ category: '' })
				}
			}

			async function loadQuestions(inventoryTypeId) {
				const inventoryTypeQuestions = await mediator.call('emitToServer', 'load inventory questions', { inventoryTypeId, categoryId: null })
				ractive.set({ inventoryTypeQuestions })
			}

			ractive.observe('categoryList', categoryList => {
				ractive.set({
					'categoryListObject': this.get('categoryList').reduce((acc, category) => {
						return { ...acc, [category.name]: category.name }
					}, {}),
				})
			})
		},
	})
}
