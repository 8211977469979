import component from './VehicleBid.svelte'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.vehicle-bid',
		route: 'vehicle-bid',
		querystringParameters: [ 'fromDate', 'toDate' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			if (parameters.fromDate && parameters.toDate) {
				const vehicleBids = await mediator.call('emitToServer', 'load display vehicle bids', {
					fromDate: parameters.fromDate,
					toDate: parameters.toDate,
				})
				return { vehicleBids }
			}
		},
	})
}
