import template from './dashboard.html'
import { makeDashboardComponent } from '@isoftdata/web-dashboard'

export default function({ stateRouter, mediator, hasPermission }) {
	stateRouter.addState({
		name: 'app.dashboard',
		route: 'dashboard/:dashboardReportId',
		querystringParameters: [ 'dashboardReportId' ],
		defaultParameters: { dashboardReportId: null },
		template: {
			template,
			components: {
				itDashboard: makeDashboardComponent({
					loadDashboardReportMetadata: ({ dashboardReportId }) => {
						return mediator.call('emitToServer', 'load dashboard report metadata', { dashboardReportId })
					},
					loadChartDataForReport: ({ dashboardReportId, chartId, parameterSelectionList }) => {
						return mediator.call('emitToServer', 'load chart data for report', { dashboardReportId, chartId, parameterSelectionList })
					},
					loadDashboardReportTitlesForUser: () => {
						return mediator.call('emitToServer', 'load dashboard report titles for user', {})
					},
				}),
			},
			hasPermission,
		},
		async resolve(data, { dashboardReportId }) {
			if (!hasPermission('PM_DASHBOARD_REPORT_VIEW')) {
				return new Error("You don't have permission to view the dashboard.")
			} else {
				if (Number(dashboardReportId)) {
					dashboardReportId = Number(dashboardReportId)
				} else {
					const dashboardReports = await mediator.call('emitToServer', 'load dashboard report titles for user', {})
					dashboardReportId = dashboardReports[0].dashboardReportId
					stateRouter.go('app.dashboard', { dashboardReportId })
				}
				return { dashboardReportId }
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.on('itDashboard.configure-report-clicked', (context, currentReportId) => {
				stateRouter.go('app.configuration.dashboard.manage', { dashboardReportId: currentReportId })
			})

			ractive.on('itDashboard.report-tab-clicked', (context, dashboardReportId) => {
				stateRouter.go('app.dashboard', { dashboardReportId })
			})
		},

	})
}
