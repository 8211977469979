import template from './auth.html'
import pProps from 'p-props'
import { pluralIfNeeded as plural } from '@isoftdata/utility-string'

//Ractive components
import makeButton from '@isoftdata/button'

const stateName = 'app.configuration.integration.quickbooks.auth'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'auth',
		querystringParameters: [ 'justConnected' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
			},
			computed: {

			},
		},
		async resolve(data, parameters) {
			const isConnectedToQuickbooks = await mediator.call('emitToServer', 'is connected to quickbooks', {})

			if (isConnectedToQuickbooks && !parameters.justConnected) {
				return Promise.reject({
					//The auth state is the defaultChild for the quickbooks state.
					//If they're authenticated, we can take them to QuickBooks >> Overview so they can start using it
					redirectTo: { name: 'app.quickbooks.overview' },
				})
			} else {
				return pProps({
					isConnectedToQuickbooks,
					justConnected: !!parameters.justConnected,
					redirectSecsRemaining: 5,
					plural,
				})
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			if (ractive.get('justConnected')) {
				const intervalId = setInterval(() => {
					ractive.subtract('redirectSecsRemaining')
				}, 1000)

				setTimeout(() => {
					clearInterval(intervalId)
					stateRouter.go(null, {}, { inherit: false, replace: true })
				}, ractive.get('redirectSecsRemaining') * 1000)
			}

			ractive.on('qb-auth', (context, event) => {
				event.preventDefault()
				const width = Math.floor(window.screen.width / 2)
				const height = Math.floor(window.screen.height / 2)

				const left = Math.floor(width / 2)
				const top = Math.floor(height / 2)

				window.open('connect_to_quickbooks', 'qbAuth', `location=1,width=${width},height=${height},left=${left},top=${top}`)
			})
		},
	})
}
