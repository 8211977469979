import component from './Basic.svelte'

import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import statusListLoader from 'utility/status-list-loader.ts'
import pProps from 'p-props'
import klona from 'klona'

let defaultPart = Object.freeze({
	quantity: 1,
	status: 'A',
	replenish: false,
	deplete: true,
	isTaxable: true,
	tag: true,
	isWorldViewable: true,
	isTagPerQuantity: false,
	interchangeNumber: '',
	upc: '',
	weight: 0,
	quantityCommitted: 0,
	data1: '',
	data2: '',
	data3: '',
	data4: '',
	vin: '',
	make: '',
	model: '',
	minimumQuantity: 1,
	maximumQuantity: 1,
	saleClassCode: 'NONE',
})

export default function({ mediator, stateRouter, hasPermission }) {
	const stateName = 'app.part.basic'

	stateRouter.addState({
		name: stateName,
		route: 'basic',
		querystringParameters: [ 'inventoryId', 'storeId', 'vehicleId' ],
		defaultParameters: {
			storeId: 1,
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_, parameters) {
			const { store, user } = JSON.parse(localStorage.getItem('session'))
			const inventoryId = Number(parameters.inventoryId)
			const vehicleId = Number(parameters.vehicleId)

			if (store?.storeId) {
				defaultPart = { ...defaultPart, storeId: store.storeId }
			}

			const stageOneRes = await pProps({
				isTruckYard: (async() => {
					//isTrue has to stay bc not all settings can be converted to bools, and we want this one to be a bool
					return isTrue((await mediator.call('emitToServer', `load global setting`, { settingName: 'Yard Type: Truck' }))?.[0]?.value)
				})(),
				allowManualTagNumberEntry: mediator.call('emitToServer', 'check global setting', {
					name: 'Allow manual entry of part tag #\'s',
					category: 'Parts',
					defaultValue: true,
					settingType: 'Optional Configuration',
					dataType: 'boolean',
				}),
				part: (async() => inventoryId ? (await mediator.call('emitToServer', 'load inventory', { inventoryId, options: { quantityCommitted: true } }))?.[0] : klona(defaultPart))(),
				vehicleMakes: mediator.call('emitToServer', 'load vehicle makes', {}),
				partConditions: mediator.call('emitToServer', 'load inventory conditions', {}),
				partLocations: mediator.call('emitToServer', 'load inventory locations', {}),
				saleClassList: mediator.call('emitToServer', 'load sale classes', {}),
				stores: mediator.call('emitToServer', 'load stores', {}),
				frequentlyUsedInventoryTypes: mediator.call('emitToServer', 'load frequently used inventory types', { limit: 8 }),
				canEditFinancial: hasPermission('PM_PARTFINANCIAL_EDIT'),
				canEditPart: hasPermission('PM_PART_EDIT'),
				canViewCost: hasPermission('PM_PARTSCOST_VIEW'),
				canEditCost: hasPermission('PM_PARTSCOST_EDIT'),
				statusList: statusListLoader(mediator, 'Part'),
			})

			let vehicleIdToLoad
			if (stageOneRes.part.vehicleId) {
				vehicleIdToLoad = stageOneRes.part.vehicleId
			} else if (!inventoryId && vehicleId) {
				//Handle the case where they're coming from the Vehicle screen via "Enter Parts"
				vehicleIdToLoad = vehicleId
			}

			const stageTwoRes = await pProps({
				vehicle: vehicleIdToLoad ? (async() => (await mediator.call('emitToServer', 'load vehicle', { vehicleId: vehicleIdToLoad }))?.[0])() : null,
				parentPart: stageOneRes?.part?.parentInventoryId ? (async() => (await mediator.call('emitToServer', 'load inventory', { inventoryId: stageOneRes.part.parentInventoryId }))?.[0])() : null,
			})

			if (stageTwoRes?.vehicle?.vehicleId) {
				//Always make sure the latest info
				//from a vehicle record is thrown on the part
				stageOneRes.part = {
					...stageOneRes.part,
					vehicleId: stageTwoRes.vehicle.vehicleId,
					vin: stageTwoRes.vehicle.vin,
					make: stageTwoRes.vehicle.make,
					model: stageTwoRes.vehicle.model,
					year: stageTwoRes.vehicle.year,
				}
			}

			return {
				...stageOneRes,
				...stageTwoRes,
				showCreatePartModal: !inventoryId && hasPermission('PM_PART_EDIT'),
				currentStore: store,
				showStorePicker: !user.restrictedToStoreId,
			}
		},
	})
}
