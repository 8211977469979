<script lang="ts">
	import type { SvelteAsr } from 'client/types/common'
	import { ReportViewer } from '@isoftdata/svelte-report-viewer'

	export let asr: SvelteAsr

	let selectedTab: 'Reports' | 'History' = getSelectedTab(asr.getActiveState().name)
	$: selectedTab = getSelectedTab(asr.getActiveState().name)

	function getSelectedTab(stateName: string) {
		return stateName.includes('history') ? 'History' : 'Reports'
	}
</script>

<ReportViewer
	reportsHref={asr.makePath('app.report-viewer.reports')}
	historyHref={asr.makePath('app.report-viewer.history')}
	bind:selectedTab
>
	<uiView></uiView>
</ReportViewer>
