<script lang="ts">
	import type { Inventory } from 'server/db/inventory-db'
	import type { InventoryType } from 'server/db/inventory-type-db'
	import type { InventoryQuestionWithAnswer } from 'server/db/inventory-question-db'
	import type { Mediator, SvelteAsr } from 'types/common'

	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'

	import { logAndAlert } from 'utility/error-handler'
	import { createEventDispatcher, getContext, onDestroy } from 'svelte'
	import { slide } from 'svelte/transition'

	export let asr: SvelteAsr
	export let part: Inventory
	export let inventoryType: InventoryType | null

	type DataKey = `data${1 | 2 | 3 | 4}`

	const dispatch = createEventDispatcher<{
		moreChange: { key: DataKey; value: string }
	}>()
	const mediator = getContext<Mediator>('mediator')

	let qa: Array<InventoryQuestionWithAnswer> = []
	/** Record of names -> values for restoring answers across questions */
	let qaCache: Record<string, string> = {}
	let showAll = false

	// init these as null, so the first fetch will happen on page load
	let lastInventoryTypeId: number | null = null
	let lastCategory: string | null = null

	$: inventoryTypeName = inventoryType?.name || ''
	$: displayMoreFieldLabels =
		inventoryType && part
			? {
					label1: inventoryType.more1,
					label2: inventoryType.more2,
					label3: inventoryType.more3,
					label4: inventoryType.more4,
				}
			: {}
	$: hasCustomFields = Object.values(displayMoreFieldLabels).some(label => label) || qa.length > 0
	$: inventoryId = part?.inventoryId ?? null
	$: qaFieldCount = qa.length
	$: moreFieldCount = Object.values(displayMoreFieldLabels).filter(label => label).length
	$: customFieldCount = moreFieldCount + qaFieldCount
	$: configureHref = asr.makePath('app.configuration.inventory.type.question', { inventoryTypeId: inventoryType?.inventoryTypeId ?? null, typeSetId: inventoryType?.typeSetId ?? null })
	// fetch in inventoryid / category change
	$: inventoryId && inventoryType && fetchQa(inventoryId, inventoryType.inventoryTypeId, part.category)
	$: displayQaFields = showAll ? qa : qa.slice(0, 4 - moreFieldCount)

	function onMoreChange(event: Event, key: DataKey) {
		if (event.target instanceof HTMLInputElement) {
			dispatch('moreChange', { key, value: event.target.value })
		}
	}

	function onQaChange(event: Event, question: InventoryQuestionWithAnswer) {
		if (event.target instanceof HTMLInputElement) {
			let value = event.target.value
			if (question.type.toUpperCase() === 'INTEGER') {
				value = parseInt(value) ? parseInt(value).toString() : ''
			}

			qaCache[question.text] = value
			void saveAnswer(question, value)
		}
	}

	function saveAnswer(question: InventoryQuestionWithAnswer, value: string) {
		const answer = {
			inventoryQuestionId: question.inventoryQuestionId,
			inventoryId: part.inventoryId,
			storeId: part.storeId,
			value: value,
		}
		mediator
			.call('emitToServer', 'save inventory question answer', {
				answer,
			})
			.catch(err => logAndAlert(err, mediator, 'Error saving inventory question answer', answer))
	}

	function getInputType(type: string) {
		switch (type.toUpperCase()) {
			case 'INTEGER':
			case 'DECIMAL':
				return 'number'
			case 'DATE':
				return 'date'
			default:
				return 'text'
		}
	}

	async function fetchQa(inventoryId: number, inventoryTypeId: number, category: string | null) {
		// Only re-fetch if something actually changed
		if (inventoryTypeId === lastInventoryTypeId && category === lastCategory) {
			return
		}
		lastInventoryTypeId = inventoryTypeId
		lastCategory = category
		const newQa = (await mediator.call('emitToServer', 'load inventory questions', {
			answer: {
				inventoryId,
				storeId: part.storeId,
			},
			categoryName: category || null,
			inventoryTypeId,
		})) as Array<InventoryQuestionWithAnswer>
		qa = newQa.map(newQ => {
			if (newQ.value && !qaCache[newQ.text]) {
				qaCache[newQ.text] = newQ.value
			} else if (qaCache[newQ.text]) {
				newQ.value = qaCache[newQ.text]
				void saveAnswer(newQ, newQ.value)
			}
			return newQ
		})
		console.log('new Q&A', qa)
	}

	onDestroy(() => {
		// Clear out any stale answers when we leave the page instead of immediately when they change type/category
		mediator.call('emitToServer', 'delete stale inventory question answers', { inventoryId })
	})
</script>

<div class="card hightlight-card mt-4 w-100">
	<div class="card-header d-flex justify-content-between">
		<h4 class="mb-0">{inventoryTypeName || 'Part Type'} Custom Fields</h4>
		<div>
			{#if hasCustomFields}
				<Button
					outline
					size="xs"
					icon="cog"
					title="Configure {inventoryTypeName.toLowerCase() || 'part type'} custom fields"
					href={configureHref}
				></Button>
			{/if}
		</div>
	</div>
	<div
		class="card-body"
		class:pb-1={customFieldCount > 4}
	>
		{#if !part.inventoryTypeId}
			<i>Specify a part type.</i>
		{/if}

		{#if hasCustomFields}
			<div class="form-row">
				{#if displayMoreFieldLabels?.label1}
					<div class="col-lg-6">
						<Input
							label={displayMoreFieldLabels.label1}
							disabled={!inventoryId}
							value={part.data1}
							onchange={event => onMoreChange(event, 'data1')}
						/>
					</div>
				{/if}

				{#if displayMoreFieldLabels?.label2}
					<div class="col-lg-6">
						<Input
							label={displayMoreFieldLabels.label2}
							disabled={!inventoryId}
							value={part.data2}
							onchange={event => onMoreChange(event, 'data2')}
						/>
					</div>
				{/if}

				{#if displayMoreFieldLabels?.label3}
					<div class="col-lg-6">
						<Input
							label={displayMoreFieldLabels.label3}
							disabled={!inventoryId}
							value={part.data3}
							onchange={event => onMoreChange(event, 'data3')}
						/>
					</div>
				{/if}

				{#if displayMoreFieldLabels?.label4}
					<div class="col-lg-6">
						<Input
							label={displayMoreFieldLabels.label4}
							disabled={!inventoryId}
							value={part.data4}
							onchange={event => onMoreChange(event, 'data4')}
						/>
					</div>
				{/if}
				{#each displayQaFields as question}
					<div
						class="col-lg-6"
						transition:slide={{ duration: 100 }}
					>
						<Input
							label={question.text}
							disabled={!inventoryId}
							value={question.value}
							type={getInputType(question.type)}
							onchange={event => onQaChange(event, question)}
						/>
					</div>
				{/each}
			</div>
			{#if customFieldCount > 4}
				<div class="d-flex justify-content-center justify-content-sm-end">
					<Button
						color="link"
						size="xs"
						icon={showAll ? 'compress' : 'expand'}
						onclick={() => (showAll = !showAll)}>{showAll ? `Hide ${customFieldCount - 4}` : `Show ${customFieldCount - 4} More`} Custom Fields...</Button
					>
				</div>
			{/if}
		{:else if inventoryType}
			<h5>
				<a href={configureHref}><i class="fas fa-external-link"></i> Add Custom Fields</a>
			</h5>
		{/if}
	</div>
</div>
