import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import saleDocumentTypes from '../sale-document-types.mjs'
import { v4 as uuid } from '@lukeed/uuid'

const valueOrElse = (val, defaultVal = '') => val ? val : defaultVal

export default class SalesOrderDocumentLine {
	constructor({ saleDocument, saleDocumentLine, documentType, saveState }) {
		const foundDocumentType = saleDocumentTypes.find(type => type.code === documentType)

		this.documentId = foundDocumentType ? valueOrElse(saleDocument[foundDocumentType.documentId], null) : null

		if (foundDocumentType && saleDocumentLine[foundDocumentType.documentLineId]) {
			this.saleDocumentLineId = saleDocumentLine[foundDocumentType.documentLineId]
		}

		this.tagNumber = valueOrElse(saleDocumentLine.tagNumber, '')
		this.trackingNumber = valueOrElse(saleDocumentLine.trackingNumber, '')
		this.partManufacturer = valueOrElse(saleDocumentLine.partManufacturer)
		this.partModel = valueOrElse(saleDocumentLine.partModel)
		this.vin = valueOrElse(saleDocumentLine.vin)
		this.vehicleMake = valueOrElse(saleDocumentLine.vehicleMake)
		this.vehicleModel = valueOrElse(saleDocumentLine.vehicleModel)
		this.vehicleYear = valueOrElse(saleDocumentLine.vehicleYear, 0)
		this.vehicleBodyStyle = valueOrElse(saleDocumentLine.vehicleBodyStyle)
		this.description = valueOrElse(saleDocumentLine.description)
		this.interchangeNumber = valueOrElse(saleDocumentLine.interchangeNumber)
		this.cost = valueOrElse(saleDocumentLine.cost, 0)
		this.price = valueOrElse(saleDocumentLine.price, 0)
		this.coreAmount = valueOrElse(saleDocumentLine.coreAmount, 0)
		this.quantity = valueOrElse(saleDocumentLine.quantity)
		this.previousQuantity = valueOrElse(saleDocumentLine.quantity)
		this.inventoryId = valueOrElse(saleDocumentLine.inventoryId, null)
		this.vehicleId = valueOrElse(saleDocumentLine.vehicleId, null)
		this.rank = valueOrElse(saleDocumentLine.rank, 0)
		this.isHeld = isTrue(typeof (saleDocumentLine.isHeld) === 'boolean' ? saleDocumentLine.isHeld : 'True')
		this.isTaxed = isTrue(typeof (saleDocumentLine.isTaxed) === 'boolean' ? saleDocumentLine.isTaxed : 'True')
		this.uuid = uuid()
		this.saveState = saveState
	}
}
