import component from './Sale.svelte'
import homeDataLoader from 'shared/home-data-loader.mjs'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.home.sale',
		route: 'sale',
		querystringParameters: [ 'fromDate', 'toDate', 'salesperson' ],
		template: {
			svelte: true,
			component,
		},
		async resolve(data, parameters) {
			const result = await homeDataLoader({
				fromDate: parameters.fromDate,
				toDate: parameters.toDate,
				documentLoadSocket: 'load sales',
				documentLoadAdditionalParameters: {
					status: 'Invoice',
					salesperson: parameters?.salesperson,
				},
				mediator,
			})
			return {
				...result,
				doSalespersonFilter: parameters?.salesperson !== undefined,
			}
		},
	})
}
