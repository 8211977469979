import template from './user-permission-auth-modal.html'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'

export default function(mediator) {
	return Ractive.extend({
		template,
		components: {
			itModal: makeModal(),
			itInput: makeInput({ twoway: true, lazy: false }),
		},
		data() {
			return {
				permissionCode: '',
				permissionName: '',
				username: '',
				passphrase: '',
				show: false,
				error: '',
				cb: () => {},
			}
		},
		oninit() {
			this.observe('username passphrase', () => this.set({ error: '' }))
		},
		async authCheck(event) {
			event.preventDefault()
			const ractive = this
			let session = localStorage.getItem('session')
			session = session ? JSON.parse(session) : {}
			const cb = ractive.get('cb')
			try {
				const res = await mediator.call('emitToServer', 'check user creds and permission', {
					userName: ractive.get('username'),
					passphrase: ractive.get('passphrase'),
					permissionCode: ractive.get('permissionCode'),
					storeId: session.storeId, //Use the currently logged in user's store for this check
				})
				ractive.set({ show: false })
				cb(null, res)
			} catch (err) {
				ractive.set({ error: err.message })
				cb(err)
			}
		},
		async requestAuthorization(permissionCode, cb) {
			const ractive = this
			const permissions = await mediator.call('emitToServer', 'load permissions', {
				code: permissionCode,
			})
			ractive.set({
				show: true,
				permissionCode,
				permissionName: permissions?.[0]?.name ?? '',
				cb,
			})
			ractive.find('#user-auth-username-input')?.focus()
		},
	})
}
