import template from './part-model-modal.html'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'

export default function createPartModelModalComponent({ mediator, logAndAlert }) {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				model: {},
				isSaving: false,
				show: false,
				inventoryType: '',
				manufacturer: '',
			}
		},
		attributes: {
			optional: [
				'model',
				'show',
			],
		},
		components: {
			itInput: makeInput({ twoway: true, lazy: false }),
			itModal: makeModal(),
		},
		async show(model = {}) {
			console.log(model)
			if ((!model.typeSetId || !model.partManufacturerId)) {
				throw logAndAlert(new Error('Missing type set id or part manufacturer id'), mediator, 'Missing type set id or part manufacterer id')
			}

			console.log(model)
			const [ inventoryType, manufacturer ] = await Promise.all([
				mediator.call('emitToServer', 'load inventory type by id', { inventoryTypeId: model.typeSetId }),
				mediator.call('emitToServer', 'load part manufacturer by id', { partManufacturerId: model.partManufacturerId }),
			])

			await this.set({
				model,
				show: true,
				inventoryType: inventoryType.name,
				manufacturer: manufacturer.name,
			})

			this.find('#partModelModalInput').select()
		},
		async save(partModel, event) {
			const ractive = this

			if (event) {
				event.preventDefault()
			}

			ractive.set({ isSaving: true })
			try {
				const savedModel = await mediator.call('emitToServer', 'save part model', { partModel }, { doNotAlert: true })
				ractive.set({ show: false })
				ractive.fire('modelSaved', { model: savedModel })
			} catch (err) {
				if (err.code === 'ER_DUP_ENTRY') {
					logAndAlert(err, mediator, `Another model "${partModel.name}" already exists. Pick a different name.`)
				} else {
					logAndAlert(err, mediator)
				}
			} finally {
				ractive.set({ isSaving: false })
				ractive.fire('')
			}
		},
	})
}
