<script lang="ts">
	import type { Report } from '@isoftdata/svelte-report-viewer'
	import type { Mediator, SvelteAsr } from 'client/types/common'
	import { getContext, onMount } from 'svelte'
	import { ReportViewerPreview, type Parameter } from '@isoftdata/svelte-report-viewer'
	import { generatePdfPreview } from 'utility/report-viewer-helper'
	import Button from '@isoftdata/svelte-button'
	import ReportJobModal from 'components/ReportJobModal.svelte'

	export let asr: SvelteAsr
	export let selectedReportId: number | null
	export let reportList: Array<Report> = []
	export let parameters: Array<Parameter> = []
	export let preview: boolean = false

	let reportViewerPreview: ReportViewerPreview
	let reportJobModal: ReportJobModal | undefined = undefined
	let allowPrint = false

	const mediator = getContext<Mediator>('mediator')

	function onPrintClick() {
		const report = reportList.find(r => r.id === selectedReportId)
		if (report && selectedReportId) {
			reportJobModal?.open(
				{
					name: report.name,
					type: report.type,
					reportId: selectedReportId,
					parameters: parameters.reduce((acc: Record<string, string>, param) => {
						acc[param.name] = param.value
						return acc
					}, {}),
				},
				{},
			)
		}
	}

	onMount(() => {
		if (preview) {
			reportViewerPreview.doPreview()
			asr.go(null, { preview: undefined }, { inherit: true })
		}
	})
</script>

{#if selectedReportId}
	<ReportViewerPreview
		{reportList}
		generatePdfPreview={async args => {
			try {
				const res = await generatePdfPreview(mediator, args)
				allowPrint = true
				return res
			} catch (err) {
				allowPrint = false
				throw err
			}
		}}
		bind:selectedReportId
		bind:parameters
		bind:this={reportViewerPreview}
	>
		<svelte:fragment slot="previewFooter">
			<Button
				outline
				size="sm"
				icon="print"
				disabled={!allowPrint}
				onclick={onPrintClick}>Print</Button
			>
		</svelte:fragment></ReportViewerPreview
	>
{/if}

<ReportJobModal
	showPreview={false}
	bind:this={reportJobModal}
></ReportJobModal>
