import template from './vehicle-search.html'
import pProps from 'p-props'
import statusListLoader from 'utility/status-list-loader.ts'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

//Ractive components
import makeInterchangeYearInput from 'components/interchange-year-input'
import makeSearchInterface from 'components/search-interface'
import makeAutocomplete from '@isoftdata/autocomplete'
import makeStatusSelect from 'components/status-select'
import makeYearInput from '@isoftdata/year-input'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeDateRangePicker from '@isoftdata/date-range'
import { stringToBoolean } from '@isoftdata/utility-string'

export default function({ mediator, stateRouter, logAndAlert }) {
	stateRouter.addState({
		name: 'app.vehicle-search',
		route: 'vehicle-search',
		querystringParameters: [ 'searchQuery', 'status', 'vehicleType' ],
		defaultParameters: {
			searchQuery: '',
			status: '@',
			vehicleType: '',
		},
		template: {
			template,
			components: {
				itStatusSelect: makeStatusSelect({ twoway: true }),
				itButton: makeButton(),
				itInput: makeInput({ twoway: true }),
				itSelect: makeSelect({ twoway: true }),
				searchInterface: makeSearchInterface(),
				itYearInput: makeYearInput({ twoway: true }),
				itAutocomplete: makeAutocomplete(),
				interchangeYearInput: makeInterchangeYearInput(),
				dateRange: makeDateRangePicker(),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			clear() {
				stateRouter.go('app.redirect', { state: 'app.vehicle-search' })
			},
			computed: {
				displayVehicleMakes() {
					return this.get('vehicleMakes').reduce((acc, make) => {
						return {
							...acc,
							[make.make]: make.make,
						}
					}, {})
				},
				displayVehicleModels() {
					return this.get('vehicleModels').reduce((acc, model) => {
						return {
							...acc,
							[model.model]: model.model,
						}
					}, {})
				},
				searchParams() {
					console.log("searchParams")
					const ractive = this
					const purchasedDate = ractive.get('purchasedDate')
					const teardownDate = ractive.get('teardownDate')
					const value = {
						searchQuery: ractive.get('searchQuery'),
						status: ractive.get('status'),
						vehicleType: ractive.get('vehicleType'),
						year: ractive.get('year'),
						yearTo: ractive.get('yearTo'),
						make: ractive.get('make'),
						model: ractive.get('model'),
						vin: ractive.get('vin'),
						trackingNum: ractive.get('trackingNum'),
						location: ractive.get('location'),
						isDismantled: ractive.get("isDismantled"),
						purchasedDate: purchasedDate.from,
						purchasedDateTo: purchasedDate.to,
						teardownDate: teardownDate.from,
						teardownDateTo: teardownDate.to,
					}
					console.log("value =", value)
					return value
				},
			},
		},
		resolve(data, { searchQuery, status, vehicleType, make, model, year, yearTo, trackingNum, vin, location, isDismantled, purchasedDate, purchasedDateTo, teardownDate, teardownDateTo }) {
			const { user } = JSON.parse(localStorage.getItem('session'))

			return pProps({
				make,
				model,
				year,
				yearTo,
				vin,
				trackingNum,
				location,
				isDismantled: !isDismantled ? undefined : stringToBoolean(isDismantled),
				purchasedDate: { from: purchasedDate ?? null, to: purchasedDateTo ?? null },
				teardownDate: { from: teardownDate ?? null, to: teardownDateTo ?? null },
				searchQuery,
				status,
				vehicleType,
				showYearTo: false,
				vehicleMakes: mediator.call('emitToServer', 'load vehicle makes', {}),
				vehicleModels: [],
				vehicleTypes: mediator.call('emitToServer', 'load vehicle types', {}),
				showAdvancedSearchFields: mediator.call('emitToServer', 'check user setting', {
					name: 'Show Advanced Search Fields',
					category: 'Search (Vehicles)',
					userAccountId: user?.userAccountId,
					settingType: 'Interface History',
					defaultValue: false,
					dataType: 'boolean',
				}),
				statusList: statusListLoader(mediator, 'Vehicle'),
			})
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get())

			if (ractive.get('vehicles')?.length < 1) {
				ractive.find('#keyword-search')?.focus()
			}

			ractive.observe('showAdvancedSearchFields', async value => {
				const res = await mediator.call('emitToServer', 'save user setting', {
					category: 'Search (Vehicles)',
					value,
					name: 'Show Advanced Search Fields',
					type: 'Interface History',
				})
				return res
			}, { init: false })

			ractive.observe('make', async make => {
				await ractive.set({ vehicleModelsLoading: true })
				try {
					const vehicleModels = await mediator.call('emitToServer', 'load vehicle models', { make })
					ractive.set({ vehicleModels })
				} catch (err) {
					logAndAlert(err, mediator, 'Failed to load vehicle models')
				} finally {
					ractive.set({ vehicleModelsLoading: false })
				}
			})

			ractive.on('searchInterface.search', async(context, event) => {
				event?.preventDefault()

				const searchParams = ractive.get('searchParams')

				const count = await mediator.call('emitToServer', 'load vehicle search count', searchParams)
				if (count <= 1000 || confirm(`This search contains ${count} results. This could take a while to load. Load the results anyway?`)) {
					stateRouter.go('app.vehicle-search.results', searchParams, { replace: true, inherit: true })
				}
			})
		},
	})
}
