import template from './purchase-order-line.html'

//Ractive components
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.entity.purchase-order.line',
		route: 'line',
		querystringParameters: [ 'selectedPurchaseOrderId' ],
		template: {
			template,
			components: {
				itTable: makeTable(),
				itButton: makeButton(),
			},
			computed: {
				displayPurchaseOrderLines() {
					const purchaseOrderLines = this.get('purchaseOrderLines')
					if (purchaseOrderLines) {
						return purchaseOrderLines.map(line => {
							return { ...line, total: (line.price * line.quantity).toFixed(2) }
						})
					} else {
						return []
					}
				},
			},
		},
		async resolve(data, { selectedPurchaseOrderId }) {
			const purchaseOrderLines = selectedPurchaseOrderId ? (await mediator.call('emitToServer', 'load purchase order lines', {
				purchaseOrderId: selectedPurchaseOrderId,
				includeTagNumber: true,
			})) : []

			return { purchaseOrderLines }
		},
		activate({ domApi: ractive }) {
			console.log(ractive.get('purchaseOrderLines'))
		},
	})
}

