export function getInventoryTypeNameFromId(inventoryTypeList, inventoryTypeId) {
	if (!inventoryTypeId) {
		return ''
	}

	const inventoryType = inventoryTypeList.find(inventoryType => {
		return inventoryType.inventoryTypeId === inventoryTypeId
	})

	if (inventoryType && inventoryType.name) {
		return inventoryType.name
	} else {
		return ''
	}
}

export function getInventoryTypeIdsFromTeardown(teardown, usesManufacturerModelOnly = false) {
	let filteredTeardown = teardown

	if (usesManufacturerModelOnly) {
		filteredTeardown = teardown.filter(teardownItem => teardownItem.usesManufacturerModel)
	}

	return filteredTeardown.map(teardownItem => teardownItem.inventoryTypeId)
}
