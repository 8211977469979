import template from './payment-line-row.html'

//Ractive components
import makeInput from '@isoftdata/input'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'

export default function createPaymentLineRowComponent() {
	return Ractive.extend({
		template,
		data() {
			return {
				paymentTerminalTransactionId: null,
			}
		},
		components: {
			itInput: makeInput({ twoway: true, lazy: true }),
			itCurrencyInput: makeCurrencyInput({ twoway: true, lazy: true }),
			itSelect: makeSelect({ twoway: true, lazy: true }),
			itButton: makeButton(),
		},
	})
}
