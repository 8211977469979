<script lang="ts">
	import type { PartModel } from '../../server/db/part-model-db'
	import type { InventoryType } from '../../server/db/inventory-type-db'
	import type { PartManufacturer } from '../../server/db/part-manufacturer-db'
	import type { Category as DbCategory } from '../../server/db/category-db'
	import type { Mediator } from 'types/common'

	import PartModelModal from './PartModelModal.svelte'
	import PartManufacturerModal from './PartManufacturerModal.svelte'
	import Autocomplete from '@isoftdata/svelte-autocomplete'

	import { v4 as uuid } from '@lukeed/uuid'
	import { createEventDispatcher, getContext, onMount, tick } from 'svelte'
	import { upsert } from '@isoftdata/utility-array'
	import { logAndAlert } from 'utility/error-handler'

	type Category = Omit<DbCategory, 'categoryId'> & { categoryId: number | null }

	// Labels
	export let inventoryTypeLabel = 'Part Type'
	export let partManufacturerLabel = 'Manufacturer'
	export let partModelLabel = 'Model'
	export let categoryLabel = 'Category'
	// Scalar values
	export let inventoryTypeId: number | null = null
	export let partManufacturer: string | null = ''
	export let partModel: string | null = ''
	export let category: string | null = ''
	// Loadings - TODO just pass the promises to the autocompletes?
	let isInventoryTypeListLoading = false
	let isManufacturerListLoading = false
	let isModelListLoading = false
	let isCategoryListLoading = false

	export let colSizeClass = 'col-sm'

	export let inventoryTypeList: Array<Required<InventoryType>> = []
	export let partManufacturerList: Array<PartManufacturer> = []
	export let partModelList: Array<PartModel> = []
	export let categoryList: Array<Category> = []

	// Ractive initialized this as an array, but seems like it's used as an object - double check that before we're done here
	export let inventoryTypeMoreFields: {
		more1?: string
		more2?: string
		more3?: string
		more4?: string
	} = {}

	export let inventoryTypeShown = true
	export let manufacturerShown = true
	export let modelShown = true
	export let categoryShown = true

	export let canAddType = false
	export let canAddCategory = true

	export let inventoryTypeDisabled = false
	export let partManufacturerDisabled = false
	export let partModelDisabled = false
	export let categoryDisabled = false

	export let inventoryTypeInputId = uuid()
	export let partManufacturerInputId = uuid()
	export let partModelInputId = uuid()
	export let categoryInputId = uuid()

	export let loadAllManufacturers = false

	let partModelModal: PartModelModal
	let partManufacturerModal: PartManufacturerModal

	// Obj Values - internal use only
	let inventoryTypeObj: InventoryType | null = null
	let partManufacturerObj: PartManufacturer | null = null
	let partModelObj: PartModel | null = null
	let categoryObj: Category | null = null

	export let partModelId: number | null = null
	$: partModelId = partModelObj?.partModelId ?? null

	const mediator = getContext<Mediator>('mediator')
	const dispatch = createEventDispatcher<{
		inventoryTypeChange: { inventoryType: InventoryType | null }
		partManufacturerChange: { partManufacturer: PartManufacturer | null }
		partModelChange: { partModel: PartModel | null }
		categoryChange: { category: Category | null }
	}>()

	export function addManufacturerToList(manufacturer: PartManufacturer) {
		partManufacturerList = upsert(partManufacturerList, 'partManufacturerId', manufacturer)
		partManufacturerObj = manufacturer
	}

	export function addModelToList(model: PartModel) {
		partModelList = upsert(partModelList, 'partModelId', model)
		partModelObj = model
	}

	async function onInventoryTypeChange() {
		inventoryTypeId = inventoryTypeObj?.inventoryTypeId ?? null
		if (inventoryTypeObj) {
			const typeSetId = inventoryTypeObj.typeSetId || inventoryTypeObj.inventoryTypeId

			await Promise.all([loadManufacturers(typeSetId), loadCategories(typeSetId)])

			inventoryTypeMoreFields = (['more1', 'more2', 'more3', 'more4'] as const).reduce((sum, more) => {
				if (inventoryTypeObj![more]) {
					return { ...sum, [more]: inventoryTypeObj![more] }
				}

				return sum
			}, {})
		} else {
			partManufacturerList = []
			partModelList = []
			categoryList = []
			inventoryTypeMoreFields = {}
		}
		dispatch('inventoryTypeChange', { inventoryType: inventoryTypeObj })
	}

	async function onPartManufacturerChange() {
		partManufacturer = partManufacturerObj?.name ?? ''
		await tick()
		if (inventoryTypeObj && partManufacturer) {
			loadModels(inventoryTypeObj.inventoryTypeId, partManufacturer)
		}
		dispatch('partManufacturerChange', { partManufacturer: partManufacturerObj })
	}

	async function onPartModelChange() {
		partModel = partModelObj?.name ?? ''
		await tick()
		dispatch('partModelChange', { partModel: partModelObj })
	}

	async function onPartCategoryChange() {
		category = categoryObj?.name ?? ''
		await tick()
		dispatch('categoryChange', { category: categoryObj })
	}

	function addModel() {
		if (inventoryTypeObj && partManufacturer) {
			const partManufacturerId = partManufacturerList.find(manu => manu.name === partManufacturer)?.partManufacturerId

			partModelModal.show({
				typeSetId: inventoryTypeObj.typeSetId ?? undefined,
				partManufacturerId,
			})
		} else {
			alert('You must select an inventory type & manufacturer before adding a model')
		}
	}

	function onModelSaved(event: CustomEvent<{ model: PartModel }>) {
		partModelList = upsert(partModelList, 'partModelId', event.detail.model)
		partModelObj = event.detail.model
		partModel = event.detail.model.name
	}

	function onManufacturerSaved(event: CustomEvent<{ manufacturer: PartManufacturer }>) {
		partManufacturerList = upsert(partManufacturerList, 'partManufacturerId', event.detail.manufacturer)
		partManufacturerObj = event.detail.manufacturer
		partManufacturer = event.detail.manufacturer.name
	}

	function createCategory(name: string) {
		const newCategory = {
			categoryId: null,
			name,
			description: '',
			core: 0,
			isoftCategoryId: null,
			partListAuthorityId: null,
			userEditable: true,
		}
		;(mediator.call('emitToServer', 'save category', { category: newCategory }) as Promise<Category>)
			.then((res: Category) => {
				categoryList = upsert(categoryList, 'categoryId', res)
				categoryObj = res
			})
			.catch(err => logAndAlert(err, mediator, 'Error saving category'))

		return newCategory
	}

	//#region Load Functions
	async function loadInventoryTypes() {
		isInventoryTypeListLoading = true
		inventoryTypeList = (await mediator.call('emitToServer', 'load inventory type list', {})) as Array<InventoryType>
		isInventoryTypeListLoading = false
	}

	async function loadManufacturers(inventoryTypeId: number) {
		isManufacturerListLoading = true
		partManufacturerList = (await mediator.call('emitToServer', 'load part manufacturers using inventory types', loadAllManufacturers ? {} : { inventoryTypeId })) as Array<PartManufacturer>
		isManufacturerListLoading = false

		if (partManufacturer && !partManufacturerList.find(manufacturer => manufacturer.name === partManufacturer)) {
			partManufacturer = ''
			partModel = ''
		}
	}

	async function loadModels(inventoryTypeId: number, partManufacturer: string) {
		if (partManufacturer && inventoryTypeId) {
			isModelListLoading = true
			const inventoryType = inventoryTypeList.find(inventoryType => inventoryType.inventoryTypeId === inventoryTypeId)

			const typeSetId = inventoryType?.typeSetId || inventoryTypeId

			partModelList = (await mediator.call('emitToServer', 'load part models using inventory type', { inventoryTypeId: typeSetId, partManufacturer })) as Array<PartModel>
			isModelListLoading = false

			if (partModel && !partModelList.find(model => model.name === partModel)) {
				partModel = ''
			}
		} else {
			partModelList = []
			isModelListLoading = false
		}
	}

	async function loadCategories(inventoryTypeId: number) {
		isCategoryListLoading = true
		categoryList = (await mediator.call('emitToServer', 'load categories', { inventoryTypeId })) as Array<Category>
		isCategoryListLoading = false

		if (category && !categoryList.find(cat => cat.name === category)) {
			category = ''
		}
	}
	// #endregion

	onMount(async () => {
		await loadInventoryTypes()
		inventoryTypeObj = inventoryTypeId ? inventoryTypeList.find(type => type.inventoryTypeId === inventoryTypeId) ?? null : null
		if (inventoryTypeObj) {
			const typeSetId = inventoryTypeObj.typeSetId || inventoryTypeObj.inventoryTypeId
			await Promise.all([
				// line break
				loadManufacturers(typeSetId),
				loadCategories(typeSetId),
			])
		}
		partManufacturerObj = partManufacturer ? partManufacturerList.find(manu => manu.name === partManufacturer) ?? null : null
		categoryObj = category ? categoryList.find(cat => cat.name === category) ?? null : null

		if (partManufacturerObj && inventoryTypeObj) {
			await loadModels(inventoryTypeObj.inventoryTypeId, partManufacturerObj.name)
			partModelObj = partModel ? partModelList.find(model => model.name === partModel) ?? null : null
		}
	})
</script>

<PartModelModal
	bind:this={partModelModal}
	on:modelSaved={onModelSaved}
/>

<PartManufacturerModal
	bind:this={partManufacturerModal}
	on:manufacturerSaved={onManufacturerSaved}
/>

<div class="form-row">
	{#if inventoryTypeShown}
		<div class={colSizeClass}>
			<Autocomplete
				id={inventoryTypeInputId}
				label={inventoryTypeLabel}
				isLoading={isInventoryTypeListLoading}
				disabled={inventoryTypeDisabled}
				canAddNew={canAddType}
				getLabel={type => (type ? `${type.inventoryTypeId} - ${type.name}` : '')}
				bind:options={inventoryTypeList}
				bind:value={inventoryTypeObj}
				on:change={onInventoryTypeChange}
			>
				<span
					slot="option"
					let:option
				>
					{#if option}
						<span class="badge badge-primary">{option?.inventoryTypeId}</span> {option.name}
					{/if}
				</span>
			</Autocomplete>
		</div>
	{/if}

	{#if manufacturerShown}
		<div class={colSizeClass}>
			<Autocomplete
				id={partManufacturerInputId}
				label={partManufacturerLabel}
				emptyValue={null}
				isLoading={isManufacturerListLoading}
				disabled={partManufacturerDisabled || !inventoryTypeObj?.usesManufacturerModel}
				hint={inventoryTypeObj?.usesManufacturerModel ? 'Add' : ''}
				hintClass="text-success cursor-pointer"
				hintClickable={!!inventoryTypeObj?.usesManufacturerModel}
				getLabel={manu => (manu ? manu.name : '')}
				bind:value={partManufacturerObj}
				bind:options={partManufacturerList}
				on:change={onPartManufacturerChange}
				on:hintClick={() => partManufacturerModal.show()}
			/>
		</div>
	{/if}

	{#if modelShown}
		<div class={colSizeClass}>
			<Autocomplete
				id={partModelInputId}
				label={partModelLabel}
				emptyValue={null}
				isLoading={isModelListLoading}
				disabled={partModelDisabled || !inventoryTypeObj?.usesManufacturerModel}
				hint={inventoryTypeObj?.usesManufacturerModel ? 'Add' : ''}
				hintClass="text-success cursor-pointer"
				hintClickable={!!inventoryTypeObj?.usesManufacturerModel}
				getLabel={model => (model ? model.name : '')}
				bind:value={partModelObj}
				bind:options={partModelList}
				on:change={onPartModelChange}
				on:hintClick={addModel}
			/>
		</div>
	{/if}

	{#if categoryShown}
		<div class={colSizeClass}>
			<Autocomplete
				id={categoryInputId}
				label={categoryLabel}
				emptyValue={null}
				placeholder="-- Select a Part Category --"
				isLoading={isCategoryListLoading}
				disabled={categoryDisabled}
				canAddNew={canAddCategory}
				getLabel={cat => (cat ? cat.name : '')}
				createOption={createCategory}
				bind:value={categoryObj}
				bind:options={categoryList}
				on:change={onPartCategoryChange}
			/>
		</div>
	{/if}
</div>
