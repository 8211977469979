import template from './connected.html'

//Ractive components
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.configuration.integration.quickbooks.connected',
		route: 'connected',
		template: {
			template,
			components: {
				itButton: makeButton(),
			},
			data: {},
			computed: {},
			async resetConnection() {
				await mediator.call('emitToServer', 'reset quickbooks connection', {})
				mediator.call('show-quickbooks-in-sidebar', false)
				stateRouter.go('app.configuration.integration.quickbooks')
			},
		},
		async resolve(data, parameters) {
			let companyInfo
			try {
				companyInfo = await mediator.call('emitToServer', 'get quickbooks company info', {})
			} catch (err) {
				// Tell them there was an error fetching company name instead of it being blank or breaking
				companyInfo = {
					CompanyName: 'Error fetching company info',
				}
			}
			return { companyInfo }
		},
		activate({ domApi: ractive }) {
			//
		},
	})
}
