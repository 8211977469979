import type { ReportQueueWithParameters } from 'server/db/report-queue-db'
import type { AppContext, Mediator, ResolveParameters } from 'types/common'
import type { PrintJob } from '@isoftdata/svelte-report-viewer'
import component from './History.svelte'
import { stringToBoolean } from '@isoftdata/utility-string'
import pProps from 'p-props'

type StateParameters = {
	showSentJobs?: boolean
	showCancelledJobs?: boolean
	showReadyJobs?: boolean
	showFailedJobs?: boolean
	showDelayedJobsOnly?: boolean
	pageNumber: number
	sortDirection: 'ASC' | 'DESC'
	sortColumnProperty: string
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.history',
		route: 'history',
		querystringParameters: ['showSentJobs', 'showCancelledJobs', 'showReadyJobs', 'showFailedJobs', 'showDelayedJobsOnly', 'lastRefreshTime'],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters: ResolveParameters<StateParameters>) {
			const { showSentJobs, showCancelledJobs, showReadyJobs, showFailedJobs, showDelayedJobsOnly } = await getFilterParameters(mediator, parameters)

			let statusFilter = []

			if (showSentJobs) {
				statusFilter.push('SENT')
			}

			if (showCancelledJobs) {
				statusFilter.push('CANCELLED')
			}

			if (showReadyJobs) {
				statusFilter.push('READY')
			}

			if (showFailedJobs) {
				statusFilter.push('FAILED')
			}

			const history: Array<ReportQueueWithParameters> = statusFilter.length
				? ((await mediator.call('emitToServer', 'load report queue job history', {
						status: statusFilter,
						includeParameters: true,
						delayed: showDelayedJobsOnly ? true : undefined,
				  })) as Array<ReportQueueWithParameters>)
				: []

			return {
				history: history.map(
					(job): PrintJob => ({
						...job,
						id: job.reportQueueId,
						destination: job.destination ?? '',
						status: job.status.toUpperCase(),
						executionTime: Number(job.executionTime?.toFixed(2)) ?? null,
						delayUntil: job.waitUntil,
					})
				),
				showSentJobs,
				showCancelledJobs,
				showReadyJobs,
				showFailedJobs,
				showDelayedJobsOnly,
			}
		},
	})
}

async function getFilterParameters(mediator: Mediator, parameters: ResolveParameters<StateParameters>) {
	// We can skip the query if the parameters are already in the URL
	return await pProps({
		showCancelledJobs: stringToBoolean(
			parameters.showCancelledJobs ??
				((await mediator.call('emitToServer', 'check user setting', {
					category: 'Printing',
					settingType: 'Interface History',
					name: 'Print Queue: show cancelled print jobs',
					defaultValue: 'False',
				})) as string)
		),
		showSentJobs: stringToBoolean(
			parameters.showSentJobs ??
				((await mediator.call('emitToServer', 'check user setting', {
					category: 'Printing',
					settingType: 'Interface History',
					name: 'Print Queue: show previously printed print jobs',
					defaultValue: 'False',
				})) as string)
		),
		showFailedJobs: stringToBoolean(
			parameters.showFailedJobs ??
				((await mediator.call('emitToServer', 'check user setting', {
					category: 'Printing',
					settingType: 'Interface History',
					name: 'Print Queue: show failed print jobs',
					defaultValue: 'True',
				})) as string)
		),
		showReadyJobs: stringToBoolean(
			parameters.showReadyJobs ??
				((await mediator.call('emitToServer', 'check user setting', {
					category: 'Printing',
					settingType: 'Interface History',
					name: 'Print Queue: show ready print jobs',
					defaultValue: 'True',
				})) as string)
		),
		showDelayedJobsOnly: stringToBoolean(
			parameters.showDelayedJobsOnly ??
				((await mediator.call('emitToServer', 'check user setting', {
					category: 'Printing',
					settingType: 'Interface History',
					name: 'Print Queue: only delayed print jobs"',
					defaultValue: 'False',
				})) as string)
		),
	})
}
