import template from './report-selection.html'
import domValue from 'dom-value'
import deepCopy from 'klona'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

//Ractive components
import makeModal from '@isoftdata/modal'
import makeInput from '@isoftdata/input'
import makeAutocomplete from '@isoftdata/autocomplete'
import makeSelect from '@isoftdata/select'
import makeCheckbox from '@isoftdata/checkbox'
import makeTextArea from '@isoftdata/textarea'
import makeReportPreviewModal from './report-preview'

const defaultData = {
	show: false,
	reports: null,
	printers: null,
	selectedReportId: '',
	selectedPrinterName: '',
	printQuantity: 1,
	showErrorDetails: false,
	error: '',
	domValue,
	callback: undefined,
	rememberPrinterChoice: false,
	reportPrinterUnavailable: false,
	reportPrinter: '',
	destinationType: 'Printer',
	emailAddress: '', // email destination
	emailBody: '',
	emailSubject: '',
	emailFrom: '',
	attachmentName: '',
	emailSuggestion: {},
	previewLoading: false,
	previewError: false,
}

export default function createReportSelectionComponent({ mediator, logAndAlert }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			modal: makeModal(),
			itInput: makeInput({ twoway: true }),
			itAutocomplete: makeAutocomplete({ twoway: true }),
			itSelect: makeSelect({ twoway: true }),
			itCheckbox: makeCheckbox({ twoway: true }),
			itTextArea: makeTextArea({ twoway: true }),
			reportPreviewModal: makeReportPreviewModal({ mediator }),
		},
		transitions: {
			slide: ractiveTransitionsSlide,
		},
		data() {
			return deepCopy(defaultData)
		},
		computed: {
			confirmButtonText() {
				const ractive = this
				const type = ractive.get('destinationType')
				if (type === 'Printer') {
					return 'Print'
				} else if (type === 'Preview') {
					return 'Download'
				}
				return type
			},
			confirmButtonFontAwesomeIcon() {
				const ractive = this
				const type = ractive.get('destinationType')
				const loading = ractive.get('previewLoading')
				if (loading) {
					return 'spinner fa-spin'
				}
				if (type === 'Printer') {
					return 'print'
				}
				if (type === 'Email') {
					return 'envelope'
				}
				if (type === 'Preview') {
					return 'download'
				}
			},
			confirmButtonDisabled() {
				const ractive = this
				if (!ractive.get('selectedReportId') || ractive.get('previewLoading')) {
					return true
				}
				const destinationType = ractive.get('destinationType')
				if (destinationType === 'Email' && !ractive.get('emailAddress')) {
					return true
				} else if (destinationType === 'Printer' && !ractive.get('selectedPrinterName')) {
					return true
				} else if (destinationType === 'Preview' && (ractive.get('previewLoading') || ractive.get('previewError'))) {
					return true
				}
				return false
			},
			selectedReport() {
				const ractive = this
				const selectedReportId = ractive.get('selectedReportId')
				const reports = ractive.get('reports')
				if (!reports || !selectedReportId) {
					return null
				}
				return reports.find(report => report.reportId === selectedReportId) ?? null
			},
		},
		// This is the function the consumer calls to open the modal
		// emailsObject is an object whose keys and values for each key value pair are the same email address
		async printReport(reportJobs, contextualEmailAddresses = {}, { disablePrintQuantity = false, hidePreviewOption = false, previewOnly = false } = {}, callback) {
			const ractive = this
			let emailAddresses
			contextualEmailAddresses = Object.fromEntries(Object.entries(contextualEmailAddresses).filter(([ key, value ]) => {
				return key && key === value
			}))
			if (!Object.keys(contextualEmailAddresses).length) {
				const recentEmailAddressesRes = await mediator.call('emitToServer', 'load recent email addresses', {})
				emailAddresses = recentEmailAddressesRes.reduce((acc, row) => {
					acc[row.emailAddress] = row.emailAddress
					return acc
				}, {})
			} else {
				emailAddresses = contextualEmailAddresses
			}

			if (!ractive.root.get('advancedPrintManageInstalled')) {
				previewOnly = true
			}

			if (!Array.isArray(reportJobs)) {
				reportJobs = [ reportJobs ]
			}

			ractive.set({ reportJob: reportJobs,
				emailSuggestion: emailAddresses,
				disablePrintQuantity,
				hidePreviewOption,
				callback,
				previewOnly,
				destinationType: previewOnly ? 'Preview' : 'Printer',
			})
			const reportJobType = reportJobs[0]?.type

			const { store } = JSON.parse(localStorage.getItem('session'))

			try {
				const [ reports, printers, reportPrinter, defaultPrinter ] = await Promise.all([
					mediator.call('emitToServer', 'load reports', { type: reportJobType }),
					mediator.call('emitToServer', 'load printers', { type: reportJobType, storeId: store.storeId }),
					mediator.call('emitToServer', 'load report printer', { reportType: reportJobType }) || {},
					mediator.call('emitToServer', 'load report printer', { reportType: '' }) || {}, //empty report type means default
				])

				//Support report type-specific printers and a global default
				const matchingPrinter = printers.find(printer => printer.name === reportPrinter.name)
				const matchingDefaultPrinter = printers.find(printer => printer.name === defaultPrinter.name)
				const matchingPrintersByType = printers.filter(printer => printer.reportType === reportJobType)

				ractive.set({
					reports,
					printers: matchingPrintersByType.length > 0 ? matchingPrintersByType : printers,
					reportPrinterUnavailable: (reportPrinter && reportPrinter.name) && !matchingPrinter,
					reportPrinter: reportPrinter && reportPrinter.name,
					selectedPrinterName: (matchingPrinter && matchingPrinter.name) || (matchingDefaultPrinter && matchingDefaultPrinter.name) || '',
				})

				let selectedReportId
				// If user specifies name, don't just do the default, select the one they specified
				if (reportJobs[0].name) {
					const foundReportId = reports.find(report => report.name === reportJobs[0].name).reportId
					if (foundReportId) {
						selectedReportId = foundReportId
					}
				}

				if (reports && printers) {
					if (reports.length === 1) {
						selectedReportId = reports[0].reportId
					} else if (reports.length > 1 && !selectedReportId) {
						const matchingReport = reports.find(report => report.name.toUpperCase().indexOf('DEFAULT') > -1)

						if (matchingReport) {
							selectedReportId = matchingReport.reportId
						}
					}
					ractive.set({ show: true, selectedReportId })
				}
				if (previewOnly) {
					ractive.showReportPreview()
				}
			} catch (err) {
				console.error(err)
				throw logAndAlert(err, mediator, `Error getting list of reports and printers`)
			}
		},
		callCallbackIfNeeded(err, res) {
			const cb = this.get('callback')

			if (cb) {
				cb(err, res)
			}
		},
		showReportPreview() {
			const ractive = this
			const selectedReportId = ractive.get('selectedReportId')
			const reportJobs = ractive.get('reportJob')
			const parameters = reportJobs?.[0]?.reportParameters?.reduce((acc, param) => {
				if (!param.parameterName) {
					return acc
				}
				return { ...acc, [param.parameterName]: param.value }
			}, {})
			// Disable download button based on the state of the preview component, disable if loading or errored
			ractive.set('previewLoading', true)
			ractive.findComponent('reportPreviewModal')?.show(selectedReportId, parameters).then(() => { // wait for render before setting observer
				const rv = ractive.findComponent('reportViewerPreview')
				rv?.observeOnce('pdfIsLoading', pdfIsLoading => {
					ractive.set({
						previewLoading: pdfIsLoading,
						previewError: !pdfIsLoading && rv.get('pdfError'),
					})
				})
			})
		},
		hideReportPreview() {
			const ractive = this
			ractive.findComponent('reportPreviewModal')?.hide()
		},
		oninit() {
			const ractive = this

			if (!mediator) {
				console.error('createReportSelectionComponent: missing required mediator property')
			}
			if (!logAndAlert) {
				console.error('createReportSelectionComponent: missing required logAndAlert property')
			}

			ractive.on('close', () => {
				ractive.callCallbackIfNeeded(null, 'DIALOG_CLOSED')
				ractive.reset(deepCopy(defaultData))
			})

			ractive.observe('printQuantity', printQuantity => {
				if (printQuantity < 1) {
					ractive.set({ printQuantity: 1 })
				}
			})

			ractive.on('print', async() => { // called when the "print" button is pressed
				const ractive = this
				const selectedReportId = ractive.get('selectedReportId')
				const selectedPrinterName = ractive.get('selectedPrinterName')

				const selectedReport = ractive.get('selectedReport')
				const destinationType = ractive.get('destinationType')
				const emailAddress = ractive.get('emailAddress')
				const emailSubject = ractive.get('emailSubject')
				const emailBody = ractive.get('emailBody')
				const emailFrom = ractive.get('emailFrom')
				const attachmentName = ractive.get('attachmentName')

				let reportJobs = ractive.get('reportJob')

				if (destinationType === 'Preview' && selectedReport) {
					const rvRactive = ractive.findComponent('reportViewerPreview')
					let pdfDataUri = rvRactive?.get('pdfDataUri')
					if (pdfDataUri) {
						rvRactive.find('#download-pdf-button')?.click()
					}
				} else if ((destinationType === 'Email' || destinationType === 'Printer') && selectedReportId && (selectedPrinterName || emailAddress)) {
					const reportQueueJob = {
						type: selectedReport.type,
						name: selectedReport.name,
						destinationType, // either 'Printer' or 'Email'
						destination: destinationType === 'Printer' ? selectedPrinterName : emailAddress, // either the printer name or the email addresss
						quantity: destinationType === 'Printer' ? ractive.get('printQuantity') : 1,
						title: emailSubject,
						emailBody,
						emailFrom,
						attachmentName,
					}

					try {
						const jobMap = reportJobs.map(job => {
							return mediator.call('emitToServer', 'create print queue job', {
								reportQueueJob: {
									...reportQueueJob,
									quantity: job.quantity ? job.quantity : reportQueueJob.quantity,
								},
								reportQueueJobParameters: job.reportParameters,
							})
						})
						let [ reportPrinterSave, ...reportQueueJobSave ] = await Promise.all([
							ractive.get('rememberPrinterChoice') ? mediator.call('emitToServer', 'save report printer', {
								reportType: selectedReport.type,
								printer: selectedPrinterName,
							}) : null,
							...jobMap,
						])
						reportQueueJobSave = reportQueueJobSave.flat(1)
						if (reportQueueJobSave[0]?.details && reportQueueJobSave[0]?.details[0]?.type === 'string.email') {
							ractive.set({
								error: "Error creating print job",
								errorDetails: `Invalid e-mail address: '${reportQueueJobSave[0].details[0].context.invalids.join('\', \'')}'`,
							})
						} else { // On Success
							await ractive.set({ show: false, error: '', errorDetails: '' })
							ractive.callCallbackIfNeeded(null, {
								reportQueueJobSave: reportQueueJobSave.length === 1 ? reportQueueJobSave[0] : reportQueueJobSave,
								reportPrinterSave,
							})
							ractive.reset(deepCopy(defaultData))
						}
					} catch (err) {
						console.error(err)
						ractive.set({
							error: 'Error creating print job',
							errorDetails: err || '',
						})
					}
				} else {
					alert('Error: please select a report & destination to print.')
				}
			})
		},
	})
}
