import template from './part-type.html'
import { stringToBoolean } from '@isoftdata/utility-string'

//Ractive Components
import makeQuickbooksEntityManagement from 'components/quickbooks-entity-management'

function makeDisplayRecordFromItrack(itrackPartType) {
	return {
		id: itrackPartType.inventoryTypeId,
		name: itrackPartType.name,
		original: itrackPartType,
	}
}

function makeDisplayRecordFromQuickbooks(quickbooksNonStockedInventory) {
	return {
		id: Number(quickbooksNonStockedInventory.Id) || quickbooksNonStockedInventory.Id,
		name: quickbooksNonStockedInventory.Name,
		description: quickbooksNonStockedInventory.Description,
		original: quickbooksNonStockedInventory,
	}
}

const stateName = 'app.quickbooks.part-type'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: stateName,
		route: 'part-type',
		querystringParameters: [ 'systemSelection', 'hideMatches' ],
		defaultParameters: {
			systemSelection: 'itrack',
			hideMatches: 'true',
		},
		template: {
			template,
			components: {
				quickbooksEntityManagement: makeQuickbooksEntityManagement({
					mediator,
					makeDisplayRecordFromItrack,
					makeDisplayRecordFromQuickbooks,
					makeItrackEntityPath: id => stateRouter.makePath('app.configuration.inventory.type.model', { inventoryTypeId: id }),
					makeQuickbooksEntityUrl: () => `__qboBasePath__/items`, // can't link directly to one; just open list
					displayQuickbooksColumns: [
						{ property: 'name', name: 'Name' },
						{ property: 'description', name: 'Description' },
					],
					// displayItrackColumns: [
					// ],
					itrackKey: 'inventoryTypeId',
				}),
			},
		},
		async resolve(data, { hideMatches, systemSelection: system }) {
			const { quickbooksEntityList, itrackEntityList } = await mediator.call('emitToServer', 'load part types for quickbooks screen', { hideSynced: stringToBoolean(hideMatches) })

			return {
				quickbooksEntityList,
				itrackEntityList,
				hideMatches: stringToBoolean(hideMatches),
				systemSelection: {
					quickbooks: system === 'quickbooks',
					itrack: system === 'itrack',
				},
			}
		},
		activate({ domApi: ractive, parameters }) {
			// have to call this here, and not in the component's oninit function,
			// because that doesn't re-fire on state reload, which is required to load new data
			ractive.findComponent('quickbooksEntityManagement')?.initializeMaps?.()

			mediator.call('activity', {
				stateName,
				stateParameters: parameters,
				stateCategory: 'QUICKBOOKS',
				action: 'VIEW',
				displayTitle: `${parameters.systemSelection === 'itrack' ? 'ITrack' : 'QuickBooks'} Part Types`,
				stateParameterKey: 'systemSelection',
			})
		},
	})
}
