import * as partTypeHelper from '../inventory-type-helper.mjs'
import * as manModHelper from '../manufacturer-model-helper.mjs'
import { stringToBoolean as isTrue } from '@isoftdata/utility-string'
import { format as currencyFormat } from '@isoftdata/utility-currency'

const currencyFormatOptions = { includeSymbol: false, includeComma: false }

export default class TeardownItem {
	constructor(part, type, inventoryTypeList, partManufacturerList, partModelList, partCategoryList) {
		//console.log("TeardownItem", part, type, inventoryTypeList, partManufacturerList, partModelList, partCategoryList)
		const matchingInventoryType = inventoryTypeList
			.find(inventoryType => inventoryType.inventoryTypeId === part.inventoryTypeId)
		const usesManufacturerModel = matchingInventoryType ? isTrue(matchingInventoryType.usesManufacturerModel) : false
		const exists = !!(type === 'BID' && part.vehicleBidPartId) || !!(type === 'INVENTORY' && part.inventoryId)
		const partManufacturerListByTypeId = manModHelper.partManufacturerListByTypeId(partManufacturerList)
		const partModelListByPartManufacturerId = manModHelper.partModelListByPartManufacturerId(partModelList)
		const partCategoryListByTypeId = manModHelper.partCategoryListByTypeId(partCategoryList)
		if (type === 'BID') {
			this.id = part.vehicleBidPartId
			this.partManufacurerId = part.partModelId ? manModHelper.getPartManufacturerIdFromModelId(partModelList) : null
			this.partModelId = part.partModelId
			this.notes = null
			this.core = currencyFormat(part.corePrice, currencyFormatOptions)
			this.status = part.vehicleBidPartId ? 'A' : null
		} else if (type === 'INVENTORY') {
			const partManufacturerId = part.partManufacturer ? manModHelper.getPartManufacturerIdFromName(partManufacturerList, part.partManufacturer) : null

			this.id = part.inventoryId
			this.partManufacturerId = partManufacturerId
			this.partModelId = (partManufacturerId && part.partModel) ? manModHelper.getPartModelIdFromModelNameAndManufacturerId(partModelList, part.partModel, partManufacturerId) : null
			this.notes = part.notes
			this.core = currencyFormat(part.core, currencyFormatOptions)
			this.status = part.status
			this.bodyStyle = part.bodyStyle
			this.make = part.make ?? null
			this.model = part.model ?? null
			this.year = part.year ?? null
			this.vehicleId = part.vehicleId ?? null
			this.vin = part.vin ?? null
		}

		this.inventoryTypeId = part.inventoryTypeId
		this.description = part.description
		this.location = part.location
		this.category = part.category
		this.side = part.side
		this.quantity = part.quantity
		this.retailPrice = currencyFormat(part.retailPrice, currencyFormatOptions)
		this.wholesalePrice = currencyFormat(part.wholesalePrice, currencyFormatOptions)
		this.listPrice = currencyFormat(part.listPrice, currencyFormatOptions)
		this.cost = currencyFormat(part.cost, currencyFormatOptions)
		this.tagNumber = part.tagNumber
		this.interchangeNumber = part.interchangeNumber
		this.inventoryTypeName = partTypeHelper.getInventoryTypeNameFromId(inventoryTypeList, part.inventoryTypeId)
		this.usesManufacturerModel = usesManufacturerModel
		this.partManufacturerList = partManufacturerListByTypeId ? partManufacturerListByTypeId[part.inventoryTypeId] ? partManufacturerListByTypeId[part.inventoryTypeId] : [] : []
		this.partModelList = partModelListByPartManufacturerId ? partModelListByPartManufacturerId[part.partManufacturerId] ? partModelListByPartManufacturerId[part.partManufacturerId] : [] : []
		this.partCategoryList = partCategoryListByTypeId ? partCategoryListByTypeId[part.inventoryTypeId] ? partCategoryListByTypeId[part.inventoryTypeId] : [] : []
		this.exists = exists
		this.fileCount = part.fileCount ? part.fileCount : 0
	}
}
