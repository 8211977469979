import template from './address-form.html'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeSelect from '@isoftdata/select'
import makeMapButton from '@isoftdata/map-button'

export default function({ mediator, logAndAlert }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		components: {
			itInput: makeInput({ twoway: true, lazy: true }),
			itSelect: makeSelect({ twoway: true, lazy: true }),
			itMapButton: makeMapButton({ twoway: true, lazy: true }),
			itButton: makeButton({ twoway: true, lazy: true }),
		},
		data() {
			return {
				disabled: false,
				showMapButton: true,
				statesLoading: true,
				saved: [],
				company: '',
				contact: '',
				streetAddress: '',
				mailingAddress: '',
				city: '',
				state: '',
				zip: '',
				country: '',
				phoneNumber: '',
				hiddenFields: [],

				address1Label: 'Street Address',
				address2Label: 'Mailing Address',

				address1DivClass: 'col-md-6',
				address2DivClass: 'col-md-6',
				cityDivClass: 'col-md-8 col-lg-4',
				stateDivClass: 'col-md-4 col-lg-2',
				zipDivClass: 'col-md-6 col-lg-3',
				countryDivClass: 'col-md-6 col-lg-3',
			}
		},
		async loadCityStateCountryFromZipCode(zipCode) {
			const ractive = this

			try {
				const place = await mediator.call('emitToServer', 'load city state country from zip code', { zipCode })
				if (place.length === 1) {
					ractive.set({
						city: place[0].city,
						state: place[0].stateAbbreviation,
						country: place[0].country,
					})
				}
			} catch (err) {
				logAndAlert(err, mediator, `error loading city, state, and country info from zip code: ${zipCode}`)
			}
		},
		onconstruct() {
			const ractive = this

			mediator.call('emitToServer', 'load states', {})
				.then(states => {
					ractive.set({ states }).then(() => {
						ractive.set({ statesLoading: false })
					})
				})
				.catch(err => {
					logAndAlert(err, mediator, 'error loading states')
				})
		},
		oninit() {
			const ractive = this

			ractive.observe('zip', zip => {
				if (zip && zip.trim().length >= 5 && !ractive.get('city') && !ractive.get('state')) {
					ractive.loadCityStateCountryFromZipCode(zip.trim())
				}
			}, { init: false })
		},
	})
}
