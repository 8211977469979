const defaultDoCreditInfo = {
	command: 'T00',
	version: '1.37',
	transactionType: '01',
	amountInformation: {
		TransactionAmount: '0000',
		TipAmount: '',
		CashBackAmount: '',
		MerchantFee: '',
		TaxAmount: '',
		FuelAmount: '',
	},
	accountInformation: {
		Account: '',
		EXPD: '',
		CVVCode: '',
		EBTtype: '',
		VoucherNumber: '',
		Force: '',
		FirstName: '',
		LastName: '',
		CountryCode: '',
		State_ProvinceCode: '',
		CityName: '',
		EmailAddress: '',
	},
	traceInformation: {
		ReferenceNumber: '1',
		InvoiceNumber: '',
		AuthCode: '',
		TransactionNumber: '',
		TimeStamp: '',
		ECRTransID: '',
	},
	avsInformation: {
		ZipCode: '',
		Address: '',
		Address2: '',
	},
	cashierInformation: {
		ClerkID: '',
		ShiftID: '',
	},
	commercialInformation: {
		PONumber: '',
		CustomerCode: '',
		TaxExempt: '',
		TaxExemptID: '',
		MerchantTaxID: '',
		DestinationZipCode: '',
		ProductDescription: '',
	},
	motoEcommerce: {
		MOTO_E_CommerceMode: '',
		TransactionType: '',
		SecureType: '',
		OrderNumber: '',
		Installments: '',
		CurrentInstallment: '',
	},
	additionalInformation: {
		TABLE: '',
		GUEST: '',
		SIGN: '',
		TICKET: '',
		HREF: '',
		TIPREQ: '',
		SIGNUPLOAD: '',
		REPORTSTATUS: '',
		TOKENREQUEST: '',
		TOKEN: '',
		CARDTYPE: '',
		CARDTYPEBITMAP: '',
		PASSTHRUDATA: '',
		RETURNREASON: '',
		ORIGTRANSDATE: '',
		ORIGPAN: '',
		ORIGEXPIRYDATE: '',
		ORIGTRANSTIME: '',
		DISPROGPROMPTS: '',
		GATEWAYID: '',
		GETSIGN: '',
		ENTRYMODEBITMAP: '',
		RECEIPTPRINT: '',
		CPMODE: '',
		ODOMETER: '',
		VEHICLENO: '',
		JOBNO: '',
		DRIVERID: '',
		EMPLOYEENO: '',
		LICENSENO: '',
		JOBID: '',
		DEPARTMENTNO: '',
		CUSTOMERDATA: '',
		USERID: '',
		VEHICLEID: '',
	},
}

const defaultGetSignatureInfo = {
	command: 'A08',
	offset: '0',
	requestlength: '90000',
	version: '1.37',
}

//HEX TO BASE64
function hexToBase64(str) {
	return window.btoa(String.fromCharCode.apply(null,
		str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")),
	)
}

//BASE64 TO HEX
function base64ToHex(str) {
	for (var i = 0, bin = window.atob(str), hex = []; i < bin.length; ++i) {
		var tmp = bin.charCodeAt(i).toString(16)
		if (tmp.length === 1) {
			tmp = `0${ tmp}`
		}
		hex[hex.length] = tmp
	}
	return hex.join(" ")
}

function StringToHex(response) {
	var responseHex = ""
	for (var i = 0; i < response.length; i++) {
		if (responseHex == "") {
			responseHex = response.charCodeAt(i).toString(16).length < 2 ? `0${ response.charCodeAt(i).toString(16)}` : response.charCodeAt(i).toString(16)
		} else {
			responseHex += response.charCodeAt(i).toString(16).length < 2 ? `${" " + '0'}${ response.charCodeAt(i).toString(16)}` : ` ${ response.charCodeAt(i).toString(16)}`
		}
	}
	return responseHex
}

function HexToString(response) {
	var responseHex = ""
	var arr = response.split(" ")
	for (var i = 0; i < arr.length; i++) {
		if (arr[i] == "") {
			continue
		}
		responseHex += String.fromCharCode(parseInt(arr[i], 16))
	}
	return responseHex
}

function parseResponse(commandType, response) {
	console.log(`Raw response: ${ response}`)

	const checkParams = StringToHex(response).split(" ").pop()
	const RedundancyCheck = StringToHex(response).split(" ").pop().substring(1)

	const check = PAX.getLRC(checkParams)

	if (check == RedundancyCheck) {
		//get package detail info
		let packetInfo = []
		var len = StringToHex(response).indexOf("03")
		var hex = StringToHex(response)
			.slice(0, len)
			.split(/02|1c/)

		console.log(hex)
		if (commandType == "DoCredit") {
			let subHex = []

			hex.forEach((item, index) => {
				if (item) {
					if (item.indexOf("1f") > 0) {
						subHex = item.split("1f")

						let subPacketInfo = []
						subHex.forEach(item => {
							if (item) {
								subPacketInfo.push(HexToString(item))
							}
						})

						packetInfo.push(subPacketInfo)
					} else {
						packetInfo[index] = HexToString(item)
					}
				}
			})
		} else {
			hex.forEach((item, index) => {
				if (item) {
					packetInfo[index] = HexToString(item)
				}
			})
		}

		console.log("Separate package info: ")
		console.log(packetInfo)

		return packetInfo
	}
	throw 'Redundancy check failed.'
}

export default {
	//IP of the POS
	mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181

	mStx: {
		hex: 0x02,
		code: "02",
	},

	mFS: {
		hex: 0x1c,
		code: "1c",
	},

	mEtx: {
		hex: 0x03,
		code: "03",
	},

	mUS: {
		hex: 0x1F,
		code: "1F",
	},

	//var _this : this;
	customData: '',

	timeout: {
		"Initialize": 120 * 1000,
		"GetSignature": 120 * 1000,
		"DoSignature": 120 * 1000,
		"DoCredit": 120 * 1000,
	},

	//Set ip and port
	Settings(ip, port) {
		console.log(ip, port)
		this.mDestinationIP = `http://${ ip }:${ port}`
		console.log(`New service address: ${ this.mDestinationIP}`)
	},

	AjaxTimeOut(command, timeout) {
		this.timeout[command] = timeout
	},

	SetCustomData(custom_data) {
		this.customData = custom_data
		console.log(custom_data)
	},

	//Get LRC
	getLRC(params) {
		var lrc = 0
		for (let i = 1; i < params.length; i++) {
			var type_of = typeof (params[i])
			if (type_of == "string") {
				var element = params[i].split("")
				for (let ii = 0; ii < element.length; ii++) {
					// eslint-disable-next-line no-bitwise
					lrc ^= element[ii].charCodeAt(0)
				}
			} else {
				// eslint-disable-next-line no-bitwise
				lrc ^= params[i]
			}
		}
		return (lrc > 0) ? String.fromCharCode(lrc) : 0
	},
	HttpCommunication(commandType, url, callback, _timeout) {
		fetch(url, {
			method: 'GET',
		}).then(response => {
			if (response.status === 200) {
				return response.text()
			}
			throw `${ commandType } communication failed. http status code ${ response.status }: ${ response.statusText }`
		}).then(responseText => {
			console.log(responseText)
			callback(null, parseResponse(commandType, responseText))
		}).catch(e => callback(e))
	},
	Initialize(initialInfo = { command: 'A00', version: '1.37' }, callback) {
		var params = [ this.mStx.hex, initialInfo.command, this.mFS.hex, initialInfo.version, this.mEtx.hex ]
		//[02]A08[1c]1.37[1c]0[1c]90000[03]
		//var params = [0x02,"A08",0x1c,"1.37",0x1c, "0", 0x1c,"90000",0x03];
		var lrc = this.getLRC(params)
		var command_hex = base64ToHex(window.btoa(initialInfo.command))
		var version_hex = base64ToHex(window.btoa(initialInfo.version))
		//var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(window.btoa(lrc))];
		var elements = [ this.mStx.code, command_hex, this.mFS.code, version_hex, this.mEtx.code, base64ToHex(window.btoa(lrc)) ]

		var final_string = elements.join(" ")
		//console.log("final_string: " + final_string);

		var final_b64 = hexToBase64(final_string)
		console.log(`LRC: ${ lrc}`)
		console.log(`Base64: ${ final_b64}`)
		var url = `${this.mDestinationIP }?${ final_b64}`
		console.log(`URL: ${ url}`)

		this.HttpCommunication('Initialize', url, callback, PAX.timeout.Initialize)
	},
	//GET SIGNATURE
	GetSignature(getSignatureInfo, callback) {
		var params = [ this.mStx.hex, getSignatureInfo.command, this.mFS.hex, getSignatureInfo.version, this.mFS.hex, getSignatureInfo.offset, this.mFS.hex, getSignatureInfo.requestlength, this.mEtx.hex ]
		var lrc = this.getLRC(params)

		//prepare for base64 encoding.
		var command_hex = base64ToHex(window.btoa(getSignatureInfo.command))
		var version_hex = base64ToHex(window.btoa(getSignatureInfo.version))
		var offset_hex = base64ToHex(window.btoa(getSignatureInfo.offset))
		var requestlength_hex = base64ToHex(window.btoa(getSignatureInfo.requestlength))
		//var elements = [this.mStx.code, command_hex, this.mFS.code, version_hex, this.mFS.code, offset_hex, this.mFS.code, requestlength_hex, this.mEtx.code, base64ToHex(window.btoa(lrc))];
		var elements = [ this.mStx.code ]
		elements.push(command_hex)
		elements.push(this.mFS.code)
		elements.push(version_hex)
		elements.push(this.mFS.code)
		if (offset_hex != '') {
			elements.push(offset_hex)
		}
		elements.push(this.mFS.code)
		if (requestlength_hex != '') {
			elements.push(requestlength_hex)
		}
		elements.push(this.mEtx.code)
		elements.push(base64ToHex(window.btoa(lrc)))

		var final_string = elements.join(" ")
		var final_b64 = hexToBase64(final_string)
		console.log(`LRC: ${ lrc}`)
		console.log(`Base64: ${ final_b64}`)
		var url = `${this.mDestinationIP }?${ final_b64}`
		console.log(`URL: ${ url}`)

		this.HttpCommunication('GetSignature', url, callback, PAX.timeout.GetSignature)
	},

	//DO SIGNATURE
	DoSignature(doSignatureInfo, callback) {
		var params = [ this.mStx.hex, doSignatureInfo.command, this.mFS.hex, doSignatureInfo.version, this.mFS.hex, doSignatureInfo.uploadFlag, this.mFS.hex, doSignatureInfo.hostReferenceNumber, this.mFS.hex, doSignatureInfo.edcType, this.mFS.hex, doSignatureInfo.timeout, this.mEtx.hex ]
		var lrc = this.getLRC(params)

		//prepare for base64 encoding.
		var command_hex = base64ToHex(window.btoa(doSignatureInfo.command))
		var version_hex = base64ToHex(window.btoa(doSignatureInfo.version))
		var uploadFlag_hex = base64ToHex(window.btoa(doSignatureInfo.uploadFlag))
		var hostReferenceNumber_hex = base64ToHex(window.btoa(doSignatureInfo.hostReferenceNumber))
		var edcType_hex = base64ToHex(window.btoa(doSignatureInfo.edcType))
		var timeout_hex = base64ToHex(window.btoa(doSignatureInfo.timeout))
		var elements = [ this.mStx.code ]

		elements.push(command_hex)
		elements.push(this.mFS.code)
		elements.push(version_hex)
		elements.push(this.mFS.code)

		if (uploadFlag_hex != '') {
			elements.push(uploadFlag_hex)
		}

		elements.push(this.mFS.code)

		if (hostReferenceNumber_hex != '') {
			elements.push(hostReferenceNumber_hex)
		}
		elements.push(this.mFS.code)

		if (edcType_hex != '') {
			elements.push(edcType_hex)
		}
		elements.push(this.mFS.code)

		if (timeout_hex != '') {
			elements.push(timeout_hex)
		}

		elements.push(this.mEtx.code)
		elements.push(base64ToHex(window.btoa(lrc)))

		var final_string = elements.join(" ")
		var final_b64 = hexToBase64(final_string)
		console.log(`LRC: ${ lrc}`)
		console.log(`Base64: ${ final_b64}`)
		var url = `${this.mDestinationIP }?${ final_b64}`
		console.log(`URL: ${ url}`)

		this.HttpCommunication('DoSignature', url, callback, PAX.timeout.DoSignature)
	},
	PushParams(params, type, objectInfo) {
		var empty = 0
		var arr = []
		arr = arr.concat(params)
		for (const keyName in objectInfo) {
			if (objectInfo[keyName] == '' && type != "additionalInformation") {
				arr.push(this.mUS.hex)
				continue
			}

			if (type == "additionalInformation") {
				if (objectInfo[keyName] == '') {
					continue
				}
				empty++
				arr.push(`${keyName}=${ objectInfo[keyName].toString()}`)
			} else {
				empty++
				arr.push(objectInfo[keyName].toString())
			}
			arr.push(this.mUS.hex)
		}
		arr.pop()
		if (empty == 0 && type != "additionalInformation") {
			arr = params
		}
		if (empty == 0 && type == "additionalInformation") {
			arr.push(this.mFS.hex)
		}
		//console.log(params);
		return arr
	},
	AddBase64(elements, type, objectInfo) {
		//console.log(objectInfo);
		var empty = 0
		var arr = []
		arr = arr.concat(elements)
		for (const keyName in objectInfo) {
			if (objectInfo[keyName] == '' && type != "additionalInformation") {
				arr.push(this.mUS.code)
				continue
			}
			if (type == "additionalInformation") {
				if (objectInfo[keyName] == '') {
					continue
				}
				empty++
				arr.push(base64ToHex(window.btoa(`${keyName }=${ objectInfo[keyName].toString()}`)))
			} else {
				empty++
				arr.push(base64ToHex(window.btoa(objectInfo[keyName].toString())))
			}
			arr.push(this.mUS.code)
		}
		arr.pop()
		if (empty == 0 && type != "additionalInformation") {
			arr = elements
		}
		if (empty == 0 && type == "additionalInformation") {
			arr.push(this.mFS.code)
		}
		//console.log(arr);
		return arr
	},
	//DO Credit
	DoCredit(doCreditInfo, callback) {
		var params = [ this.mStx.hex, doCreditInfo.command, this.mFS.hex, doCreditInfo.version ]
		params.push(this.mFS.hex)
		if (doCreditInfo.transactionType != '') {
			params.push(doCreditInfo.transactionType)
		}
		params.push(this.mFS.hex)
		params = this.PushParams(params, "amountInformation", doCreditInfo.amountInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "accountInformation", doCreditInfo.accountInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "traceInformation", doCreditInfo.traceInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "avsInformation", doCreditInfo.avsInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "cashierInformation", doCreditInfo.cashierInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "commercialInformation", doCreditInfo.commercialInformation)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce)

		params.push(this.mFS.hex)
		params = this.PushParams(params, "additionalInformation", doCreditInfo.additionalInformation)

		params.push(this.mEtx.hex)

		var lrc = this.getLRC(params)

		//prepare for base64 encoding.
		var command_hex = base64ToHex(window.btoa(doCreditInfo.command))
		var version_hex = base64ToHex(window.btoa(doCreditInfo.version))
		var transactionType_hex = base64ToHex(window.btoa(doCreditInfo.transactionType))

		//var elements = [this.mStx.code, command_hex, this.mFS.code, version_hex, this.mFS.code, uploadFlag_hex, this.mFS.code, timeout, this.mEtx.code, base64ToHex(window.btoa(lrc))];
		var elements = [ this.mStx.code ]
		elements.push(command_hex)
		elements.push(this.mFS.code)
		elements.push(version_hex)
		elements.push(this.mFS.code)

		if (transactionType_hex != '') {
			elements.push(transactionType_hex)
		}
		elements.push(this.mFS.code)

		elements = this.AddBase64(elements, "amountInformation", doCreditInfo.amountInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "accountInformation", doCreditInfo.accountInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "traceInformation", doCreditInfo.traceInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "avsInformation", doCreditInfo.avsInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "cashierInformation", doCreditInfo.cashierInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "commercialInformation", doCreditInfo.commercialInformation)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce)
		elements.push(this.mFS.code)
		elements = this.AddBase64(elements, "additionalInformation", doCreditInfo.additionalInformation)

		elements.push(this.mEtx.code)
		elements.push(base64ToHex(window.btoa(lrc)))
		console.log("elements")
		console.log(elements)

		var final_string = elements.join(" ")
		var final_b64 = hexToBase64(final_string)
		console.log(`LRC: ${ lrc}`)
		console.log(`Base64: ${ final_b64}`)

		// if(customData != ''){
		// 	final_b64 = hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
		// }

		var url = `${this.mDestinationIP }?${ final_b64}`
		console.log(`URL: ${ url}`)

		this.HttpCommunication('DoCredit', url, callback, PAX.timeout.DoCredit)
	},
	PerformTransaction(transactionInfo, cb) {
		const doCreditInfo = { ...defaultDoCreditInfo }
		const { requireSignature, amount, saleId, referenceNumber } = transactionInfo

		if (requireSignature) {
			doCreditInfo.additionalInformation.SIGN = '1'
		}

		if (amount) {
			doCreditInfo.amountInformation.TransactionAmount = amount.replace(/[^0-9]+/g, '')
		}

		if (saleId) {
			doCreditInfo.traceInformation.InvoiceNumber = saleId
		}

		if (referenceNumber) {
			doCreditInfo.traceInformation.ReferenceNumber = referenceNumber
		}

		this.DoCredit(doCreditInfo, (err, doCreditResponse) => {
			if (err) {
				return cb(err)
			} else if (doCreditResponse[5] !== 'OK') {
				console.log(doCreditResponse)
				return cb(Error(doCreditResponse[5]))
			}

			const parsedResponse = this.parseDoCredit(doCreditResponse)
			console.log(parsedResponse)
			if (parsedResponse.responseMessage === 'OK') {
				const validSignStatuses = [ 'Local Captured', 'Successfully Uploaded' ]

				if (validSignStatuses.includes(parsedResponse.additional.SIGNSTATUS)) {
					this.GetSignature(defaultGetSignatureInfo, (_err, getSigResponse) => {
						cb(null, { ...parsedResponse, signature: getSigResponse[8] })
					})
				} else {
					cb(null, { ...parsedResponse, signature: false })
				}
			} else {
				cb(Error(doCreditResponse[5]))
			}
		})
	},
	buildObjectFromResponseArray(array, list) {
		if (!list) {
			if (Array.isArray(array)) {
				return array.reduce((sum, item) => {
					item = item.split('=')
					return { ...sum, [item[0]]: item[1] }
				}, {})
			}
			return {}
		}
		return list.reduce((sum, item, index) => {
			const val = array[index] ? array[index] : ''
			return { ...sum, [item]: val }
		}, {})
	},
	mapCodeToString(code, map, defaultValue) {
		defaultValue = defaultValue ? defaultValue : code
		const match = map.find(value => value.code === code)

		return match ? match.text : defaultValue
	},
	parseDoCredit(doCreditResponse) {
		console.log(doCreditResponse)

		let status = doCreditResponse[1]
		let command = doCreditResponse[2]
		let version = doCreditResponse[3]
		let responseCode = doCreditResponse[4]
		let responseMessage = doCreditResponse[5]
		let host = this.buildObjectFromResponseArray(doCreditResponse[6], [
			'responseCode',
			'responseMessage',
			'authCode',
			'referenceNumber',
			'traceNumber',
			'batchNumber',
		])
		let transactionType = doCreditResponse[7]
		let amount = this.buildObjectFromResponseArray(doCreditResponse[8], [
			'approveAmount',
			'amountDue',
			'tipAmount',
			'cashBackAmount',
			'merchantFeeSurchargeFee',
			'taxAmount',
			'balance1',
			'balance2',
		])
		let account = this.buildObjectFromResponseArray(doCreditResponse[9], [
			'cardNumberLastFour',
			'entryMode',
			'expireDate',
			'ebtType',
			'voucherNumber',
			'newAccountNumber',
			'cardType',
			'cardHolder',
			'cvdApprovalCode',
			'cvdMessage',
			'cardPresentIndicator',
		])
		let trace = this.buildObjectFromResponseArray(doCreditResponse[10], [
			'transactionNumber',
			'referenceNumber',
			'timeStamp',
		])
		let avs = this.buildObjectFromResponseArray(doCreditResponse[11], [
			'approvalCode',
			'message',
		])
		let commercial = this.buildObjectFromResponseArray(doCreditResponse[12], [
			'poNumber',
			'customerCode',
			'taxExempt',
			'taxExemptId',
			'merchantTaxId',
			'destinationZipCode',
			'productDescription',
		])
		let motoEcommerce = this.buildObjectFromResponseArray(doCreditResponse[13], [
			'motoECommerceMode',
			'transactionType',
			'secureType',
			'orderNumber',
			'installments',
			'currentInstallment',
		])
		let additional = this.buildObjectFromResponseArray(doCreditResponse[14])

		if (additional.SIGNSTATUS) {
			additional.SIGNSTATUS = this.mapCodeToString(additional.SIGNSTATUS, this.signatureStatus, 'N/A')
		}

		if (additional.CVM) {
			additional.CVM = this.mapCodeToString(additional.CVM, this.cvm, 'NO_VERIFICATION')
		}

		if (account.entryMode) {
			account.entryMode = this.mapCodeToString(account.entryMode, this.entryMode, 'Unknown')
		}

		if (account.cardType) {
			account.cardType = this.mapCodeToString(account.cardType, this.cardType, 'Other')
		}

		if (account.cardPresentIndicator) {
			account.cardPresentIndicator = this.mapCodeToString(account.cardPresentIndicator, this.cardPresentIndicator)
		}

		if (trace.timeStamp && trace.timeStamp.length === 14) {
			const year = trace.timeStamp.substring(0, 4)
			const month = trace.timeStamp.substring(4, 6)
			const day = trace.timeStamp.substring(6, 8)
			const hour = trace.timeStamp.substring(8, 10)
			const minute = trace.timeStamp.substring(10, 12)
			const second = trace.timeStamp.substring(12, 14)

			trace.timeStamp = `${ year }-${ month }-${ day } ${ hour }:${ minute }:${ second }`
		}

		if (account.expireDate && account.expireDate.length === 4) {
			const month = account.expireDate.substring(0, 2)
			const year = account.expireDate.substring(2, 4)

			account.expireDate = `${ month }/${ year }`
		} else {
			account.expireDate = null
		}

		if (amount.approveAmount) {
			const amountlength = amount.approveAmount.length

			amount.approveAmount = [
				amount.approveAmount.slice(0, amountlength - 2),
				amount.approveAmount.slice(amountlength - 2, amountlength),
			].join('.')
		}

		let verificationStatus = additional.CVM

		const validSignStatuses = [
			'Local Captured',
			'N/A',
			'Successfully Uploaded',
		]

		if (!validSignStatuses.includes(additional.SIGNSTATUS)
				&& additional.CVM !== 'None Required') {
			verificationStatus = 'Signature Required'
		}

		return {
			status,
			command,
			version,
			responseCode,
			responseMessage,
			host,
			transactionType,
			amount,
			account,
			trace,
			avs,
			commercial,
			motoEcommerce,
			additional,
			verificationStatus,
		}
	},
	signatureStatus: [
		{ code: '1', text: 'Local Captured' },
		{ code: '2', text: 'Aborted' },
		{ code: '3', text: 'Timeout' },
		{ code: '4', text: 'N/A' },
		{ code: '5', text: 'Successfully Uploaded' },
		{ code: '6', text: 'Failed During Uploading' },
	],
	entryMode: [
		{ code: '0', text: 'Manual' },
		{ code: '1', text: 'Swipe' },
		{ code: '2', text: 'Contactless' },
		{ code: '3', text: 'Scanner' },
		{ code: '4', text: 'Chip' },
		{ code: '5', text: 'Swipe' },
	],
	//Cardholder Verification Method
	cvm: [
		{ code: '0', text: 'Signature Required', docString: 'Fail CVM processing' },
		{ code: '1', text: 'PIN Verified', docString: 'Plaintext Offline PIN Verification' },
		{ code: '2', text: 'PIN Verified', docString: 'Online PIN' },
		{ code: '3', text: 'Signature Received', docString: 'Plaintext Offline PIN and Signature' },
		{ code: '4', text: 'PIN Verified', docString: 'Enciphered Offline PIN Verification' },
		{ code: '5', text: 'Signature Received', docString: 'Enciphered Offline PIN Verification and Signature' },
		{ code: '6', text: 'Signature Received', docString: 'Signature' },
		{ code: '7', text: 'None Required', docString: 'No CVM Required.' },
	],
	cardType: [
		{ code: '01', text: 'Visa' },
		{ code: '02', text: 'MasterCard' },
		{ code: '03', text: 'AMEX' },
		{ code: '04', text: 'Discover' },
		{ code: '05', text: 'Diner Club' },
		{ code: '06', text: 'enRoute' },
		{ code: '07', text: 'JCB' },
		{ code: '08', text: 'RevolutionCard' },
		{ code: '09', text: 'VisaFleet' },
		{ code: '10', text: 'MasterCardFleet' },
		{ code: '11', text: 'FleetOne' },
		{ code: '12', text: 'Fleetwide' },
		{ code: '13', text: 'Fuelman' },
		{ code: '14', text: 'Gascard' },
		{ code: '15', text: 'Voyager' },
		{ code: '16', text: 'WrightExpress' },
	],
	cardPresentIndicator: [
		{ code: '0', text: 'Card Present' },
		{ code: '1', text: 'Card Not Present' },
	],
	cardTypeList: [
		{ name: 'Visa', code: 'Visa', icon: 'fa-cc-visa' },
		{ name: 'MasterCard', code: 'MasterCard', icon: 'fa-cc-mastercard' },
		{ name: 'JCB', code: 'JCB', icon: 'fa-cc-jcb' },
		{ name: 'Discover', code: 'Discover', icon: 'fa-cc-discover' },
		{ name: 'American Express', code: 'AMEX', icon: 'fa-cc-amex' },
	],
}
